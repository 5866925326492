import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import styles from "./EmailVerification.module.scss";
import reactionLogo from "assets/images/reactionlogonew.png";
import Button from "components/Button/Button";
import { HorizontalBar } from "components/layouts/HorizontalBar/HorizontalBar";
import FlexRow from "components/layouts/FlexRow/FlexRow";
import Modal from "components/ReactModal/ReactModal";
import PA from "pages/register/PA";


const EmailVerification = () => {
    const [privacy, setPrivacy] = useState(false);
    const location = useLocation();
    const email = location.state?.email;

    console.log("You have arrived to earth 54");

    return (
        <div className={styles.page}>
            <div className={styles.email_container}>
                {/* Logo */}
                <h1 className={styles.logo}>
                    <img src={reactionLogo} />
                </h1>
                <h2 className={styles.title}>Check Your Email</h2>
                {/* Main Content */}
                <div>
                    {email ? (
                        <p className={styles.message}>
                            We have sent you an email to{" "}
                            <a
                                href={`mailto:${email}`}
                                className={styles.email_link}
                            >
                                {email}
                            </a>
                            , please validate to continue creation.
                        </p>
                    ) : (
                        <p className={styles.message}>
                            No email provided. Please check your registration
                            details.
                        </p>
                    )}
                </div>

                <p className={styles.resend_message}>
                    If you do not receive the email after 5 minutes, click
                    “Resend Email” to try again.
                </p>

                {/* Resend Button */}
                <div className={styles.resend_button}>
                    <Button blue>Resend Email</Button>
                </div>

                <HorizontalBar height="3px"></HorizontalBar>

                <div className={styles.footer}>
                    <Button blue width="150px">
                        Sign In
                    </Button>
                    <span className={styles.or_text}>Or</span>
                    <Button blue width="150px">
                        Join Organization
                    </Button>
                </div>

                <p className={styles.footer_link}>
                    Request a demo or learn more at{" "}
                    <a
                        href="https://reactiondata.com"
                        className={styles.footer_anchor}
                    >
                        reactiondata.com
                    </a>
                </p>
            </div>

            <div className={styles.copyrightBar}>
                <div></div>
                <FlexRow
                    style={{
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <Button
                        style={{
                            paddingBottom: ".2em",
                            color: "#0096A1",
                        }}
                        link
                        onClick={() => setPrivacy(true)}
                    >
                        Privacy Policy
                    </Button>
                    <HorizontalBar
                        width={2}
                        height={30}
                        backgroundColor={"black"}
                        rotate
                    />
                    <Button
                        style={{
                            paddingBottom: ".2em",
                            color: "#0096A1",
                        }}
                        link
                        OnClick={() => setTos(true)}
                    >
                        Terms & Service
                    </Button>
                    <HorizontalBar
                        width={2}
                        height={30}
                        backgroundColor={"black"}
                        rotate
                    />
                    Copyright © 2024 Reaction Data, Inc. All rights reserved.{" "}
                </FlexRow>
                <Modal
                    dark
                    modalStyle={{
                        borderRadius: ".5rem",
                        height: "95%",
                    }}
                    show={privacy}
                    onClose={() => setPrivacy(false)}
                >
                    <PA/>
                </Modal>
            </div>
        </div>
    );
};

export default EmailVerification;
