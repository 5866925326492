// External
import React, { useEffect, useRef, useState } from "react";
import styles from "./SigBuilder.module.scss";
import ReactModal from "components/ReactModal/ReactModal.jsx";
import { TextFieldSimple } from "components/inputs";
import { Label } from "components/layouts/Label/Label";
import { TextArea } from "components/inputs/input_fields/TextArea/TextArea";
import Button from "components/Button/Button";
import parse from "html-react-parser";
import {
  useCreateDistConfig,
  useDeleteDistConfig,
  useMakeDefaultSig,
  useTieUserToSig,
  useUntieUserFromSig,
  useUpdateDistConfig,
} from "api/resources/projects/distributions";
import { Loading } from "components/Loading/Loading";
import { OneOrTheOther } from "components/inputs/input_fields/OneOrTheOther/OneOrTheOther";
import EmailEditor from "pages/projects/project/delivery/distributions/EmailBuilder/EmailEditor";
import Content from "pages/projects/project/delivery/distributions/EmailBuilder/Content/Content";
import { useFetchCurrOrgsFields } from "api/resources/organization/organization";
import FlexRow from "components/layouts/FlexRow/FlexRow";
import { useSearchUsersPages } from "api/resources/organization/users";

export const SigBuilder = ({ orig, onClose, refetch, userId }) => {
  const [sig, setSig] = useState(
    orig.id
      ? { ...orig }
      : {
          id: "",
          user: [],
          signature: "",
          encoding: "",
          aliasName: "",
          fromAddress: "",
        }
  );
  const [saving, setSaving] = useState(false);
  const [html, setHtml] = useState(sig.signature ? true : false); // TODO: Switch it back to false. Going to only have builder going forward.
  const [content, setContent] = useState(false);
  const updateDistConfig = useUpdateDistConfig();
  const createDistConfig = useCreateDistConfig();
  const deleteDistConfig = useDeleteDistConfig();
  const fetchCustomFields = useFetchCurrOrgsFields();
  const [tie, setTie] = useState(false);
  const [loading, setLoading] = useState("");
  const makeDefaultSig = useMakeDefaultSig();
  const tieSig = useTieUserToSig();
  const untieUser = useUntieUserFromSig();

  function changeValue(field, value) {
    let copy = { ...sig };
    copy[field] = value;
    setSig(copy);
  }

  // function viewClick(e) {
  //   debugger;
  // }

  // useEffect(() => {
  //   document.addEventListener("click", viewClick, true);
  //   return () => document.removeEventListener("click", viewClick, true);
  // }, []);

  function handleUpdateSignature() {
    setSaving(true);

    if (sig.id) {
      let data = {
        signature: sig?.signature,
        encoding: sig?.encoding,
        aliasName: sig?.aliasName,
        fromAddress: sig?.fromAddress,
      };
      updateDistConfig.mutate(
        {
          data: data,
          id: orig.id,
        },
        {
          onSuccess: () => {
            if (refetch) {
              refetch();
            }
            setSaving(false);
          },
        }
      );
    } else {
      if (sig.aliasName && sig.fromAddress) {
        let data = {
          signature: sig?.signature,
          aliasName: sig?.aliasName,
          fromAddress: sig?.fromAddress,
          type: "signature",
          encoding: sig?.encoding,
          userId: userId ? userId : "",
        };
        createDistConfig.mutate(
          {
            data: data,
          },
          {
            onSuccess: (data) => {
              if (data.sig) {
                let copy = { ...sig, ...data.sig };
                setSig(copy);
              }
              if (refetch) {
                refetch();
              }
              setSaving(false);
            },
          }
        );
      } else {
        let missing = "";
        if (!sig.aliasName) {
          missing += "an 'alias name'";
        }
        if (!sig.fromAddress) {
          if (missing) missing += " and ";
          missing += "a 'from address'";
        }

        window.alert(`Missing ${missing}.`);
        setSaving(false);
      }
    }
  }

  function onDelete() {
    if (
      window.confirm(
        "Are you sure you want to delete this signature? It will NOT affect emails with this signature already in it."
      )
    ) {
      deleteDistConfig.mutate(
        { id: sig.id },
        {
          onSuccess: (data) => {
            if (data.deleted) {
              if (refetch) {
                refetch();
              }
              onClose();
            }
          },
        }
      );
    }
  }

  function onToggleHTML() {
    let copy = { ...sig };
    if (html) {
      copy.signature = "";
    } else {
      copy.encoding = "";
    }
    setHtml(!html);
    setSig(copy);
  }

  function saveEncoding(text, code, html) {
    changeValue("encoding", code);
  }

  function onUntie(user, i) {
    untieUser.mutate(
      {
        sigId: sig.id,
        userId: user.id,
      },
      {
        onSuccess: (data) => {
          console.log("untied");

          let copy = [...sig.user];
          copy.splice(i, 1);
          changeValue("user", copy);
          setLoading("");
        },
        onError: (data) => {
          console.log("error making signature the default");
          setLoading("");
        },
      }
    );
    setLoading(user.id);
  }

  function onTieUser(user) {
    let copy = [...sig.user];
    copy.push(user);
    changeValue("user", copy);

    tieSig.mutate(
      {
        sigId: sig.id,
        userId: user.id,
      },
      {
        onSuccess: (data) => {
          console.log("tied");

          setLoading("");
        },
        onError: (data) => {
          console.log("error making signature the default");
          setLoading("");
        },
      }
    );
    setLoading(user.id);
    setTie(false);
  }

  function onMakeDefault(user, i) {
    makeDefaultSig.mutate(
      {
        sigId: sig.id,
        userId: user.id,
      },
      {
        onSuccess: (data) => {
          console.log("made default");

          let copy = [...sig.user];
          copy[i].activeDistConfigId = sig.id;
          changeValue("user", copy);
          setLoading("");

          if (refetch) {
            refetch();
          }
        },
        onError: (data) => {
          console.log("error making signature the default");
          setLoading("");
        },
      }
    );
    setLoading(user.id);
  }

  return (
    <>
      <ReactModal
        show={sig}
        onClose={onClose}
        modalStyle={{
          borderRadius: "1em",
          padding: "1em",
          gap: "1em",
          height: "96%",
          width: "96%",
          maxWidth: "1200px",
        }}
        dark
      >
        <>
          <FlexRow>
            <TextFieldSimple
              value={sig?.aliasName}
              onChange={(val) => changeValue("aliasName", val)}
              label="Alias Name"
              placeholder="Type Alias..."
            />
            <TextFieldSimple
              value={sig?.fromAddress}
              onChange={(val) => changeValue("fromAddress", val)}
              label="From Address (Email)"
              placeholder="Type Email..."
            />
          </FlexRow>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              height: "100%",
              width: "100%",
              flexGrow: 1,
            }}
          >
            <div className={styles.sigLabel}>
              Signature
              <OneOrTheOther
                checked={html}
                onCheck={onToggleHTML}
                left="Builder"
                right="HTML"
                activeColor="#738c91"
              />
            </div>

            {html && (
              <div className={styles.html}>
                <TextArea
                  value={sig?.signature}
                  onSave={(val) => changeValue("signature", val)}
                  placeholder="Paste HTML Signature..."
                  className={styles.thearea}
                />
                <div className={styles.htmlPreview}>
                  {parse(`<div>${sig?.signature}</div>`)}
                </div>
              </div>
            )}

            {!html && (
              <>
                {fetchCustomFields.isLoading && <Loading />}
                {fetchCustomFields.isSuccess && (
                  <div className={styles.sigBuilderContainer}>
                    <div className={styles.content}>
                      <Content content={content} setContent={setContent} />
                    </div>

                    <EmailEditor
                      encoding={sig.encoding}
                      onSave={saveEncoding}
                      editable
                      incoming={content}
                      clearIncoming={() => setContent("")}
                      customFields={fetchCustomFields.data.getCurrOrgFields}
                    />
                  </div>
                )}
                {/* <div style={{ padding: "1em" }}>
                  {parse(`<div>${sig?.signature}</div>`)}
                </div> */}
              </>
            )}
          </div>

          <FlexRow align={"flex-start"} justify={"space-between"}>
            {sig.id && (
              <div className={styles.manageUsers}>
                <div className={styles.tiedTitle}>Tied Users</div>
                {/* <div className={styles.defaultTip}>*Default</div> */}
                <div className={styles.userList}>
                  {sig.user.map((user, i) => (
                    <div className={styles.tiedUser} key={user.id}>
                      <div className={styles.name}>
                        {`${user.firstName} ${user.lastName}${
                          user.activeDistConfigId === sig.id ? "*" : ""
                        }`}
                      </div>
                      <div
                        className={styles.x}
                        onClick={() => onUntie(user, i)}
                      >
                        <i className="bi-x"></i>
                      </div>

                      {user.activeDistConfigId !== sig.id && (
                        <>
                          <span className={styles.pipe}>|</span>
                          <div
                            className={styles.makeDefault}
                            onClick={() => onMakeDefault(user, i)}
                          >
                            {" "}
                            make default
                          </div>
                        </>
                      )}
                      {loading === user.id && (
                        <Loading height={25} width={25} />
                      )}
                    </div>
                  ))}

                  {!tie && (
                    <div
                      className={styles.addUser}
                      onClick={() => setTie(true)}
                    >
                      + User
                    </div>
                  )}

                  {tie && (
                    <TieUsers
                      onClose={() => setTie(false)}
                      onPick={onTieUser}
                      alreadyIn={sig.user.map((u) => u.id)}
                    />
                  )}
                </div>
              </div>
            )}
            <div className={styles.buttons}>
              {/* {isDefault && !orgLevel && (
                <div className={styles.thisIsDefault}>Default signature*</div>
              )} */}
              <Button height={2.4} shadow seafoam onClick={onClose}>
                Cancel
              </Button>
              {sig.id && (
                <Button red height={2.4} shadow onClick={onDelete}>
                  <div className={styles.buttonSpace}>
                    Delete <i className="bi bi-trash" />
                  </div>
                </Button>
              )}

              <Button
                height={2.4}
                shadow
                onClick={saving ? undefined : () => handleUpdateSignature()}
              >
                <div className={styles.buttonSpace}>
                  {saving ? (
                    <>
                      Saving <Loading height={20} width={20}></Loading>
                    </>
                  ) : (
                    <>Save Changes</>
                  )}
                </div>
              </Button>
            </div>
          </FlexRow>
        </>
      </ReactModal>
    </>
  );
};

function TieUsers({ onClose, onPick, alreadyIn }) {
  const [search, setSearch] = useState("");
  const ref = useRef(null);

  const pageSkip = 0;
  const perPage = 20;
  const sort = {
    descend: true,
    item: "firstName",
  };

  const getUsers = useSearchUsersPages(search, pageSkip, perPage, sort);

  function onClickOut(e) {
    if (!ref.current || !ref.current.contains(e.target)) onClose();
  }

  useEffect(() => {
    document.addEventListener("click", onClickOut, true);
    return () => document.removeEventListener("click", onClickOut, true);
  }, []);

  function getList() {
    return getUsers.data.users.filter((u) => !alreadyIn.includes(u.id));
  }

  return (
    <>
      <div className={styles.searchUsers} ref={ref}>
        <div className={styles.searchResults}>
          {getUsers.isLoading && <Loading />}
          {getUsers.isSuccess && (
            <>
              {getList().length == 0 && (
                <div className={styles.noData}>No data</div>
              )}
              {getList().map((u) => (
                <div
                  className={styles.option}
                  onClick={() => onPick(u)}
                  key={u.id}
                >{`${u.firstName} ${u.lastName}`}</div>
              ))}
            </>
          )}
        </div>
        <TextFieldSimple
          value={search}
          onChange={setSearch}
          placeholder={"Search Users"}
          autoFocus
        />
      </div>
    </>
  );
}
