import { useState } from "react";
import { UGSidebar } from "components/sidebars/UGSidebar/UGSidebar";
import { TextFieldSimple } from "components/inputs";
import styles from "./Roles.module.scss";
import {
  useCreateRoleGql,
  useDeleteRoleGql,
  useFetchRolesGql,
  useUpdateRoleGql,
} from "api/resources/organization/roles";
import PermissionsCategories from "./permissions";
import Button from "components/Button/Button";
import { useParams } from "react-router-dom";
import SettingToggle from "./SettingToggle";
import { HorizontalBar } from "components/layouts/HorizontalBar/HorizontalBar";
import { Loading } from "components/Loading/Loading";
import FlexRow from "components/layouts/FlexRow/FlexRow";
import FlexCol from "components/layouts/FlexColumn/FlexCol";
import { Footer } from "App";

export const Roles = ({ roles }) => {
  const { id } = useParams();

  const fetchRolesQuery = useFetchRolesGql(id);
  const createRole = useCreateRoleGql();
  const update = useUpdateRoleGql();
  const deleteRole = useDeleteRoleGql();
  const [currRole, setCurrRole] = useState(null);
  const [saving, setSaving] = useState(false);
  const [saved, setSaved] = useState(false);
  const [error, setError] = useState(false);
  const [creating, setCreating] = useState(false);
  const [active, setActive] = useState(0);

  const blankRole = {
    name: "",
    description: "",
    isAdmin: false,

    //Contacts
    canViewContacts: true,
    canEditContacts: true,
    canSeeContactInfo: true,
    canViewOrgAudiences: true,
    canCreateEditOrgAudiences: true,

    //Surveys
    canCreateEditOrgSurveys: true,
    canViewOrgSurveys: true,

    //Analyze
    canViewOrgReports: true,
    canCreateEditOrgReports: true,
    canShareOrgReports: true,

    //Dashboard
    canEditPersonalDashboard: true,
    canViewPersonalDashboard: true,
    canViewOrgDashboard: true,
  };

  const handleActiveUpdate = (id) => {
    setActive(id);
    for (let i = 0; i < fetchRolesQuery.data.rolesByOrgId.length; i++) {
      if (fetchRolesQuery.data.rolesByOrgId[i].id === id) {
        setCurrRole(fetchRolesQuery.data.rolesByOrgId[i]);
      }
    }
    setSaved(false);
    if (error) setError(false);
  };

  const createNewRole = () => {
    createRole.mutate(
      {
        data: blankRole,
      },
      {
        onSuccess: (data) => {
          setActive(data.role.id);
          fetchRolesQuery.refetch();
          setCurrRole({ ...data.role });
          setSaving(false);
          setSaved(false);
          if (error) setError(false);
          setCreating(false);
        },
        onError: (data) => {
          setError(true);
          setCreating(false);
        },
      }
    );

    setCreating(true);
  };

  const updateRole = (role) => {
    setCurrRole(role);

    let data = { ...role };
    delete data.id;
    delete data.createdAt;

    update.mutate(
      {
        updateRoleId: currRole.id,
        data: data,
      },
      {
        onSuccess: (data) => {
          setCurrRole({ ...data.role });
          setSaving(false);
          setSaved(true);
        },
        onError: (data) => {
          setError(true);
          setSaving(false);
          setSaved(false);
        },
      }
    );

    setSaving(true);
    if (error) setError(false);
  };

  const onDelete = () => {
    deleteRole.mutate(
      {
        deleteRoleId: currRole.id,
      },
      {
        onSuccess: (data) => {
          console.log("Role Deleted", data);
          setCurrRole(null);
        },
        onError: (data) => {
          setError(true);
        },
      }
    );
  };

  function changePermission(permission, e) {
    if (!currRole.isAdmin) {
      let role = { ...currRole };
      role[permission] = e.target.checked;
      updateRole(role);
    } else {
      alert("Admin Role Cannot be changed");
    }
  }

  const handleDelete = () => {
    if (
      window.confirm(
        "Are you sure you want to delete the " + currRole.name + " role?"
      )
    ) {
      onDelete();
    }
  };

  const updateRoleField = (field, val) => {
    let role = { ...currRole };
    role[field] = val;
    updateRole(role);
  };

  return (
    <div className={styles.page}>
      <div className={styles.top}>
        <div className={styles.header2} style={{ margin: "0", color: "black" }}>
          User Roles
        </div>
        <div className={styles.text_3}>
          These are the permission roles of your organization. You can decide
          what access each role provides and then assign each role to a user.
        </div>
      </div>
      {fetchRolesQuery.isLoading && (
        <div style={{ height: "100%" }}>
          <Loading></Loading>
        </div>
      )}
      {fetchRolesQuery.isError && <p>Error</p>}
      {fetchRolesQuery.isSuccess && (
        <>
          <div className={styles.splitContainer}>
            <UGSidebar
              menuItems={
                fetchRolesQuery.data.rolesByOrgId
                  ? fetchRolesQuery.data.rolesByOrgId
                  : []
              }
              active={active}
              updateActive={handleActiveUpdate}
              onNewClick={createNewRole}
              canEdit={roles.isAdmin}
              creating={creating}
            />
            {currRole && (
              <div className={styles.pageContent}>
                {!currRole?.isAdmin && (
                  <div className={styles.textBox}>
                    <div style={{ width: "400px" }}>
                      <TextFieldSimple
                        label="Name"
                        placeholder="Role Name"
                        value={currRole.name}
                        onBlur={(val) => updateRoleField("name", val)}
                        onEnter={(val) => updateRoleField("name", val)}
                      ></TextFieldSimple>

                      <TextFieldSimple
                        label="Description"
                        placeholder="Description"
                        value={currRole.description}
                        onBlur={(val) => updateRoleField("description", val)}
                        onEnter={(val) => updateRoleField("description", val)}
                      ></TextFieldSimple>
                    </div>

                    {(saving || saved || error) && (
                      <FlexRow
                        className={`${styles.saving} ${
                          error ? styles.error : ""
                        }`}
                        gap={10}
                        fit
                        style={{ height: "30px" }}
                      >
                        {saving ? (
                          <>
                            Saving <Loading height={30} width={30}></Loading>
                          </>
                        ) : error ? (
                          <>
                            Error
                            <i className="bi-slash-circle"></i>
                          </>
                        ) : (
                          <>
                            Saved <i className="bi-check2"></i>
                          </>
                        )}
                      </FlexRow>
                    )}
                  </div>
                )}
                {currRole?.isAdmin && (
                  <div className={styles.textBox}>
                    <FlexCol>
                      <div
                        className={styles.header_5}
                        style={{ margin: "0", fontWeight: "600" }}
                      >
                        Admin (Owner)
                      </div>
                      <FlexRow gap={".5rem"} align={"flex-start"}>
                        <div
                          className={styles.text_3}
                          style={{ margin: "0", maxWidth: "700px" }}
                        >
                          Admins can edit and view organization settings and
                          users. They can also edit user roles and invite new
                          users to join their organization. All functionality is
                          available to Admins.
                        </div>
                      </FlexRow>
                    </FlexCol>
                  </div>
                )}
                <div className={styles.accordianContainer}>
                  {PermissionsCategories.map((set) => (
                    <>
                      {((currRole?.isAdmin &&
                        set?.org_permissions.length > 0) ||
                        set?.permissions.length > 0) && (
                        <div className={styles.setting}>
                          <div
                            className={styles.header_5}
                            style={{ margin: "0" }}
                          >
                            {set.set_name}
                          </div>
                          <HorizontalBar height={2}></HorizontalBar>
                          <div className={styles.allPermissions}>
                            {set?.permissions.length > 0 && (
                              <div className={styles.permissions}>
                                {set.permissions.map((permission) => (
                                  <SettingToggle
                                    text={permission.label}
                                    key={permission.db_field}
                                    checked={currRole[permission.db_field]}
                                    onCheck={(e) =>
                                      changePermission(permission.db_field, e)
                                    }
                                  />
                                ))}
                              </div>
                            )}
                            {currRole?.isAdmin &&
                              set?.org_permissions.length > 0 && (
                                <div className={styles.permissions}>
                                  {set.org_permissions.map((permission) => (
                                    <SettingToggle
                                      text={permission.label}
                                      key={permission.db_field}
                                      checked={true}
                                      onCheck={(e) =>
                                        changePermission(permission.db_field, e)
                                      }
                                    />
                                  ))}
                                </div>
                              )}
                          </div>
                        </div>
                      )}
                    </>
                  ))}
                </div>

                {!currRole.isAdmin && (
                  <FlexRow justify={"flex-end"} style={{ paddingTop: "40px" }}>
                    <Button
                      onClick={handleDelete}
                      shadow
                      red
                      width={"fit-content"}
                      style={{ gap: ".5rem" }}
                    >
                      <i className={"bi-trash"}></i> Delete Role
                    </Button>
                  </FlexRow>
                )}
              </div>
            )}
            {!currRole && (
              <div className={styles.noRoleSelected}>No Role Selected</div>
            )}
          </div>
        </>
      )}
      <Footer></Footer>
    </div>
  );
};
