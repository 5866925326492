import React, { useEffect, useState, useRef } from "react";

import styles from "./Tasks.module.scss";
import {
  useDeleteNotification,
  useSearchTasks,
  useCancelTask,
  useUpdateTask,
} from "api/resources/account/notification";
import { Loading } from "components/Loading/Loading";
import { NavTextField } from "components/inputs/input_fields/NavTextField/NavTextField";
import { Player } from "@lottiefiles/react-lottie-player";
import { useNavigate } from "react-router-dom";
import Button from "components/Button/Button";
import Icon from "components/Icon/Icon";

const days = {
  0: "Sun",
  1: "Mon",
  2: "Tue",
  3: "Wed",
  4: "Thu",
  5: "Fri",
  6: "Sat",
};

const months = {
  0: "Jan",
  1: "Feb",
  2: "Mar",
  3: "Apr",
  4: "May",
  5: "Jun",
  6: "Jul",
  7: "Aug",
  8: "Sep",
  9: "Oct",
  10: "Nov",
  11: "Dec",
};

function getTime(date) {
  date = new Date(date);
  let hr = date.getHours();
  let ext = "am";
  if (hr > 12) {
    hr -= 12;
    ext = "pm";
  }
  let min = date.getMinutes();
  if (min < 10) {
    min = "0" + min;
  }
  let day = date.getDay();
  day = days[day];
  let month = date.getMonth();
  month = months[month];
  let dayofmonth = date.getDate();

  return (
    day + ", " + month + " " + dayofmonth + " at " + hr + ":" + min + " " + ext
  );
}

export function Tasks({ finished }) {
  const [active, setActive] = useState(0);
  const [notifs, setNotifs] = useState(false);
  const [selected, setSelected] = useState("");
  const [search, setSearch] = useState("");
  const [perPage, setPerpage] = useState(50);
  const [pageSkip, setPageSkip] = useState(0);
  const [sort, setSort] = useState({
    item: "createdAt",
    descend: true,
  });

  const deleteNotification = useDeleteNotification();
  const fetchTasks = useSearchTasks(
    sort,
    pageSkip,
    perPage,
    search,
    finished ? true : false,
    true
  );

  useEffect(() => {
    fetchTasks.refetch();
    setNotifs(false);
  }, [active, perPage, search, finished]);

  function trimDate(date) {
    let d = new Date(date);
    let niceString = d.toDateString();
    let month = niceString.substring(4, 7);
    let day = niceString.substring(8, 10);
    //eslint-disable-next-line no-unused-expressions
    day.charAt(0) === "0" ? (day = day.charAt(1)) : null;
    let year = niceString.substring(11);
    return month + " " + day + ", " + year;
  }

  function hideDeleteNotification(id) {
    deleteNotification.mutate(
      {
        deleteNotificationId: id,
      },
      {
        onSuccess: () => {
          fetchTasks.refetch();
          setSelected("");
        },
      }
    );
  }

  function getDayOfWeek(date) {
    date = new Date(date);
    let day = date.getDay();
    day = days[day];
    return day;
  }

  function sortNotifications() {
    let notifications = {
      today: [],
      week: [],
      month: [],
      older: [],
    };

    const today = new Date();
    const timezoneOffset = today.getTimezoneOffset();
    today.setMinutes(today.getMinutes() + timezoneOffset);

    const yesterday = new Date(today);
    yesterday.setDate(yesterday.getDate() - 1);

    const lastWeek = new Date(today);
    lastWeek.setDate(lastWeek.getDate() - 7);

    const lastMonth = new Date(today);
    lastMonth.setMonth(lastMonth.getMonth() - 1);

    for (let notif of fetchTasks.data.tasks) {
      let date = new Date(notif.createdAt);
      date.setMinutes(date.getMinutes() + timezoneOffset);
      if (
        date.getDate() === today.getDate() &&
        date.getMonth() === today.getMonth() &&
        date.getFullYear() === today.getFullYear()
      ) {
        notif.sent = getTime(notif.createdAt);
        notifications.today.push(notif);
      } else if (
        date.getDate() === yesterday.getDate() &&
        date.getMonth() === yesterday.getMonth() &&
        date.getFullYear() === yesterday.getFullYear()
      ) {
        notif.sent = "Yesterday";
        notifications.week.push(notif);
      } else if (date > lastWeek) {
        notif.sent = getDayOfWeek(notif.createdAt);
        notifications.week.push(notif);
      } else if (date > lastMonth) {
        notif.sent = trimDate(notif.createdAt);
        notifications.month.push(notif);
      } else {
        notif.sent = trimDate(notif.createdAt);
        notifications.older.push(notif);
      }
    }

    return notifications;
  }

  function getNotifications() {
    if (notifs) {
      return notifs;
    }
    let frames = [];
    let frameSettings = {
      today: true,
      withinWeek: true,
      withinMonth: true,
      monthPlus: true,
    };

    let all = sortNotifications();

    let bucket = [];
    if (frameSettings.today) {
      let today = { title: "Today", notifications: all.today };
      frames.push(today);
    } else {
      if (!frameSettings.today) {
        bucket = [...all.today];
      }
    }

    if (frameSettings.withinWeek) {
      let week = {
        title: "Last 7 days",
        notifications: [...bucket, ...all.week],
      };
      frames.push(week);
      bucket = [];
    } else {
      bucket = [...bucket, ...all.week];
    }

    if (frameSettings.withinMonth) {
      let month = {
        title: "This month",
        notifications: [...bucket, ...all.month],
      };
      frames.push(month);
      bucket = [];
    } else {
      bucket = [...bucket, ...all.month];
    }

    if (frameSettings.monthPlus) {
      let older = {
        title: "Month +",
        notifications: [...bucket, ...all.older],
      };
      frames.push(older);
    }

    setNotifs(frames);
    return frames;
  }

  const navigate = useNavigate();

  return (
    <div className={styles.page}>
      <div className={styles.navContainer}>
        <div className={styles.pageHeader}>
          {finished ? "Finished Jobs" : "Running Jobs"}
        </div>
      </div>

      {/* {fetchTasks.isError && <p>{fetchTasks.error}</p>} */}

      <>
        <div className={styles.notifsPage}>
          <div className={styles.section}>
            <div className={styles.header}>
              You are also notified once a job is completed.
            </div>
            <div className={styles.description}>
              To see details of completed jobs view click the three dots on the
              jobs widget.
            </div>
            <Button
              link
              onClick={() => {
                navigate("/notifications");
                localStorage.setItem("activepage", 5);
              }}
            >
              View Notifications
            </Button>
          </div>
          <div className={styles.pageNotifContainer}>
            <NavTextField
              value={search}
              setValue={setSearch}
              placeholder="Search Tasks..."
              className={styles.search}
              shadow
            ></NavTextField>
            {(fetchTasks.isRefetching || fetchTasks.isLoading) && (
              <div className={styles.pageNotifContainer}>
                <Loading></Loading>
              </div>
            )}
            {!fetchTasks.isRefetching &&
              fetchTasks.isSuccess &&
              getNotifications().map((frame, i) => (
                <React.Fragment key={i}>
                  {frame?.notifications?.length != 0 && (
                    <>
                      <div className={styles.wordDivider}>{frame.title}</div>
                      <div className={styles.notifBlock}>
                        {frame?.notifications?.map((notification) => (
                          <Notification
                            key={notification.id}
                            notification={notification}
                            read={notification.read}
                            important={notification.important}
                            updateFunc={function (unread) {
                              if (unread) {
                                setUnreadCount((count) => count + 1);
                              } else {
                                setUnreadCount((count) => count - 1);
                              }
                            }}
                            // currCount={
                            //   fetchUnreadNotification.data
                            //     .getUnreadNotificationCount
                            // }
                            click={function (notification) {
                              setSelected(notification);
                            }}
                            deleteNotification={hideDeleteNotification}
                            getTime={getTime}
                          ></Notification>
                        ))}
                        {frame?.notifications?.length === 0 && (
                          <div className={styles.none}>None</div>
                        )}
                      </div>
                    </>
                  )}
                </React.Fragment>
              ))}
            {fetchTasks.isSuccess && fetchTasks.data?.tasks?.length === 0 && (
              <div className={styles.none}>No tasks running</div>
            )}
          </div>
          {!finished && (
            <div className={styles.section}>
              Accidentally started a job? Click the three dots to view the
              details of the job or cancel the job from there.
            </div>
          )}
          {finished && (
            <div className={styles.section}>
              Click the three dots to view the details of the task. All finished
              jobs will dissapear after 30 days. The tasks the jobs completed
              will not be changed after 30 days.
            </div>
          )}
        </div>
      </>
    </div>
  );
}

const Notification = ({ notification, getTime }) => {
  const [options, setOptions] = useState(false);
  function getType(notif) {
    if (notif.type === "Import") {
      return require("assets/animations/uploading.json");
    } else if (notif.type === "Scheduler") {
      return require("assets/animations/Calendar.json");
    } else if (notif.type === "Results") {
      return require("assets/animations/ChartLoader.json");
    } else if (notif.type === "Bucketing") {
      return require("assets/animations/Gears.json");
    } else {
      return require("assets/animations/uploading.json");
    }
  }

  function getStyle(notif) {
    if (notif.type === "Import") {
      return {
        height: "60px",
        width: "60px",
      };
    } else if (notif.type === "Scheduler") {
      return {
        height: "30px",
        width: "30px",
      };
    } else if (notif.type === "Results") {
      return {
        height: "50px",
        width: "50px",
      };
    } else {
      return {
        height: "60px",
        width: "60px",
      };
    }
  }

  const ref2 = useRef(null);

  function clickOutside(e) {
    if (ref2.current && !ref2.current?.contains(e.target)) {
      setOptions(false);
    }
  }

  useEffect(() => {
    document.addEventListener("click", clickOutside, true);
    return () => {
      document.removeEventListener("click", clickOutside, true);
    };
  }, []);

  const navigate = useNavigate();
  const cancelTask = useCancelTask();

  function handleClick(notif) {
    if (notif.type === "Import" && notif?.itemId) {
      navigate(`/previous-imports/${notif?.itemId2}`);
    } else if (notif.type === "Scheduler" && notif?.itemId2) {
      navigate(`/project/${notif?.itemId}/delivery/messages`);
    } else if (notif.type === "Results" && notif?.itemId && notif?.itemId2) {
      navigate(`/project/${notif?.itemId2}/surveyresults/${notif?.itemId}`);
    } else {
      navigate(`/previous-imports/${notif?.itemId2}`);
    }
  }

  function handleCancel(notif) {
    cancelTask.mutate({
      id: notif?.id,
    });
  }

  const updateTask = useUpdateTask();
  function hideTask(id) {
    updateTask.mutate({
      data: {
        hide: false,
      },
      id: id,
    });
  }

  return (
    <div
      className={styles.notificationContainer}
      // onClick={() => onNotifClick(notification.id)}
      style={{ backgroundColor: options ? "#E9E9E9" : "" }}
    >
      <div className={styles.player}>
        {notification.progress != 100 && (
          <Player
            autoplay
            loop
            onEvent={(e) => {
              // if (e === "play") {
              //   setTimeout(() => {
              //     player1?.current?.pause();
              //   }, 2000);
              // }
            }}
            // ref={player1}
            src={getType(notification)}
            style={getStyle(notification)}
          />
        )}
        {notification.progress === 100 && <Icon iconName="check" style={{fontSize: "2rem"}} green ></Icon>}
      </div>
      <div className={styles.notification}>
        <div
          className={styles.notifBody}
          style={{ fontSize: ".9em", fontWeight: "500" }}
        >
          {notification.body}
        </div>
        <div className={styles.notifBody}>
          <div>Progress:</div> {notification?.progress}%
        </div>
        <div className={styles.notifBody}>
          <div>Started:</div> {getTime(notification.createdAt)}
        </div>
        <div
          className={styles.navContainer}
          style={{ minHeight: "auto", padding: "0px", alignItems: "center" }}
        ></div>
      </div>

      <i
        className={`bi-three-dots-vertical ${styles.threeDots}`}
        onClick={(e) => {
          e.stopPropagation();
          setOptions(true);
        }}
      ></i>

      {options && (
        <div className={styles.settingsBox} ref={ref2}>
          <div
            className={styles.menuItem}
            onClick={(e) => {
              e.stopPropagation();
              handleClick(notification);
            }}
          >
            <i
              className="bi-layout-text-sidebar-reverse"
              style={{ color: "#A7D5C5" }}
            ></i>{" "}
            View Details
          </div>
          {notification.hide && (
            <div
              className={styles.menuItem}
              onClick={(e) => {
                e.stopPropagation();
                hideTask(notification?.id);
              }}
            >
              <i className="bi-eye" style={{ color: "#A7D5C5" }}></i> Unhide
            </div>
          )}

          <div
            className={styles.menuItem}
            onClick={(e) => {
              e.stopPropagation();
              handleCancel(notification);
            }}
          >
            <i className="bi-trash" style={{ color: "#FF8878" }}></i> Cancel
          </div>
        </div>
      )}
    </div>
  );
};
