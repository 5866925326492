import React, { useEffect, useRef, useState } from "react";
import ReactModal from "components/ReactModal/ReactModal.jsx";
import styles from "./ContactProfile.module.scss";
import Button from "components/Button/Button";
import {
  useDeleteContactGql,
  useFetchContactOnly,
  useUpdateContactGql,
} from "api/resources/contacts/contacts";
import {
  useFetchAnswersByAContact,
  useUpdateAnswer,
} from "api/resources/projects/answers";
import { Loading } from "components/Loading/Loading";
import { SearchSurvey } from "pages/results/Charts/Settings/AccordianItems/SurveyData/QData";
import { useFetchRole } from "api/resources/organization/roles";
import { ErrorPage } from "pages";
import {
  useAddContactToAudiences,
  useFetchAudiencesForAContact,
  useFetchAudiencesSimple,
  useRemoveContactFromAudience,
} from "api/resources/contacts/audiences";
import { Label } from "components/layouts/Label/Label";
import { TextFieldSimple } from "components/inputs";
import { trimDate } from "assets/functions/DateFunctions";
import { randomColor } from "components/tables/EditableTable/utils";
import Icon from "components/Icon/Icon";
import FlexRow from "components/layouts/FlexRow/FlexRow";
import Status from "components/Status/Status";
import StatusItem from "components/StatusItem/StatusItem";
import { GetContactLayout } from "./ContactLayout";

export function GetContact({ contact, editable, onClose }) {
  const fetchContact = useFetchContactOnly(contact?.id);

  return (
    <>
      {fetchContact.isLoading && <Loading></Loading>}
      {fetchContact.isSuccess && (
        <ContactProfile
          contact={fetchContact?.data?.contact}
          editable={editable}
          onClose={onClose}
        ></ContactProfile>
      )}
    </>
  );
}

/**
 * Displays a modal with the person's information and picture available to be edited
 * @param {string} title title of this popout
 * @param {Function} setShow will close the modal
 * @param {object} person the person's object
 * @param {Function} update function to hit when user clicks 'save', passes back the modified person object
 * @param {boolean} contact is this to be used for the Contact Details ?
 * @param {boolean} user is this to be used for the User Details ?
 * @returns
 */
export default function ContactProfile({ contact, editable, onClose, roles }) {
  const [currContact, setContact] = useState(contact);
  const [seePastAnswers, setSeePastAnswers] = useState(true);
  const [showUnsub, setShowUnsub] = useState(false);
  const [showDeleteTooltip, setShowDeleteTooltip] = useState(false);
  const [active, setActive] = useState(0);
  const [potentialProjects, setPotentialProjects] = useState(
    currContact?.unsubscribedSurveys &&
      typeof currContact.unsubscribedSurveys === "string"
      ? JSON.parse(currContact.unsubscribedSurveys)
      : currContact?.unsubscribedSurveys
      ? currContact?.unsubscribedSurveys
      : []
  );
  const [editName, setEditName] = useState(false);

  const userRoles = useFetchRole();
  const fetchAnswers = useFetchAnswersByAContact(currContact.id);

  const updateContact = useUpdateContactGql();
  const deletePerson = useDeleteContactGql();

  const deleteContact = () => {
    if (
      editable &&
      window.confirm("Are you sure you want to delete this contact?")
    ) {
      deletePerson.mutate(
        {
          deleteContactId: currContact.id,
        },
        {
          onSuccess: (data) => {
            // console.log(data);
            onClose();
            location.reload();
          },
        }
      );
    }
  };

  const unsubscribeContact = (status) => {
    if (
      editable &&
      window.confirm(
        `Are you sure you want to ${
          status ? "Unsubscribe" : "Re-subscribe"
        } this contact?`
      )
    ) {
      updateContact.mutate(
        {
          id: currContact.id,
          data: {
            unsubscribed: status,
            unsubscribedAt: new Date(),
          },
        },
        {
          onSuccess: (data) => {
            setContact(data?.updateContact);
            console.log(data);
          },
        }
      );
    }
  };

  const unsubscribeSurvey = () => {
    if (
      editable &&
      window.confirm(`Are you sure you want to save these changes?`)
    ) {
      updateContact.mutate(
        {
          id: currContact.id,
          data: {
            unsubscribedSurveys: JSON.stringify(potentialProjects),
          },
        },
        {
          onSuccess: (data) => {},
        }
      );
    }
  };

  // console.log(role);

  const saveChanges = (data) => {
    if (editable) {
      updateContact.mutate(
        {
          data: data,
          id: currContact.id,
        },
        {
          onSuccess: (data) => {
            setEditName(false);
          },
        }
      );
    }
  };

  function addSurvey(id) {
    let surveys = [...potentialProjects];
    surveys.push(id);

    setPotentialProjects(surveys);
  }

  function removeSurvey(id) {
    let surveys = [...potentialProjects];
    let index = surveys.findIndex((s) => s === id);
    surveys.splice(index, 1);

    setPotentialProjects(surveys);
  }

  const [add, setAdd] = useState(false);
  const [color, setColor] = useState(
    currContact?.color ? currContact?.color : randomColor()
  );
  const tabBarItems = [
    {
      id: 0,
      name: "Information",
      icon: "house",
    },
    // {
    //   id: 7,
    //   name: "Persona",
    //   icon: "person",
    // },
    // {
    //   id: 1,
    //   name: "Activity",
    //   icon: "graph-up",
    // },
    {
      id: 2,
      name: `Survey Answers`,
      icon: "list-check",
    },
    {
      id: 3,
      name: "Audiences ",
      icon: "people",
    },
    // {
    //   id: 4,
    //   name: `Emails`,
    //   icon: "envelope-arrow-down",
    // },
    // {
    //   id: 5,
    //   name: "Messages",
    //   icon: "chat-left",
    // },
    // {
    //   id: 6,
    //   name: "Documents",
    //   icon: "file-earmark",
    // },
  ];

  function checkStatus() {
    if (!contact.lastEmailStatus) {
      return "warning";
    }
    if (contact.lastEmailStatus === "verified") {
      return "verified";
    }
    if (contact.lastEmailStatus != "verified") {
      return "bad";
    }
  }

  return (
    <>
      <ReactModal
        show={true}
        onClose={onClose}
        modalStyle={{
          borderRadius: ".5rem",
          height: "fit-content",
          width: "95%",
          height: "95%",
          // maxWidth: "1200px",
        }}
        dark
      >
        {userRoles.isError && <ErrorPage error={userRoles.error} />}
        {userRoles.isLoading && <Loading />}
        {userRoles.isSuccess && (
          <div className={styles.page}>
            <div className={styles.contactHeader}>
              <FlexRow start style={{ width: "fit-content" }}>
                <div
                  className={styles.profileImage}
                  style={{ backgroundColor: color }}
                >
                  <img
                    src={require("assets/images/blank-profile-picture.png")}
                    height={"55px"}
                    width={"55px"}
                  ></img>
                </div>
                <div>
                  <div
                    className={styles.header_3}
                    style={{
                      margin: "0",
                      fontWeight: "600",
                      display: "flex",
                      gap: "1rem",
                      flexWrap: "wrap",
                      fontSize: "1.2rem",
                    }}
                  >
                    <span style={{ whiteSpace: "nowrap" }}>
                      {" "}
                      {userRoles.data.role?.canSeeContactInfo ? (
                        <>
                          {currContact?.firstName + " " + currContact?.lastName}
                        </>
                      ) : (
                        "..."
                      )}
                    </span>
                    {currContact.salesforceId && (
                      <FlexRow
                        start
                        gap={"0px"}
                        style={{
                          width: "fit-content",
                          alignItems: "center",
                          height: "fit-content",
                        }}
                      >
                        <Icon iconName={"cloud"} blue></Icon>{" "}
                        <div className={styles.header_6}>
                          Synced with Salesforce
                        </div>
                      </FlexRow>
                    )}
                  </div>
                  <div className={styles.description}>
                    {userRoles.data.role?.canSeeContactInfo ? (
                      <>{currContact?.email}</>
                    ) : (
                      "..."
                    )}
                  </div>
                  {currContact?.unsubscribed && (
                    <div
                      className={`${styles.description} ${styles.red}`}
                      style={{ color: "red" }}
                    >
                      Unsubscribed
                    </div>
                  )}
                </div>
              </FlexRow>
              <FlexRow
                style={{
                  gap: "2rem",
                  width: "fit-content",
                  height: "100%",
                  padding: "1rem",
                }}
                align={"center"}
              >
                {userRoles.data.role?.canEditContacts && (
                  <div
                    className={`${styles.link} ${styles.red}`}
                    onClick={() => setShowDeleteTooltip(true)}
                    height={35}
                  >
                    <i className="bi-trash"></i> Delete
                  </div>
                )}

                {userRoles.data.role?.canEditContacts &&
                  !currContact?.unsubscribed && (
                    <div
                      className={`${styles.link} ${styles.yellow}`}
                      onClick={() => unsubscribeContact(true)}
                      height={35}
                    >
                      <i className="bi-heartbreak"></i> Unsubscribe
                    </div>
                  )}
                {userRoles.data.role?.canEditContacts &&
                  currContact?.unsubscribed && (
                    <div
                      className={`${styles.link} ${styles.green}`}
                      onClick={() => unsubscribeContact(false)}
                      height={35}
                    >
                      <i className="bi-heart"></i> Resubscribe
                    </div>
                  )}
              </FlexRow>

              {showDeleteTooltip && (
                <DeleteTooltip
                  close={() => setShowDeleteTooltip(false)}
                  partCount={currContact?.participationCount}
                  onDelete={() => deleteContact()}
                />
              )}
            </div>

            <div className={styles.pages}>
              <div className={styles.tabs}>
                <FlexRow wrap="wrap">
                  {tabBarItems?.map((item) => (
                    <div>
                      <Button
                        active={active === item?.id}
                        onClick={() => {
                          setActive(item?.id);
                        }}
                      >
                        {item?.name}
                      </Button>
                    </div>
                  ))}
                </FlexRow>
              </div>

              {!userRoles.data.role?.canSeeContactInfo && active === 0 && (
                <div className={styles.text_1} style={{ margin: "2rem" }}>
                  You do not have access to see the contacts data
                </div>
              )}
              {userRoles.data.role?.canSeeContactInfo && active === 0 && (
                <>
                  <GetContactLayout
                    contactId={currContact?.id}
                  ></GetContactLayout>
                </>
              )}

              {!userRoles.data.role?.canSeeContactInfo && active === 2 && (
                <div className={styles.text_1} style={{ margin: "2rem" }}>
                  You do not have access to see the contacts answers
                </div>
              )}
              {userRoles.data.role?.canSeeContactInfo && active === 2 && (
                <>
                  <div
                    className={styles.settingSection}
                    style={{ gap: "1rem", overflow: "auto" }}
                  >
                    <div className={styles.header2}>Survey Answers</div>
                    {seePastAnswers && fetchAnswers.isError && <div>Error</div>}
                    {seePastAnswers && fetchAnswers.isLoading && <Loading />}
                    {seePastAnswers && fetchAnswers.isSuccess && (
                      <PastAnswers
                        participations={
                          fetchAnswers.data.getAllAnswersByAContact
                            .participations
                        }
                        refetch={fetchAnswers.refetch}
                      />
                    )}
                  </div>
                </>
              )}
              {(!userRoles.data.role?.canViewOrgAudiences ||
                !userRoles.data.role?.canEditContacts) &&
                active === 3 && (
                  <div className={styles.text_1} style={{ margin: "2rem" }}>
                    You do not have access to view the audiences
                  </div>
                )}
              {userRoles.data.role?.canViewOrgAudiences &&
                userRoles.data.role?.canEditContacts &&
                active === 3 && (
                  <GetAudiences currContact={currContact}></GetAudiences>
                )}
              {/* {active === 1 && (
                <ContactActivity id={currContact.id}></ContactActivity>
              )} */}
            </div>
          </div>
        )}
      </ReactModal>

      <ReactModal
        show={showUnsub}
        onClose={() => setShowUnsub(false)}
        modalStyle={{
          borderRadius: "1em",
          padding: "1em",
          overflow: "visible",
        }}
      >
        <div className={styles.unsubContainer}>
          <div>
            Unsubscribe <span>{currContact.email}</span> from a survey <br />{" "}
            This person will no longer recieve the emails for this survey
          </div>
          <SearchSurvey
            addProject={addSurvey}
            exceptForIds={potentialProjects}
            userInfo={currContact}
          ></SearchSurvey>
          {potentialProjects.length > 0 && (
            <>
              <div>
                Surveys that <span>{currContact.email}</span> are currently
                unsubscribed from:{" "}
              </div>
              {potentialProjects.map((survey, i) => (
                <div style={{ display: "flex", gap: ".5em" }} key={i}>
                  <span>{survey} </span>
                  <i
                    className="bi bi-dash-circle"
                    onClick={() => removeSurvey(survey)}
                  ></i>
                </div>
              ))}
            </>
          )}
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button onClick={unsubscribeSurvey}>Save</Button>
          </div>
        </div>
      </ReactModal>

      {editName && (
        <EditName
          contact={currContact}
          onClose={() => setEditName(false)}
          onSave={saveChanges}
        />
      )}
    </>
  );

  function DeleteTooltip({ close, partCount, onDelete }) {
    function clickOutside(e) {
      if (ref.current && !ref.current?.contains(e.target)) {
        close();
        document.removeEventListener("click", clickOutside, true);
      }
    }

    useEffect(() => {
      document.addEventListener("click", clickOutside, true);
      return () => {
        document.removeEventListener("click", clickOutside, true);
      };
    }, []);

    const ref = useRef();

    return (
      <div className={styles.deleteTooltip} ref={ref}>
        <div style={{ textAlign: "center" }}>
          This contact has participated in {partCount} surveys. Delete them?
        </div>
        <div className={styles.choices}>
          <div className={`${styles.btn} ${styles.yes}`} onClick={onDelete}>
            Yes <i className="bi bi-trash3-fill"></i>
          </div>{" "}
          or{" "}
          <div className={`${styles.btn} ${styles.no}`} onClick={close}>
            No <i className="bi bi-x-circle"></i>
          </div>
        </div>
      </div>
    );
  }
}

function GetAudiences({ currContact }) {
  const fetchAudiences = useFetchAudiencesForAContact(currContact.id);

  return (
    <div className={styles.settingSection}>
      <div className={styles.header2}>Audiences</div>
      {fetchAudiences.isError && <div>Error</div>}
      {fetchAudiences.isLoading && <Loading />}
      {fetchAudiences.isSuccess && (
        <Audiences
          audiences={fetchAudiences.data.getAContactsAudiences}
          refetch={() => fetchAudiences.refetch()}
          contact={currContact}
        />
      )}
    </div>
  );
}

function Audiences({ audiences, contact, refetch }) {
  function sortList(copy) {
    copy.sort((a, b) => {
      let aDate = new Date(a.updatedAt);
      let bDate = new Date(b.updatedAt);
      return aDate.getTime() - bDate.getTime();
    });
    return copy;
  }

  const [list, setList] = useState(sortList([...audiences]));
  const [add, setAdd] = useState(false);

  const removeAudience = useRemoveContactFromAudience();
  const addAudiences = useAddContactToAudiences();

  function handleAddAudiences(picked) {
    if (picked.length) {
      addAudiences.mutate(
        {
          contactId: contact.id,
          audienceIds: {
            ids: picked.map((a) => a.id),
          },
        },
        {
          onSuccess: () => {
            console.log("Added to Audiences!");
            refetch();
          },
        }
      );
      let copy = [...list, ...picked];
      setList(sortList(copy));

      setTimeout(() => setAdd(false), 1500);
    }
  }

  function removeFromAudience(audience) {
    if (
      window.confirm(
        `Are you sure you would like to remove ${
          contact?.prefix ? contact.prefix : ""
        } ${contact?.firstName ? contact.firstName : ""} ${
          contact?.lastName ? contact.lastName : ""
        } from the '${audience.name}' audience?`
      )
    ) {
      removeAudience.mutate(
        {
          contactId: contact.id,
          audienceId: audience.id,
        },
        {
          onSuccess: () => {
            console.log("removed!");
            refetch();
          },
        }
      );

      let copy = [...list];
      let index = copy.findIndex((a) => a.id === audience.id);
      copy.splice(index, 1);
      setList(copy);
    }
  }

  return (
    <>
      <div className={styles.totalSomething}>
        Total Audiences: <span className={styles.total}>{list.length}</span>
      </div>
      <div className={styles.audiences}>
        {list.map((aud, i) => (
          <div className={styles.audience} key={i}>
            <div className={styles.name}>{aud.name}</div>
            <div
              className={styles.deleteFromAud}
              onClick={() => removeFromAudience(aud)}
            >
              <i className="bi-trash"></i>
            </div>
          </div>
        ))}

        <div className={styles.plus} onClick={() => setAdd(true)}>
          + add to audience
        </div>
      </div>

      {add && (
        <AddToAudiences
          onClose={() => setAdd(false)}
          currentAudiences={list.map((aud) => aud.id)}
          onAdd={handleAddAudiences}
        />
      )}
    </>
  );
}

function PastAnswers({ participations, refetch }) {
  const updateAnswer = useUpdateAnswer();

  function getAnswer(answer) {
    if (answer.choiceAnswer) {
      const ranking = answer.question.choiceQuestion.isRanking;
      let string = "";
      for (let i = 0; i < answer.choiceAnswer.length; i++) {
        if (ranking) {
          string += i + 1 + ": ";
        }
        string += answer.choiceAnswer[i];
        if (i < answer.choiceAnswer.length - 1) {
          if (ranking) {
            string += "  ";
          } else {
            string += ", ";
          }
        }
      }
      return string;
    }
    if (answer.scaleAnswer || answer.scaleAnswer === 0) {
      return answer.scaleAnswer;
    }
    if (answer.textAnswer) {
      return answer.textAnswer;
    }
    if (answer.matrixAnswer) {
      return getMatrixAnswer(answer);
    }
  }

  function getMatrixAnswer(answer) {
    let matrixAnswer = JSON.parse(answer.matrixAnswer);
    let string = "";
    for (let option of answer.question.matrixQuestion.options) {
      if (option in matrixAnswer) {
        if (string) {
          string += "\n";
        }
        string += option + ": ";
        if (answer.question.choiceQuestion) {
          let choiceAnswer = matrixAnswer[option];
          const ranking = answer.question.choiceQuestion.isRanking;
          for (let i = 0; i < choiceAnswer.length; i++) {
            if (ranking) {
              string += i + 1 + ": ";
            }
            string += choiceAnswer[i];
            if (i < choiceAnswer.length - 1) {
              if (ranking) {
                string += "  ";
              } else {
                string += ", ";
              }
            }
          }
        } else {
          string += matrixAnswer[option];
        }
      }
    }
    return <div style={{ whiteSpace: "pre" }}>{string}</div>;
  }

  function getDescription(question) {
    if (question.choiceQuestion) {
      let choices = question.choiceQuestion.choices;
      let stringValue = choices[0];
      for (let i = 1; i < choices.length; i++) {
        stringValue += `, ${choices[i]}`;
      }
      return "Out of: " + stringValue;
    }
    if (question.scaleQuestion) {
      return `On a scale from ${question.scaleQuestion.min} to ${question.scaleQuestion.max}`;
    }
    if (question.textQuestion) {
      return "Free response";
    }
  }

  function getSurveys() {
    let surveys = [];
    for (let partic of participations) {
      let survey = {
        surveyName: partic?.project?.name,
        startedAt: partic?.project?.startedAt,
        partStartedAt: partic?.startedAt
          ? partic?.startedAt
          : partic?.updatedAt,
        status: partic?.project?.status,
      };
      let questions = [];

      for (let answer of partic.answers) {
        questions.push({
          questionText: answer.question.questionText,
          answer: getAnswer(answer),
          description: getDescription(answer.question),
          timeAnswered: answer?.createdAt,
          createdAt: answer?.createdAt,
          updatedAt: answer?.updatedAt,
          hide: answer.hide,
          id: answer.id,
          pageNumber: answer.question.pageNumber,
          pageOrderIndex: answer.question.pageOrderIndex,
        });
        survey.surveyName = answer.question.project?.name;
        survey.startedAt = answer.question.project?.startedAt;
      }
      questions.sort((a, b) => {
        if (a.pageNumber === b.pageNumber) {
          return a.pageOrderIndex > b.pageOrderIndex ? 1 : -1;
        } else {
          return a.pageNumber > b.pageNumber ? 1 : -1;
        }
      });
      survey.questions = questions;
      if (!survey?.questions || survey?.questions?.length === 0) {
        survey.partStartedAt = undefined;
      }
      if (survey.questions && survey.questions.length > 0) {
        surveys.push(survey);
      }
      // surveys.push(survey);
    }

    surveys.sort((a, b) => {
      if (a.startedAt && b.startedAt) {
        let aDate = new Date(a.startedAt);
        let bDate = new Date(b.startedAt);
        return bDate - aDate;
      }
      return 0;
    });
    return surveys;
  }
  function getSurveysCount() {
    let surveys = [];
    for (let partic of participations) {
      let survey = {};
      let questions = [];
      let i = 0;
      for (let answer of partic.answers) {
        questions.push({
          questionText: answer.question.questionText,
          answer: getAnswer(answer),
          description: getDescription(answer.question),
          timeAnswered:
            answer.createdAt &&
            new Date(answer.createdAt).valueOf() > date.valueOf()
              ? trimDate(i > 0 ? answer?.updatedAt : answer.createdAt, true)
              : "",

          hide: answer.hide,
          id: answer.id,
          pageNumber: answer.question.pageNumber,
          pageOrderIndex: answer.question.pageOrderIndex,
        });
        survey.surveyName = answer.question.project?.name;
        survey.startedAt = answer.question.project?.startedAt;
        i++;
      }
      survey.questions = questions;

      // if (survey.questions && survey.questions.length > 0) {
      //   surveys.push(survey);
      // }
      surveys.push(survey);
    }
    return surveys;
  }

  const [date] = useState(new Date("2024-01-30 23:54:16.047108"));

  function changeAnswer(id, hide) {
    updateAnswer.mutate(
      {
        data: {
          hide: hide,
        },
        id: id,
      },
      {
        onSuccess: () => {
          console.log("Success!");
          refetch();
        },
      }
    );
  }

  return (
    <>
      {/* <div className={styles.totalSomething}>
        Total Participations:{" "}
        <span className={styles.total}>{getSurveysCount().length}</span>
      </div> */}
      <div className={styles.answersContainer}>
        {getSurveys().map((survey, i) => (
          <SurveyAnswers
            survey={survey}
            changeAnswer={changeAnswer}
            i={i}
          ></SurveyAnswers>
        ))}
        {getSurveys().length === 0 && <div className={styles.none}>None</div>}
      </div>
    </>
  );
}

function SurveyAnswers({ survey, changeAnswer, i }) {
  const [open, setOpen] = useState(false);
  return (
    <React.Fragment key={i}>
      <div className={styles.surveyContainer}>
        <div className={styles.surveyTop} onClick={() => setOpen(!open)}>
          <div className={styles.surveyTitle}>
            <span>
              <FlexRow start gap=".5rem">
                <div className={styles.header_5} style={{ margin: "0" }}>
                  {survey.surveyName}
                </div>
                <Status green>{survey.status}</Status>
              </FlexRow>
            </span>

            {survey.partStartedAt && (
              <FlexRow
                start
                gap=".5rem"
                style={{ whiteSpace: "nowrap", width: "fit-content" }}
              >
                <div className={styles.label6}>
                  <FlexRow start gap=".5rem">
                    <i className="bi-calendar"> </i>
                    <span>Started Answering:</span>
                  </FlexRow>
                </div>
                <span className={styles.text_3}>
                  {trimDate(new Date(survey.partStartedAt), true)}
                </span>
                <div style={{ fontSize: ".8em", color: "lightgrey" }}>
                  {survey.partStartedAt ? "(Mountain Time, US & Canada)" : ""}
                </div>
              </FlexRow>
            )}

            <Icon
              iconName={"chevron-right"}
              className={`${open && styles.rotate}`}
            ></Icon>
          </div>

          <FlexRow start gap="1rem">
            <StatusItem
              icon="person-raised-hand"
              title={"Questions Answered: " + survey?.questions?.length}
            ></StatusItem>{" "}
            {/* {survey.questions.length > 0 && (
              <StatusItem
                icon="stopwatch"
                title={
                  "Survey Duration: " +
                  `${
                    survey?.questions[0]?.timeAnswered
                      ? calculateDateDifference([
                          ...survey?.questions?.map((q) => {
                            return q?.createdAt;
                          }),
                          ...survey?.questions?.map((q) => {
                            return q?.updatedAt;
                          }),
                        ]).differenceString
                      : ""
                  }`
                }
              ></StatusItem>
            )} */}
          </FlexRow>
        </div>

        {open && (
          <div className={styles.questionsContainer}>
            {survey.questions &&
              survey.questions.length > 0 &&
              survey.questions.map((q, i) => (
                <div className={styles.question} key={i}>
                  <div className={styles.label5}>{q.questionText}</div>
                  <div className={styles.desc}>{q.description}</div>
                  <Hide answer={q} changeAnswer={changeAnswer}></Hide>
                </div>
              ))}

            {survey.questions.length === 0 && (
              <div className={styles.question} key={i}>
                {/* <div className={styles.questionText}>
              {q.questionText}
            </div>
            <div className={styles.desc}>{q.description}</div> */}
                <div className={styles.answer}>Did not participate</div>
              </div>
            )}
          </div>
        )}
      </div>
    </React.Fragment>
  );
}

function Hide({ answer, changeAnswer }) {
  const [changeHide, setChangeHide] = useState(false);

  return (
    <div
      className={
        answer.answer === "No answer" ? styles.noAnswer : styles.answer
      }
    >
      {answer.answer}{" "}
      {answer.answer != "No answer" && (
        <>
          {" "}
          {answer.hide && (
            <>
              {!changeHide && (
                <span
                  className={styles.questionMask}
                  onMouseEnter={() => setChangeHide(!changeHide)}
                >
                  (hidden in results)
                </span>
              )}
              {changeHide && (
                <span
                  onClick={() => changeAnswer(answer.id, !answer.hide)}
                  className={styles.questionMask}
                  onMouseLeave={() => setChangeHide(!changeHide)}
                >
                  (Click to unhide from results)
                </span>
              )}
            </>
          )}{" "}
          {!answer.hide && (
            <>
              {!changeHide && (
                <span
                  className={styles.questionMask}
                  onMouseEnter={() => setChangeHide(!changeHide)}
                >
                  (visible in results){" "}
                </span>
              )}
              {changeHide && (
                <span
                  onClick={() => changeAnswer(answer.id, !answer.hide)}
                  className={styles.questionMask}
                  onMouseLeave={() => setChangeHide(!changeHide)}
                >
                  (Click to hide in results)
                </span>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
}

function AddToAudiences({ onClose, currentAudiences, onAdd }) {
  const [picked, setPicked] = useState([]);
  const [addWords, setAddWords] = useState("Add");

  const pageSkip = 0;
  const perPage = 0;
  const pageSort = {
    name: true,
    members: false,
    modifiedDate: false,
    createdDate: false,
    descend: false,
  };

  const getAudiences = useFetchAudiencesSimple(pageSkip, perPage, pageSort);

  function pick(aud) {
    let copy = [...picked];
    let index = copy.findIndex((a) => a.id === aud.id);
    if (index >= 0) {
      copy.splice(index, 1);
    } else {
      copy.push(aud);
    }
    setPicked(copy);
  }

  function startAdding() {
    onAdd(picked);
    setAddWords(
      <div style={{ display: "flex", gap: "5px" }}>
        Adding <Loading height={20} width={20} />
      </div>
    );
  }

  return (
    <ReactModal
      show={true}
      onClose={onClose}
      modalStyle={{
        borderRadius: "1em",
        padding: "1em",
      }}
    >
      <div className={styles.addAudiences}>
        {getAudiences.isError && <div>Error</div>}
        {getAudiences.isLoading && <Loading />}
        {getAudiences.isSuccess && (
          <>
            <div className={styles.pickAudiencesHeader}>Pick Audiences</div>

            <div className={styles.audienceList}>
              {getAudiences.data.audiences.map((aud) => {
                if (!currentAudiences.includes(aud.id)) {
                  return (
                    <div
                      className={`${styles.newAudience} ${
                        picked.some((a) => a.id === aud.id)
                          ? styles.picked
                          : styles.unpicked
                      }`}
                      onClick={() => pick(aud)}
                      key={aud.id}
                    >
                      <div className={styles.nameAndNumber}>
                        {" "}
                        {aud.name} <span>{aud.members}</span>
                        {picked.some((a) => a.id === aud.id) && (
                          <i className={`bi bi-check-lg ${styles.check}`}></i>
                        )}
                      </div>
                    </div>
                  );
                }
              })}
            </div>

            <Button shadow disable={!picked.length} onClick={startAdding}>
              {addWords}
            </Button>
          </>
        )}
      </div>
    </ReactModal>
  );
}

function EditName({ contact, onClose, onSave }) {
  const [contactCopy, setContactCopy] = useState({
    prefix: contact.prefix,
    firstName: contact.firstName,
    lastName: contact.lastName,
    email: contact.email,
  });
  const [saveWords, setSaveWords] = useState("Save Changes");
  const [changesMade, setChangesMade] = useState(false);

  function startSaving() {
    onSave(contactCopy);
    setSaveWords(
      <div style={{ display: "flex", flexDirection: "row" }}>
        Saving <Loading height={20} width={20}></Loading>
      </div>
    );
  }

  function editField(field, val) {
    let copy = { ...contactCopy };
    copy[field] = val;
    setContactCopy(copy);
    setChangesMade(true);
  }

  return (
    <ReactModal
      show={true}
      onClose={onClose}
      modalStyle={{
        borderRadius: "1em",
        padding: "1em",
      }}
    >
      <div className={styles.editNameContainer}>
        <div className={styles.editNameField} style={{ width: "50%" }}>
          <Label>Prefix</Label>
          <TextFieldSimple
            value={contactCopy.prefix}
            onChange={(val) => editField("prefix", val)}
          />
        </div>

        <div className={styles.editNameField}>
          <Label>First Name</Label>
          <TextFieldSimple
            value={contactCopy.firstName}
            onChange={(val) => editField("firstName", val)}
          />
        </div>

        <div className={styles.editNameField}>
          <Label>Last Name</Label>
          <TextFieldSimple
            value={contactCopy.lastName}
            onChange={(val) => editField("lastName", val)}
          />
        </div>

        <div className={styles.editNameField}>
          <Label>Email</Label>
          <TextFieldSimple
            value={contactCopy.email}
            onChange={(val) => editField("email", val)}
          />
        </div>

        <div
          style={{
            visibility: changesMade ? "visible" : "hidden",
            display: "flex",
            justifyContent: "center",
            paddingTop: "10px",
          }}
        >
          <Button onClick={startSaving}>{saveWords}</Button>
        </div>
      </div>
    </ReactModal>
  );
}
