import React from "react";
import styles from "./SurveyDesign.module.scss";
import { ToggleSwitch } from "components/inputs/input_fields/ToggleSwitch/ToggleSwitch";
import { SurveyButton } from "components/Survey/SurveyButton/SurveyButton";
import ColorPickerContainer from "components/inputs/input_fields/ColorPicker/ColorPickerContainer";
import { shadows } from "assets/functions/Variables";
import { SelectFieldCustom, TextEntry } from "components/inputs";
import FlexCol from "components/layouts/FlexColumn/FlexCol";

function PageNavSettings({ settings, changeSetting }) {
  const buttonStyles = ["outline", "fill", "link"];

  const color = settings.buttonColor
    ? settings.buttonColor
    : settings.baseColor;

  return (
    <div className={styles.settingVertical} style={{ gap: "1rem" }}>
      <div className={styles.label5} style={{ margin: "0", fontWeight: "500" }}>
        Page Navigation
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "10px",
          paddingBottom: "10px",
          height: "fit-content",
          flexWrap: "wrap",
        }}
      >
        <div className={styles.label6}>Button Style</div>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "15px",
            paddingLeft: "10px",
            flexWrap: "wrap",
          }}
        >
          {buttonStyles.map((style) => (
            <div
              className={`${styles.buttonOption} ${
                settings.buttonStyle == style ? styles.chosenBtnOption : ""
              }`}
              onClick={() => changeSetting("buttonStyle", style)}
            >
              <SurveyButton
                styleType={style}
                color={color}
                shadow={settings?.buttonShadow}
              >
                {settings.next ? settings.next : "Next"}
              </SurveyButton>
            </div>
          ))}
        </div>
      </div>

      <FlexCol gap={10}>
        <div className={styles.label6}>Button Text</div>
        <TextEntry
          label={"Next"}
          value={settings.next}
          onChange={(text) => changeSetting("next", text)}
          placeholder={"Next"}
        />
        <TextEntry
          label={"Back"}
          value={settings.back}
          onChange={(text) => changeSetting("back", text)}
          placeholder={"Back"}
        />
        <TextEntry
          label={"Finish"}
          value={settings.finish}
          onChange={(text) => changeSetting("finish", text)}
          placeholder={"Finish"}
        />
      </FlexCol>

      <ColorPickerContainer
        onChange={(color) => changeSetting("buttonColor", color)}
        defaultColor={
          settings.buttonColor ? settings.buttonColor : settings.baseColor
        }
        title="Button Color"
        //   colors={settings.colorBank}
      />
      <SelectFieldCustom
        onChange={(color) => changeSetting("buttonShadow", color.value)}
        value={
          settings?.buttonShadow
            ? shadows.find((s) => s.value === settings?.buttonShadow)
            : { value: "", label: "No Shadow" }
        }
        label="Button Border Shadow"
        // icon="bi-chevron-down"
        options={shadows}
        select
        anyWhereOptions
      />
      <div className={styles.settingVertical}>
        <div className={styles.label6}>Page Progress</div>
        <div className={styles.settingHorizontal}>
          {/* <div className={styles.colorContainer}> */}
          <ToggleSwitch
            startChecked={settings.showPageProg}
            handleCheck={(val) => changeSetting("showPageProg", val)}
          ></ToggleSwitch>
          {/* </div> */}
          <div className={styles.text_3}>Show Page Progress</div>
        </div>
      </div>
    </div>
  );
}

export default PageNavSettings;
