import {
  useAddBucket,
  useCreateBucket,
  useFetchBucketCounts,
  useFetchBuckets,
  useRemoveBucket,
  useRunSingleBucketingBatch,
  useUpdateBucket,
} from "api/resources/organization/buckets";
import styles from "./Buckets.module.scss";

import { useState, useEffect, useRef } from "react";
import { ToggleSwitch } from "components/inputs/input_fields/ToggleSwitch/ToggleSwitch";
import { Label } from "components/layouts/Label/Label";
import { EditBucket } from "pages/organization/organization_details/OrgBuckets/OrgBuckets";
import { useUpdateTextQ } from "api/resources/projects/questions";
import FlexRow from "components/layouts/FlexRow/FlexRow";
import Icon from "components/Icon/Icon";

export default function Buckets({ question, status }) {
  const fetchBuckets = useFetchBuckets();
  const addBucket = useAddBucket();
  const removeBucket = useRemoveBucket();
  const updateBucket = useUpdateBucket();
  const createBucket = useCreateBucket();
  const updateTextQ = useUpdateTextQ();

  const [edit, setEdit] = useState("");
  const [create, setCreate] = useState(false);

  const ref = useRef();

  // function clickOut(event) {
  //   if (ref.current && !ref.current?.contains(event.target)) {
  //     close();
  //   }
  // }

  // useEffect(() => {
  //   document.addEventListener("click", clickOut, true);
  //   return () => {
  //     document.removeEventListener("click", clickOut, true);
  //   };
  // }, []);

  function toggleBucket(val, bucket) {
    if (val) {
      addBucket.mutate(
        {
          textQId: question.textQuestion.id,
          bucketId: bucket.id,
        },
        {
          onSuccess: (data) => {
            if (data.addBucket) {
              question.textQuestion.bucket.push(bucket);
              console.log("bucket added");
            }
          },
        }
      );
    } else {
      removeBucket.mutate(
        {
          textQId: question.textQuestion.id,
          bucketId: bucket.id,
        },
        {
          onSuccess: (data) => {
            if (data.removeBucket) {
              let ind = question.textQuestion.bucket.findIndex(
                (b) => b.id === bucket.id
              );
              if (ind > -1) {
                question.textQuestion.bucket.splice(ind, 1);
              }
              console.log("bucket removed");
            }
          },
        }
      );
    }
  }

  // console.log(question.textQuestion.bucket);

  function propsList(bucket) {
    let string = "";
    for (let prop of bucket.properties) {
      if (string) {
        string += ", ";
      } else {
        // string += "- ";
      }
      string += prop;
    }
    return string;
  }

  function saveBucket(bucket) {
    updateBucket.mutate(
      {
        data: {
          name: bucket.name,
          properties: bucket.properties,
        },
        id: edit,
      },
      {
        onSuccess: (data) => {
          console.log("updated bucket!");
          fetchBuckets.refetch();
        },
      }
    );
    setEdit("");
  }

  function buildBucket(bucket) {
    createBucket.mutate(
      {
        data: {
          name: bucket.name,
          properties: bucket.properties,
        },
      },
      {
        onSuccess: (data) => {
          console.log("created bucket!");

          if (data.createBucket) {
            toggleBucket(true, data.createBucket);
          }

          fetchBuckets.refetch();
        },
      }
    );
    setCreate(false);
  }

  function toggleAuto(val) {
    updateTextQ.mutate(
      {
        id: question.textQuestion.id,
        data: {
          autoBucket: val,
        },
      },
      {
        onSuccess: (data) => {
          question.textQuestion.autoBucket = data.updateTextQuestion.autoBucket;
        },
      }
    );
  }

  return (
    <>
      <div className={styles.settingsContainer} ref={ref}>
        {fetchBuckets.isSuccess && question?.textQuestion?.bucket && (
          <>
            <div className={styles.buckets}>
              {fetchBuckets.data.orgBuckets.map((bucket) => {
                const included = question.textQuestion.bucket.some(
                  (b) => b.id === bucket.id
                );

                const editing = edit === bucket.id;

                return (
                  <div className={styles.bucket}>
                    <div className={styles.bucketName}>
                      <ToggleSwitch
                        startChecked={included}
                        handleCheck={(val) => toggleBucket(val, bucket)}
                      ></ToggleSwitch>
                      {bucket.name}
                      {included && (
                        <div
                          className={styles.edit}
                          onClick={() => setEdit(bucket.id)}
                        >
                          edit
                        </div>
                      )}
                    </div>
                    {included && !editing && (
                      <div className={styles.propsList}>
                        {propsList(bucket)}
                      </div>
                    )}
                    {included && status !== "Draft" && (
                      <RunBuckets bucket={bucket} question={question} />
                    )}

                    {editing && (
                      <EditBucket
                        initBucket={bucket}
                        onClose={() => setEdit("")}
                        onDone={saveBucket}
                        words={"Save"}
                        noModal
                      />
                    )}
                  </div>
                );
              })}

              <div
                className={styles.createBucket}
                onClick={() => setCreate(true)}
              >
                + Create Bucket
              </div>

              <div
                className={`${styles.auto} ${
                  !question.textQuestion.autoBucket ? styles.off : ""
                }`}
              >
                <ToggleSwitch
                  startChecked={question.textQuestion.autoBucket}
                  handleCheck={toggleAuto}
                ></ToggleSwitch>
                Automatic Bucketing
              </div>
            </div>
          </>
        )}
      </div>
      {create && (
        <EditBucket
          initBucket={{ name: "", properties: [] }}
          onClose={() => setCreate(false)}
          onDone={buildBucket}
          words={"Create"}
          noModal
        />
      )}
    </>
  );
}

function RunBuckets({ bucket, question }) {
  const [running, setRunning] = useState(false);
  const [runningAll, setRunningAll] = useState(false);

  const fetchCounts = useFetchBucketCounts(bucket.id, question.id);
  const runBuckets = useRunSingleBucketingBatch();

  const runMissing = (val) => {
    if (val) {
      runBuckets.mutate(
        {
          questionId: question.id,
          bucketId: bucket.id,
          all: false,
        },
        {
          onSuccess: (data) => {
            console.log("Bucketed " + data.count + "!");
            fetchCounts.refetch();
            setRunning(false);
          },
        }
      );
    }

    setRunning(val);
  };

  const runAll = (val) => {
    if (val) {
      runBuckets.mutate(
        {
          questionId: question.id,
          bucketId: bucket.id,
          all: true,
        },
        {
          onSuccess: (data) => {
            console.log("Bucketed " + data.count + "!");
            fetchCounts.refetch();
            setRunningAll(false);
          },
        }
      );
    }

    setRunningAll(val);
  };

  return (
    <>
      {fetchCounts.isSuccess && (
        <div className={styles.runBuckets}>
          {fetchCounts.data?.bucketCounts.bucketed > 0 && (
            <div className={styles.runSwitch}>
              <ToggleSwitch
                startChecked={runningAll}
                handleCheck={runAll}
                disable={running || runningAll}
              ></ToggleSwitch>
              {`Re-bucket ${
                fetchCounts.data?.bucketCounts.unbucketed ? "all" : ""
              } comments`}
            </div>
          )}
          {!runningAll && fetchCounts.data?.bucketCounts.unbucketed > 0 && (
            <>
              <div className={styles.comment}>
                {`You have ${
                  fetchCounts.data?.bucketCounts.unbucketed == 1
                    ? fetchCounts.data?.bucketCounts.unbucketed +
                      " comment that has"
                    : fetchCounts.data?.bucketCounts.unbucketed +
                      " comments that have"
                } not been bucketed for "${bucket.name}"`}
              </div>

              <div className={styles.runSwitch} style={{ paddingLeft: "10px" }}>
                <ToggleSwitch
                  startChecked={running}
                  handleCheck={runMissing}
                  disable={running || runningAll}
                ></ToggleSwitch>
                Bucket missing comments
              </div>
            </>
          )}
        </div>
      )}
    </>
  );
}
