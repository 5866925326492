import React from "react";

function TAC() {
  return (
    <div style={{ height: "100%", overflow: "auto", padding: "1rem" }}>
      <h1>License and Services Agreement</h1>
      <h2>Terms and Conditions</h2>

      <div class="section">
        <h3>1. The Services</h3>
        <ol>
          <li>
            <strong>1.1 The Services:</strong> Subject to Company’s performance
            of its duties under this Agreement, including but not limited to
            timely payment of all Fees (as defined below), during the Term (as
            defined below) Service Provider shall provide Company with the
            technology services described in the initial Service Order entered
            into between the parties, and any additional Service Order entered
            into in substantially the form attached hereto (each an “Additional
            Service Order” and tougher with the initial Service order, each a
            “Service Order” and collectively the “Service Orders”) and related
            technical support and integration services as expressly set forth
            herein (collectively, the “Services”), with Services to be provided
            through Service Providers’ Research as a Service Platform
            (“Platform”).
          </li>
          <li>
            <strong>1.2 Limitations on Services:</strong> Service Provider shall
            not be obligated to provide any services or perform any tasks not
            specifically set forth in this Agreement or in a Service Order
            attached hereto. Other than providing URL information as necessary
            for Company to provide a hyperlink from its platform(s) to the
            Platform, the Services do not include:
            <ol>
              <li>custom functionality to the Services,</li>
              <li>
                necessary modifications to third-party applications enabling
                them to function properly with updates, upgrades or
                modifications provided by Service Provider, or
              </li>
              <li>
                any new functionality or module Service Provider makes generally
                available as a separately priced offering.
              </li>
            </ol>
          </li>
        </ol>
      </div>

      <div class="section">
        <h3>2. Right to Use; Restrictions and Limitations</h3>
        <ol>
          <li>
            <strong>2.1 Right to Use for Company Users:</strong> Subject to the
            terms and conditions of this Agreement, Service Provider hereby
            grants to Company and its authorized employees (“Company Users”) (a)
            a limited, non-exclusive, non-transferable, and irrevocable right
            and license to use and access the Services through the Platform
            during the Term.
          </li>
          <li>
            <strong>2.3 Limitations:</strong> Neither Company nor any Company
            User will:
            <ol>
              <li>
                reproduce, download, modify or distribute all or any portion of
                the Services, any computer software installed or used by Service
                Provider in maintaining and hosting the Platform or otherwise
                providing the Services (“Software”), or any intellectual
                property relating to such Services or Software
              </li>
              <li>
                unless expressly permitted by this Agreement, a Service Order,
                or applicable law, reverse engineer, decompile or disassemble
                the Software;
              </li>
              <li>
                market, distribute, sell or resell the Services, or any part
                thereof, whether as a distributor, reseller, service bureau or
                otherwise;
              </li>
              <li>
                utilize framing or similar techniques to present the Services or
                the Platform (or any part thereof) on any internet website or
                internet domain owned or controlled by Company;
              </li>
              <li>
                rent, lease, or otherwise permit third parties to use the
                Services or Software (provided that Company may allow its third
                party consultants to utilize the Services or Software for the
                Company’s purposes);
              </li>
              <li>
                use the Services to provide services to third parties (e.g., as
                a service bureau); or
              </li>
              <li>
                circumvent or disable any security or other technological
                features or measures of the services. Neither the Company nor
                any Company User shall remove Service Provider logos, privacy
                statements, trademarks, Terms of Use or similar materials from
                the Platform (or any viewable or printable output generated by
                the Platform or the Services provided hereunder) without Service
                Provider’s prior written consent or unless other wise authorized
                under a Service Order (such as in the event the Company
                purchases “transparent” research branded with the Company’s
                branding). The Services (and the Platform) may only be used for
                lawful purposes and in a lawful manner. Company shall comply
                with all applicable laws, statutes and regulations.
              </li>
            </ol>
          </li>
          <li>
            {" "}
            <strong>2.4 Authorized Users Only. </strong>This Agreement restricts
            the use of the Services to the Company, those Company Users to which
            Company has granted access, and End Users subject to Section 2.2.
          </li>
          <li>
            {" "}
            <strong>2.5 Protection against Unauthorized Use. </strong>This
            Agreement restricts the use of the Services to the Company, those
            Company Users to which Company has granted access, and End Users
            subject to Section 2.2.
          </li>
        </ol>
      </div>

      <div class="section">
        <h3>5. Compensation; Payment Terms</h3>
        <ol>
          <li>
            <strong>5.1 Set-Up Fee:</strong> In consideration for the Platform
            Services to be rendered by Service Provider, Company shall pay to
            Service Provider pursuant to the payment terms set forth in the
            Service Order, a non-refundable one-time fee in the amount set forth
            in the applicable Service Order (the “Set-Up Fee”). If the Company
            elects during the Term to obtain additional Services under the
            applicable Service Order not originally subscribed for, then an
            additional set-up fee(s) may apply.
          </li>
          <li>
            <strong>5.2 Annual Fees:</strong> In addition to the Set-Up Fee,
            Company agrees to pay to Service Provider annual system license fees
            in the amount set forth in the applicable Service Order (the “Annual
            Fee,” and collectively with the Set-Up Fees and any fees set forth
            in a Service Order, the “Fees”). If additional functionality is
            added to the Services, the parties agree to negotiate in good faith
            for an increase in the Fees to become effective as of the time of
            such addition.
          </li>
          <li>
            <strong>5.3 Payment of Fees:</strong> Company shall pay the Fees as
            provided in the applicable Service Order. Any amount not paid when
            due will be subject to finance charges equal to 1% of the unpaid
            balance per month or the highest rate permitted by applicable usury
            law, whichever is less, determined and compounded monthly from the
            date due until the date paid. Failure of Company to make any payment
            of Fees when due, shall be deemed a material breach of this
            Agreement and Service Provider shall have the right (in addition to
            any other rights or remedies it may have) suspend the Services (in
            whole or in part) upon three (3) days’ written notice to Company.
            All payments shall be made in U.S. dollars and shall not be subject
            to set-off for any claims against Service Provider.
          </li>
        </ol>
      </div>

      <div class="section">
        <h3>6. Confidentiality; Privacy</h3>
        <ol>
          <li>
            <strong>6.1 Confidentiality:</strong> Subject to the other terms and
            conditions of this Agreement, as a result of this Agreement and the
            ongoing relationship of the parties, each of the parties may have
            access to or acquire knowledge of confidential and proprietary
            information concerning the other and the other’s business (the
            “Confidential Information”), and each agrees to treat the
            Confidential Information as confidential. All Confidential
            Information shall remain solely the property of the disclosing
            party, and the recipient shall maintain and protect the
            confidentiality of such Confidential Information with the same
            degree of care used to protect its own confidential information, but
            in any event, no less than a reasonable degree of care; provided,
            however, that neither party shall have any such obligation with
            respect to the use or disclosure to third parties of such
            Confidential Information as can be established to: (a) have been
            known publicly; (b) have been known generally in the industry on a
            non-confidential basis before communication by the disclosing party
            to the recipient; (c) have become known publicly without breach on
            the recipient’s part; (d) have been known otherwise by the recipient
            before communication by the disclosing party; (e) have been received
            by the recipient without any obligation of confidentiality from a
            source (other than the disclosing party) lawfully having possession
            of such information. Except as prohibited by applicable law or legal
            process or to the extent part of an examination by a regulatory or
            self-regulatory body, if the recipient is requested or required (by
            deposition, interrogatories, requests for information or documents
            in legal proceedings, subpoenas, regulatory processes (including
            those of self-regulatory organizations), or similar process) in
            connection with any proceeding to disclose or otherwise becomes
            legally compelled to disclose any Confidential Information, the
            recipient shall provide the disclosing party with prompt written
            notice and, if requested by the disclosing party after receipt of
            such notice, the recipient shall provide disclosing party with
            reasonable assistance (subject to reimbursement by the disclosing
            party of all reasonable and out-of-pocket expenses incurred by the
            recipient in providing such assistance) so as to enable the
            disclosing party to seek a protective order or other appropriate
            remedy or waive compliance with this Agreement. To the extent this
            Agreement applies, if such a protective order or other remedy is not
            obtained or if the disclosing party waives compliance with this
            Agreement in writing, the recipient may disclose Confidential
            Information, but only such Confidential Information as it is legally
            required to disclose in the reasonable opinion of counsel to the
            recipient, and shall exercise reasonable efforts to obtain reliable
            assurance that confidential treatment will be accorded such
            Confidential Information disclosed. Company’s obligations under this
            paragraph will survive the termination of this Agreement or of any
            license granted under this Agreement for whatever reason. Each of
            the parties agrees not to utilize another’s Confidential Information
            for any purpose other than the performance of this Agreement. Each
            of the parties understands, acknowledges and agrees that a breach of
            this Section may cause the non-breaching party irreparable damage,
            for which the award of damages would be inadequate compensation.
            Accordingly, the non-breaching party may institute an action to
            enjoin the breaching party from any and all acts in violation of
            those provisions, which remedy shall be cumulative and not
            exclusive.
          </li>
          <li>
            <strong>6.2 Publicity:</strong> Service Provider may, with Company’s
            prior written approval and consent, identify Company on Service
            Provider’s customer lists and in its marketing and advertising
            materials, and announce that Company is a customer of Service
            Provider (including as to the Services), and reproduce Company’s
            name, logo, trademark, trade name, service mark, or other commercial
            designations in connection therewith. No prior consent shall not be
            required for Service Provider to disclose Company’s name and the
            value of this Agreement in connection with any filings or
            disclosures required by Service Provider under applicable state or
            federal securities laws or in connection with due diligence by a
            potential financier or acquirer of Company.
          </li>
        </ol>
      </div>

      <div class="section">
        <h3>7. Title</h3>
        <ol>
          <li>
            <strong>7.1 Ownership:</strong> As between Service Provider and
            Company, (a) Service Provider is, and shall be, the sole and
            exclusive owner of the Services, Software, including but not limited
            to all related IP Rights (as defined below) therein, subject to the
            licenses granted to the Company herein, (b) Company is, and shall
            be, the sole and exclusive owner of the Company Content and Company
            Data (as defined below) subject to clause (c) of this paragraph,
            including but not limited to all related IP Rights therein, and (c)
            Service Provider and Company shall be joint owners, with rights to
            use such information independently, including after the termination
            or expiration of this Agreement, of the name and contact information
            of every person whose information is entered into or processed by
            the Services, the Platform, or the Software. Title to any software
            and hardware now owned by Service Provider and/or its third party
            providers and used in providing the Services remains exclusively
            owned by Service Provider and/or its third party providers and is
            not transferred to Company. Company understands and agrees that
            Service Provider may use and disclose, in an aggregated format only,
            any and all data derived or collected from Company's use of the
            Services for the purpose of generally improving the look and feel of
            the Services and to otherwise operate, manage, maintain and improve
            Service Provider's products and services including for developing
            and distributing benchmarks and similar reports and databases;
            provided that such aggregated data is not identified or identifiable
            as originating with or associated with any particular End User.
          </li>
          <li>
            <strong>7.2 Reservation of Rights:</strong> Upon written request of
            Company during the Term or during the ninety (90) day period
            thereafter Service Provider shall, at its election, deliver
            electronic copies of Company Data to Company or otherwise permit
            Company to access such Company Data. With respect to any such
            request received by Service Provider after the Term, the Company
            shall pay to Service Provider, in advance, the reasonable fees or
            charges imposed by Service Provider for providing or permitting
            access to such data (calculated in accordance with Service
            Provider’s then-current labor rates or fee schedule). Subsequent to
            the ninetieth (90th) day following any expiration or termination of
            this Agreement, Service Provider shall either return to the Company
            or destroy any Company Data and shall have no liability or
            obligation with respect to such Company Data.
          </li>
          <li>
            <strong>7.3 Company Access: </strong> Except for the express rights
            granted to Company and its Company Users under this Agreement or a
            Service Order, nothing contained herein shall be deemed or construed
            as a grant of any further rights or licenses in or to the Services,
            Software, Platform or Usage Information and Statistics. Service
            Provider reserves to itself all rights to the Services not expressly
            granted to Company in accordance with this Agreement.
          </li>
          <li>
            <strong>7.4 Company Data: </strong> “Company Data” means recorded
            information related to Company’s customers, processes, and
            employees, and all information intended to be processed through the
            use of the Services or Software, regardless of the form or method of
            recording, that is stored or maintained on one or more file servers
            or other storage devices owned or controlled by Service Provider,
            including all IP Rights in and to Company Data.
          </li>
          <li>
            <strong>7.5 IP Rights: </strong> “IP Rights” means (i) rights in
            patents, patent applications and patentable subject matter, whether
            or not the subject of an application, (ii) rights in trademarks,
            service marks, trade names, trade dress and other designators of
            origin, registered or unregistered, (iii) rights in copyrightable
            subject matter or protectable designs, registered or unregistered,
            (iv) rights in computer software, databases and documentation, (v)
            trade secrets, (vi) rights in internet domain names, uniform
            resource locators and e-mail addresses, (vii) rights in
            semiconductor topographies (mask works), registered or unregistered,
            (viii) know-how, and (ix) all other intellectual and industrial
            property rights of every kind and nature and however designated,
            whether arising by operation of law, contract, license or otherwise.
          </li>
          <li>
            <strong>7.6 Feedback: </strong> Service Provider is hereby granted a
            royalty-free, fully paid-up, nonexclusive, perpetual, irrevocable,
            worldwide, transferable (only to a successor in interest by way of
            merger, reorganization or sale of all or substantially all assets or
            equity), sublicensable license to use, copy, modify, or distribute,
            including by incorporating into the Services, any suggestions,
            enhancement requests, recommendations or other feedback provided by
            Company or its users relating to the operation of the Services, but
            only to the extent such feedback does not include any Company Data
            or individually identifiable information.
          </li>
        </ol>
      </div>

      <div class="section">
        <h3>8. Disclaimers</h3>
        <ol>
          <li>
            <strong>8.2 Limitation of Liability:</strong> IN NO EVENT SHALL
            EITHER PARTY BE LIABLE TO THE OTHER PARTY, ITS USERS, OR TO ANY
            THIRD PARTY FOR CONSEQUENTIAL, INCIDENTAL, SPECIAL, INDIRECT OR
            PUNITIVE DAMAGES ARISING OUT OF OR RELATING TO THIS AGREEMENT OR THE
            USE OF OR RELIANCE UPON THE SERVICES, EVEN IF THE PARTY HAS BEEN
            ADVISED, KNEW OR SHOULD HAVE KNOWN OF THE POSSIBILITY OF SUCH
            DAMAGES. SERVICE PROVIDER DOES NOT WARRANT AGAINST INTERFERENCE WITH
            THE ENJOYMENT OF THE SERVICES. SERVICE PROVIDER DOES NOT WARRANT
            THAT THE SERVICES ARE ERROR-FREE OR THAT OPERATION OF THE SERVICES
            WILL BE UNINTERRUPTED. SERVICE PROVIDER EXERCISES NO CONTROL OVER
            AND EXPRESSLY DISCLAIMS ANY LIABILITY ARISING OUT OF OR BASED UPON
            THE RESULTS OF COMPANY’S USE OF THE SERVICES. IN NO EVENT SHALL
            SERVICE PROVIDER BE LIABLE FOR PROCUREMENT COSTS OF SUBSTITUTE
            PRODUCTS OR SERVICES OR ANY UNAUTHORIZED USE OR MISUSE OF ANY
            SOFTWARE, PLATFORM OR INFORMATION, EXCEPT IN THE CASE OF SERVICE
            PROVIDER’S GROSS NEGLIGENCE WITH RESPECT TO SUCH USE OR MISUSE.
            Company’s exclusive remedy for breach by Service Provider of its
            limited warranty shall be to discontinue the Services and receive an
            immediate pro-rated refund of Annual Fees prepaid for any portion of
            the Term subsequent to discontinuation of Services. Notwithstanding
            anything to the contrary in this Agreement, neither party’s
            liability hereunder for any type of damages whatsoever, regardless
            of the form of action, shall exceed the aggregate dollar amount paid
            by Company to Service Provider in the twelve (12) months prior to
            the claimed injury or damage.
          </li>
          <li>
            <strong>8.3 Indemnity:</strong> Indemnity. Each party (the
            “Indemnifying Party”) will hold harmless, defend, and indemnify the
            other party, its affiliates, employees, officers, agents, licensors
            and successors and assigns (collectively, the “Indemnified Party”)
            from all damages and liability including, without limitation,
            reasonable attorneys’ fees, incurred as a result of (a) the
            Indemnifying Party’s violation of its obligations under this
            Agreement or a Service Order, (b) the negligent or willful acts of
            the Indemnifying Party, (c) violation of any third party
            intellectual property or privacy rights, (d) violation of any law or
            regulation, including but not limited to those respecting online
            privacy and security, or (e) any claim by any third party arising
            out of the Indemnifying Party’s conduct based on the use of the
            Services, Platform, or Software. The Indemnifying Party’s obligation
            to indemnify under this provision shall survive termination of this
            Agreement.
          </li>
        </ol>
      </div>

      <p>(end of document)</p>
    </div>
  );
}

export default TAC;
