//External
import React, { useEffect, useRef, useState } from "react";

//internal
import styles from "./ForgotPassword.module.scss";
import reactionLogo from "assets/images/reactionlogodark.png";
import technology from "assets/images/laptop.png";
import { TextFieldSimple } from "components/inputs/input_fields/TextFieldSimple/TextFieldSimple";
import { Header } from "components/layouts";
import Button from "components/Button/Button";
import { useNavigate, useParams } from "react-router-dom";
import {
  useResetPassword,
  useSendResetPassword,
} from "api/resources/authentication/forgotpassword";
import { Loading } from "components/Loading/Loading";
import FlexRow from "components/layouts/FlexRow/FlexRow";
import { Player } from "@lottiefiles/react-lottie-player";
import FlexCol from "components/layouts/FlexColumn/FlexCol";
import TokenExpired from "pages/error_page/TokenExpired";
import { useCheckPasswordResetToken } from "api/resources/authentication/register";

export function ForgotPassword() {
  const { token } = useParams();
  const checkTokenService = useCheckPasswordResetToken();
  const navigate = useNavigate();
  const [valid, setValid] = useState(true);
  const [message, setMessage] = useState("");
  const [email, setEmail] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    checkTokenService.mutate(
      {
        token: token,
      },
      {
        onSuccess: (data) => {
          setLoading(false);
          if (data?.status?.status === 1) {
            setEmail(data?.status?.email);
          } else {
            setValid(false);
            setMessage(data?.status?.description);
          }
        },
      }
    );
  }, []);

  return valid ? (
    <>
      {loading && <Loading></Loading>}
      {!loading && <FP email={email} token={token} />}
    </>
  ) : (
    <TokenExpired message={message}>
      {/* <Button blue width={200} shadow onClick={() => navigate("/register")}>
        Create new account
      </Button> */}
    </TokenExpired>
  );
}

function FP({}) {
  const { token } = useParams();
  let navigate = useNavigate();
  const routeChange = (path) => {
    navigate(path);
  };

  const resetPassword = useResetPassword();

  const [password, setPassword] = useState("");

  const [invalidPasswordMessage, setInvalidPasswordMessage] = useState(
    "Password must be at least 8 characters. Password must contain at least 1 uppercase letter, 1 lowercase letter, and 1 special character."
  );

  const [validPassword, setValidPassword] = useState();
  const [validConfirmPassword, setValidConfirmPassword] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [validationRules, setValidationRules] = useState([]);
  const [passwordType, setPasswordType] = useState({
    icon: "eye-slash",
    type: "password",
  });

  const [confirmPasswordType, setConfirmPasswordType] = useState({
    icon: "eye-slash",
    type: "password",
  });

  const handleResetPassword = (e) => {
    e.preventDefault();
    if (validConfirmPassword) {
      resetPassword.mutate(
        {
          password: password,
          id: token,
        },
        {
          onSuccess: (data) => {
            console.log(data);
            alert("Password Changed");
            routeChange("/");
            //go to login page
          },
        }
      );
    }
  };

  function validateUserPassword(p) {
    //   check if meets length requirement
    //   check if contains number
    //   check if contains capital letter
    //   check if contains lower case letter
    //   check if contains special character
    //   If doesn't meet requirments display message with what is missing
    let message = "";
    let valid = true;
    if (p.length < 8 || p.length > 64) {
      console.log("print");
      message =
        message +
        "Password must be at least 8 characters and less than 64 characters. ";
      valid = false;
    }
    if (!/[A-Z]/.test(p)) {
      message = message + "Password must contain at least 1 uppercase letter. ";
      valid = false;
    }
    if (!/[a-z]/.test(p)) {
      message = message + "Password must contain at least 1 lowercase letter. ";
      valid = false;
    }
    if (!/[0-9]/.test(p)) {
      message = message + "Password must contain at least 1 number. ";
      valid = false;
    }
    if (!/[" !"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~"]/.test(p)) {
      message =
        message + "Password must contain at least 1 special character. ";
      valid = false;
    }
    setInvalidPasswordMessage(message);
    setValidPassword(valid);
  }

  function handleChangePassword(password) {
    if (password === confirmPassword) {
      setValidConfirmPassword(true);
    } else {
      setValidConfirmPassword(false);
    }
    setPassword(password);
    validateUserPassword(password);
  }

  function handleChangeValidatePassword(p) {
    setConfirmPassword(p)
    if (p === password) {
      setValidConfirmPassword(true);
    } else {
      setValidConfirmPassword(false);
    }
  }

  function validateUserPassword(p) {
    //   check if meets length requirement
    //   check if contains number
    //   check if contains capital letter
    //   check if contains lower case letter
    //   check if contains special character
    //   If doesn't meet requirments display message with what is missing
    let message = "";
    let valid = false;
    const rules = [
      {
        rule: "Password must be at least 8 characters and less than 64 characters.",
        valid: p.length >= 8 && p.length <= 64,
      },
      {
        rule: "Password must contain at least 1 uppercase letter.",
        valid: /[A-Z]/.test(p),
      },
      {
        rule: "Password must contain at least 1 lowercase letter.",
        valid: /[a-z]/.test(p),
      },
      {
        rule: "Password must contain at least 1 number.",
        valid: /[0-9]/.test(p),
      },
      {
        rule: "Password must contain at least 1 special character.",
        valid: /[" !"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~"]/.test(p),
      },
    ];

    valid = rules.every((rule) => rule.valid);
    setValidationRules(rules);

    setInvalidPasswordMessage(message);
    setValidPassword(valid);
  }

  return (
    <>
      <div className={styles.page} style={{ width: "100%" }}>
        <div className={styles.imageContainer}>
          <img src={reactionLogo} />
        </div>
        <FlexRow justify={"center"}>
          <div className={styles.imageContainer2}>
            <img src={technology} />
          </div>
          <div className={styles.formContainer}>
            <>
              <div className={styles.header_4} style={{ margin: "0" }}>
                Welcome!
              </div>
              <div className={styles.text_1}>
                Please enter your new password
              </div>

              <form onSubmit={handleResetPassword}>
                <div className={styles.form}>
                  <TextFieldSimple
                    type={passwordType?.type}
                    onChange={handleChangePassword}
                    value={password}
                    valid={password && validPassword}
                    finalIcon={passwordType?.icon}
                    onClickFinalIcon={() => {
                      if (passwordType?.type === "password") {
                        setPasswordType({
                          icon: "eye",
                          type: "text",
                        });
                      } else {
                        setPasswordType({
                          icon: "eye-slash",
                          type: "password",
                        });
                      }
                    }}
                    invalidMessage={invalidPasswordMessage}
                    password
                    label="Password*"
                    validationRules={validationRules}
                    validationRulesTitle="Password Requirements"
                  />
                  <TextFieldSimple
                    type={confirmPasswordType?.type}
                    onChange={handleChangeValidatePassword}
                    value={confirmPassword}
                    finalIcon={confirmPasswordType?.icon}
                    onClickFinalIcon={() => {
                      if (confirmPasswordType?.type === "password") {
                        setConfirmPasswordType({
                          icon: "eye",
                          type: "text",
                        });
                      } else {
                        setConfirmPasswordType({
                          icon: "eye-slash",
                          type: "password",
                        });
                      }
                    }}
                    // placeholder="Confirm Password"
                    valid={validConfirmPassword}
                    invalidMessage={"Passwords must match"}
                    password
                    label="Confirm Password*"
                  />
                  <div className={styles.buttonContainer}>
                    <Button
                      id="submitButton"
                      blue
                      onClick={handleResetPassword}
                      type="submit"
                      shadow
                      disable={!validPassword || !password || !confirmPassword}
                    >
                      Reset Password
                    </Button>
                  </div>
                </div>
              </form>
            </>
          </div>
        </FlexRow>
      </div>
    </>
  );
}

export function useToken() {
  const getToken = () => {
    const tokenString = localStorage.getItem("reaction_token");
    if (tokenString != undefined) {
      return JSON.parse(tokenString);
    }
    return null;
  };

  const [token, setToken] = useState(getToken());

  const saveToken = (userToken) => {
    localStorage.setItem("reaction_token", JSON.stringify(userToken));
    setToken(userToken);
  };

  return {
    setToken: saveToken,
    token,
  };
}

export function ForgotPasswordStart({}) {
  const sendResetPassword = useSendResetPassword();
  const [loading, setLoading] = useState(false);
  const [validEmail, setValidEmail] = useState();
  const [sentInstructions, setSentInstructions] = useState(false);
  const [resend, setResend] = useState(true);
  const [resendCount, setResendCount] = useState(0);
  const [invalidEmailMessage, setInvalidEmailMessage] =
    useState("Reqire an Email");
  const [email, setEmail] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    if (email != "" || email.includes("@")) {
      setValidEmail(true);
    } else {
      setValidEmail(false);
    }
    if (email != "" || email.includes("@")) {
      setLoading(true);
      sendResetPassword.mutate(
        {
          email: email,
        },
        {
          onSuccess: (data) => {
            if (data.sendResetPassword) {
              setSentInstructions(true);
            } else {
              setValidEmail(false);
              setInvalidEmailMessage("User email does not exist");
            }

            setLoading(false);
          },
        }
      );
    }
  };

  function handleChangeEmail(email) {
    setEmail(email);
    // validateUserEmail();
  }

  useEffect(() => {
    // Stop countdown when it reaches 0
    if (resendCount <= 0) {
      if (!resend) {
        setResend(true);
      }

      return;
    }

    // Decrease the countdown every second
    const timer = setInterval(() => {
      setResendCount((prevTime) => prevTime - 1);
    }, 1000);

    // Cleanup interval on component unmount
    return () => clearInterval(timer);
  }, [resendCount]); // Re-run the effect when timeLeft changes

  useEffect(() => {
    if (sentInstructions) {
      setResend(false);
      setResendCount(30);
    }
  }, [sentInstructions]);

  const ref = useRef(null);
  return (
    <div className={styles.page} style={{ width: "100%" }}>
      <div className={styles.imageContainer}>
        <img src={require("assets/images/reactionlogonew.png")} />
      </div>
      <FlexRow justify={"center"} style={{ flexWrap: "wrap" }} gap={"2rem"}>
        <div className={styles.imageContainer2}>
          <img src={technology} />
        </div>
        <div className={styles.formContainer} style={{ width: "550px" }}>
          {sentInstructions === false && !loading && (
            <>
              <div className={styles.header_4} style={{ margin: "0" }}>
                Welcome!
              </div>
              <div className={styles.text_1}>
                To reset your password, please provide your email.
              </div>

              <div className={styles.form}>
                <div>
                  <TextFieldSimple
                    id="emailField"
                    label="Email"
                    type="text"
                    onChange={handleChangeEmail}
                    placeholder="Email"
                    valid={validEmail}
                    invalidMessage={invalidEmailMessage}
                  />
                </div>

                <div className={styles.buttonContainer}>
                  <Button
                    id="submitButton"
                    blue
                    onClick={handleSubmit}
                    type="submit"
                    shadow
                  >
                    Send Reset Instructions
                  </Button>
                </div>
              </div>
            </>
          )}
          {loading && (
            <FlexCol>
              <Player
                autoplay
                loop
                src={require("assets/animations/EmailSending.json")}
                style={{
                  height: "150px",
                  // width: "100px",
                }}
              />
              <FlexRow gap={".1rem"} justify={"center"}>
                <div className={styles.text_1}>Sending Reset Instructions</div>
                <span className={styles.dots}>
                  <span>.</span>
                  <span>.</span>
                  <span>.</span>
                </span>
              </FlexRow>
            </FlexCol>
          )}
          {sentInstructions === true && (
            <>
              <FlexRow>
                {" "}
                <div className={styles.header_4} style={{ margin: "0" }}>
                  Instructions Sent
                </div>{" "}
                <Player
                  autoplay={true}
                  loop={false}
                  ref={ref}
                  onEvent={(e) => {
                    if (e === "play") {
                      setTimeout(() => {
                        ref?.current?.pause();
                      }, 2000);
                    }
                  }}
                  src={require("assets/animations/checkmarkgreen.json")}
                  style={{
                    height: "40px",
                    // width: "50px",
                  }}
                />
              </FlexRow>

              <div className={styles.text_1}>Sent to {email}</div>
              <div className={styles.text_1}>
                Please check your email to finish resetting your password.
              </div>
              <div className={styles.text_1}>You may now exit this page.</div>
              <div
                className={styles.text_2}
                style={{ marginTop: "1rem", textAlign: "center" }}
              >
                Didn't get the email?{" "}
                {resend ? (
                  <a
                    className={styles.link}
                    onClick={(e) => {
                      setResend(false);
                      setResendCount(30);
                      setSentInstructions(false);
                      handleSubmit(e);
                    }}
                  >
                    Click to resend code
                  </a>
                ) : (
                  <span>Can resend instructions in {resendCount} seconds</span>
                )}
              </div>
            </>
          )}{" "}
        </div>
      </FlexRow>
    </div>
  );
}
