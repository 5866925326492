import { useEffect, useRef, useState } from "react";
import styles from "./Formula.module.scss";
import { shortId } from "components/tables/EditableTable/utils";
import { forEach, getReversed } from "assets/functions/ArrayFunctions";
import { functions, operators } from "../Writer/FrontEndCalculator/Calculator";

export const parenthColors = [
  "#ffc17a", // Peach
  "#417394", // Blue
  "#58b346", // Green
  "#f5856c", // Red
];

export const funcColor = "#e8886f";
export const opColor = "#738c91";
export const tagColor = "#52afc7";
export const optionColor = "#74b9a8";
export const choiceColor = "#e3b719";
export const numColor = "#8960a8";
export const varColor = "#6793a5";

export function Formula({
  formula,
  onSave,
  onLoseFocus,
  onChange,
  editable,
  tags,
}) {
  const [active, setActive] = useState(false);
  const [lastSelection, setLastSelection] = useState();
  const [runSave, setRunSave] = useState(false);
  const [edited, setEdited] = useState(false);

  const defaultBlack = "#050606";

  const [empty, setEmpty] = useState(false);

  const [record, setRecord] = useState([]);

  function createDisplay(dataCopy, onComplete) {
    if (entry.current) {
      entry.current.innerHTML = "";

      if (dataCopy.length === 1 && !dataCopy[0].text) {
        setEmpty(true);
      } else {
        setEmpty(false);
      }

      let variables = [];

      for (let div of dataCopy) {
        let spans = [];
        let word = "";
        let pInd = 0;
        let colorInd = 0;

        function isNumber(val) {
          const num = parseFloat(val);
          if (!isNaN(num) && isFinite(num) && num == val) {
            return true;
          }
          return false;
        }

        function isTagChoice(name) {
          let tag = tags.find((t) => t.name === name);
          if (tag && tag.question.length) {
            let trimmed = word.trim();
            if (tag.type.includes("Number Scale")) {
              if (isNumber(trimmed)) {
                const num = parseFloat(trimmed);
                return tag.question.some(
                  (q) =>
                    q.scaleQuestion?.min <= num && q.scaleQuestion?.max >= num
                );
              }
            } else if (
              tag.type.includes("Multiple Choice") ||
              tag.type.includes("Ranking")
            ) {
              return tag.question.some((q) =>
                q.choiceQuestion?.choices?.includes(trimmed)
              );
            }
          }

          return false;
        }

        function addWord() {
          if (word) {
            let span = { text: word };

            let trimmed = word.trim();

            if (functions.includes(trimmed)) {
              span.func = true;
            } else if (tags.some((t) => t.name === trimmed)) {
              span.tag = true;
            } else if (
              spans.length >= 2 &&
              spans[spans.length - 1].text.trim() === "." &&
              spans[spans.length - 2].tag
            ) {
              let tag = tags.find(
                (t) => t.name === spans[spans.length - 2].text.trim()
              );
              if (
                tag &&
                tag.question.some((q) =>
                  q.matrixQuestion?.options.includes(word.trim())
                )
              ) {
                span.option = true;
              }
            } else if (
              spans.length >= 2 &&
              spans[spans.length - 1].text.trim() === "?" &&
              spans[spans.length - 2].tag
            ) {
              if (isTagChoice(spans[spans.length - 2].text.trim()))
                span.choice = true;
            } else if (
              spans.length >= 4 &&
              spans[spans.length - 1].text.trim() === "?" &&
              spans[spans.length - 2].option &&
              spans[spans.length - 3].text === "." &&
              spans[spans.length - 4].tag
            ) {
              // Matrix
              if (isTagChoice(spans[spans.length - 4].text.trim()))
                span.choice = true;
            } else if (variables.includes(trimmed)) {
              span.var = true;
            } else if (isNumber(trimmed)) {
              span.num = true;
            }

            spans.push(span);
            word = "";
          }
        }

        for (let char of div.text) {
          if (char == "(") {
            addWord();
            spans.push({
              text: char,
              parenths: true,
              open: true,
              ind: pInd,
            });
            pInd++;
          } else if (char == ")") {
            pInd--;
            addWord();
            spans.push({
              text: char,
              parenths: true,
              close: true,
              ind: pInd,
            });
          } else if (char == "=" && !spans.length && word) {
            variables.push(word.trim());
            addWord();
            spans.push({ text: char, op: true });
          } else if (operators.includes(char)) {
            addWord();
            spans.push({ text: char, op: true });
          } else if (char == ".") {
            addWord();
            spans.push({ text: char });
          } else {
            word += char;

            if (word.length >= 3) {
              let end = word.length - 1;
              if (
                word[end - 2] == " " &&
                word[end - 1] == "o" &&
                word[end] == "r"
              ) {
                word = word.slice(0, end - 1);
                addWord();
                spans.push({ text: "or", op: true });
              }
            }

            if (word.length >= 4) {
              let end = word.length - 1;
              if (
                word[end - 3] == " " &&
                word[end - 2] == "a" &&
                word[end - 1] == "n" &&
                word[end] == "d"
              ) {
                word = word.slice(0, end - 2);
                addWord();
                spans.push({ text: "and", op: true });
              }
            }
          }
        }

        addWord();

        if (!spans.length) {
          spans = [{ text: "" }];
        }

        let realDiv = document.createElement("div");
        realDiv.id = div.id;
        for (let i = 0; i < spans.length; i++) {
          let span = spans[i];
          let realSpan = document.createElement("span");

          if (
            span.parenths &&
            (span.close ||
              (span.open &&
                spans
                  .slice(i + 1)
                  .some((s) => s.parenths && s.close && s.ind == span.ind)))
          ) {
            if (span.close) colorInd--;
            let nextCol = colorInd % parenthColors.length;
            realSpan.style.color = parenthColors[nextCol];
            if (span.open) colorInd++;
          } else if (span.func) {
            realSpan.style.color = funcColor;
          } else if (span.op) {
            realSpan.style.color = opColor;
          } else if (span.tag) {
            realSpan.style.color = tagColor;
          } else if (span.option) {
            realSpan.style.color = optionColor;
          } else if (span.choice) {
            realSpan.style.color = choiceColor;
          } else if (span.num) {
            realSpan.style.color = numColor;
          } else if (span.var) {
            realSpan.style.color = varColor;
          }

          realSpan.appendChild(
            document.createTextNode(span.text ? span.text : "\n")
          );
          realDiv.appendChild(realSpan);
        }

        entry.current.appendChild(realDiv);
      }

      if (onComplete) {
        onComplete();
      }
    }
  }

  const initEncoding = () => {
    let given = formula ? formula : "";
    if (given) {
      let t = typeof formula;
      if (t !== "string") {
        if (t === "number") {
          given = given.toString();
        } else {
          given = "";
        }
      }
    }

    let lines = given.split("\n");
    let divs = [];
    for (let line of lines) {
      divs.push({
        id: shortId(),
        text: line,
      });
    }

    return divs;
  };

  const [data, setData] = useState(initEncoding());

  useEffect(() => {
    createDisplay(data);
  }, []);

  useEffect(() => {
    if (runSave) {
      onBlur();
      setRunSave(false);
    }
  }, [runSave]);

  function saveData(copy) {
    let recordCopy = [...record];
    recordCopy.push({ data: data, selection: lastSelection });
    setRecord(recordCopy);
    setData(copy);
    setEdited(true);
  }

  function getDataCopy() {
    let copy = [...data];
    let trueCopy = JSON.parse(JSON.stringify(copy));
    return trueCopy;
  }

  function getText() {
    let text = "";
    for (let i = 0; i < data.length; i++) {
      text += data[i].text;
      if (i < data.length - 1) {
        text += "\n";
      }
    }
    return text;
  }

  function onBlur() {
    if ((onSave && edited) || onChange || onLoseFocus) {
      let text = getText();
      if (onSave) {
        onSave(text);
      }

      if (onChange) {
        onChange(text);
      }

      if (onLoseFocus) {
        onLoseFocus(text);
      }

      setEdited(false);
    }
  }

  function handleChange(e) {
    e.preventDefault();

    if (!lastSelection) {
      return;
    }
    if (lastSelection.isCollapsed) {
      if (
        e.nativeEvent.inputType === "deleteContentBackward" &&
        lastSelection.offset == 0
      ) {
        onDeleteBegginningOfDiv(e);
        return;
      }

      let copy = getDataCopy();
      let index = data.findIndex((d) => d.id === lastSelection.divId);
      let endOffset = lastSelection.offset;

      if (e.nativeEvent.inputType === "insertText") {
        let addition = e.nativeEvent.data;

        if (addition == "(") {
          addition += ")";
        }
        if (endOffset === copy[index].text.length) {
          copy[index].text += addition;
        } else if (!endOffset) {
          // at the start
          copy[index].text = addition + copy[index].text;
        } else {
          let first = copy[index].text.slice(0, endOffset);
          let second = copy[index].text.slice(endOffset);

          if (addition != ")" || second.charAt(0) != ")") {
            copy[index].text = first + addition + second;
          }
        }

        endOffset++;
      } else if (e.nativeEvent.inputType === "deleteContentBackward") {
        if (endOffset === copy[index].text.length) {
          copy[index].text = copy[index].text.slice(
            0,
            copy[index].text.length - 1
          );
        } else {
          let out = copy[index].text.charAt(endOffset - 1);

          let first = copy[index].text.slice(0, endOffset - 1);
          let second = copy[index].text.slice(endOffset);

          if (out === "(" && second.charAt(0) === ")") {
            second = second.slice(1);
          }
          copy[index].text = first + second;
        }

        endOffset--;
      }

      saveData(copy);
      createDisplay(copy, () => {
        let target = document.getElementById(copy[index].id);
        let subCount = 0;
        let count = 0;
        for (let node of target.childNodes) {
          let text = node.nodeName === "#text" ? node : node.childNodes[0];
          if (text) {
            if (text.nodeValue) {
              subCount = 0;
              for (let i = 0; i < text.nodeValue.length; i++) {
                if (count < endOffset) {
                  count++;
                  subCount++;
                }
              }

              if (count == endOffset) {
                target = text;
                break;
              }
            }
          }
        }

        const newRange = document.createRange();
        newRange.setStart(target, subCount);
        newRange.collapse(true); // Collapse the range to the start position

        // Remove any existing selections and set the new range
        const selection = window.getSelection();
        selection.removeAllRanges();
        selection.addRange(newRange);
      });
    } else {
      // Inserting or deleting on a selection
      let isADelete = e.nativeEvent.inputType === "deleteContentBackward";
      let isAnInsert = e.nativeEvent.inputType === "insertText";
      if (isAnInsert || isADelete) {
        let newChar = isAnInsert ? e.nativeEvent.data : "";
        handleSelectionInsert(newChar);
      }
    }
  }

  function onDeleteBegginningOfDiv(e) {
    let copy = getDataCopy();
    // Merge a section back on to another one;
    let index = copy.findIndex((c) => c.id === lastSelection.divId);

    if (!index) {
      // it's at the top
      createDisplayAndDoSelection(data, lastSelection);

      return;
    }

    let selectionToBe = {
      isCollapsed: true,
      divId: copy[index - 1].id,
      offset: copy[index - 1].text.length,
    };

    if (copy[index].text) {
      copy[index - 1].text += copy[index].text;
    }

    copy.splice(index, 1);
    saveData(copy);
    createDisplayAndDoSelection(copy, selectionToBe);
  }

  function insertNewLine() {
    if (!lastSelection) {
      return;
    }

    if (lastSelection.isCollapsed) {
      // if it's got text, split the text and the styles.....
      let copy = getDataCopy();
      let index = copy.findIndex((d) => d.id === lastSelection.divId);
      let firstPart = copy[index].text.substring(0, lastSelection.offset);
      let secondPart = copy[index].text.substring(lastSelection.offset);

      copy[index].text = firstPart;
      let newDiv = {
        id: shortId(),
        text: secondPart,
      };

      copy.splice(index + 1, 0, newDiv);
      saveData(copy);

      createDisplay(copy, () => {
        let targetNode = document.getElementById(newDiv.id);
        while (targetNode.nodeName !== "#text") {
          targetNode = targetNode.childNodes[0];
        }

        const newRange = document.createRange();
        newRange.setStart(targetNode, 0);
        newRange.collapse(true); // Collapse the range to the start position

        // Remove any existing selections and set the new range
        const selection = window.getSelection();
        selection.removeAllRanges();
        selection.addRange(newRange);
      });
    } else {
      let copy = getDataCopy();
      let divTrashCan = [];
      let startInd = copy.findIndex((d) => d.id === lastSelection.start.divId);
      let endInd = copy.findIndex((d) => d.id === lastSelection.end.divId);

      for (let i = startInd; i <= endInd; i++) {
        if (i === startInd && i === endInd) {
          let beginning = copy[i].text.substring(0, lastSelection.start.offset);
          let end = copy[i].text.substring(lastSelection.end.offset + 1);
          copy[i].text = beginning;
          let newDiv = {
            text: end,
            id: shortId(),
          };

          copy.splice(i + 1, 0, newDiv);
        } else if (i == startInd) {
          let beginning = copy[i].text.substring(0, lastSelection.start.offset);
          copy[i].text = beginning;

          let end = copy[endInd].text.substring(lastSelection.end.offset + 1);
          copy[endInd].text = end;

          //add appended end text and styles
        } else if (startInd < i && i < endInd) {
          divTrashCan.push(i);
        }
      }

      if (divTrashCan.length) {
        let keeping = [];
        forEach(copy, (d, i) => {
          if (!divTrashCan.includes(i)) {
            keeping.push(d);
          }
        });
        copy = keeping;
      }

      saveData(copy);
      createDisplayAndDoSelection(copy, {
        divId: copy[startInd + 1].id,
        offset: 0,
        isCollapsed: true,
      });
    }
  }

  function handlePaste(e) {
    e.preventDefault();

    if (lastSelection && e.clipboardData.types.includes("text/plain")) {
      let newText = e.clipboardData.getData("text");
      let paragraphs = newText.split("\n");
      if (paragraphs.length > 1) {
        handlePasteParagraphs(paragraphs);
        return;
      }

      if (lastSelection.isCollapsed) {
        let copy = getDataCopy();
        let div = copy.find((d) => d.id === lastSelection.divId);
        // add new text to this div
        let beginning = div.text.substring(0, lastSelection.offset);
        let end = div.text.substring(lastSelection.offset);
        div.text = beginning + newText + end;

        saveData(copy);
        createDisplay(copy, () => {
          let target = document.getElementById(lastSelection.divId);
          let count = 0;
          let targetOffset = 0;
          for (let node of target.childNodes) {
            let text = node.nodeName === "#text" ? node : node.childNodes[0];
            if (text) {
              let textOffset = 0;
              if (text.nodeValue) {
                for (let i = 0; i < text.nodeValue.length; i++) {
                  if (count < lastSelection.offset + newText.length) {
                    count++;
                    textOffset++;
                  }
                }
              }
              if (count == lastSelection.offset + newText.length) {
                target = text;
                targetOffset = textOffset;
                break;
              }
            }
          }

          const newRange = document.createRange();
          newRange.setStart(target, targetOffset);
          newRange.collapse(true); // Collapse the range to the start position

          // Remove any existing selections and set the new range
          const selection = window.getSelection();
          selection.removeAllRanges();
          selection.addRange(newRange);
        });
      } else {
        handleSelectionInsert(newText);
      }
    }
  }

  function handleSelectionInsert(newText) {
    let copy = getDataCopy();
    let divTrashCan = [];
    let startInd = copy.findIndex((d) => d.id === lastSelection.start.divId);
    let endInd = copy.findIndex((d) => d.id === lastSelection.end.divId);

    for (let i = startInd; i <= endInd; i++) {
      if (i === startInd && i === endInd) {
        let beginning = copy[i].text.substring(0, lastSelection.start.offset);
        let end = copy[i].text.substring(lastSelection.end.offset + 1);
        copy[i].text = beginning + newText + end;
        // insert the new text after deleting the old.
      } else if (i == startInd) {
        let beginning = copy[i].text.substring(0, lastSelection.start.offset);
        copy[i].text = beginning + newText;

        //add appended end text and styles
        let end = copy[endInd].text.substring(lastSelection.end.offset + 1);
        if (end) {
          copy[i].text += end;
        }
      } else if (startInd < i && i < endInd) {
        divTrashCan.push(i);
      } else if (i == endInd) {
        divTrashCan.push(i);
      }
    }

    if (divTrashCan.length) {
      let keeping = [];
      forEach(copy, (d, i) => {
        if (!divTrashCan.includes(i)) {
          keeping.push(d);
        }
      });
      copy = keeping;
    }

    saveData(copy);
    createDisplay(copy, () => {
      let target = document.getElementById(lastSelection.start.divId);
      let count = 0;
      let targetOffset = 0;
      for (let node of target.childNodes) {
        let text = node.nodeName === "#text" ? node : node.childNodes[0];
        if (text) {
          let textOffset = 0;
          if (text.nodeValue) {
            for (let i = 0; i < text.nodeValue.length; i++) {
              if (count < lastSelection.start.offset) {
                count++;
                textOffset++;
              }
            }
          }
          if (
            count == lastSelection.start.offset &&
            textOffset < text.nodeValue.length
          ) {
            target = text;
            targetOffset = textOffset + newText.length;

            break;
          }
        }
      }

      const newRange = document.createRange();
      newRange.setStart(target, targetOffset);
      newRange.collapse(true); // Collapse the range to the start position

      // Remove any existing selections and set the new range
      const selection = window.getSelection();
      selection.removeAllRanges();
      selection.addRange(newRange);
    });
  }

  function handlePasteParagraphs(paragraphs) {
    let copy = getDataCopy();
    if (lastSelection.isCollapsed) {
      let index = copy.findIndex((d) => d.id === lastSelection.divId);
      // break the styles in two.
      let beginning = copy[index].text.substring(0, lastSelection.offset);
      let end = copy[index].text.substring(lastSelection.offset + 1);
      copy[index].text = beginning + paragraphs[0];

      // keep the old styles
      let endFirstPart = paragraphs[paragraphs.length - 1];

      let endDiv = {
        id: shortId(),
        text: endFirstPart + end,
      };
      copy.splice(index + 1, 0, endDiv);

      // Add the middle ones in
      for (let i = 1; i < paragraphs.length - 1; i++) {
        let newDiv = {
          text: paragraphs[i],
          id: shortId(),
        };
        copy.splice(index + i, 0, newDiv);
      }

      saveData(copy);
      createDisplay(copy, () => {
        let target = document.getElementById(endDiv.id);
        let count = 0;
        let targetOffset = 0;
        for (let node of target.childNodes) {
          let text = node.nodeName === "#text" ? node : node.childNodes[0];
          if (text) {
            let textOffset = 0;
            if (text.nodeValue) {
              for (let i = 0; i < text.nodeValue.length; i++) {
                if (count < endFirstPart.length) {
                  count++;
                  textOffset++;
                }
              }
            }
            if (count == endFirstPart.length) {
              target = text;
              targetOffset = textOffset;
              break;
            }
          }
        }

        const newRange = document.createRange();
        newRange.setStart(target, targetOffset);
        newRange.collapse(true); // Collapse the range to the start position

        // Remove any existing selections and set the new range
        const selection = window.getSelection();
        selection.removeAllRanges();
        selection.addRange(newRange);
      });
    } else {
      let divTrashCan = [];
      let startInd = copy.findIndex((d) => d.id === lastSelection.start.divId);
      let endInd = copy.findIndex((d) => d.id === lastSelection.end.divId);
      let endingId;

      for (let i = startInd; i <= endInd; i++) {
        if (i === startInd && i === endInd) {
          let beginning = copy[i].text.substring(0, lastSelection.start.offset);
          let end = copy[i].text.substring(lastSelection.end.offset + 1);

          let newStartDiv = {
            text: beginning + paragraphs[0],
            id: shortId(),
          };
          let newEndDiv = {
            text: paragraphs[paragraphs.length - 1] + end,
            id: shortId(),
          };
          endingId = newEndDiv.id;

          copy.splice(startInd, 1, newStartDiv);
          copy.splice(startInd + 1, 0, newEndDiv);
        } else if (i == startInd) {
          let beginning = copy[i].text.substring(0, lastSelection.start.offset);
          copy[i].text = beginning + paragraphs[0];
        } else if (startInd < i && i < endInd) {
          divTrashCan.push(i);
        } else if (i == endInd) {
          let lastParagraphText = paragraphs[paragraphs.length - 1];

          // append the last paragraph onto the end div
          let end = copy[endInd].text.substring(lastSelection.end.offset + 1);
          copy[i].text = lastParagraphText + end;
          endingId = copy[i].id;
        }
      }

      if (divTrashCan.length) {
        let keeping = [];
        forEach(copy, (d, i) => {
          if (!divTrashCan.includes(i)) {
            keeping.push(d);
          }
        });
        copy = keeping;
      }

      // Add the middle ones in
      for (let j = 1; j < paragraphs.length - 1; j++) {
        let newDiv = {
          text: paragraphs[j],
          id: shortId(),
        };
        copy.splice(startInd + j, 0, newDiv);
      }

      saveData(copy);
      createDisplay(copy, () => {
        let target = document.getElementById(endingId);
        let count = 0;
        let targetOffset = 0;
        let endOfPaste = paragraphs[paragraphs.length - 1].length;
        for (let node of target.childNodes) {
          let text = node.nodeName === "#text" ? node : node.childNodes[0];
          if (text) {
            let textOffset = 0;
            if (text.nodeValue) {
              for (let i = 0; i < text.nodeValue.length; i++) {
                if (count < endOfPaste) {
                  count++;
                  textOffset++;
                }
              }
            }
            if (count == endOfPaste) {
              target = text;
              targetOffset = textOffset;
              break;
            }
          }
        }

        const newRange = document.createRange();
        newRange.setStart(target, targetOffset);
        newRange.collapse(true); // Collapse the range to the start position

        // Remove any existing selections and set the new range
        const selection = window.getSelection();
        selection.removeAllRanges();
        selection.addRange(newRange);
      });
    }
  }

  function handleCut(e) {
    if (lastSelection) {
      if (!lastSelection.isCollapsed) {
        if (navigator?.clipboard) {
          let startInd = data.findIndex(
            (div) => div.id === lastSelection.start.divId
          );
          let endInd = data.findIndex(
            (div) => div.id === lastSelection.end.divId
          );
          let string = "";

          for (let i = startInd; i <= endInd; i++) {
            if (i == startInd && i == endInd) {
              string = data[i].text.slice(
                lastSelection.start.offset,
                lastSelection.end.offset + 1
              );
            } else if (i == startInd) {
              string += data[i].text.slice(lastSelection.start.offset) + "\n";
            } else if (i > startInd && i < endInd) {
              string += data[i].text + "\n";
            } else if (i == endInd) {
              string += data[i].text.slice(0, lastSelection.end.offset + 1);
            }
          }

          if (document.hasFocus()) {
            navigator.clipboard.writeText(string);
            // .then(() => console.log("clipboard cut"));
          }
        }

        handleSelectionInsert("");
      }
    }
  }

  const container = useRef();
  const entry = useRef();
  const colorRef = useRef();

  function closeEditor(e) {
    if (
      container.current &&
      !container.current.contains(e.target) &&
      !colorRef.current
    ) {
      document.removeEventListener("click", closeEditor, true);
      setActive(false);
    }
  }

  function onSelect(e) {
    if (!active) {
      setActive(true);
      setTimeout(() => {
        // In case there was a select and the cursor ends outside
        document.addEventListener("click", closeEditor, true);
      }, 100);
    }

    const selection = window.getSelection();

    if (!selection.rangeCount) {
      return;
    }
    const range = selection.getRangeAt(0);

    // TODO - Is this necessary \/ (below)
    if (range.startContainer == entry.current) {
      // console.log("hit");
      setLastSelection(null);
      return;
    } // This /\ -- might be

    if (selection.isCollapsed) {
      let endOffset = range.endOffset;
      const endContainer = range.endContainer;

      let section = endContainer;

      if (
        endContainer.nodeName === "DIV" &&
        endContainer.parentElement === entry.current &&
        endOffset == 1
      ) {
        // its a blank div
        endOffset = 0;
      } else {
        while (section.parentElement !== entry.current) {
          section = section.parentElement;
        }

        // count the characters until the actual offset
        for (let node of section.childNodes) {
          if (node.nodeName === "BR") {
            continue;
          }
          let text = node.nodeName === "#text" ? node : node.childNodes[0]; // In case the text is outside of the span, which the browser will do on text entry on a blank line/div
          if (text === endContainer) {
            break;
          }
          if (text.nodeValue) {
            endOffset += text.nodeValue.length;
          }
        }
      }

      setLastSelection({
        divId: section.id,
        offset: endOffset,
        isCollapsed: selection.isCollapsed,
      });
    } else {
      // use selection.focusNode && focusOffset to find cursor position

      let start = range.startContainer;
      let startOffset = range.startOffset;

      if (start.nodeName === "#text") {
        while (start.parentElement !== entry.current) {
          start = start.parentElement;
        }

        for (let node of start.childNodes) {
          let text = node.nodeName === "#text" ? node : node.childNodes[0];
          if (text === range.startContainer) {
            break;
          }
          if (text.nodeValue) {
            startOffset += text.nodeValue.length;
          }
        }
      }

      let end = range.endContainer;
      let endOffset = range.endOffset - 1;

      if (end.nodeName === "#text") {
        while (end.parentElement !== entry.current) {
          end = end.parentElement;
        }

        for (let node of end.childNodes) {
          let text = node.nodeName === "#text" ? node : node.childNodes[0];
          if (text === range.endContainer) {
            break;
          }
          if (text.nodeValue) {
            endOffset += text.nodeValue.length;
          }
        }
      }

      let focus = null;
      let focusOffset = 0;

      if (selection.focusNode === range.startContainer) {
        focus = start;
        focusOffset = startOffset;
      } else if (selection.focusNode === range.endContainer) {
        focus = end;
        focusOffset = endOffset;
      }

      setLastSelection({
        start: {
          divId: start.id,
          offset: startOffset,
        },
        end: {
          divId: end.id,
          offset: endOffset,
        },
        focus: {
          divId: focus?.id,
          offset: focusOffset,
        },
        isCollapsed: selection.isCollapsed,
      });
    }
  }

  function createDisplayAndDoSelection(dataCopy, selection) {
    createDisplay(dataCopy, () => {
      if (selection) {
        if (selection.isCollapsed) {
          let target = document.getElementById(selection.divId);
          let subCount = 0;
          let count = 0;
          for (let node of target.childNodes) {
            let text = node.nodeName === "#text" ? node : node.childNodes[0];
            if (text) {
              if (text.nodeValue) {
                subCount = 0;
                for (let i = 0; i < text.nodeValue.length; i++) {
                  if (count < selection.offset) {
                    count++;
                    subCount++;
                  }
                }

                if (count == selection.offset) {
                  target = text;
                  break;
                }
              }
            }
          }

          const newRange = document.createRange();
          newRange.setStart(target, subCount);
          newRange.collapse(true); // Collapse the range to the start position

          // Remove any existing selections and set the new range
          const windowSelection = window.getSelection();
          windowSelection.removeAllRanges();
          windowSelection.addRange(newRange);
        } else {
          let startTarget = document.getElementById(selection.start.divId);
          let subCount = 0;
          let count = 0;
          for (let node of startTarget.childNodes) {
            let text = node.nodeName === "#text" ? node : node.childNodes[0];
            if (text) {
              if (text.nodeValue) {
                subCount = 0;
                for (let i = 0; i < text.nodeValue.length; i++) {
                  if (count < selection.start.offset) {
                    count++;
                    subCount++;
                  }
                }

                if (count == selection.start.offset) {
                  startTarget = text;
                  break;
                }
              }
            }
          }

          let endTarget = document.getElementById(selection.end.divId);
          let endSubCount = 0;
          count = 0;
          for (let node of endTarget.childNodes) {
            let text = node.nodeName === "#text" ? node : node.childNodes[0];
            if (text) {
              if (text.nodeValue) {
                endSubCount = 0;
                for (let i = 0; i < text.nodeValue.length; i++) {
                  if (count < selection.end.offset + 1) {
                    count++;
                    endSubCount++;
                  }
                }

                if (count == selection.end.offset + 1) {
                  endTarget = text;
                  break;
                }
              }
            }
          }

          const newRange = document.createRange();
          newRange.setStart(startTarget, subCount);
          newRange.setEnd(endTarget, endSubCount);

          // Remove any existing selections and set the new range
          const windowSelection = window.getSelection();
          windowSelection.removeAllRanges();
          windowSelection.addRange(newRange);
        }
      }
    });
  }

  function undo() {
    let recordCopy = [...record];
    let last = recordCopy.pop();
    if (last) {
      if (last.data && last.selection) {
        createDisplayAndDoSelection(last.data, last.selection);
        setData(last.data);
        setEdited(true);
        setRunSave(true);
      }
      setRecord(recordCopy);
    }
  }

  function onKeydown(e) {
    if (e.key === "Enter") {
      e.preventDefault();
      insertNewLine();
    }

    if (e.ctrlKey || e.metaKey) {
      if (e.key === "z") {
        e.preventDefault();
        undo();
      }
      if (e.key === "b") {
        e.preventDefault();
      }
      if (e.key === "i") {
        e.preventDefault();
      }
      if (e.key === "u") {
        e.preventDefault();
      }
    }

    if (e.key === "Tab") {
      e.preventDefault();
    }
  }

  useEffect(() => {
    let val = initEncoding();
    setData(val);
    createDisplay(val);
  }, [formula]);

  useEffect(() => {
    let val = initEncoding();
    setData(val);
    createDisplay(val);
    setActive(false);
  }, [editable]);

  useEffect(() => {
    if (editable && onChange) {
      onBlur();
    }
  }, [data]);

  return (
    <div
      className={`${styles.container} ${editable ? styles.editable : ""}`}
      ref={container}
      onBlur={onBlur}
      // style={{ ...containerStyle }}
    >
      {empty && (
        <div className={`${styles.placeholder}`}>Enter Calculation</div>
      )}
      <div
        className={`${styles.entry} ${active ? styles.entryActive : ""}`}
        contentEditable={editable}
        onInput={handleChange}
        onSelect={editable ? onSelect : null}
        ref={entry}
        onKeyDown={editable ? onKeydown : null}
        onPaste={editable ? handlePaste : null}
        onCut={editable ? handleCut : null}
        suppressContentEditableWarning={true}
        spellCheck={"false"}
      ></div>
    </div>
  );
}
