import styles from "./Calculations.module.scss";
import FlexCol from "components/layouts/FlexColumn/FlexCol";
import {
  useCreateCalculation,
  useDeleteCalculation,
  useFetchCalculations,
} from "api/resources/organization/calculations";
import { Loading } from "components/Loading/Loading";
import FlexRow from "components/layouts/FlexRow/FlexRow";
import { useMemo, useState } from "react";
import { CalculationWriter } from "./Writer/CalculationWriter";
import Button from "components/Button/Button";

export function Calculations({ updateRoute }) {
  const fetchCalculations = useFetchCalculations();
  const create = useCreateCalculation();
  const deleteCalc = useDeleteCalculation();

  const [calc, setCalc] = useState();
  const [creating, setCreating] = useState(false);
  const [error, setError] = useState(false);

  useMemo(() => {
    if (updateRoute) {
      updateRoute(7);
    }
  }, []);

  function onClose() {
    setCalc(null);
  }

  function onCreate() {
    if (creating) return;
    create.mutate(
      {
        data: {
          name: "",
          formula: "",
          decimals: 0,
          type: "number",
        },
      },
      {
        onSuccess: (data) => {
          setCalc({ ...data.calc });
          setCreating(false);
        },
        onError: (data) => {
          setCreating(false);
          setError(true);
        },
      }
    );
    setCreating(true);
    if (error) setError(false);
  }

  function onDelete(calculation) {
    if (
      window.confirm(`Are you sure you want to delete: ${calculation.name}`)
    ) {
      deleteCalc.mutate({
        id: calculation.id,
      });
    }
  }

  return (
    <FlexCol gap={"1rem"} style={{ height: "100vh", padding: "1rem" }}>
      {calc ? (
        <CalculationWriter
          calculation={calc}
          onClose={onClose}
        ></CalculationWriter>
      ) : (
        <>
          <FlexCol gap={"10px"}>
            <div className={styles.header_4} style={{ margin: "0" }}>
              Custom Calculations
            </div>

            {fetchCalculations.isLoading && <Loading></Loading>}
            {fetchCalculations.isSuccess &&
              fetchCalculations.data.calculations
                .filter((c) => c.organizationId)
                .map((calc) => (
                  <FlexRow gap={15}>
                    <FlexRow
                      className={styles.calculation}
                      onClick={() => setCalc(calc)}
                      
                    >
                      <FlexCol gap={".5rem"} style={{width: "90%"}}>
                        <span>{calc.name}</span>
                        <span className={styles.desc}>{calc.formula}</span>
                      </FlexCol>
                      <div className={styles.edit}>edit</div>
                    </FlexRow>

                    <i
                      className={`bi-trash3 ${styles.trash}`}
                      onClick={() => onDelete(calc)}
                    ></i>
                  </FlexRow>
                ))}

            <FlexRow style={{ padding: "5px 10px" }}>
              <Button shadow seafoam style={{ gap: "10px" }} onClick={onCreate}>
                {creating ? (
                  <>
                    Creating... <Loading height={30} width={30}></Loading>
                  </>
                ) : (
                  <>+ Add Calculation</>
                )}
              </Button>
              {error && (
                <div className={styles.error}>
                  Error: Can't create calculation
                </div>
              )}
            </FlexRow>
          </FlexCol>

          {fetchCalculations.isSuccess && (
            <FlexCol gap={"10px"}>
              <div className={styles.header_4} style={{ margin: "0" }}>
                Given Calculations
              </div>

              {fetchCalculations.isLoading && <Loading></Loading>}
              {fetchCalculations.isSuccess &&
                fetchCalculations.data.calculations
                  .filter((c) => !c.organizationId)
                  .map((calc) => (
                    <FlexRow
                      className={styles.calculation}
                      onClick={() => setCalc(calc)}
                    >
                      <FlexCol gap={".5rem"} style={{width: "90%"}}>
                        <span>{calc.name}</span>
                        <span className={styles.desc}>{calc.formula}</span>
                      </FlexCol>
                      <div className={styles.edit}>view</div>
                    </FlexRow>
                  ))}
            </FlexCol>
          )}
        </>
      )}
    </FlexCol>
  );
}
