import { useState } from "react";

//Internal
import styles from "./CreateJoinCode.module.scss";
import ReactModal from "components/ReactModal/ReactModal.jsx";
import { useCreateUserJoinCode } from "api/resources/authentication/register";
import { Loading } from "components/Loading/Loading";
import Button from "components/Button/Button";
import { SelectFieldCustom } from "components/inputs";
import { useFetchRolesGql } from "api/resources/organization/roles";
import FlexCol from "components/layouts/FlexColumn/FlexCol";
import Icon from "components/Icon/Icon";
import FlexRow from "components/layouts/FlexRow/FlexRow";

export default function CreateJoinCode({ onClose, orgid }) {
  const fetchRoles = useFetchRolesGql(orgid);
  const createJoinCode = useCreateUserJoinCode();
  const [role, setRole] = useState({
    value: "",
    label: "",
    id: "",
  });
  const [codeCreated, setCodeCreated] = useState(false);
  const [code, setCode] = useState();

  const [copied, setCopied] = useState(false);

  const handleCreateCode = () => {
    if (!createJoinCode.isLoading) {
      createJoinCode.mutate(
        {
          data: {
            forOrganization: true,
            forUser: true,
            roleId: role.id,
          },
        },
        {
          onSuccess: (data) => {
            setTimeout(() => {
              setCode(data.createCode.code);
              setCodeCreated(true);
            }, 500);
          },
        }
      );
    }
  };

  function getOptions() {
    let roles = fetchRoles.data.rolesByOrgId.map((role, index) => ({
      id: role.id,
      value: role.id,
      label: role.name,
    }));
    return roles;
  }

  return (
    <>
      {fetchRoles.isLoading && <p>Loading...</p>}
      {fetchRoles.isError && <p>Error</p>}
      {fetchRoles.isSuccess && (
        <ReactModal
          show
          onClose={onClose}
          modalStyle={{
            borderRadius: "1em",
            padding: "1rem",
            paddingTop: "2rem",
            minWidth: "350px",
            overflow: "visible",
          }}
          dark
        >
          <FlexCol gap={20}>
            <FlexRow align={"flex-end"}>
              <Icon iconName={"lock-fill"} className={styles.lock}></Icon>

              <div className={styles.header_3} style={{ margin: "0" }}>
                {codeCreated ? "Join Code Created!" : "Create a new join code"}{" "}
              </div>
            </FlexRow>

            {!codeCreated && (
              <div className={styles.fields}>
                <SelectFieldCustom
                  options={getOptions()}
                  value={role}
                  onChange={(val) => setRole(val)}
                  label="For Role"
                ></SelectFieldCustom>
                <div className={styles.saveBtn}>
                  <Button onClick={handleCreateCode} disable={!role.id} shadow>
                    {!createJoinCode.isLoading &&
                      !createJoinCode.isSuccess &&
                      !createJoinCode.isError &&
                      "Create Code"}
                    {createJoinCode.isLoading && (
                      <div style={{ display: "flex", flexDirection: "row" }}>
                        <Loading height="20" width="20"></Loading>Working
                      </div>
                    )}
                    {createJoinCode.isSuccess && (
                      <div
                        style={{
                          display: "flex",
                          gap: "5px",
                          alignItems: "center",
                        }}
                      >
                        <i className="bi bi-check-lg"></i>Created
                      </div>
                    )}
                    {createJoinCode.isError && "Error creating one"}
                  </Button>
                </div>
              </div>
            )}
            {codeCreated && (
              <>
                <div className={styles.codeInstructions}>
                  <span>Copy this code to give to an intended user</span>
                  <span>
                    This is what they will use to register with the system.
                  </span>
                </div>
                <div className={styles.codeCreatedDiv}>
                  <div className={styles.code}>
                    {code}{" "}
                    <i
                      className={`${"bi bi-clipboard"} ${styles.clipboard}`}
                      onClick={() => {
                        navigator.clipboard.writeText(code);
                        setCopied(true);
                      }}
                    >
                      {copied && (
                        <i
                          className={`${"bi bi-check"} ${styles.checkmark}`}
                        ></i>
                      )}
                    </i>
                  </div>
                </div>
              </>
            )}
          </FlexCol>
        </ReactModal>
      )}
    </>
  );
}
