// External
import React from "react";

// Internal
import styles from "./TabBar.module.scss";
import { TabBarItem } from "./TabBarItem";
import { SelectFieldCustom } from "components/inputs";
import { useNavigate } from "react-router-dom";
import FlexRow from "../FlexRow/FlexRow";

/**
 * A component that is a simple navigation bar in forms of tabs
 * @param {boolean} tabBarItems an array of objects with paths to be navigated to and displayed
 * @param {boolean} active id of the active item
 * @param {int} updateActive function to be called that updates the active menu item, takes in the item index
 * @returns {React.ReactElement} a TabBar component
 */

export const TabBar = ({ tabBarItems, active, updateActive }) => {
  const navigate = useNavigate();
  return (
    <div className={styles.tabBar}>
      {window.innerWidth > 560 &&
        tabBarItems.map((tabBarItem, i) => (
          <React.Fragment key={i}>
            {tabBarItem && (
              <TabBarItem
                key={tabBarItem.id}
                id={tabBarItem.id}
                label={tabBarItem.name}
                to={tabBarItem.to}
                isActive={active == tabBarItem.id}
                makeActive={(index) => {
                  updateActive(index);
                }}
                icon={tabBarItem?.icon}
              />
            )}
          </React.Fragment>
        ))}
      {window.innerWidth < 560 && (
        <div style={{width: "100%", margin: "1rem 0"}}><SelectFieldCustom
          placeholder={"Go To"}
          // label={tabBarItem.name}
          // to={tabBarItem.to}
          // isActive={active == tabBarItem.id}
          noCreate
          value={{label: "Go To", value: "Go Tos"}}
          options={tabBarItems.map((item) => {
            return {
              id: item?.id,
              to: item?.to,
              label: (
                <FlexRow>
                 {typeof item?.icon === "string" ? <i className={`bi-${item?.icon}`}></i> : item?.icon} {item?.name}
                </FlexRow>
              ),
              value: item?.id,
            };
          })}
          onChange={(v) => {
            updateActive(v.id);
            navigate(v.to);
          }}
        /></div>
      )}
    </div>
  );
};
