import React, { useRef } from "react";
import styles from "./EditableNavigation.module.scss";
import { Label } from "components/layouts/Label/Label";
import { ColorPicker } from "components/inputs/input_fields/ColorPicker/ColorPicker";
import { ToggleSwitch } from "components/inputs/input_fields/ToggleSwitch/ToggleSwitch";
import { SurveyButton } from "components/Survey/SurveyButton/SurveyButton";

function EditableNavigation({ settings, page, maxPage, active, setActive }) {
  const color = settings.buttonColor
    ? settings.buttonColor
    : settings.baseColor;

  function onBackgroundClick(e) {
    setActive("nav");
  }

  return (
    <div
      className={`${styles.navContainer} ${
        active ? styles.activeNavContainer : styles.inactiveNavContainer
      } `}
      onClick={onBackgroundClick}
    >
      {!active && <div className={styles.editSettings}>Click to edit</div>}
      {page != 1 && (
        <SurveyButton
          styleType={settings.buttonStyle}
          color={color}
          shadow={settings?.buttonShadow}
        >
          {settings.back ? settings.back : "Back"}
        </SurveyButton>
      )}
      {page != maxPage && (
        <SurveyButton
          styleType={settings.buttonStyle}
          color={color}
          shadow={settings?.buttonShadow}
        >
          {settings.next ? settings.next : "Next"}
        </SurveyButton>
      )}
      {page === maxPage && (
        <SurveyButton
          styleType={settings.buttonStyle}
          color={color}
          shadow={settings?.buttonShadow}
        >
          {settings.finish ? settings.finish : "Finish"}
        </SurveyButton>
      )}

      {settings.showPageProg && (
        <span style={{ zIndex: "0" }}>
          {" "}
          PAGE {page} OF {maxPage}
        </span>
      )}
    </div>
  );
}

export default EditableNavigation;
