import React, { useState } from "react";
import styles from "./ConnectSalesProcess.module.scss";

import FlexRow from "components/layouts/FlexRow/FlexRow";
import Button from "components/Button/Button";
import axios from "axios";
import { SelectFieldCustom, TextFieldSimple } from "components/inputs";
import { HorizontalBar } from "components/layouts/HorizontalBar/HorizontalBar";
import {
  useUpdateOrgSettings,
  useVerifyClient,
} from "api/resources/organization/organization";
import { ToggleSwitch } from "components/inputs/input_fields/ToggleSwitch/ToggleSwitch";
import Icon from "components/Icon/Icon";
import Checkbox from "components/inputs/input_fields/CheckboxBlue/Checkbox";
import FlexCol from "components/layouts/FlexColumn/FlexCol";

function ConnectSalesProcess({ organization, setShow, show, user }) {
  const [clientID, setClientID] = useState(
    organization?.sfClientId ? organization?.sfClientId : ""
  );
  const [clientSecret, setClientSecret] = useState(
    organization?.sfClientSecret ? organization?.sfClientSecret : ""
  );
  const [valid, setValid] = useState();
  const [page, setPage] = useState(0);
  const [syncType, setSyncType] = useState(
    organization.sfSyncType ? organization.sfSyncType : "two-way"
  );
  const [objects, setObjects] = useState({
    Surveys: true,
    Answers: true,
    Questions: true,
    Participations: true,
    Contacts: true,
  });

  const handleLoginSalesforce = async () => {
    try {
      const url = `${process.env.REACT_APP_SALESFORCE_KEY}/organization/${organization.id}/Salesforce&userID=${user.id}&organizationID=${organization.id}`;
      const response = await axios.get(url, { withCredentials: true });
      window.location.href = response.data.loginUrl;
      localStorage.setItem("activeorgtab", 7);
    } catch (error) {
      console.error("Error during login:", error);
    }
  };

  // const updateOrg = useUpdateOrgSettings();

  function linkaccount(a) {
    if (a === "Salesforce") {
      handleLoginSalesforce();
      // verifyClientCredentials(clientID, clientSecret);
    }
  }

  const updateOrg = useUpdateOrgSettings();
  const verifyClient = useVerifyClient();

  async function verifyClientCredentials(clientId, clientSecret) {
    updateOrg.mutate(
      {
        updateOrganizationId: organization.id,
        data: {
          sfClientId: clientId,
          sfClientSecret: clientSecret,
          sfSyncType: syncType,
        },
      },
      {
        onSuccess: (data) => {
          handleLoginSalesforce();
        },
      }
    );
    // verifyClient.mutate(
    //   {
    //     clientId: clientId,
    //     clientSecret: clientSecret
    //   },
    //   {
    //     onSuccess: (data) => {
    //       if (data.success) {
    //         console.log("TRUE");
    //       } else {
    //         console.log("FALSE");
    //       }
    //     },
    //   }
    // );
  }
  return (
    <div className={styles.linkpage}>
      <div
        className={`${styles.header_3} `}
        style={{ fontWeight: "500", color: "black", margin: "0" }}
      >
        Connect Salesforce
      </div>
      <div className={styles.text_2}>
        Get the most out of all your services when connected
      </div>
      <FlexCol gap={"1rem"}>
        <div
          className={`${styles.header_4} `}
          style={{
            fontWeight: "500",
            color: "black",
            margin: "0",
            marginTop: "1rem",
          }}
        >
          Environment
        </div>
        <FlexRow>
          <Checkbox checked></Checkbox>{" "}
          <div className={styles.text_2}>Production</div>
        </FlexRow>
      </FlexCol>
      <FlexCol gap={"1rem"}>
        <div
          className={`${styles.header_4} `}
          style={{
            fontWeight: "500",
            color: "black",
            margin: "0",
            marginTop: "1rem",
          }}
        >
          Salesforce API Version
        </div>
        <FlexRow>
          <SelectFieldCustom
            value={{ label: "62.0", value: "62.0" }}
            options={[{ label: "62.0", value: "62.0" }]}
            style={{ width: "100px" }}
          ></SelectFieldCustom>{" "}
        </FlexRow>
      </FlexCol>
      <FlexCol gap={"1rem"}>
        <div
          className={`${styles.header_4} `}
          style={{
            fontWeight: "500",
            color: "black",
            margin: "0",
            marginTop: "1rem",
          }}
        >
          Salesforce Login Method
        </div>
        <FlexRow>
          <Checkbox checked></Checkbox>{" "}
          <div className={styles.text_2}>
            OAuth (Open Authorization) - Most Secure
          </div>
        </FlexRow>
      </FlexCol>
      <FlexRow
        style={{ justifyContent: "flex-end", width: "100%", marginTop: "2rem" }}
      >
        <Button
          shadow
          onClick={() => setShow(false)}
          style={{ width: "fit-content" }}
        >
          Cancel
        </Button>
        <Button
          blue
          shadow
          onClick={() => linkaccount(show)}
          style={{ width: "fit-content" }}
        >
          OAuth Log In
        </Button>
      </FlexRow>

      {/* {(objects["Contact"] || objects["Account"]) && page === 1 && (
        <div className={styles.linkpagecontent}>
          <FlexRow style={{ marginBottom: "1rem" }}>
            <div className={styles.header_3} style={{ margin: "0" }}>
              How do you want to manage your Contacts and Accounts?
            </div>
            <FlexRow style={{ width: "fit-content" }} start>
              <img
                src={require("assets/images/reactionlogonew.png")}
                width={"100px"}
                // style={{margin: "0 .4rem"}}
              ></img>
              <HorizontalBar
                height="25px"
                width={"2px"}
                backgroundColor={"gray"}
              ></HorizontalBar>
              <img
                src={require("assets/images/Salesforce.png")}
                width={"80px"}
                // style={{margin: "0 .4rem"}}
              ></img>
            </FlexRow>
          </FlexRow>
          <div className={styles.page1}>
            <div
              className={`${styles.option} ${
                syncType === "reaction" && styles.active2
              }`}
              onClick={() => setSyncType("reaction")}
            >
              <div className={styles.header_4} style={{ margin: "0" }}>
                One Way Reaction
              </div>
              <img
                src={require("assets/images/reactiontosalesforce.png")}
                width={"200px"}
              ></img>
              <span className={styles.text_2}>
                Make Reaction the source of truth. Changes in Reaction are
                updated to Salesforce, but updates in Salesforce are not pushed
                to Reaction.
              </span>
              {syncType === "reaction" && (
                <div
                  style={{ position: "absolute", right: "1rem", top: "40%" }}
                >
                  <Icon iconName="check" blue style={{ fontSize: "1.5rem" }} />
                </div>
              )}
            </div>
            <div
              className={`${styles.option} ${
                syncType === "salesforce" && styles.active2
              }`}
              onClick={() => setSyncType("salesforce")}
            >
              <div className={styles.header_4} style={{ margin: "0" }}>
                One Way Salesforce
              </div>
              <img
                src={require("assets/images/salesforcetoreaction.png")}
                width={"200px"}
              ></img>
              <span className={styles.text_2}>
                Make Salesforce the source of truth. Changes in Salesforce are
                updated to Reaction, but updates in Reaction are not pushed to
                Salesforce.
              </span>
              {syncType === "salesforce" && (
                <div
                  style={{ position: "absolute", right: "1rem", top: "40%" }}
                >
                  <Icon iconName="check" blue style={{ fontSize: "1.5rem" }} />
                </div>
              )}
            </div>
            <div
              className={`${styles.option} ${
                syncType === "two-way" && styles.active2
              }`}
              onClick={() => setSyncType("two-way")}
            >
              <div className={styles.header_4} style={{ margin: "0" }}>
                Two Way Synchronization
              </div>
              <img
                // src={}
                width={"200px"}
              ></img>
              <span className={styles.text_2}>
                Both Salesforce and Reaction will both stay up to date at the
                same time. Changes in Salesforce are updated to Reaction and
                changes in Reaction are updated to Salesforce.
              </span>
              {syncType === "two-way" && (
                <div
                  style={{ position: "absolute", right: "1rem", top: "40%" }}
                >
                  <Icon iconName="check" blue style={{ fontSize: "1.5rem" }} />
                </div>
              )}
            </div>

          </div>
          <HorizontalBar
            width="100%"
            height="2px"
            style={{ margin: "0" }}
          ></HorizontalBar>

          <FlexRow style={{ justifyContent: "flex-end", width: "100%" }}>
            <Button
              blue
              shadow
              onClick={() => setPage((page) => (page -= 1))}
              style={{ width: "fit-content" }}
              link
            >
              Back
            </Button>
            <Button
              blue
              shadow
              onClick={() => setPage((page) => (page += 1))}
              style={{ width: "fit-content" }}
            >
              Next
            </Button>
          </FlexRow>
        </div>
      )}
      {page === 0 && (
        <div className={styles.linkpagecontent}>
          <div className={styles.top}>
            <FlexRow style={{ marginBottom: "1rem" }}>
              <div
                className={styles.header_3}
                style={{ margin: "0 1rem", cursor: "pointer", color: "black" }}
              >
                Connection Settings
              </div>
              <FlexRow style={{ width: "fit-content" }} start>
                <img
                  src={require("assets/images/reactionlogonew.png")}
                  width={"100px"}
                  // style={{margin: "0 .4rem"}}
                ></img>
                <HorizontalBar
                  height="25px"
                  width={"2px"}
                  backgroundColor={"gray"}
                ></HorizontalBar>
                <img
                  src={require("assets/images/Salesforce.png")}
                  width={"80px"}
                  // style={{margin: "0 .4rem"}}
                ></img>
              </FlexRow>
            </FlexRow>
            <span
              className={styles.text_2}
              style={{ padding: "0rem 1rem", margin: "0" }}
            >
              {" "}
              Connecting a Salesforce account to Reaction will allow you to view
              real-time survey results and more advanced contact and account
              information in Salesforce widgets, dashboards, and views. <br />
              <br />
              When connected, you can choose on how to import and/or export contacts and other Reaction objects.
            </span>
          </div>
          <SectionPage title={"Object Details"} startOpen={true}>
            <span
              className={styles.text_2}
              style={{ padding: "0rem 0rem", margin: "0" }}
            >
              {" "}
              These objects will be available to sync after you connect to
              Salesforce
            </span>
            <FlexRow start style={{ alignItems: "flex-start" }}>
              <div className={styles.header_4} style={{ margin: "0" }}>
                Contacts{" "}
                <span className={styles.text_2} style={{ margin: "0" }}>
                  (Individuals tied to all other data.)
                </span>
                <ul>
                  <li className={styles.text_2} style={{ margin: "0" }}>
                    Can choose which columns get mapped over to Reaction
                  </li>
                  <li className={styles.text_2} style={{ margin: "0" }}>
                    Links directly to Participation and Answers. If synced, all
                    Answers and Participation get auto sent to Salesforce (if
                    checked).
                  </li>
                </ul>
              </div>
            </FlexRow>
            <FlexRow start style={{ alignItems: "flex-start" }}>
              <div className={styles.header_4} style={{ margin: "0" }}>
                Surveys{" "}
                <span className={styles.text_2} style={{ margin: "0" }}>
                  (Surveys that contacts are allowed to participate in.)
                </span>
                <ul>
                  <li className={styles.text_2} style={{ margin: "0" }}>
                    Connected to Questions and Participations.
                  </li>
                  <li className={styles.text_2} style={{ margin: "0" }}>
                    Has dates for when the survey started and finished.
                  </li>
                </ul>
              </div>
            </FlexRow>
            <FlexRow start style={{ alignItems: "flex-start" }}>
              <div className={styles.header_4} style={{ margin: "0" }}>
                Questions{" "}
                <span className={styles.text_2} style={{ margin: "0" }}>
                  (The questions of the survey)
                </span>
                <ul>
                  <li className={styles.text_2} style={{ margin: "0" }}>
                    Types include: Free Response, Multiple Choice, Scale,
                    Ranking, and Matrix.
                  </li>
                </ul>
              </div>
            </FlexRow>
            <FlexRow start style={{ alignItems: "flex-start" }}>
              <div className={styles.header_4} style={{ margin: "0" }}>
                Answers{" "}
                <span className={styles.text_2} style={{ margin: "0" }}>
                  (One Answer for each Question a Contact answers. Answers are
                  tied to Contacts and Participations)
                </span>
                <ul>
                  <li className={styles.text_2} style={{ margin: "0" }}>
                    Dates for when the answer was created and updated
                  </li>
                  <li className={styles.text_2} style={{ margin: "0" }}>
                    All answer types are displayed within the answer. Text
                    Answer, Choice/Mulit Choice Answer, Ranking, Scale, and
                    Matrix Answers.
                  </li>
                  <li className={styles.text_2} style={{ margin: "0" }}>
                    Shows which Survey this Answer was part of and which
                    Question this Answer was for.
                  </li>
                </ul>
              </div>
            </FlexRow>

            <FlexRow start style={{ alignItems: "flex-start" }}>
              <div className={styles.header_4} style={{ margin: "0" }}>
                Participations{" "}
                <span className={styles.text_2} style={{ margin: "0" }}>
                  (One Participation is created per contact for every survey
                  that contact is invited too.)
                </span>
                <ul>
                  <li className={styles.text_2} style={{ margin: "0" }}>
                    Shows whether the contact started, completed, or abandoned
                    the survey
                  </li>
                  <li className={styles.text_2} style={{ margin: "0" }}>
                    Gives dates for when they started and finished the survey
                  </li>
                  <li className={styles.text_2} style={{ margin: "0" }}>
                    Holds their unique survey link
                  </li>
                  <li className={styles.text_2} style={{ margin: "0" }}>
                    Shows which survey it was that they were invited too and/or
                    participated in
                  </li>
                </ul>
              </div>
            </FlexRow>
          </SectionPage>

          <HorizontalBar width="100%" height="2px"></HorizontalBar>
          <FlexRow style={{ justifyContent: "flex-end", width: "100%" }}>
            <Button
              blue
              shadow
              onClick={() => setShow(false)}
              style={{ width: "fit-content" }}
              link
            >
              Back
            </Button>
            <Button
              blue
              shadow
              onClick={() => setPage((page) => (page += 1))}
              style={{ width: "fit-content" }}
            >
              Next
            </Button>
          </FlexRow>
        </div>
      )}
      {(((objects["Contact"] || objects["Account"]) && page === 2) ||
        (!objects["Contact"] && !objects["Account"] && page === 1)) && (
        <div className={styles.linkpagecontent}>
          <FlexRow style={{ marginBottom: "3rem" }}>
            <div className={styles.header_3} style={{ margin: "0" }}>
              Connect Salesforce to Reaction
            </div>
            <FlexRow style={{ width: "fit-content" }} start>
              <img
                src={require("assets/images/reactionlogonew.png")}
                width={"100px"}
                // style={{margin: "0 .4rem"}}
              ></img>
              <HorizontalBar
                height="25px"
                width={"2px"}
                backgroundColor={"gray"}
              ></HorizontalBar>
              <img
                src={require("assets/images/Salesforce.png")}
                width={"80px"}
                // style={{margin: "0 .4rem"}}
              ></img>
            </FlexRow>
          </FlexRow>
          <div className={styles.linkpagesection}>
            <span className={styles.text_2}>
              In order to connect to Salesforce account, Saleforce requires the
              user to create a new "Connected App".
              <br />
              Follow the Instructions in the link below to learn how to create a
              "Connected App" to connect to Reaction.
            </span>
            <span className={styles.text_2}>
              {" "}
              <a className={styles.link}>
                How to create a connect app in Salesforce for Reaction
              </a>
            </span>
            <span className={styles.text_2}>
              Copy and Paste the Consumer ID and Consumer Secret generated from
              your new "Connected App"
            </span>

            <span className={styles.text_2}>
              {" "}
              Clicking "Log In" will direct you to the Saleforce log in page.
            </span>
            <TextFieldSimple
              value={clientID}
              onChange={setClientID}
              label="Salesforce Consumer Key"
              // type="password"
              valid={valid}
            ></TextFieldSimple>
            <TextFieldSimple
              value={clientSecret}
              onChange={setClientSecret}
              label="Salesforce Consumer Secret"
              type="password"
              valid={valid}
            ></TextFieldSimple>
            {valid === false && (
              <div className={`${styles.text_3} ${styles.red}`}>
                {" "}
                - ID and Secret are not valid
              </div>
            )}
          </div>
          <HorizontalBar width="100%" height="2px"></HorizontalBar>
          <FlexRow style={{ justifyContent: "flex-end", width: "100%" }}>
            <Button
              blue
              shadow
              onClick={() => setPage((page) => (page -= 1))}
              style={{ width: "fit-content" }}
              link
            >
              Back
            </Button>
            <Button
              blue
              shadow
              onClick={() => linkaccount(show)}
              disabled={!clientSecret || !clientID}
              style={{ width: "fit-content" }}
            >
              Log In
            </Button>
          </FlexRow>
        </div>
      )} */}
    </div>
  );
}

export default ConnectSalesProcess;

function SectionPage({ children, startOpen, title, future }) {
  const [open, setOpen] = useState(startOpen);
  return (
    <div className={styles.linkpagesection}>
      <FlexRow
        style={{ margin: "0rem 0", paddingRight: ".5rem", cursor: "pointer" }}
        onClick={() => setOpen(!open)}
      >
        <div
          className={styles.header_3}
          style={{
            margin: "0",
            display: "flex",
            alignItems: "center",
            gap: "1rem",
            color: "black",
          }}
          onClick={() => setOpen(!open)}
        >
          {title}
          {future && <span className={styles.text_3}>(Coming Soon)</span>}
        </div>
        <Icon iconName={"chevron-down"} onClick={() => setOpen(!open)}></Icon>
      </FlexRow>
      {open && <>{children}</>}
    </div>
  );
}
