// External
import { useQueryClient } from "react-query";
import { gql } from "graphql-request";

// Internal
import { useGqlQuery, useGqlMutation, useGqlInfiniteQuery } from "api/Api";

export const useCreateCustomFieldGroup = () => {
  const mutation = gql`
    mutation ($data: CustomFieldGroupInput!) {
      CreateCustomFieldGroup(data: $data) {
        id
        name
        position
        enabled
        icon
        customFieldGroupRow {
          id
          position
          customFieldGroupRowField {
            id
            position
            customFieldId
            customField {
              id
              name
              displayName
              generic
            }
          }
        }
      }
    }
  `;

  return useGqlMutation(mutation, {});
};

export const useFetchCustomFieldAttributes = () => {
  const mutation = gql`
    mutation ($id: String!) {
      attributes: getCustomFieldAttributes(id: $id) {
        name
        id
      }
    }
  `;

  return useGqlMutation(mutation, {});
};

export const useGetCustomFieldAttributes = (
  search,
  perPage,
  sort,
  id,
) => {
  const query = gql`
    query Query(
      $search: String!
      $skip: Float!
      $perPage: Float!
      $sort: SortAttribute!
      $id: String!
    ) {
      response: getCustomFieldAttributesPaginated(
        search: $search
        skip: $skip
        perPage: $perPage
        sort: $sort
        id: $id
      ) {
        attributes {
          id
          name
        }
        count
        hasMore
      }
    }
  `;

  return useGqlInfiniteQuery(
    ["Get Attributes:" + id],
    query,
    {
      search,
      skip: 0,
      perPage,
      sort,
      id
    },
    {
      getNextPageParam: (lastPage, allPages) => {
        const currentCount = allPages.flatMap(
          (page) => page?.response?.attributes
        ).length;
        return lastPage?.response?.hasMore ? currentCount : undefined; // Use `currentCount` as `skip` for the next page
      },
    }
  );
};



export const useCheckUniqueAttribute = () => {
    const mutation = gql`
      mutation ($id: String!, $name: String!, $generic: Boolean!) {
        exists: checkUniqueAttribute(id: $id, name: $name, generic: $generic)
      }
    `;
  
    return useGqlMutation(mutation, {});
  };
  


  export const useSaveGroupLayout = () => {
    const mutation = gql`
      mutation ($data: [CustomFieldGroupInput!]!) {
        SaveGroupLayout(data: $data)
      }
    `;
  
    return useGqlMutation(mutation, {});
  };
  
