import styles from "../SettingsAccordion.module.scss";
import {
  DoughnutType,
  Flywheel,
  ParticipationCount,
  PieType,
  TableType,
  VertBarType,
  splitSomehow,
} from "../../QChart/QuestionChart";
import { forEach } from "assets/functions/ArrayFunctions";
import {
  useFetchFilterableColumnAtts,
  useIsHealthCatalyst,
} from "api/resources/organization/organization";
import { useGetFilterableAnswers } from "api/resources/projects/answers";
import { combinedProjIds, combinedQs } from "../../Visualization";
import { Comparison } from "./SurveyData/Comparison";
import { useGetComparisonQs } from "api/resources/projects/visualizations";
import ComparisonFitler from "./SurveyData/ComparisonFilter/ComparisonFilter";
import { OneOrTheOther } from "components/inputs/input_fields/OneOrTheOther/OneOrTheOther";
import FlexRow from "components/layouts/FlexRow/FlexRow";
import { Switch, TextTool } from "../Tools";

// I split these two components up because then the option for Pivot By can still appear in the settings, because
// if there have been no filters created for this chart yet, the Pivots component will break. Filter Settings creates
// and updates the filters, so PivotSettings waits for a moment for Filter Settings to create it, then Pivots will be
// able to be seen. Again, this is just a safeguard against the condition of no filters having been created yet. If the
// user clicks on Pivot By without any filters built in time, nothing will drop down, giving the appearance there are none.

export function AdvancedSettings({
  viz,
  updateViz,
  changeSettingsField,
  filters,
  visible,
  setVisible,
}) {
  return (
    <>
      <div
        key={viz.id}
        className={`${styles.header} ${visible ? styles.headervisible : ""}`}
        onClick={!visible ? setVisible : undefined}
      >
        <FlexRow
          onClick={visible ? setVisible : undefined}
          style={{ cursor: "pointer" }}
          className={styles.accordionTitle}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "1em",
            }}
          >
            Advanced Settings
            <i
              style={{ fontSize: "1.2em" }}
              className="bi bi-gear-wide-connected"
            ></i>
          </div>

          <span className={styles.accordionicon}>
            <i className="bi bi-caret-left-fill"></i>
          </span>
        </FlexRow>
        {visible && (
          <Settings
            viz={viz}
            updateViz={updateViz}
            changeSettingsField={changeSettingsField}
            filters={filters}
          />
        )}
      </div>
    </>
  );
}

function Settings({
  viz,
  updateViz,
  changeSettingsField,
  filters,
}) {
  const getComparisonQs = useGetComparisonQs();
  const fetchColAttributes = useFetchFilterableColumnAtts();

  const projIds = combinedProjIds(viz);
  const vizQs = combinedQs(viz);

  function toggleByPercentOfParticipants(val) {
    let newViz = { ...viz };
    newViz.designSettings.byPercent = val;
    newViz.designSettings.byPercentOfTotal = false;
    newViz.designSettings.graphMax = null;
    newViz.designSettings.graphMin = null;
    updateViz(newViz);
  }

  function toggleByPercentOfTotal(val) {
    let newViz = { ...viz };
    newViz.designSettings.byPercent = val;
    newViz.designSettings.byPercentOfTotal = val;
    newViz.designSettings.graphMax = null;
    newViz.designSettings.graphMin = null;
    updateViz(newViz);
  }

  function toggleCount(val) {
    let newViz = { ...viz };
    newViz.designSettings.showN = val;
    updateViz(newViz);
  }

  const isFlywheel = viz.designSettings.answerType === Flywheel;

  const isHC = useIsHealthCatalyst();

  function showFlywheel() {
    if (isHC.isSuccess && isHC.data.isHealthCatalyst) {
      // I need two questions for each survey
      let surveys = {};
      for (let q of vizQs) {
        if (
          q.scaleQuestion &&
          q.scaleQuestion.min == 1 &&
          q.scaleQuestion.max == 6 &&
          q.questionText.includes("How effective")
        ) {
          if (!(q.projectId in surveys)) {
            surveys[q.projectId] = 0;
          }
          surveys[q.projectId]++;
        }
      }

      for (let survey in surveys) {
        if (surveys[survey] !== 2) {
          return false;
        }
      }
      return true;
    }
    return false;
  }

  const valueTypes = [
    { value: "npsscore", label: "NPS" },
    { value: "answercount", label: "Answer Count" },
    { value: "static", label: "Static Value" },
    { value: "average", label: "Average Value" },
    { value: "custom", label: "Custom Average" },
    { value: "responseRate", label: "Response Rate" },
    { value: "audienceSize", label: "Audience Size" },
  ];

  function getValueTypes() {
    let items = [...valueTypes];
    if (showFlywheel()) {
      items.unshift({ value: "flywheel", label: "Flywheel Score" });
    }
    return items;
  }

  function toggleComparison(val) {
    let copy = JSON.parse(JSON.stringify(viz)); // So it doesn't render comparison.jsx before getComparisonQs could be finished
    copy.designSettings.useComparison = val;
    if (val) {
      copy.designSettings.hasLegend = true;
      if (copy.type === DoughnutType || copy.type === PieType) {
        copy.type = VertBarType;
        copy.designSettings.legendPosition = "top";
        copy.designSettings.dataLabelValue.position = "end";
        copy.designSettings.dataLabelValue.alignment = "end";
      }
      if (copy.comparison) {
        // need to them on
        getComparisonQs.mutate(
          { comparison: JSON.stringify(copy.comparison) },
          {
            onSuccess: (data) => {
              copy.comparisonQs = data.comparisonQs;
              updateViz(copy);
            },
          }
        );
        return;
      } else {
        copy.comparison = {
          questionIds: [],
          questionTags: [],
        };
        copy.comparisonQs = [];
      }
    } else {
      copy.designSettings.hasLegend =
        copy.designSettings.split ||
        copy.designSettings.asRanking ||
        vizQs[0].matrixQuestion;
    }

    updateViz(copy);
  }

  function toggleToLast(val) {
    let newViz = { ...viz };
    let settings = newViz.designSettings;
    settings.toLast = val;

    if (val) {
      settings.toLastFields = {
        firstName: { name: "first name", show: true },
        lastName: { name: "last name", show: true },
        email: { name: "email", show: false },
      };
      settings.toLastOrder = ["firstName", "lastName", "email"];

      let bucketKeys = [];

      for (let q of vizQs) {
        settings.toLastFields[q.id] = {
          name: q.questionText,
          show: true,
          project: q.projectId,
        };
        settings.toLastFields["last" + q.id] = {
          name: "Last - " + q.questionText,
          show: true,
        };
        settings.toLastOrder.push(q.id);
        settings.toLastOrder.push("last" + q.id);

        if (q.textQuestion && q.textQuestion.bucket) {
          for (let bucket of q.textQuestion.bucket) {
            if (!(bucket.id in settings.toLastFields)) {
              settings.toLastFields[bucket.id] = {
                name: bucket.name,
                show: true,
                isBucket: true,
              };
              settings.toLastFields["last" + bucket.id] = {
                name: "Last - " + bucket.name,
                show: true,
                isBucket: true,
              };
              bucketKeys.push(bucket.id);
              bucketKeys.push("last" + bucket.id);
            }
          }
        }
      }

      settings.toLastFields.lastSurvey = {
        name: "Last Survey",
        show: true,
      };
      settings.toLastOrder.push("lastSurvey");
      settings.toLastFilter = {};
      settings.toLastOrder = [...settings.toLastOrder, ...bucketKeys];
    } else {
      delete settings.toLastFields;
      delete settings.toLastOrder;
      delete settings.toLastFilter;
    }

    settings.tableSort = undefined;

    updateViz(newViz);
  }

  function toggleUndefined(val) {
    let newViz = { ...viz };
    newViz.designSettings.showUndefined = val;

    if (newViz.type === TableType) {
      if (!val && viz.designSettings.split) {
        if ("Undefined" in viz.designSettings.tablePivotFields) {
          delete viz.designSettings.tablePivotFields.Undefined;
          let ind = viz.designSettings.tablePivotOrder.indexOf("Undefined");
          viz.designSettings.tablePivotOrder.splice(ind, 1);
        }
      }
    }

    updateViz(newViz);
  }

  function undefinedLabelChange(val) {
    if (
      viz.type === TableType &&
      "Undefined" in viz.designSettings.tablePivotFields
    ) {
      viz.designSettings.tablePivotFields.Undefined.name = val;
    }
    changeSettingsField("undefinedLabel", val);
  }

  function toggleEmpty(val) {
    if (
      viz.type === TableType &&
      val &&
      viz.pivotString &&
      viz.designSettings.split &&
      !viz.designSettings.split.includes("survey") &&
      viz.designSettings.split !== "nps" &&
      viz.designSettings.split !== "question"
    ) {
      let newViz = { ...viz };

      let columns = { ...newViz.designSettings.tablePivotFields };
      let order = [...newViz.designSettings.tablePivotOrder];

      let fields = fetchColAttributes.data.fields.find(
        (f) => f.name === viz.designSettings.split
      );
      if (fields?.attribute) {
        let props = fields.attribute.map((a) => a.name);

        for (let i = 0; i < props.length; i++) {
          if (props[i] && !(props[i] in columns)) {
            let label = props[i];
            columns[label] = {
              name: label,
              show: true,
            };
            order.splice(i + 1, 0, label);
          }
        }

        newViz.designSettings.tablePivotFields = columns;
        newViz.designSettings.tablePivotOrder = order;
        newViz.designSettings.showNonParticipating = val;
        updateViz(newViz);
      }
    } else {
      changeSettingsField("showNonParticipating", val);
    }
  }

  function toggleSeparate(separate) {
    let newViz = { ...viz };
    newViz.designSettings.separateBySurvey = separate;

    const allQs = combinedQs(newViz);

    if (separate) {
      for (let q of allQs) {
        // removing old q Ids
        delete newViz.designSettings.tableFields[q.id];
        let ind = newViz.designSettings.tableOrder.indexOf(q.id);
        newViz.designSettings.tableOrder.splice(ind, 1);

        if (q.textQuestion && q.textQuestion.bucket) {
          for (let bucket of q.textQuestion.bucket) {
            delete newViz.designSettings.tableFields[bucket.id + "_" + q.id];
            ind = newViz.designSettings.tableOrder.indexOf(
              bucket.id + "_" + q.id
            );
            if (ind > -1) newViz.designSettings.tableOrder.splice(ind, 1);
          }
        }
      }

      // add new column headers and survey column
      newViz.designSettings.tableFields.survey = {
        show: true,
        name: "Survey",
      };

      let newKeys = ["survey"];

      for (let q of allQs) {
        if (!(q.questionText in newViz.designSettings.tableFields)) {
          newViz.designSettings.tableFields[q.questionText] = {
            show: true,
            name: q.questionText,
            contains: [q.id],
          };
          newKeys.push(q.questionText);
        } else {
          newViz.designSettings.tableFields[q.questionText].contains.push(q.id);
        }

        if (q.textQuestion && q.textQuestion.bucket) {
          for (let bucket of q.textQuestion.bucket) {
            if (!(bucket.id in newViz.designSettings.tableFields)) {
              newViz.designSettings.tableFields[bucket.id] = {
                show: true,
                name: bucket.name,
                isBucket: true,
              };
              newKeys.push(bucket.id);
            }
          }
        }
      }

      let startInd = newViz.designSettings.tableOrder.findIndex(
        (a) => !newViz.designSettings.tableFields[a]?.show
      );
      if (startInd == -1) {
        startInd = newViz.designSettings.tableOrder.length;
      }

      let order = newViz.designSettings.tableOrder;
      newViz.designSettings.tableOrder = [
        ...order.slice(0, startInd),
        ...newKeys,
        ...order.slice(startInd),
      ];
    } else {
      // Take out columns
      let columnKeys = ["survey"];
      for (let key of newViz.designSettings.tableOrder) {
        if ("contains" in newViz.designSettings.tableFields[key]) {
          columnKeys.push(key);
        }
      }
      for (let key of columnKeys) {
        let ind = newViz.designSettings.tableOrder.indexOf(key);
        if (ind > -1) {
          newViz.designSettings.tableOrder.splice(ind, 1);
        }
        delete newViz.designSettings.tableFields[key];
      }

      for (let q of allQs) {
        if (q.textQuestion && q.textQuestion.bucket) {
          for (let bucket of q.textQuestion.bucket) {
            delete viz.designSettings.tableFields[bucket.id];
            let ind = newViz.designSettings.tableOrder.indexOf(bucket.id);
            if (ind > -1) newViz.designSettings.tableOrder.splice(ind, 1);
          }
        }
      }

      // Add new columns

      let startInd = newViz.designSettings.tableOrder.findIndex(
        (a) => !newViz.designSettings.tableFields[a]?.show
      );
      if (startInd == -1) {
        startInd = newViz.designSettings.tableOrder.length;
      }
      for (let q of allQs) {
        // Add first so they are after the questions
        if (q.textQuestion && q.textQuestion.bucket) {
          for (let bucket of q.textQuestion.bucket) {
            if (!newViz.designSettings.tableFields[bucket.id + "_" + q.id]) {
              newViz.designSettings.tableFields[bucket.id + "_" + q.id] = {
                name: bucket.name,
                show: true,
                isBucket: true,
                project: q.projectId,
              };
              newViz.designSettings.tableOrder.splice(
                startInd,
                0,
                bucket.id + "_" + q.id
              );
            }
          }
        }

        newViz.designSettings.tableFields[q.id] = {
          show: true,
          name: q.questionText,
          project: q.projectId,
        };
        newViz.designSettings.tableOrder.splice(startInd, 0, q.id);
      }
    }
    newViz.designSettings.tableSort = undefined;

    updateViz(newViz);
  }

  function allNPS() {
    for (let q of vizQs) {
      if (!q.isScore) {
        return false;
      }
    }
    return true;
  }

  const showByPercentOfTotal = !(
    viz.designSettings.answerType === ParticipationCount ||
    viz.designSettings.split ||
    (viz.designSettings.asRanking && !viz.designSettings.scaleByRank)
  );

  return (
    <div
      style={{
        gap: "5px",
      }}
      className={styles.body}
    >
      {viz.type !== TableType && (
        <>
          <Switch checked={viz.designSettings.showN} onCheck={toggleCount}>
            Show Count
          </Switch>

          {viz.designSettings.showN && (
            <TextTool
              value={viz.designSettings.NLabel}
              onChange={(val) => changeSettingsField("NLabel", val)}
              style={{
                paddingLeft: "10px",
                paddingBottom: "5px",
                paddingTop: "2px",
              }}
            >
              Count Label
            </TextTool>
          )}

          {!viz.pivotString && !vizQs[0].textQuestion && !isFlywheel && (
            <HideNonChosen
              viz={viz}
              filters={filters}
              changeSettingsField={changeSettingsField}
            />
          )}

          {!viz.designSettings.scaleByRank &&
            ((viz.designSettings.answerType !== "avg score" &&
              (viz.designSettings.answerType !== "nps score" ||
                viz.designSettings.split === "nps") &&
              viz.designSettings.answerType !== Flywheel) ||
              viz.pivotString === "nps") && (
              <>
                <Switch
                  checked={
                    viz.designSettings.byPercent &&
                    (showByPercentOfTotal
                      ? !viz.designSettings.byPercentOfTotal
                      : true)
                  }
                  onCheck={toggleByPercentOfParticipants}
                >
                  {" "}
                  By percent of participants
                </Switch>

                {showByPercentOfTotal && (
                  <Switch
                    checked={viz.designSettings.byPercentOfTotal}
                    onCheck={toggleByPercentOfTotal}
                  >
                    By percent of total{" "}
                  </Switch>
                )}
              </>
            )}

          {allNPS() && (
            <Switch
              checked={viz.designSettings.includeComment}
              onCheck={(val) => changeSettingsField("includeComment", val)}
            >
              Include comment in tables
            </Switch>
          )}
        </>
      )}

      {(viz.designSettings.split || viz.pivotString) && !isFlywheel && (
        <>
          <Switch
            checked={viz.designSettings.showUndefined}
            onCheck={toggleUndefined}
          >
            Show Undefined{" "}
          </Switch>

          {viz.designSettings?.showUndefined && (
            <TextTool
              value={viz.designSettings.undefinedLabel}
              onSave={undefinedLabelChange}
              style={{
                paddingLeft: "10px",
                paddingBottom: "5px",
                paddingTop: "2px",
              }}
            >
              Undefined Label
            </TextTool>
          )}

          {fetchColAttributes.isSuccess && (
            <Switch
              checked={viz.designSettings?.showNonParticipating}
              onCheck={toggleEmpty}
            >
              Include Empty Fields
            </Switch>
          )}

          {(viz.designSettings.pivotingByBucket ||
            viz.designSettings.splittingByBucket) && (
            <>
              <div className={styles.title} style={{ paddingTop: "10px" }}>
                Bucket Pivot <i className="bi-bucket"></i>
              </div>
              <OneOrTheOther
                onCheck={(val) => changeSettingsField("includeAll", val)}
                checked={viz.designSettings?.includeAll}
                left="Overall Bucket"
                right="Any Bucket"
                style={{
                  fontWeight: "400",
                  fontSize: ".65rem",
                  letterSpacing: "1px",
                  paddingLeft: "10px",
                }}
                activeColor={"#738c91"} // seafoam100
                // inactiveColor={"#8dabb2"} // seafoam60
              />
            </>
          )}
        </>
      )}

      {viz.type === TableType && (
        <>
          {viz.pivotString && (
            <Totals viz={viz} changeSettingsField={changeSettingsField} />
          )}
          {!viz.pivotString && (
            <>
              {projIds.length > 1 && (
                <Switch
                  checked={viz.designSettings.separateBySurvey}
                  onCheck={toggleSeparate}
                >
                  Separate Rows By Survey{" "}
                </Switch>
              )}

              {projIds.length === 1 && (
                <>
                  <Switch
                    checked={viz.designSettings.toLast}
                    onCheck={toggleToLast}
                    tooltip={
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "5px",
                        }}
                      >
                        Will compare with each person's last participation.{" "}
                        <span
                          style={{
                            fontSize: ".85em",
                          }}
                        >
                          (Works only when looking at one survey)
                        </span>
                      </div>
                    }
                    tipStyle={{
                      width: "150px",
                    }}
                  >
                    Compare To Last
                  </Switch>

                  {viz.designSettings.toLast && (
                    <ComparisonFitler viz={viz} updateViz={updateViz} />
                  )}
                </>
              )}
            </>
          )}
        </>
      )}

      {viz.type !== TableType && (
        <>
          <div
            className={`${styles.title}`}
            style={{ marginTop: "1em", paddingBottom: "5px" }}
          >
            Comparison
            <i
              style={{ fontSize: "1.2em" }}
              className="bi bi-graph-up-arrow"
            ></i>
          </div>

          <Switch
            checked={viz.designSettings.useComparison}
            onCheck={toggleComparison}
            tooltip={
              "Runs the average count or calculation over compared data."
            }
            tipStyle={{
              width: "160px",
            }}
          >
            Run Comparison
          </Switch>

          {viz.designSettings.useComparison && vizQs.length > 0 && (
            <>
              {viz.comparisonQs.length > 0 && (
                <>
                  {!splitSomehow(viz) && (
                    <TextTool
                      value={viz.designSettings.chosenLabel}
                      onSave={(val) => changeSettingsField("chosenLabel", val)}
                      placeholder={"Chosen"}
                    >
                      Chosen Label
                    </TextTool>
                  )}
                  <TextTool
                    onSave={(val) =>
                      changeSettingsField("comparisonLabel", val)
                    }
                    value={viz.designSettings.comparisonLabel}
                    placeholder={"Comparison"}
                  >
                    Comparison Label
                  </TextTool>
                </>
              )}

              <Comparison viz={viz} updateViz={updateViz} />
            </>
          )}
        </>
      )}
    </div>
  );
}

function Totals({ viz, changeSettingsField }) {
  function toggleRows(val) {
    if (val) {
      viz.designSettings.tablePivotFields.totalRows = {
        name: "Total",
        show: true,
      };
      viz.designSettings.tablePivotOrder.push("totalRows");
    } else {
      delete viz.designSettings.tablePivotFields.totalRows;
      let ind = viz.designSettings.tablePivotOrder.indexOf("totalRows");
      viz.designSettings.tablePivotOrder.splice(ind, 1);
    }
    changeSettingsField("totalRows", val);
  }

  return (
    <>
      {/* <div className={styles.setting2}>
        <ToggleSwitch
          startChecked={viz.designSettings?.totalColumns}
          handleCheck={(val) => changeSettingsField("totalColumns", val)}
        />
        <Label
          style={{
            fontWeight: "400",
            width: "fit-content",
            fontSize: ".9em",
            paddingLeft: "0px",
          }}
        >
          Total the columns
         
        </Label>
      </div> */}

      <div className={styles.title} style={{ paddingTop: "10px" }}>
        Totals <i className="bi-calculator"></i>
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "5px",
          paddingLeft: "8px",
        }}
      >
        <Switch
          checked={viz.designSettings?.totalColumns}
          onCheck={(val) => changeSettingsField("totalColumns", val)}
        >
          <div className={styles.row} style={{ gap: ".5em" }}>
            Total <i className="bi bi-arrow-down"></i>
          </div>
        </Switch>

        <Switch checked={viz.designSettings?.totalRows} onCheck={toggleRows}>
          <div className={styles.row} style={{ gap: ".5em" }}>
            Total <i className="bi bi-arrow-right"></i>
          </div>
        </Switch>
      </div>
    </>
  );
}

function HideNonChosen({ viz, filters, changeSettingsField }) {
  const vizQs = combinedQs(viz);

  const fetchAnswers = useGetFilterableAnswers(
    vizQs.map((q) => q.id),
    filters,
    { names: [] },
    viz.designSettings?.dynamic
      ? viz.designSettings.userConstraints
      : undefined,
    null
  );

  function show() {
    let labels = [];
    let labelMap = {};

    if (viz?.question && vizQs[0].choiceQuestion) {
      forEach(viz?.question, (q) => {
        let choices = q.choiceQuestion.choices;
        if (choices) {
          for (let choice of choices) {
            let lowered = choice.toLowerCase();
            if (!(lowered in labelMap)) {
              labelMap[lowered] = choice;
              labels.push(choice);
            }
          }
        }
      });
    }
    if (vizQs[0].scaleQuestion) {
      for (let question of vizQs) {
        for (
          let i = question.scaleQuestion.min;
          i <= question.scaleQuestion.max;
          i += question.scaleQuestion.step
        ) {
          if (!labels.includes(i)) {
            labels.push(i);
          }
        }
      }
    }

    let record = {};
    forEach(labels, (l) => (record[l] = 0));

    for (let answer of fetchAnswers.data.answers) {
      if (answer.choiceAnswer) {
        for (let chosen of answer.choiceAnswer) {
          let key = chosen.toLowerCase();
          let val = labelMap[key];
          record[val] += 1;
        }
      }
      if (answer.scaleAnswer !== null) {
        record[answer.scaleAnswer] += 1;
      }
    }

    for (let opt of labels) {
      if (!record[opt]) {
        return true;
      }
    }

    return false;
  }

  return (
    <>
      {fetchAnswers.isSuccess && show() && (
        <Switch
          checked={viz.designSettings?.hideNonChosen}
          onCheck={(val) => changeSettingsField("hideNonChosen", val)}
          tooltip={
            <>
              Will hide any ${vizQs[0].choiceQuestion ? "choices" : "numbers"}{" "}
              from the chart that nobody chose
            </>
          }
          tipStyle={{
            widdth: "125px",
          }}
        >
          Hide {vizQs[0].choiceQuestion ? "choices" : "numbers"} not chosen
        </Switch>
      )}
    </>
  );
}
