import React, { useState, useEffect } from "react";
import { useTransferTempContact } from "api/resources/contacts/contacts";
import styles from "./ImportedCleaner.module.scss";
import { Loading } from "components/Loading Rectangle/Loading";
import { BasicTable } from "components/tables/BasicTable/BasicTable";
import Button from "components/Button/Button";
import { validateEmail } from "assets/functions/StringFunctions";
import { shortId } from "components/tables/EditableTable/utils";
import { useParams } from "react-router-dom";
import { useFetchErrorContactsByUpload } from "api/resources/contacts/temp_contacts";

export function ImportedCleaner({ upload }) {
  const { id } = useParams();
  const fetchContactImport = useFetchErrorContactsByUpload(
    upload ? upload.id : id
  );

  return (
    <div className={styles.page}>
      {fetchContactImport.isError && <div>Error</div>}
      {fetchContactImport.isLoading && <Loading></Loading>}
      {fetchContactImport.isSuccess &&
        fetchContactImport.data?.output?.headers?.length > 0 && (
          <>
            <TableContainer
              data={fetchContactImport.data?.output}
              upload={upload}
              refetch={fetchContactImport.refetch}
            ></TableContainer>
          </>
        )}
    </div>
  );
}

function TableContainer({ data, upload, refetch }) {
  const [contacts, setContacts] = useState(initContacts(data.contacts));
  const [headers, setHeaders] = useState(initHeaders(data.headers));
  const uploadContact = useTransferTempContact();

  function handleImportAnyways(index) {
    let contact = { ...contacts[index] };
    let confirmed = true;
    if (Object.keys(contact?.flags).length > 0) {
      if (
        !window.confirm(
          "This contact still has errors in it. Are you sure you want to upload it?"
        )
      ) {
        confirmed = false;
      }
    }

    if (confirmed) {
      let contactData = {
        firstName: contact?.firstName,
        lastName: contact?.lastName,
        email: contact?.email,
        customField: contact?.customField,
        temp_contact_id: `${contact.id}`,
      };

      let uploadData = {
        id: upload.id,
        status: upload.status,
        totalWarnings: upload.totalWarnings - 1,
        type: upload.type,
      };

      uploadContact.mutate({
        contactData: contactData,
        audienceId: upload?.audienceId ? upload?.audienceId : "",
        upload: uploadData,
      });
      onRemoveContact(index);
    }
  }

  useEffect(() => {
    setContacts(initContacts(data.contacts));
  }, [data]);

  function initContacts(list) {
    let endList = [...list];

    for (let i = 0; i < endList.length; i++) {
      if (endList[i]?.customField) {
        let fields = endList[i]?.customField;
        while (typeof fields === "string") {
          fields = JSON.parse(fields);
        }
        let flags = endList[i]?.flags;
        while (typeof flags === "string") {
          flags = flags.replace(/'/g, '"');
          flags = JSON.parse(flags);
        }
        endList[i].flags = flags;
        endList[i] = { ...endList[i], ...fields };
      }
      endList[i].index = i;
    }
    return endList;
  }

  function onRemoveContact(index) {
    let list = [...contacts];
    list.splice(index, 1);
    setContacts(list);
  }

  function initHeaders(list) {
    let endList = [
      {
        id: shortId(),
        name: "Error",
        accessor: "flags",
        canSort: false,
        enabled: true,
        editable: false,
        cell_style: (val) => (
          <div className={styles.errorheaders}>
            {val && Object.keys(val)?.length > 1
              ? "Multiple Errors"
              : val[Object.keys(val)[0]]}
          </div>
        ),
      },
      ...list,
    ];

    return endList;
  }

  function changeValue(value, header, rowdata, i) {
    let temp = { ...rowdata };
    temp[header?.accessor] = value;

    if (rowdata?.flags) {
      if (header.value === "email") {
        let validated = validateEmail(value);
        if (!validated) {
          if (temp.flags?.email && temp.flags?.email?.length > 0) {
            let index = temp.flags?.email.indexOf("Invalid Email Syntax");
            if (index < 0) {
              temp.flags?.email.push("Invalid Email Syntax");
            }
          } else {
            temp.flags.email = ["Invalid Email Syntax"];
          }
        } else if (
          validated &&
          temp.flags?.email &&
          temp.flags?.email?.length > 0
        ) {
          let index = temp.flags?.email.indexOf("Invalid Email Syntax");
          temp.flags?.email.splice(index, 1);
        }

        if (
          validated &&
          (!temp.flags?.email || temp.flags?.email.length === 0)
        ) {
          delete temp.flags?.email;
        }
      }

      if (header.value === "firstName") {
        let validated = value?.length != 1;
        if (!validated) {
          if (temp.flags?.firstName && temp.flags?.firstName?.length > 0) {
            let index = temp.flags?.firstName.indexOf(
              "Single Letter First Name"
            );
            if (index < 0) {
              temp.flags?.firstName.push("Single Letter First Name");
            }
          } else {
            temp.flags.firstName = ["Single Letter First Name"];
          }
        } else if (
          validated &&
          temp.flags?.firstName &&
          temp.flags?.firstName?.length > 0
        ) {
          let index = temp.flags?.firstName.indexOf("Single Letter First Name");
          temp.flags?.firstName.splice(index, 1);
        }

        if (
          validated &&
          (!temp.flags?.firstName || temp.flags?.firstName.length === 0)
        ) {
          delete temp.flags?.firstName;
        }
      }

      if (header.value === "lastName") {
        let validated = value?.length != 1;
        if (!validated) {
          if (temp.flags?.lastName && temp.flags?.lastName?.length > 0) {
            let index = temp.flags?.lastName.indexOf("Single Letter Last Name");
            if (index < 0) {
              temp.flags?.lastName.push("Single Letter Last Name");
            }
          } else {
            temp.flags.lastName = ["Single Letter Last Name"];
          }
        } else if (
          validated &&
          temp.flags?.lastName &&
          temp.flags?.lastName?.length > 0
        ) {
          let index = temp.flags?.lastName.indexOf("Single Letter Last Name");
          temp.flags?.lastName.splice(index, 1);
        }

        if (
          validated &&
          (!temp.flags?.lastName || temp.flags?.lastName.length === 0)
        ) {
          delete temp.flags?.lastName;
        }
      }
    }

    let data = [...contacts];
    data[i] = temp;
    setContacts(data);
  }

  return (
    <>
      <div className={styles.header}>
        <span>Contacts Failed</span> Rows: {contacts.length}
      </div>
      <BasicTable
        initheaders={headers}
        data={contacts}
        rowNumbers
        onRowClick={() => undefined}
        paginateScroll
        actionCell
        actionCellText={
          <div className={styles.button}>
            <Button blue link>
              <div
                style={{
                  whiteSpace: "nowrap",
                  fontSize: ".9em",
                  padding: "0px 10px",
                  display: "flex",
                  // height: "30px",
                  gap: ".5em",
                  alignItems: "center",
                }}
              >
                <i
                  className="bi-cloud-arrow-up"
                  style={{ fontSize: "1.3em" }}
                ></i>
                Upload
              </div>
            </Button>
          </div>
        }
        onClickActionCell={(val, index) => {
          handleImportAnyways(index);
        }}
        editableFields
        onChangeEditableCell={(value, header, rowdata, i) =>
          changeValue(value, header, rowdata, i)
        }
        style={{
          borderRadius: "1em",
          boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
          height: "fit-content",
          maxHeight: "100%",
        }}
      ></BasicTable>
    </>
  );
}

// function InputField({ val, header, onChange, setCurrContact }) {
//   const span = useRef(null);
//   const [width, setWidth] = useState(0);
//   // const [contact, setContact] = useState(val);
//   const [error, setError] = useState(checkValue(val, header));
//   // let error  = checkValue(val, header)

//   useEffect(() => {
//     setWidth(span.current.offsetWidth + 20);
//     setError(checkValue(val, header));
//   }, [val[header.value]]);

//   function checkValue(val, header) {
//     if (
//       header.value === "email" &&
//       (val.flags.find((val) => val === "Invalid Email Domain") ||
//         val.flags.find((val) => val === "Invalid Email Syntax"))
//     ) {
//       return true;
//     }

//     if (
//       header.value === "firstName" &&
//       (val.flags.find((val) => val === "Has Suffix in First Name") ||
//         val.flags.find((val) => val === "Has Prefix in First Name") ||
//         val.flags.find((val) => val === "Single Letter First Name"))
//     ) {
//       return true;
//     }

//     if (
//       header.value === "lastName" &&
//       (val.flags.find((val) => val === "Has Suffix in Last Name") ||
//         val.flags.find((val) => val === "Has Prefix in Last Name") ||
//         val.flags.find((val) => val === "Single Letter Last Name"))
//     ) {
//       return true;
//     }
//   }

//   function checkError(value, contact, header) {
//     let temp = { ...contact };
//     // debugger;
//     if (header.value === "email" && temp.flags?.email) {
//       //check email syntax
//       let index = temp.flags?.email.indexOf("Invalid Email Syntax");
//       let validated = validateEmail(value);
//       if (!validated && index < 0) {
//         temp.flags?.email.push("Invalid Email Syntax");
//       } else if (validated && index >= 0) {
//         temp.flags?.email.splice(index, 1);
//       }
//       if (validated) {
//         if (!temp.flags?.email || temp.flags?.email.length === 0) {
//           delete temp.flags?.email;
//         }
//         setError(false);
//       } else {
//         setError(true);
//       }
//     }

//     if (header.value === "firstName" && temp.flags?.firstName) {
//       //check email syntax
//       let index = temp.flags?.firstName.indexOf("Single Letter First Name");
//       let validated = value?.length != 1;
//       if (!validated && index < 0) {
//         temp.flags?.firstName.push("Single Letter First Name");
//       } else if (validated && index >= 0) {
//         temp.flags?.firstName.splice(index, 1);
//       }
//       if (validated) {
//         if (!temp.flags?.firstName || temp.flags?.firstName.length === 0) {
//           delete temp.flags?.firstName;
//         }
//         setError(false);
//       } else {
//         setError(true);
//       }
//     }

//     if (header.value === "lastName" && temp.flags?.lastName) {
//       //check email syntax
//       let index = temp.flags?.lastName.indexOf("Single Letter Last Name");
//       let validated = value?.length != 1;
//       if (!validated && index < 0) {
//         temp.flags?.lastName.push("Single Letter Last Name");
//       } else if (validated && index >= 0) {
//         temp.flags?.lastName.splice(index, 1);
//       }
//       if (validated) {
//         if (!temp.flags?.lastName || temp.flags?.lastName.length === 0) {
//           delete temp.flags?.lastName;
//         }
//         setError(false);
//       } else {
//         setError(true);
//       }
//     }
//     // debugger;
//     temp[header.value] = value;
//     // setContact(temp);
//     val = temp;
//     // setCurrContact(temp);
//     onChange(value, header.value, temp.index);
//   }

//   return (
//     <>
//       <TextFieldSimple
//         value={val[header.value]}
//         customStyles={`${styles.textField}`}
//         style={{
//           width: width,
//           color: error ? "#FF8878" : "",
//         }}
//         // autoFocus
//         onChange={(value) => checkError(value, val, header)}
//         noState
//       ></TextFieldSimple>
//       <div className={styles.error}></div>
//       <span
//         ref={span}
//         style={{ opacity: 0, position: "absolute", pointerEvents: "none" }}
//       >
//         {val[header.value]}
//       </span>
//     </>
//   );
// }
