import React, { useEffect, useState } from "react";
import styles from "./ContactLayout.module.scss";
import DataInput from "components/layouts/ObjectLayout/DataInput";
import {
  useFetchCurrOrgsFields,
  useFetchUnassignedOrgsFields,
  useSearchEnabledOrgGroups,
  useSearchOrgGroups,
} from "api/resources/organization/organization";
import { Loading } from "components/Loading/Loading";
import {
  useFetchContact,
  useUpdateContactAttGql,
} from "api/resources/contacts/contacts";
import { TabPill } from "components/layouts";
import Button from "components/Button/Button";
import FlexCol from "components/layouts/FlexColumn/FlexCol";
import FlexRow from "components/layouts/FlexRow/FlexRow";

export function GetContactLayout({ contactId, onSave }) {
  const fetchContact = useFetchContact(contactId);

  return (
    <>
      {fetchContact.isLoading && <Loading></Loading>}
      {fetchContact.isSuccess && (
        <ContactLayout
          contact={fetchContact.data?.contact}
          onSave={onSave}
          newContact={false}
        ></ContactLayout>
      )}
    </>
  );
}

export function ContactLayout({ contact, newContact, onSave }) {
  const [contactState, setContact] = useState(contact);
  const [contactData, setContactData] = useState({});
  const [changed, setChanged] = useState(false);
  const [saving, setSaving] = useState(false);
  const [allFieldsValid, setAllFieldsValid] = useState(true);
  const [searchString, setSearchString] = useState("");
  const [requiredMessage, setRequireMessage] = useState("");

  // Update top-level fields
  const handleFieldChange = (field, value, multiple, remove) => {
    setContact((prevContact) => ({
      ...prevContact,
      [field]: value,
    }));
    setContactData((prevContact) => ({
      ...prevContact,
      [field]: value,
    }));
    setChanged(true);
  };

  // Update attributes by id
  const handleAttributeChange = (field, value, multiple, remove) => {
    let prevContact = { ...contactState };

    if (multiple && remove) {
      let index = prevContact?.attribute?.findIndex(
        (a) => a?.name === value?.name
      );
      if (index >= 0) {
        prevContact?.attribute?.splice(index, 1);
        setContact(prevContact);
        setChanged(true);
      }
      return;
    }

    let index = prevContact?.attribute?.findIndex(
      (a) => a?.customFieldId === field
    );
    if (index >= 0 && !multiple) {
      prevContact.attribute[index].name = value;
      prevContact.attribute[index].changed = true;
      setContact(prevContact);
      setChanged(true);
    } else if (index < 0 && !multiple) {
      prevContact.attribute.push({
        id: "new",
        name: value,
        customFieldId: field,
        changed: true,
      });
      setContact(prevContact);
      setChanged(true);
    } else if (multiple) {
      let hasItem = prevContact?.attribute?.find((a) => a?.name === value);
      if (!hasItem) {
        prevContact.attribute.push({
          id: "new",
          name: value,
          customFieldId: field,
          changed: true,
        });
        setContact(prevContact);
        setChanged(true);
      }
    }
  };

  const updateContact = useUpdateContactAttGql();
  function saveUserFields() {
    // debugger
    if (allFieldsValid && !saving && changed) {
      setSaving(true);
      let attributeData = [];
      let attributeMap = {};
      for (let att of contactState?.attribute) {
        if (att?.name) {
          attributeData.push({
            customFieldId: att?.customFieldId,
            name: att?.name,
            id: att?.id,
          });
          attributeMap[att?.customFieldId] = true;
        }
      }

      let groups = searchGroups?.data?.groups;

      let map = {};
      for (let group of groups) {
        for (let row of group?.customFieldGroupRow) {
          for (let field of row?.customFieldGroupRowField) {
            map[field?.customField?.id] = field?.customField;

            if (
              field?.customField?.editable &&
              !field?.customField?.defaultValue &&
              field?.customField?.required &&
              field?.customField?.generic
            ) {
              if (contactState[field?.customField?.name] === "") {
                setSaving(false);
                setRequireMessage("Fill in all required fields");
                return;
              }
            }
            if (
              field?.customField?.editable &&
              !field?.customField?.defaultValue &&
              field?.customField?.required &&
              !field?.customField?.generic
            ) {
              if (attributeMap[field?.customField?.id] === "") {
                setSaving(false);
                setRequireMessage("Fill in all required fields");
                return;
              }
            }
          }
        }
      }
      setRequireMessage("");

      updateContact.mutate(
        {
          data: contactData,
          id: contactState.id,
          attributeData: {
            attributes: attributeData,
          },
          newContact: newContact ? true : false,
        },
        {
          onSuccess: (data) => {
            setChanged(false);
            //   onSaved();
            // setColumns(finalFields ? finalFields : {});
          },
          onSettled: () => {
            if (onSave) {
              onSave();
            }

            setSaving(false);
          },
        }
      );
    }
  }

  function cancelChanges() {
    setContact(contact);
    setChanged(false);
    setSaving(false);
  }

  const items = [
    {
      id: 0,
      name: "Layout",
    },
    {
      id: 1,
      name: "All Fields",
    },
  ];

  const [active, setActive] = useState(0);

  const searchGroups = useSearchOrgGroups(
    searchString,
    contactState?.organizationId
  );

  return (
    <div className={styles.page}>
      <div className={styles.content}>
        <FlexRow wrap="wrap">
          {items?.map((item) => (
            <div>
              <Button
                active={active === item?.id}
                onClick={() => {
                  setActive(item?.id);
                }}
              >
                {item?.name}
              </Button>
            </div>
          ))}
        </FlexRow>
        {changed && (
          <FlexCol align={"center"} gap={"1rem"}>
            {" "}
            {requiredMessage && (
              <div className={`${styles.text_3} ${styles.red}`}>
                {requiredMessage}
              </div>
            )}
            <div className={styles.save}>
              <Button width={100} shadow onClick={cancelChanges}>
                Cancel
              </Button>
              <Button
                width={100}
                blue
                shadow
                onClick={saveUserFields}
                disable={!searchGroups.isSuccess}
              >
                {!saving ? (
                  "Save"
                ) : (
                  <>
                    Saving
                    <span className={styles.dots}>
                      <span>.</span>
                      <span>.</span>
                      <span>.</span>
                    </span>
                  </>
                )}
              </Button>
            </div>
          </FlexCol>
        )}
        {searchGroups.isLoading && <Loading></Loading>}
        {active === 0 && searchGroups.isSuccess && (
          <ContactLayoutEnabled
            contactState={contactState}
            handleFieldChange={handleFieldChange}
            handleAttributeChange={handleAttributeChange}
            setValidFields={setAllFieldsValid}
            validate={allFieldsValid}
            newContact={newContact}
            changed={changed}
            groups={searchGroups?.data?.groups}
          ></ContactLayoutEnabled>
        )}
        {active === 1 && searchGroups.isSuccess && (
          <ContactLayoutAllFields
            contactState={contactState}
            handleFieldChange={handleFieldChange}
            handleAttributeChange={handleAttributeChange}
            newContact={newContact}
            groups={searchGroups?.data?.groups}
          ></ContactLayoutAllFields>
        )}
        {changed && (
          <FlexCol align={"center"} gap={"1rem"}>
            {" "}
            {requiredMessage && (
              <div className={`${styles.text_3} ${styles.red}`}>
                {requiredMessage}
              </div>
            )}
            <div className={styles.save}>
              <Button width={100} shadow onClick={cancelChanges}>
                Cancel
              </Button>
              <Button
                width={100}
                blue
                shadow
                onClick={saveUserFields}
                disable={!searchGroups.isSuccess}
              >
                {!saving ? (
                  "Save"
                ) : (
                  <>
                    Saving
                    <span className={styles.dots}>
                      <span>.</span>
                      <span>.</span>
                      <span>.</span>
                    </span>
                  </>
                )}
              </Button>
            </div>
          </FlexCol>
        )}
      </div>
    </div>
  );
}

export function ContactLayoutEnabled({
  contactState,
  handleFieldChange,
  handleAttributeChange,
  setValidFields,
  validate,
  newContact,
  changed,
  groups,
}) {
  return (
    <>
      {groups &&
        groups?.map(({ name, customFieldGroupRow, editable, enabled }, i) => (
          <React.Fragment key={i}>
            {enabled && (!newContact || (newContact && editable)) && (
              <div className={styles.rowsContainer} key={i}>
                <div
                  style={{ width: "100%", color: "black" }}
                  className={styles.header_4}
                >
                  {name}
                </div>
                {customFieldGroupRow?.map(
                  ({ id, customFieldGroupRowField }, i2) => (
                    <div className={`${styles.sortableItemContainer}`} key={i2}>
                      {customFieldGroupRowField?.map((field, i3) => (
                        <React.Fragment key={i3}>
                          {(!newContact ||
                            (newContact && field?.customField?.editable)) && (
                            <>
                              {field?.customField?.generic && (
                                <DataInput
                                  label={field?.customField?.displayName}
                                  value={contactState[field?.customField?.name]}
                                  shadow
                                  onChange={(value, remove) =>
                                    handleFieldChange(
                                      field?.customField?.name,
                                      value,
                                      field?.customField?.multiple,
                                      remove
                                    )
                                  }
                                  dataType={field?.customField?.dataType}
                                  icon={field?.customField?.icon}
                                  min={field?.customField?.min}
                                  max={field?.customField?.max}
                                  falseValue={field?.customField?.falseValue}
                                  trueValue={field?.customField?.trueValue}
                                  disabled={!field?.customField?.editable}
                                  id={field?.customField?.id}
                                  required={field?.customField?.required}
                                  multiple={field?.customField?.multiple}
                                  unique={field?.customField?.unique}
                                  generic={field?.customField?.generic}
                                ></DataInput>
                              )}
                              {!field?.customField?.generic && (
                                <DataInput
                                  label={field?.customField?.displayName}
                                  value={
                                    field?.customField?.multiple
                                      ? contactState?.attribute?.filter(
                                          (attr) =>
                                            attr?.customFieldId ===
                                            field?.customField?.id
                                        ) || []
                                      : contactState?.attribute?.find(
                                          (attr) =>
                                            attr.customFieldId ===
                                            field?.customField?.id
                                        )?.name || ""
                                  }
                                  onChange={(value, remove) =>
                                    handleAttributeChange(
                                      field?.customField?.id,
                                      value,
                                      field?.customField?.multiple,
                                      remove
                                    )
                                  }
                                  shadow
                                  dataType={field?.customField?.dataType}
                                  icon={field?.customField?.icon}
                                  min={field?.customField?.min}
                                  max={field?.customField?.max}
                                  falseValue={field?.customField?.falseValue}
                                  trueValue={field?.customField?.trueValue}
                                  disabled={!field?.customField?.editable}
                                  id={field?.customField?.id}
                                  required={field?.customField?.required}
                                  multiple={field?.customField?.multiple}
                                  unique={field?.customField?.unique}
                                  generic={field?.customField?.generic}
                                ></DataInput>
                              )}
                            </>
                          )}
                        </React.Fragment>
                      ))}
                    </div>
                  )
                )}
              </div>
            )}
          </React.Fragment>
        ))}
    </>
  );
}

export function ContactLayoutAllFields({
  contactState,
  handleFieldChange,
  handleAttributeChange,
  setValidFields,
  validate,
  newContact,
  changed,
  groups,
}) {
  // const [search, setSearch] = useState("")
  const fetchColumns = useFetchCurrOrgsFields();

  // useEffect(() => {
  //   fetchColumns.refetch();
  // }, [search])

  return (
    <>
      {/* <NavTextField setValue={setSearch} value={search} placeholder={"Search Field..."} shadow></NavTextField> */}

      {fetchColumns.isLoading && <Loading></Loading>}
      {fetchColumns.isSuccess &&
        fetchColumns?.data?.getCurrOrgFields?.map((field, i3) => (
          <React.Fragment key={i3}>
            {(!newContact || (newContact && field?.editable)) && (
              <>
                {field?.generic && (
                  <DataInput
                    label={field?.displayName}
                    value={contactState[field?.name]}
                    shadow
                    onChange={(value, remove) =>
                      handleFieldChange(
                        field?.name,
                        value,
                        field?.multiple,
                        remove
                      )
                    }
                    dataType={field?.dataType}
                    icon={field?.icon}
                    min={field?.min}
                    max={field?.max}
                    falseValue={field?.falseValue}
                    trueValue={field?.trueValue}
                    disabled={!field?.editable}
                    id={field?.id}
                    required={field?.required}
                    multiple={field?.multiple}
                    unique={field?.unique}
                    generic={field?.generic}
                  ></DataInput>
                )}
                {!field?.generic && (
                  <DataInput
                    label={field?.displayName}
                    value={
                      field?.multiple
                        ? contactState?.attribute?.filter(
                            (attr) => attr.customFieldId === field?.id
                          ) || []
                        : contactState?.attribute?.find(
                            (attr) => attr.customFieldId === field?.id
                          )?.name || ""
                    }
                    onChange={(value, remove) =>
                      handleAttributeChange(
                        field?.id,
                        value,
                        field?.multiple,
                        remove
                      )
                    }
                    shadow
                    dataType={field?.dataType}
                    icon={field?.icon}
                    min={field?.min}
                    max={field?.max}
                    falseValue={field?.falseValue}
                    trueValue={field?.trueValue}
                    disabled={!field?.editable}
                    id={field?.id}
                    required={field?.required}
                    multiple={field?.multiple}
                    unique={field?.unique}
                    generic={field?.generic}
                  ></DataInput>
                )}
              </>
            )}
          </React.Fragment>
        ))}
      {/* {customFieldGroupRow?.map(
                ({ id, customFieldGroupRowField }, i2) => (
                  <div className={`${styles.sortableItemContainer}`} key={i2}>
                    {customFieldGroupRowField?.map((field, i3) => (
                      <React.Fragment key={i3}>
                        <>
                          {field?.customField?.generic && (
                            <DataInput
                              label={field?.customField?.displayName}
                              value={contactState[field?.customField?.name]}
                              shadow
                              onChange={(value, remove) =>
                                handleFieldChange(
                                  field?.customField?.name,
                                  value,
                                  field?.customField?.multiple,
                                  remove
                                )
                              }
                              dataType={field?.customField?.dataType}
                              icon={field?.customField?.icon}
                              min={field?.customField?.min}
                              max={field?.customField?.max}
                              falseValue={field?.customField?.falseValue}
                              trueValue={field?.customField?.trueValue}
                              disabled={!field?.customField?.editable}
                              id={field?.customField?.id}
                              required={field?.customField?.required}
                              multiple={field?.customField?.multiple}
                              unique={field?.customField?.unique}
                              generic={field?.customField?.generic}
                            ></DataInput>
                          )}
                          {!field?.customField?.generic && (
                            <DataInput
                              label={field?.customField?.displayName}
                              value={
                                field?.customField?.multiple
                                  ? contactState?.attribute?.filter(
                                      (attr) =>
                                        attr?.customFieldId ===
                                        field?.customField?.id
                                    ) || []
                                  : contactState?.attribute?.find(
                                      (attr) =>
                                        attr.customFieldId ===
                                        field?.customField?.id
                                    )?.name || ""
                              }
                              onChange={(value, remove) =>
                                handleAttributeChange(
                                  field?.customField?.id,
                                  value,
                                  field?.customField?.multiple,
                                  remove
                                )
                              }
                              shadow
                              dataType={field?.customField?.dataType}
                              icon={field?.customField?.icon}
                              min={field?.customField?.min}
                              max={field?.customField?.max}
                              falseValue={field?.customField?.falseValue}
                              trueValue={field?.customField?.trueValue}
                              disabled={!field?.customField?.editable}
                              id={field?.customField?.id}
                              required={field?.customField?.required}
                              multiple={field?.customField?.multiple}
                              unique={field?.customField?.unique}
                              generic={field?.customField?.generic}
                            ></DataInput>
                          )}
                        </>
                      </React.Fragment>
                    ))}
                  </div>
                )
              )} */}
    </>
  );
}
