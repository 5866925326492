import React from "react";
import styles from "./ErrorPage.module.scss";

function TokenExpired({children, message}) {
  return (
    <div className={styles.container} style={{ padding: "2rem" }}>
      <img
        width={400}
        src={require("assets/images/reactionlogonew.png")}
      ></img>
      <div className={styles.tokenContent}>
        <div className={styles.text_2}>
          {message}
        </div>
        {children}
      </div>
    </div>
  );
}

export default TokenExpired;
