// External
import React, { useState } from "react";

// Internal
import { ContactRoutes } from "routes";
import styles from "./Contacts.module.scss";
import { TabBar } from "components/layouts";



export const Contacts = ({ refetchTasks, onExport, roles}) => {

  const [active, setActive] = useState(handlepage());
  function handlepage() {
    let page = localStorage.getItem("contactspage");
    if (page === "1") {
      return 1;
    } else if (page === "0") {
      return 0;
    }
    return 0;
  }

  function updateActive(id) {
    localStorage.setItem("contactspage", id);
    setActive(id);
  }

  const menuItems = [
    roles?.canViewContacts && {
      id: 0,
      name: "Contacts",
      to: "/contacts",
      icon: `people`,
    },
    roles?.canViewOrgAudiences && {
      id: 1,
      name: "Audiences",
      to: "/contacts/audiences",
      icon: `person-video3`,
    },
  ];

  return (
    <div className={styles.page}>
      <div className={styles.tabs}>
        <TabBar
          tabBarItems={menuItems}
          active={active}
          updateActive={updateActive}
        ></TabBar>
        {/* <span className={styles.header_3}>Contacts</span> */}
      </div>
      <ContactRoutes refetchTasks={refetchTasks} onExport={onExport}/>
    </div>
  );
};
