import MultipleChoice from "./MultipleChoice/MultipleChoice";
import NumberScale from "./NumberScale/NumberScale";
import NumberSlider from "./NumberSlider/NumberSlider";
import TextQ from "./TextQuestion/TextQ";
import styles from "./Question.module.scss";
import TextEditorTry from "components/inputs/input_fields/TextEditorTry/TextEditorTry";
import MatrixQ from "./Matrix/MatrixQ";

/**
 *
 * @param {Object} question the question object
 * @param {Function} onAnswered what to do when answered
 * @param {Function} onRetract what to do when the answer is taken back
 * @param {Map} answerMap to know and hold the current answers
 * @param {string} color the base color for the buttons and such
 * @returns
 */
export const Question = ({
  question,
  onAnswered,
  onRetract,
  answerMap,
  color,
  multipleChoiceBars,
  settings,
  otherMap,
  questionNumber,
}) => {
  return (
    <div
      className={styles.questionContainer}
      style={{
        boxShadow: settings?.boxShadow ? settings?.boxShadow : "",
      }}
    >
      <div className={styles.descriptionContainer}>
        <div style={{ display: "flex", width: "100%" }}>
          {settings?.numbers && (
            <div className={styles.number}>{questionNumber}.</div>
          )}
          <TextEditorTry encoding={question.encoding} />
        </div>

        {question.description &&
          question.description != "" &&
          question.description != "\n" && (
            <TextEditorTry encoding={question.descriptionEncoding} />
          )}
      </div>
      {(question.type === "MultipleChoice" || question.type === "Ranking") &&
        question.choiceQuestion && (
          <MultipleChoice
            question={question}
            onAnswered={onAnswered}
            answerMap={answerMap}
            onRetract={onRetract}
            color={color}
            isMultiSelect={question?.choiceQuestion?.isMultiSelect}
            isRanking={question?.choiceQuestion?.isRanking}
            barDesign={multipleChoiceBars}
            savedOther={otherMap[question.id]}
            settings={settings}
          />
        )}
      {question.type === "NumberScale" && question.scaleQuestion && (
        <NumberScale
          question={question}
          onAnswered={onAnswered}
          onRetract={onRetract}
          color={color}
          startVal={answerMap[question.id]}
        />
      )}
      {question.type === "NumberSlider" && question.scaleQuestion && (
        <NumberSlider
          question={question}
          onAnswered={onAnswered}
          color={color}
          value={answerMap[question.id]}
        />
      )}
      {question.type === "Text" && (
        <TextQ
          question={question}
          onAnswered={onAnswered}
          onRetract={onRetract}
          color={color}
          value={answerMap[question.id] ? answerMap[question.id] : ""}
          answered={question.id in answerMap}
        />
      )}
      {question.type === "Matrix" && (
        <MatrixQ
          question={question}
          designSettings={settings}
          onAnswer={onAnswered}
          onRetract={onRetract}
          answerMap={answerMap}
        />
      )}
    </div>
  );
};

export default Question;
