import styles from "./Export.module.scss";
import FlexCol from "components/layouts/FlexColumn/FlexCol";
import FlexRow from "components/layouts/FlexRow/FlexRow";
import Checkbox from "components/inputs/input_fields/CheckboxBlue/Checkbox";
import Button from "components/Button/Button";
import { TextFieldSimple } from "components/inputs";
import { useState } from "react";
import { Loading } from "components/Loading/Loading";

export function Export({ survey, onExport, exporting }) {
  function constructName() {
    let name = survey.name + " results";
    // let filterList = "";
    // for (let filter of filters) {
    //   let sub = "";
    //   sub += filter.label + ":";
    //   let list = "";
    //   if (filter.properties) {
    //     for (let prop of filter.properties) {
    //       if (list) list += "_";
    //       list += prop.label;
    //     }
    //     sub += list;
    //   }

    //   filterList += (filterList ? "&" : "_with_") + sub;
    // }

    // name += filterList;
    return name;
  }

  const [settings, setSettings] = useState({
    fileType: "csv",
    name: constructName(),
    locally: true,
    email: false,
  });

  function editField(field, val) {
    let copy = { ...settings };
    copy[field] = val;
    setSettings(copy);
  }

  function runExport() {
    if (!settings.email && !settings.locally) {
      return;
    }
    onExport(settings);
  }

  return (
    <div className={styles.container}>
      <div
        className={`${styles.header_3}`}
        style={{ margin: "0", fontSize: "1rem" }}
      >
        Download Options
      </div>

      <FlexCol gap={"6px"}>
        <div className={styles.subtitle}>File Type</div>

        <FlexRow style={{ width: "fit-content", paddingLeft: "10px" }}>
          <Checkbox
            checked={settings.fileType === "csv"}
            onChange={(e) => editField("fileType", "csv")}
          />
          <div className={styles.option}>csv </div>
        </FlexRow>
        <FlexRow style={{ width: "fit-content", paddingLeft: "10px" }}>
          <Checkbox
            checked={settings.fileType === "xlsx"}
            onChange={(e) => editField("fileType", "xlsx")}
          />
          <div className={styles.option}>xlsx</div>
        </FlexRow>
      </FlexCol>

      <FlexRow align={"flex-end"} gap={".2rem"} start>
        <TextFieldSimple
          label={"File Name"}
          containerStyle={{ width: "250px" }}
          style={{
            fontSize: ".85rem",
          }}
          value={settings.name}
          onChange={(val) => editField("name", val)}
        />

        <div
          className={styles.option}
          style={{ paddingBottom: "2px", fontSize: ".85rem" }}
        >
          {`.${settings.fileType}`}
        </div>
      </FlexRow>

      <FlexCol gap={"6px"}>
        <div className={styles.subtitle}>Delivery</div>

        <FlexRow style={{ width: "fit-content", paddingLeft: "10px" }}>
          <Checkbox
            onChange={(e) => editField("locally", e.target.checked)}
            checked={settings.locally}
          />{" "}
          <div className={styles.option}>
            <i
              className={`bi-download ${
                settings.locally ? styles.applied : ""
              }`}
            ></i>
            Download to my computer
          </div>
        </FlexRow>
        <FlexRow style={{ width: "fit-content", paddingLeft: "10px" }}>
          <Checkbox
            onChange={(e) => editField("email", e.target.checked)}
            checked={settings.email}
          />
          <div className={styles.option}>
            <i
              className={`bi-send ${settings.email ? styles.applied : ""}`}
            ></i>
            Email me the file
          </div>
        </FlexRow>
      </FlexCol>

      <FlexRow justify={"center"}>
        <Button
          blue
          shadow
          style={{ alignSelf: "center", gap: "10px" }}
          onClick={runExport}
        >
          {exporting ? (
            <>
              <Loading height={25} width={25} /> Exporting
            </>
          ) : (
            <>
              {" "}
              <i className="bi-cloud-download"> </i>Export
            </>
          )}
        </Button>
      </FlexRow>
    </div>
  );
}
