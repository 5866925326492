import React, { useState, useRef } from "react";
import { SketchPicker } from "react-color";
import { Popover } from "@mui/material";
import styles from "./TextColorPicker.module.scss";
import { SurveyColors, TextColors } from "assets/functions/ColorFunctions";

/**
 * A color picker component
 * @param {Array} colors something
 * @param {String} defaultColor something
 * @param {Function} onChange a function that is called when a color is chosen
 * @returns {React.ReactElement} a simple color picker component
 */

export const TextColorPicker = ({ color, onChange, colorRef }) => {
  const [show, setShow] = useState(false);
  const pickerRef = useRef();

  const colors = [...SurveyColors];

  const handleColorChange = (color) => {
    onChange(color.hex);
  };

  let paper = {
    paper: {
      sx: { backgroundColor: "transparent", boxShadow: "none" },
    },
  };

  return (
    <>
      <div
        className={styles.colorBtn}
        style={{ color: color === "#ffffff" ? "#050606" : color }}
        onClick={() => setShow(true)}
      >
        a
        <div
          style={{
            backgroundColor: color,
            border: color === "#ffffff" ? "2px solid #e9e9e9" : undefined,
          }}
          ref={pickerRef}
          className={styles.color}
        ></div>
      </div>

      <Popover
        ref={colorRef}
        open={show}
        anchorEl={pickerRef.current}
        onClose={() => setShow(false)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        slotProps={paper}
        onClick={(e) => e.stopPropagation()}
      >
        <div style={{ padding: "10px" }}>
          <SketchPicker
            color={color}
            presetColors={colors}
            onChangeComplete={handleColorChange}
          />
        </div>
      </Popover>
    </>
  );
};
