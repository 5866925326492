import {
  useAddLinkToDash,
  useGetCopyToDashInfo,
} from "api/resources/account/dashboard";
import styles from "./AddLinkToDash.module.scss";
import { defaultLinkSettings, LinkType } from "pages/results/Charts/Link/Link";
import { CopyToDashes } from "../PickDash/PickDash";
import { Player } from "@lottiefiles/react-lottie-player";
import { Loading } from "components/Loading/Loading";
import { useState } from "react";

export default function AddLinkToDash({ projectId, reportId, role, ref }) {
  const copyToDashInfo = useGetCopyToDashInfo();
  const addToDash = useAddLinkToDash();
  const [copying, setCopying] = useState(false);
  const [coppied, setCoppied] = useState(false);
  const [pullingDash, setPullingDash] = useState(false);
  const [dashList, setDashList] = useState();
  const [pickDash, setPickDash] = useState(false);

  const link = {
    type: LinkType,
    designSettings: JSON.stringify({
      ...defaultLinkSettings,
      ...{
        projectId: projectId ? projectId : "",
        reportId: reportId ? reportId : "",
      },
    }),
  };

  function onCopyToDash() {
    if (dashList) {
      setDashList();
      setPullingDash(false);
      return;
    }

    copyToDashInfo.mutate(
      {},
      {
        onSuccess: (data) => {
          setPullingDash(false);
          let list = [];
          if (data.info.mine) {
            list.push({
              label: "My Dash",
              dashId: data.info.mine,
            });
          }
          if (data.info.orgs) {
            list.push({
              label: "Org Dash",
              dashId: data.info.orgs,
            });
          }
          if (role.isAdmin) {
            list.push({ label: "Others'", users: true });
          }
          if (data.info.myRoles) {
            list.push({
              label: role.name + "'s Dash",
              dashId: data.info.myRoles,
            });
          }
          if (role.isAdmin) {
            list.push({ label: "Other Roles'", roles: true });
          }
          if (list.length == 1 && list[0].dashId) {
            copyToADash(list[0].dashId);
          } else {
            setDashList(list);
          }
        },
        onError: () => setPullingDash(false),
      }
    );
    setPullingDash(true);
  }

  function copyToADash(dashId) {
    setCopying(true);
    addToDash.mutate(
      { link: link, dashId: dashId },
      {
        onSuccess: (data) => {
          setCopying(false);
          if (data.coppied) {
            setCoppied(true);
            setTimeout(() => setCoppied(false), 2000);
          }
        },
        onError: (data) => {
          setCopying(false);
        },
      }
    );
  }

  function onPickDash(d) {
    setPickDash(d);
  }

  return (
    <>
      <div className={styles.menuItem} onClick={onCopyToDash}>
        <i className="bi bi-speedometer2" style={{ color: "#DF9B6D" }}></i>
        <div className={styles.dashLoading}>
          Add To Dash
          {(pullingDash || copying) && <Loading height={20} width={20} />}
          {coppied && (
            <Player
              autoplay
              src={require("assets/animations/checkmarkblue.json")}
              style={{
                height: "20px",
              }}
            />
          )}
        </div>
      </div>

      {dashList && (
        <div className={`${styles.copyMenu}`}>
          {dashList.map((d, i) => (
            <DashItem dashItem={d} onPickDash={onPickDash} link={link} />
          ))}
        </div>
      )}

      {pickDash && (
        <CopyToDashes
          roles={pickDash.roles}
          users={pickDash.users}
          onClose={() => setPickDash()}
          link={link}
        />
      )}
    </>
  );
}

function DashItem({ dashItem, onPickDash, link }) {
  const [copying, setCopying] = useState(false);
  const [coppied, setCoppied] = useState(false);
  const addToDash = useAddLinkToDash();

  function onClick() {
    if (dashItem.dashId) {
      copy();
    } else {
      onPickDash(dashItem);
    }
  }

  function copy() {
    addToDash.mutate(
      { link: link, dashId: dashItem.dashId },
      {
        onSuccess: (data) => {
          setCopying(false);
          if (data.coppied) {
            setCoppied(true);
            setTimeout(() => setCoppied(false), 2000);
          }
        },
        onError: (data) => {
          setCopying(false);
        },
      }
    );

    setCopying(true);
  }
  return (
    <div className={`${styles.menuItem}`} onClick={onClick}>
      <div className={styles.dashLoading}>
        {coppied && (
          <Player
            autoplay
            src={require("assets/animations/checkmarkblue.json")}
            style={{
              height: "20px",
            }}
          />
        )}{" "}
        {copying && <Loading height={20} width={20} />}
      </div>

      {dashItem.label}
    </div>
  );
}
