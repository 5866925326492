import { useEffect, useState } from "react";
import ReactModal from "components/ReactModal/ReactModal.jsx";
import { Loading } from "components/Loading/Loading";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import { useFetchQuestionsGql } from "api/resources/projects/questions";
import { useFetchRole } from "api/resources/organization/roles";
import { addColIdStrings } from "assets/functions/ObjectFunctions";
import { useFetchCustomFieldNames } from "api/resources/organization/organization";
import {
  useFetchProject,
  useGetDownloadResultsData,
} from "api/resources/projects/projects";
import { DTable } from "./Table";
import { getNiceDate } from "assets/functions/DateFunctions";
import { useGetEmailsInSurveys } from "api/resources/projects/distributions";

export function DownloadTable({ surveyId, onClose, externalFilter }) {
  const fetchQs = useFetchQuestionsGql(surveyId);
  const fetchRole = useFetchRole();
  const fetchProject = useFetchProject(surveyId);
  const fetchFields = useFetchCustomFieldNames();
  const fetchEmails = useGetEmailsInSurveys([surveyId]);

  return (
    <ReactModal
      show
      onClose={onClose}
      modalStyle={{
        overflow: "hidden",
        height: "95vh",
        width: "95vw",
        borderRadius: "1em",
        // paddingBottom: "3rem",
      }}
      dark
    >
      {(fetchQs.isLoading ||
        fetchRole.isLoading ||
        fetchFields.isLoading ||
        fetchProject.isLoading ||
        fetchEmails.isLoading) && <Loading />}
      {fetchQs.isSuccess &&
        fetchRole.isSuccess &&
        fetchFields.isSuccess &&
        fetchProject.isSuccess &&
        fetchEmails.isSuccess && (
          <Data
            survey={fetchProject.data.project}
            questions={fetchQs.data.QuestionByProjectID}
            custom_fields={fetchFields.data.fields}
            role={fetchRole.data.role}
            externalFilter={externalFilter}
            emails={fetchEmails.data.emails}
          />
        )}
    </ReactModal>
  );
}

function Data({
  survey,
  questions,
  custom_fields,
  role,
  externalFilter,
  emails,
}) {
  const [data, setData] = useState(null);

  function getChosenFilter() {
    return {};
    // if (externalFilter) {
    //   return typeof externalFilter != "string"
    //     ? JSON.stringify(externalFilter)
    //     : externalFilter;
    // } else {
    //   return {};
    // }
  }

  const [filters, setFilters] = useState(getChosenFilter());
  const [headers, setHeaders] = useState(getHeaders());
  const [headersDownload, setHeadersDownload] = useState(getHeadersDownload());

  const pullData = useGetDownloadResultsData();

  useEffect(() => {
    pullData.mutate(
      {
        projectId: survey.id,
        filters: JSON.stringify(filters),
      },
      {
        onSuccess: (data) => {
          fillData(data.participations);
        },
        onError: (err) => {
          console.log(err);
        },
      }
    );
  }, [filters]);

  function getHeadersDownload() {
    let tempHeaders = [];

    tempHeaders.push({
      label: "First Name",
      key: "firstName",
      type: "header",
      text: "First Name",
    });
    tempHeaders.push({
      label: "Last Name",
      key: "lastName",
      type: "header",
      text: "Last Name",
    });
    tempHeaders.push({
      label: "Email",
      key: "email",
      type: "header",
      text: "Email",
    });
    tempHeaders.push({
      label: "Survey",
      key: "survey",
      type: "header",
      text: "Survey",
    });
    tempHeaders.push({
      label: "Time taken",
      key: "shortTime",
      type: "header",
      text: "Time taken",
    });

    tempHeaders = [
      ...tempHeaders,
      // ...custom_fields
      //   .filter((f) => f.filterable !== false)
      //   .map((f) => {
      //     return {
      //       label: f.displayName,
      //       key: f.id,
      //       type: "header",
      //       text: f.displayName,
      //     };
      //   }),
    ];

    // insert buckets after corresponding q's
    for (let q of questions) {
      if (q.type === "Matrix") {
        for (let option of q.matrixQuestion.options) {
          tempHeaders.push({
            label: q.questionText + ": " + option,
            key: q.id + "_" + option,
          });
        }
      } else {
        tempHeaders.push({
          label: q.questionText,
          key: q.id,
        });
        if (q.textQuestion && q.textQuestion.bucket) {
          for (let b of q.textQuestion.bucket) {
            tempHeaders.push({
              label: b.name,
              key: b.name,
            });
          }
        }
      }
    }

    return tempHeaders;
  }

  function getHeaders() {
    let tempHeaders = [];

    if (role.canSeeContactInfo) {
      let fn = custom_fields.find((f) => f.generic && f.name === "firstName");
      tempHeaders.push({
        label: fn ? fn.displayName : "First Name",
        acc: "firstName",
        show: true,
        contact: true,
      });
      let ln = custom_fields.find((f) => f.generic && f.name === "lastName");
      tempHeaders.push({
        label: ln ? ln.displayName : "Last Name",
        acc: "lastName",
        show: true,
        contact: true,
      });
      let email = custom_fields.find((f) => f.generic && f.name === "email");
      tempHeaders.push({
        label: email ? email.displayName : "Email",
        acc: "email",
        show: true,
        contact: true,
      });
    }

    tempHeaders.push({
      label: "Survey",
      acc: "survey",
      show: true,
    });

    tempHeaders.push({
      label: "Time taken",
      acc: "timeTaken",
      show: true,
    });

    tempHeaders.push({
      label: "Taken on Email Send",
      acc: "send",
      show: true,
    });

    for (let q of questions) {
      if (q.type === "Matrix") {
        for (let option of q.matrixQuestion.options) {
          tempHeaders.push({
            label: q.questionText + ": " + option,
            acc: q.id + "_" + option, // TODO
            show: true,
          });
        }
      } else {
        tempHeaders.push({
          label: q.questionText,
          acc: q.id,
          show: true,
        });
        if (q.textQuestion && q.textQuestion.bucket) {
          for (let b of q.textQuestion.bucket) {
            tempHeaders.push({
              label: b.name,
              acc: b.id,
              show: true, // TODO
            });
          }
        }
      }
    }

    if (role.canSeeContactInfo) {
      let dem = [
        { name: "phone", backup: "Phone" },
        { name: "country", backup: "Country" },
        { name: "stateProvince", backup: "State/Province" },
        { name: "city", backup: "City" },
      ];

      for (let field of dem) {
        let column = custom_fields.find(
          (f) => f.generic && f.name === field.name
        );
        tempHeaders.push({
          label: column ? column.displayName : field.backup,
          acc: field.name,
          show: false,
          contact: true,
        });
      }

      for (let field of custom_fields) {
        if (!field.generic) {
          tempHeaders.push({
            label: field.displayName,
            acc: field.id,
            show: false,
            contact: true,
          });
        }
      }
    }

    return tempHeaders;
  }

  function fillData(participations) {
    let bucketMap = {};
    for (let q of questions) {
      if (q.textQuestion && q.textQuestion.bucket) {
        bucketMap[q.id] = q.textQuestion.bucket;
      }
    }

    let emailMap = {};
    let num = 1;
    for (let email of emails) {
      if (email.hasLink) {
        emailMap[email.id] = num;
        num++;
      }
    }

    let rows = [];

    for (let part of participations) {
      let obj = {
        participationId: part.id,
        survey: survey.name,
        timeTaken: getNiceDate(new Date(part.startedAt)),
        startedAt: part.startedAt,
      };
      if (part.contact) {
        addColIdStrings(part.contact);
        obj.contactId = part.contact.id;
        for (let h of headers) {
          if (h.contact) {
            obj[h.acc] = part.contact[h.acc];
          }
        }
      } else {
        obj.firstName = "Anonymous";
        obj.lastName = "...";
      }

      if (part.distIdUsed) {
        let email = emails.find((e) => e.id === part.distIdUsed);
        if (email) {
          obj.send = emailMap[email.id]
            ? emailMap[email.id] + " - " + email.subject
            : email.subject;
        }
      }

      for (let answer of part.answers) {
        if (answer.matrixAnswer) {
          let q = questions.find((q) => q.id === answer.questionId);
          let matrixAnswer = JSON.parse(answer.matrixAnswer);

          for (let option in matrixAnswer) {
            obj[answer?.questionId + "_" + option] = getMatrixAnswer(
              option,
              matrixAnswer,
              q
            );
          }
        } else {
          obj[answer?.questionId] = getAnswer(answer);
        }

        // Add in buckets
        if (bucketMap[answer.questionId] && answer.buckets) {
          let buckets = bucketMap[answer.questionId];
          let answerBuckets = JSON.parse(answer.buckets);
          for (let bucket of buckets) {
            if (answerBuckets[bucket.name]) {
              let string = "";
              for (let prop of getAnswerBucketLabels(
                answerBuckets[bucket.name]
              )) {
                if (string) {
                  string += ", ";
                }
                string += prop;
              }

              obj[bucket.id] = string;
            }
          }
        }
      }

      rows.push(obj);
    }

    setData(rows);
  }

  function downloadFiltersSeperately() {
    // debugger;
    let filtersKeys = Object.keys(filters);
    let filteredData = [];

    for (let key of filtersKeys) {
      let field = filters[key].name;
      for (let property of filters[key].properties) {
        let newArray = [];
        for (let answer of data) {
          if (answer[field].includes(property)) {
            newArray.push(answer);
          }
        }
        filteredData.push({ array: newArray, property: property });
      }
    }
    // Loop the array of objects
    var zip = new JSZip();
    let i = 0;
    for (let dataObject of filteredData) {
      let items = dataObject.array;
      let csv = "";
      for (let row = 0; row < items.length; row++) {
        let keysAmount = headersDownload.length;
        let keysCounter = 0;

        // If this is the first row, generate the headings
        if (row === 0) {
          // Loop each property of the object

          for (let key of headersDownload) {
            // This is to not add a comma at the last cell
            // The '\r\n' adds a new line
            csv +=
              `"${key.label}"` + (keysCounter + 1 < keysAmount ? "," : "\r\n");
            keysCounter++;
          }
          keysCounter = 0;
          for (let key of headersDownload) {
            csv +=
              `"${items[row][key.key] ? items[row][key.key] : ""}"` +
              (keysCounter + 1 < keysAmount ? "," : "\r\n");
            keysCounter++;
          }
        } else {
          for (let key of headersDownload) {
            csv +=
              `"${items[row][key.key] ? items[row][key.key] : ""}"` +
              (keysCounter + 1 < keysAmount ? "," : "\r\n");
            keysCounter++;
          }
        }

        keysCounter = 0;
      }

      zip.file(survey.name + "_" + dataObject.property + "_results.csv", csv);
      i += 1;
    }

    zip.generateAsync({ type: "blob" }).then(function (content) {
      saveAs(content, survey.name + "_filtered_results.zip");
    });
  }

  function getAnswerBucketLabels(result) {
    if (Array.isArray(result)) {
      return result;
    } else {
      return [result.overall];
    }
  }

  function isRanking(qId) {
    let q = questions.find((q) => q.id === qId);
    return q.choiceQuestion?.isRanking;
  }

  function getMatrixAnswer(option, matrixAnswer, question) {
    let string = "";

    if (question.choiceQuestion) {
      let choiceAnswer = matrixAnswer[option];
      const ranking = question.choiceQuestion.isRanking;
      for (let i = 0; i < choiceAnswer.length; i++) {
        if (ranking) {
          string += i + 1 + ": ";
        }
        string += choiceAnswer[i];
        if (i < choiceAnswer.length - 1) {
          if (ranking) {
            string += "  ";
          } else {
            string += ", ";
          }
        }
      }
    } else {
      string += matrixAnswer[option];
    }

    return string;
  }

  function getRankingAnswer(answer) {
    let string = "";
    for (let i = 0; i < answer.choiceAnswer.length; i++) {
      if (string) string += " ";
      string += i + 1 + ": ";
      string += answer.choiceAnswer[i];
    }
    return string;
  }

  function getAnswer(answer) {
    if (answer.choiceAnswer) {
      if (isRanking(answer.questionId)) {
        return getRankingAnswer(answer);
      }
      let string = "";
      for (let i = 0; i < answer.choiceAnswer.length; i++) {
        if (string) string += ", ";
        string += answer.choiceAnswer[i];
      }

      return string;
    }
    if (answer.scaleAnswer || answer.scaleAnswer === 0) {
      return answer.scaleAnswer;
    }
    if (answer.textAnswer) {
      let string = answer.textAnswer.replaceAll(`"`, ``);
      string = string.replaceAll(`'`, ``);
      return string;
    }

    return "";
  }

  function onRowClick(row) {}

  return (
    <>
      <DTable
        data={data}
        headers={headers}
        filters={filters}
        setFilters={setFilters}
        survey={survey}
        setHeaders={setHeaders}
        onRowClick={onRowClick}
      />
    </>
  );
}
