import { TextFieldSimple } from "components/inputs";
import styles from "./EditTags.module.scss";
import { useEffect, useRef, useState } from "react";
import { ColorPicker } from "components/inputs/input_fields/ColorPicker/ColorPicker";
import Button from "components/Button/Button";
import {
  useAddQToQTag,
  useCreateQuestionTag,
  useRemoveTagFromQ,
  useSearchQTags,
} from "api/resources/organization/questiontag";
import { Loading } from "components/Loading/Loading";
import { Label } from "components/layouts/Label/Label";
import {
  MultChoice,
  Scale,
  Text,
  getQuestionType,
} from "pages/results/Charts/Settings/AccordianItems/SurveyData/QData";
import FlexRow from "components/layouts/FlexRow/FlexRow";
import Icon from "components/Icon/Icon";

export default function EditTags({
  question,
  saveQuestion,
  allQuestions,
  onUpdate,
}) {
  const createTag = useCreateQuestionTag();
  const removeTagFromQ = useRemoveTagFromQ();
  const addTagToQ = useAddQToQTag();

  const [show, setShow] = useState(false);

  const ref = useRef();
  const colorRef = useRef();

  const [newTag, setNewTag] = useState(null);

  function setUpNewTag() {
    setNewTag({
      name: "",
      color: "",
      description: "",
      type: getQuestionType(question),
    });
    setShow(true);
  }

  useEffect(() => {
    if (show) setShow(false);
    setTags([...question.questionTag]);
  }, [question]);

  const [tags, setTags] = useState([...question.questionTag]);

  const [create, setCreate] = useState("Create");

  function updateTags(newTags) {
    question.questionTag = newTags;
    setTags(newTags);
  }

  function handleCreateTag() {
    setCreate(
      <>
        Creating <Loading height={30} width={30}></Loading>
      </>
    );
    createTag.mutate(
      {
        data: newTag,
        questionId: question.id,
      },
      {
        onSuccess: (data) => {
          // console.log("tag created", data);
          setCreate(
            <>
              Created <i className="bi bi-check"></i>
            </>
          );
          setTimeout(() => {
            setCreate("Create");
            setShow(false);
            updateTags([...tags, data.tag]);
            setNewTag({
              name: "",
              color: "",
              description: "",
            });
            onUpdate();
          }, 750);
        },
      }
    );
  }

  function removeTag(tag, i) {
    let copy = [...tags];
    copy.splice(i, 1);
    updateTags(copy);

    removeTagFromQ.mutate(
      {
        tagId: tag.id,
        questionId: question.id,
      },
      {
        onSuccess: (data) => {
          console.log("tag removed", data);
          onUpdate();
        },
      }
    );
  }

  function addTag(tag) {
    addTagToQ.mutate(
      { tagId: tag.id, questionId: question.id },
      {
        onSuccess: (data) => {
          // console.log("tag added", data);
          updateTags([...tags, tag]);
          onUpdate();
        },
      }
    );
    setShow(false);
  }

  function toggleIsScore() {
    let copy = { ...question };
    copy.isScore = !copy.isScore;
    saveQuestion(copy);
  }

  function toggleIsComment() {
    let copy = { ...question };
    copy.isComment = !copy.isComment;
    saveQuestion(copy);
  }

  return (
    <div className={styles.settingsContainer} ref={ref}>
      <div className={styles.currentTagsContainer}>
        {question.isScore || question.isComment || tags.length > 0 ? (
          <>
            <div className={styles.tags}>
              {question?.isScore && (
                <div className={styles.tag}>
                  <span className={styles.tagName}>NPS</span>
                  <i
                    className={`bi bi-tag-fill ${styles.tagIcon}`}
                    style={{ color: "#15bcc7" }}
                  ></i>
                  <div className={styles.deleteTag}>
                    <i className="bi bi-trash-fill" onClick={toggleIsScore}></i>
                  </div>
                </div>
              )}
              {question?.isComment && (
                <div className={styles.tag}>
                  <span className={styles.tagName}>NPS Comment</span>
                  <i
                    className={`bi bi-tag-fill ${styles.tagIcon}`}
                    style={{ color: "#15bcc7" }}
                  ></i>
                  <div className={styles.deleteTag}>
                    <i
                      className="bi bi-trash-fill"
                      onClick={toggleIsComment}
                    ></i>
                  </div>
                </div>
              )}
              {tags.map((tag, i) => (
                <div className={styles.tag}>
                  <span className={styles.tagName}>{tag.name}</span>
                  {tag.color && (
                    // <div
                    //   className={styles.tagColor}
                    //   style={{ backgroundColor: tag.color }}
                    // ></div>
                    <i
                      className={`bi bi-tag-fill ${styles.tagIcon}`}
                      style={{ color: tag.color }}
                    ></i>
                  )}
                  <div className={styles.deleteTag}>
                    <i
                      className="bi bi-trash-fill"
                      onClick={() => removeTag(tag, i)}
                    ></i>
                  </div>
                </div>
              ))}
            </div>
          </>
        ) : (
          <>
            <div className={styles.noTags}>No Tags</div>
          </>
        )}
      </div>
      {!show && (
        <FlexRow style={{ justifyContent: "center" }}>
          {" "}
          <Button onClick={setUpNewTag} shadow>
            + Tag
          </Button>
        </FlexRow>
      )}

      {show && (
        <div className={styles.twoCreateMethods}>
          <div className={styles.searchContainer}>
            <SearchTags
              question={question}
              addTag={addTag}
              addNPS={() => {
                toggleIsScore();
                setShow(false);
              }}
              addNPSComment={() => {
                toggleIsComment();
                setShow(false);
              }}
              includedTags={tags}
              allQuestions={allQuestions}
            />
          </div>

          <div className={styles.createNewContainer}>
            <div className={styles.createNew}>Create New</div>
            <div className={styles.entry}>
              Name:
              <input
                type={"text"}
                onChange={(e) =>
                  setNewTag((old) => {
                    return { ...old, name: e.target.value };
                  })
                }
                className={styles.nameInput}
                value={newTag.name}
              ></input>
            </div>
            <div className={styles.entry} style={{ fontSize: ".9em" }}>
              Description:
              <input
                type={"text"}
                onChange={(e) =>
                  setNewTag((old) => {
                    return { ...old, description: e.target.value };
                  })
                }
                className={styles.nameInput}
                value={newTag.description}
                style={{ fontSize: ".9em" }}
              ></input>
            </div>

            <div className={styles.entry} style={{ fontSize: ".9em" }}>
              Type:
              <div className={styles.tagType}>{newTag.type}</div>
            </div>
            <div className={styles.entry} style={{ gap: "12px" }}>
              Color:{" "}
              <ColorPicker
                colorRef={colorRef}
                onChange={(color) =>
                  setNewTag((old) => {
                    return { ...old, color: color };
                  })
                }
              />
            </div>
            <FlexRow
              style={{
                alignSelf: "center",
                paddingTop: "5px",
                justifyContent: "center",
              }}
            >
              {newTag.name && (
                <Button shadow blue onClick={handleCreateTag}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "6px",
                    }}
                  >
                    {create}
                  </div>
                </Button>
              )}

              <Button shadow onClick={() => setShow(false)}>
                Cancel
              </Button>
            </FlexRow>
          </div>
        </div>
      )}
    </div>
  );
}

function SearchTags({
  question,
  addTag,
  addNPS,
  addNPSComment,
  includedTags,
  allQuestions,
}) {
  const [searchString, setSearchString] = useState("");

  const searchTags = useSearchQTags(searchString, getQuestionType(question));

  const [active, setActive] = useState(false);

  function existsOtherNPSInSurvey() {
    for (let q of allQuestions) {
      if (q.id !== question.id) {
        if (q.isScore) return true;
      }
    }
    return false;
  }

  function existsOtherNPSCommentInSurvey() {
    for (let q of allQuestions) {
      if (q.id !== question.id) {
        if (q.isComment) return true;
      }
    }
    return false;
  }

  function relevantScoreSearch() {
    if (!searchString) {
      return true;
    }
    return "nps".includes(searchString.toLowerCase());
  }

  function relevantCommentSearch() {
    if (!searchString) {
      return true;
    }
    return "nps comment".includes(searchString.toLowerCase());
  }

  function getAllPossible() {
    let all = [...searchTags.data.tags].filter(
      (tag) => !includedTags.some((t) => t.id === tag.id)
    );
    return all;
  }

  const showNPS =
    question.scaleQuestion &&
    !question.isScore &&
    !question.scaleQuestion.min &&
    question.scaleQuestion.max == 10 &&
    !question.matrixQuestion &&
    !existsOtherNPSInSurvey() &&
    relevantScoreSearch();

  const showComment =
    question.textQuestion &&
    !question.isComment &&
    !question.matrixQuestion &&
    !existsOtherNPSCommentInSurvey() &&
    relevantCommentSearch();

  return (
    <div className={styles.searchDiv}>
      <div className={styles.searchLabel}>Search Question Tag</div>
      <input
        type="text"
        onChange={(e) => setSearchString(e.target.value)}
        className={styles.search}
        value={searchString}
        // placeholder=""
        onFocus={() => setActive(true)}
        onBlur={() => setTimeout(() => setActive(false), 200)}
        id="searchTag"
      ></input>
      <div className={styles.resultsAnchor}>
        {active && searchTags.isSuccess && (
          <div className={styles.results} id="searchTagResults">
            {getAllPossible().map((tag) => (
              <div className={styles.tagOption} onClick={() => addTag(tag)}>
                {tag.name}
                {tag.color && (
                  <i
                    className={`bi bi-tag-fill`}
                    style={{ color: tag.color, paddingTop: "3px" }}
                  ></i>
                )}
              </div>
            ))}
            {showNPS && (
              <div className={styles.tagOption} onClick={addNPS}>
                NPS
                <i
                  className={`bi bi-tag-fill`}
                  style={{ color: "#15bcc7", paddingTop: "3px" }}
                ></i>
              </div>
            )}
            {showComment && (
              <div className={styles.tagOption} onClick={addNPSComment}>
                NPS Comment{" "}
                <i
                  className={`bi bi-tag-fill`}
                  style={{ color: "#15bcc7", paddingTop: "3px" }}
                ></i>
              </div>
            )}

            {!getAllPossible().length && !showNPS && !showComment && (
              <div className={styles.noneLeft}>No Data</div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}
