import FlexRow from "components/layouts/FlexRow/FlexRow";
import styles from "./Accordian.module.scss";
import FlexCol from "components/layouts/FlexColumn/FlexCol";

export function Accordian({
  children,
  show,
  toggle,
  title,
  symbol,
  icon,
  extra,
}) {
  return (
    <div
      className={`${styles.accordian} ${show ? styles.visible : ""}`}
      onClick={!show ? toggle : undefined}
    >
      <FlexRow
        onClick={show ? toggle : undefined}
        className={styles.accordionTitle}
      >
        <FlexRow>
          {title} {symbol && <div className={styles.symbol}>{symbol}</div>}
          {icon && (
            <i style={{ fontSize: "1.2em" }} className={`bi-${icon}`}></i>
          )}
          {extra && <>{extra}</>}
        </FlexRow>

        <span className={styles.accordionicon}>
          <i className="bi bi-caret-left-fill"></i>
        </span>
      </FlexRow>

      {show && <FlexCol className={styles.body}>{children}</FlexCol>}
    </div>
  );
}
