/*
======================= START OF LICENSE NOTICE =======================
  Copyright (C) 2023 Reaction. All Rights Reserved

  NO WARRANTY. THE PRODUCT IS PROVIDED BY DEVELOPER "AS IS" AND ANY
  EXPRESS OR IMPLIED WARRANTIES, INCLUDING, BUT NOT LIMITED TO, THE
  IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR
  PURPOSE ARE DISCLAIMED. IN NO EVENT SHALL DEVELOPER BE LIABLE FOR
  ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, EXEMPLARY, OR CONSEQUENTIAL
  DAMAGES (INCLUDING, BUT NOT LIMITED TO, PROCUREMENT OF SUBSTITUTE
  GOODS OR SERVICES; LOSS OF USE, DATA, OR PROFITS; OR BUSINESS
  INTERRUPTION) HOWEVER CAUSED AND ON ANY THEORY OF LIABILITY, WHETHER
  IN CONTRACT, STRICT LIABILITY, OR TORT (INCLUDING NEGLIGENCE OR
  OTHERWISE) ARISING IN ANY WAY OUT OF THE USE OF THE PRODUCT, EVEN
  IF ADVISED OF THE POSSIBILITY OF SUCH DAMAGE.
======================== END OF LICENSE NOTICE ========================
  Primary Author: natehanson
*/

import { useGqlMutation, useGqlQuery } from "api/Api";
import { gql } from "graphql-request";
import { useQueryClient } from "react-query";





export const useFetchSharedByReportId = (reportId) => {
  const query = gql`
    query Query($reportId: String!) {
      sharedByReportId(reportId: $reportId) {
        canEdit
        id
        reportId
        userId
        organizationId
        roleId
        organization {
          name
        }
        role {
          name
        }
        user {
          firstName
          lastName
          email
          image {
            imageURL
          }
        }
      }
    }
  `;

  return useGqlQuery(["reportOrgByID"], query, { reportId: reportId });
};

export const useCreateSharedReport = () => {
  const mutation = gql`
    mutation Mutation($data: sharedReportInput!) {
      createSharedReport(data: $data) {
        canEdit
        id
        userId
      }
    }
  `;

  const queryClient = useQueryClient();
  const option = {
    onError: (err, _project, rollback) => {
      if (rollback) rollback();
    },
    onSettled: () => {
      queryClient.invalidateQueries("share");
    },
  };
  return useGqlMutation(mutation, option);
};

export const useDeleteShared = () => {
  const mutation = gql`
    mutation Mutation($sharedUserId: String!) {
      deleteSharedReport(sharedUserId: $sharedUserId)
    }
  `;

  const queryClient = useQueryClient();
  const option = {
    onError: (err, _project, rollback) => {
      if (rollback) rollback();
    },
    onSettled: () => {
      queryClient.invalidateQueries("share");
    },
  };
  return useGqlMutation(mutation, option);
};

export const useFetchUsersShareSurvey = (id) => {
  const query = gql`
    query UsersToShareSurvey($surveyId: String!) {
      users: usersToShareSurvey(surveyId: $surveyId) {
        value
        label
        access
        email
      }
    }
  `;
  return useGqlQuery(["usersToShare"], query, { surveyId: id });
};

export const useFetchOrgsShareSurvey = (id) => {
  const query = gql`
    query OrgsToShareSurvey($surveyId: String!) {
      organizations: orgsToShareSurvey(surveyId: $surveyId) {
        value
        label
        type
      }
    }
  `;
  return useGqlQuery(["orgsToShare"], query, { surveyId: id });
};

export const useDeleteSharedSurvey = () => {
  const mutation = gql`
    mutation Mutation($sharedUserId: String!) {
      deletesharedSurvey(sharedUserId: $sharedUserId)
    }
  `;

  const queryClient = useQueryClient();
  const option = {
    onError: (err, _project, rollback) => {
      if (rollback) rollback();
    },
    onSettled: () => {
      queryClient.invalidateQueries("sharedsurveybyuserid");
    },
  };
  return useGqlMutation(mutation, option);
};
