import React, { useEffect, useState, useRef } from "react";
import styles from "./BasicTable.module.scss";
import SimpleBar from "simplebar-react";

import "simplebar-react/dist/simplebar.min.css";
import { shortId } from "../EditableTable/utils";
import Checkbox from "components/inputs/input_fields/CheckboxBlue/Checkbox";
import { HorizontalBar } from "components/layouts/HorizontalBar/HorizontalBar";
import FlexRow from "components/layouts/FlexRow/FlexRow";
import Icon from "components/Icon/Icon";
import SalesforceMapping from "pages/contacts/all_contacts/SalesforceMapping";
import Button from "components/Button/Button";
import EditSingleCustomField from "components/CustomFieldsEditor/EditSingle";
import {
  convertToStandardTime,
  trimDate,
} from "assets/functions/DateFunctions";
import { Loading } from "components/Loading/Loading";
import DataInput from "components/layouts/ObjectLayout/DataInput";

/**
 * A Table that is used to display data
 * @param {type} initHeaders something
 * @param {type} createTitle something
 * @param {type} createMethod something
 * @param {type} data something
 * @param {type} onRowClick something
 * @param {type} setPageNumber something
 * @param {type} pageNumber something
 * @param {type} bottomLeft something
 * @param {type} bottomRight something
 * @param {boolean} noFilter no filter button
 * @param {boolean} noSearch no search bar
 * @param {type} maxPage something
 * @returns {React.ReactElement} a Table component
 */

export function BasicTable({
  initheaders,
  data,
  onRowClick,
  sort,
  setSort,
  emptyMessage,
  headerSize,
  tableSize,
  padding,
  rowNumbers,
  paginateScroll,
  onSaveEditableCell,
  select,
  selected,
  setSelected,
  editableFields,
  overflowVisible,
  moveableColumns,
  style,
  onRowClickSelect,
  headerSettings,
  salesforce,
  user,
  organization,
  addFilter,
  customFields,
  draggable,
  tablepadding,
  scrollPage,
  loading,
  pageNum,
  setPageNum,
  maxItems,
  fullLoading,
  noRowAction,
  onClickEditAction,
  onClickDeleteAction,
  actionCell,
  actionOptions,
}) {
  const [headers, setHeaders] = useState(checkSort(initheaders));
  const [topOfList, setTopOfList] = useState();
  const [draggingColumnIndex, setDraggingColumnIndex] = useState(null);
  const [startingColumnIndex, setStartingColumnIndex] = useState(null);
  const [isDragging, setIsDragging] = useState(false);
  const [draggingX, setDraggingX] = useState(0);
  const tableRef = useRef(null);
  const [overlayPosition, setOverlayPosition] = useState({ x: 0, y: 0 });
  const [overlayWidth, setOverlayWidth] = useState(null);
  const [mousePo, setMousePo] = useState(null);
  const [longPressTimeout, setLongPressTimeout] = useState(null);
  const [dragHeader, setDragHeader] = useState(null);
  const [checked, setChecked] = useState(false);
  // Use refs to store the latest values of state
  const draggingColumnIndexRef = useRef(startingColumnIndex);
  const isDraggingRef = useRef(isDragging);
  const overlayWidthRef = useRef(overlayWidth);
  const mousePoRef = useRef(mousePo);
  const newColumnIndexRef = useRef(draggingColumnIndex);

  // Keep refs in sync with state
  useEffect(() => {
    draggingColumnIndexRef.current = startingColumnIndex;
    newColumnIndexRef.current = draggingColumnIndex;
    isDraggingRef.current = isDragging;
    overlayWidthRef.current = overlayWidth;
    mousePoRef.current = mousePo;
  }, [
    startingColumnIndex,
    isDragging,
    overlayWidth,
    mousePo,
    draggingColumnIndex,
  ]);

  const handleMouseDowndrag = (index, event) => {
    event.stopPropagation();
    event.preventDefault();
    const timer = setTimeout(() => {
      setOpenHeader(null);
      setDraggingColumnIndex(index);
      setDraggingX(event.clientX);
      setIsDragging(true);
      setStartingColumnIndex(index);
      setDragHeader(headers[index]);
      const headerElement = event.target.closest("th");
      const headerRect = headerElement.getBoundingClientRect();

      setOverlayWidth(headerRect.width);

      const tableRect = tableRef.current.getBoundingClientRect();
      const mouseX = event.clientX - tableRect.left;
      const mousePositionInHeader = event.clientX - headerRect.left;
      setMousePo(mousePositionInHeader);

      setOverlayPosition({
        x: mouseX - mousePositionInHeader + 16,
        y: headerRect.top,
      });

      // Add event listeners for mouse move and mouse up
      document.addEventListener("mousemove", handleMouseMovedrag);
      document.addEventListener("mouseup", handleMouseUpdrag);
    }, 300); // 1 second delay for long press
    // Save the timeout to be cleared if the user doesn't hold down long enough
    setLongPressTimeout(timer);
  };

  const handleMouseMovedrag = (event) => {
    const tableRect = tableRef.current.getBoundingClientRect();
    let mouseX = event.clientX - tableRect.left - mousePoRef.current + 16;
    // Update overlay position to follow the mouse
    let newpo = {
      x: mouseX,
      y: event.clientY,
    };
    setOverlayPosition(newpo);

    // Get the bounding rectangles of all headers
    const headersRects = Array.from(
      tableRef.current.querySelectorAll("th")
    ).map((th) => th.getBoundingClientRect());

    let newDropIndex = newColumnIndexRef.current;
    mouseX = event.clientX - overlayWidthRef.current;
    // const dropIndex = Math.floor((mouseX / tableRect.width) * headers.length);
    // Loop through all headers to find the one the mouse is over
    for (let i = 1; i < headersRects.length; i++) {
      if (i !== newColumnIndexRef.current + 1) {
        // Skip the currently dragged header
        const headerRect = headersRects[i];

        // console.log(`header-${i}`, headerRect.left, " : ", headerRect.right);
        // Check if the mouse is in the middle of the current header
        if (mouseX > headerRect.left && mouseX < headerRect.right) {
          // Check if the mouse is close to the midpoint
          newDropIndex = i - 1;
          break;
        }
      }
    }

    // Perform the column swap only if the dropIndex is different
    if (newDropIndex !== newColumnIndexRef.current) {
      const newColumns = [...headers];
      const draggedColumn = newColumns[draggingColumnIndexRef.current];
      newColumns.splice(draggingColumnIndexRef.current, 1);
      newColumns.splice(newDropIndex, 0, draggedColumn);
      setHeaders(newColumns);
      setDraggingColumnIndex(newDropIndex);
    }
  };

  const handleMouseUpdrag = () => {
    setIsDragging(false);
    setDraggingColumnIndex(null);
    // Remove mousemove and mouseup event listeners
    document.removeEventListener("mousemove", handleMouseMovedrag);
    document.removeEventListener("mouseup", handleMouseUpdrag);
  };

  const HandleMouseUpclearTimer = () => {
    clearTimeout(longPressTimeout);
  };

  useEffect(() => {
    setTopOfList(
      paginateScroll && data && data?.length > 0 ? data?.slice(0, 50) : data
    );
  }, [data]);

  useEffect(() => {
    setHeaders(checkSort(initheaders));
  }, [initheaders]);

  function onScroll(e) {
    if (paginateScroll && topOfList?.length < data?.length) {
      var scrollableContainer = e.target;
      let distanceToBottom =
        scrollableContainer.scrollHeight -
        (scrollableContainer.scrollTop + scrollableContainer.clientHeight);

      if (distanceToBottom <= 50) {
        let nextlength = topOfList?.length + 50;
        setTopOfList(data.slice(0, nextlength));
      }
    }

    if (scrollPage && maxItems > data?.length && !loading && !fullLoading) {
      var scrollableContainer = e.target;
      let distanceToBottom =
        scrollableContainer.scrollHeight -
        (scrollableContainer.scrollTop + scrollableContainer.clientHeight);

      if (distanceToBottom <= 50) {
        setPageNum(pageNum + 1);
      }
    }
  }

  function checkSort(heads) {
    for (let i = 0; i < heads?.length; i++) {
      let header = heads[i];
      if (sort?.item === header?.value) {
        heads[i].sort = true;
      } else {
        heads[i].sort = false;
      }
    }

    if (heads) {
      heads = heads?.filter((header) => header.enabled);
    }
    return heads;
  }
  function trimDate(date) {
    let d = new Date(date);
    let niceString = d.toDateString();
    let month = niceString.substring(4, 7);
    let day = niceString.substring(8, 10);
    //eslint-disable-next-line no-unused-expressions
    day.charAt(0) == "0" ? (day = day.charAt(1)) : null;
    let year = niceString.substring(11);
    return month + " " + day + ", " + year;
  }

  function isDate(dateString) {
    // Basic regex for matching YYYY-MM-DD or MM/DD/YYYY format
    const regex =
      /^\d{4}-\d{2}-\d{2}$|^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d{3})?(?:Z|[+-]\d{2}:\d{2})?$/;

    // Check if the input matches the date format first
    if (!regex.test(dateString)) {
      return false;
    }

    // Now check if it's a valid date using Date object
    const date = new Date(dateString);
    return !isNaN(date.getTime());
  }

  function screenForDate(value) {
    if (isDate(value)) {
      return trimDate(value);
    } else {
      if (typeof value === "object") {
        if (Array.isArray(value)) {
          return "";
        }
      }
      return value;
    }
  }

  function handleSetSort(name, index, canSort) {
    if (canSort) {
      let newSort = { ...sort };
      newSort.item = name;
      if (name === sort.item) {
        newSort.descend = !sort.descend;
      }
      for (let i = 0; i < headers?.length; i++) {
        headers[i].sort = false;
      }
      headers[index].sort = true;
      setSort(newSort);
    }
  }
  function handleSetSortSpecific(name, index, canSort, descend) {
    if (canSort) {
      let newSort = { ...sort };
      newSort.item = name;

      newSort.descend = descend;

      for (let i = 0; i < headers?.length; i++) {
        headers[i].sort = !descend;
      }
      headers[index].sort = descend;
      setSort(newSort);
    }
  }

  const [resizingIndex, setResizingIndex] = useState(null);
  const [originalWidth, setOriginalWidth] = useState(0);
  const [originalX, setOriginalX] = useState(0);
  const [openHeader, setOpenHeader] = useState();
  const [adjustFields, setAdjustFields] = useState(false);
  const [mapColumns, setMapColumns] = useState(false);
  const [changeHeader, setChangeHeader] = useState(false);
  const ref = useRef();

  const handleClickOutside = (event) => {
    if (headerSettings && ref.current && !ref.current.contains(event.target)) {
      setOpenHeader(null);
      setChangeHeader(false);
    }
  };

  useEffect(() => {
    if (headerSettings) {
      document.addEventListener("click", handleClickOutside, true);
      return () => {
        document.removeEventListener("click", handleClickOutside, true);
      };
    }
  }, []);

  const handleMouseDown = (index, event) => {
    event.stopPropagation();
    setResizingIndex(index);
    setOriginalWidth(event.target.offsetParent.clientWidth);
    setOriginalX(event.pageX);
    document.addEventListener("mouseup", handleMouseUp);
  };

  const handleMouseMove = (e) => {
    if (resizingIndex !== null) {
      const newWidth = originalWidth + (e.pageX - originalX);
      let newHeaders = [...headers];
      newHeaders[resizingIndex].width = newWidth;
      setHeaders(newHeaders);
    }
  };

  const handleMouseUp = () => {
    setResizingIndex(null);
    document.removeEventListener("mouseup", handleMouseUp);
  };

  const scrollableNodeRef = useRef(null);
  const headersBox = useRef(null);

  function changeHeaderName(e) {
    let val = e.target.value;
    setChangeHeader(val);
  }

  function handleSelectPage() {
    let toadd = [];
    let firstIndex = 100;
    for (let c of data) {
      let index = selected.findIndex((i) => i.id === c.id);
      if (index < 0) {
        toadd.push(c);
      } else if (index < firstIndex) {
        firstIndex = index;
      }
    }
    let items = [...selected, ...toadd];
    if (toadd.length === 0) {
      items.splice(firstIndex, data.length);
    }

    setSelected(items);
  }

  function handleSelect(item, val) {
    let items = [...selected];
    if (val) {
      items.push(item);
    } else {
      let index = items.findIndex((c) => c.id === item?.id);
      items.splice(index, 1);
    }

    setSelected(items);
  }

  return (
    <>
      <SimpleBar
        className={styles.tableContainer2}
        onScrollCapture={onScroll}
        // style={{overflow: "auto"}}
        // forceVisible="y"
        // ref={scrollableNodeRef}
        scrollableNodeProps={{ ref: scrollableNodeRef }}
        onMouseMove={moveableColumns && handleMouseMove}
        style={style}
      >
        <table
          className={styles.table}
          ref={tableRef}
          style={{ padding: tablepadding ? tablepadding : "" }}
        >
          {/* Custom Overlay for Dragging */}
          {isDragging && (
            <div
              style={{
                top: 0, // Offset for better visibility
                left: overlayPosition?.x,
                maxWidth: overlayWidth + "px",
              }}
              className={styles.overlayContent}
            >
              <table className={styles.table}>
                <thead className={styles.header}>
                  <tr>
                    <th
                      // key={ind}
                      id={dragHeader.id}
                      className={`${dragHeader.sort && styles.sortedHeader} ${
                        headerSettings && !isDragging && styles.thSettings
                      } ${
                        draggingColumnIndex === startingColumnIndex
                          ? styles.draggingheader
                          : ""
                      }`}
                      style={{
                        fontSize: headerSize ? headerSize : "",
                        minWidth: dragHeader?.width ? dragHeader.width : "",
                        maxWidth: dragHeader?.width ? dragHeader.width : "",
                        cursor: isDragging ? "grabbing" : "",
                      }}
                      // onMouseDown={(event) =>
                      //   !resizingIndex && resizingIndex != 0
                      //     ? handleMouseDowndrag(ind, event)
                      //     : undefined
                      // }
                      // onMouseUp={HandleMouseUpclearTimer}
                    >
                      <div
                        className={`${styles.headerContainer} ${
                          headerSettings && styles.headerSettings
                        } ${
                          headerSettings &&
                          openHeader?.id === dragHeader?.id &&
                          styles.headerOpen
                        }`}
                        // onClick={() =>
                        //   headerSettings && !isDragging
                        //     ? setOpenHeader(header)
                        //     : handleSetSort(
                        //         header.value,
                        //         ind,
                        //         header.canSort
                        //       )
                        // }
                      >
                        <div className={styles.innerHeader}>
                          <div className={styles.innerHeader2}>
                            {dragHeader?.label}
                          </div>
                          {dragHeader.canSort && (
                            <>
                              {dragHeader.sort &&
                                (sort.descend ? (
                                  <i className="bi bi-arrow-down"></i>
                                ) : (
                                  <i className="bi bi-arrow-up"></i>
                                ))}
                              {!dragHeader.sort && (
                                <i className="bi bi-dash"></i>
                              )}
                            </>
                          )}
                        </div>

                        <div
                          className={`${
                            moveableColumns && !isDragging
                              ? styles.mover
                              : styles.unmove
                          }`}
                          onMouseDown={(e) =>
                            moveableColumns &&
                            handleMouseDown(ind, e, header.id)
                          }
                        ></div>
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {(paginateScroll ? topOfList : data)?.map((rowdata, ind) => (
                    <tr
                      key={ind}
                      // className={!editableFields ? styles.hovers : ""}
                    >
                      {dragHeader?.enabled && (
                        <Cell
                          head={dragHeader}
                          i={startingColumnIndex}
                          selected={selected}
                          handleSelect={handleSelect}
                          rowdata={rowdata}
                          onRowClick={onRowClick}
                          onRowClickSelect={onRowClickSelect}
                          padding={padding}
                          overflowVisible={overflowVisible}
                          draggingColumnIndex={draggingColumnIndex}
                          tableSize={tableSize}
                          ind={ind}
                          editableFields={editableFields}
                        ></Cell>
                      )}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
          {headers?.length > 0 && (
            <thead className={styles.header} ref={headersBox}>
              <tr>
                {rowNumbers && (
                  <th
                    key={"#"}
                    style={{
                      fontSize: headerSize ? headerSize : "",
                      // width: "150px",
                      paddingLeft: ".5rem",
                    }}
                  >
                    #
                  </th>
                )}
                {select && (
                  <th
                    key={"check"}
                    style={{
                      fontSize: headerSize ? headerSize : "",
                      // display: "flex",
                      // justifyContent: "center",
                      // alignItems: "center",
                      // width: "100%",
                      padding: "7px 10px",
                      paddingLeft: "1rem",
                      height: "100%",
                    }}
                  >
                    <Checkbox
                      checked={checked}
                      onChange={(e) => {
                        handleSelectPage(e);
                        setChecked(e.target.checked);
                      }}
                      className={styles.check}
                    ></Checkbox>
                  </th>
                )}
                {actionCell && (
                  <th
                    key={shortId()}
                    style={{
                      fontSize: headerSize ? headerSize : "",
                      width: "40px",
                    }}
                  >
                    {/* Action */}
                  </th>
                )}
                {headers?.map((header, ind) => (
                  <React.Fragment key={ind}>
                    {header?.enabled && (
                      <th
                        key={ind}
                        id={header.id}
                        className={`${header.sort && styles.sortedHeader} ${
                          headerSettings && !isDragging && styles.thSettings
                        } ${
                          draggingColumnIndex === ind
                            ? styles.draggingheader
                            : ""
                        }`}
                        style={{
                          fontSize: headerSize ? headerSize : "",
                          minWidth: header?.width ? header.width : "",
                          maxWidth: header?.width ? header.width : "",
                          cursor: isDragging ? "grabbing" : "",
                        }}
                        onMouseDown={(event) =>
                          draggable && !resizingIndex && resizingIndex != 0
                            ? handleMouseDowndrag(ind, event)
                            : undefined
                        }
                        onMouseUp={HandleMouseUpclearTimer}
                      >
                        <div
                          className={`${styles.headerContainer} ${
                            headerSettings && styles.headerSettings
                          } ${
                            headerSettings &&
                            openHeader?.id === header?.id &&
                            styles.headerOpen
                          }`}
                          onClick={() =>
                            headerSettings && !isDragging
                              ? setOpenHeader(header)
                              : handleSetSort(header.value, ind, header.canSort)
                          }
                        >
                          <div className={styles.innerHeader}>
                            {header?.icon && (
                              <i className={`bi-${header?.icon}`}></i>
                            )}
                            <div className={styles.innerHeader2}>
                              {header?.label}
                            </div>
                            {header.canSort && (
                              <>
                                {header.sort &&
                                  (sort.descend ? (
                                    <i className="bi bi-arrow-down"></i>
                                  ) : (
                                    <i className="bi bi-arrow-up"></i>
                                  ))}
                                {!header.sort && <i className="bi bi-dash"></i>}
                              </>
                            )}
                          </div>
                          <div
                            className={`${
                              moveableColumns && !isDragging
                                ? styles.mover
                                : styles.unmove
                            }`}
                            onMouseDown={(e) =>
                              moveableColumns &&
                              handleMouseDown(ind, e, header.id)
                            }
                          ></div>
                        </div>
                        {openHeader?.id === header?.id && (
                          <div className={styles.settingspopup} ref={ref}>
                            <FlexRow gap=".5rem" start>
                              <input
                                value={
                                  changeHeader != false || changeHeader === ""
                                    ? changeHeader
                                    : header?.label
                                }
                                className={styles.changeInput}
                                onChange={changeHeaderName}
                              ></input>
                              {changeHeader && (
                                <Button blue shadow>
                                  Save
                                </Button>
                              )}
                            </FlexRow>
                            {salesforce && header?.salesforceColumn && (
                              <FlexRow start style={{ padding: "0 .5rem" }}>
                                <Icon iconName={"cloud"} blue></Icon>
                                <span className={styles.text_3}>
                                  {header.salesforceColumn}
                                </span>
                              </FlexRow>
                            )}
                            {salesforce && !header?.salesforceColumn && (
                              <FlexRow start style={{ padding: "0 .5rem" }}>
                                <Icon iconName={"cloud"} blue></Icon>
                                <span className={styles.text_3}>
                                  Not mapped to Salesforce
                                </span>
                              </FlexRow>
                            )}
                            <HorizontalBar
                              width={"100%"}
                              height="2px"
                              style={{ margin: "0rem" }}
                            ></HorizontalBar>
                            {header?.canSort && (
                              <>
                                <div
                                  className={styles.menu_item}
                                  style={{
                                    fontSize: ".8rem",
                                    padding: ".5rem 1rem",
                                  }}
                                  onClick={() =>
                                    handleSetSortSpecific(
                                      header.value,
                                      ind,
                                      header.canSort,
                                      false
                                    )
                                  }
                                >
                                  <i className={"bi-arrow-up"}></i> Sort
                                  Ascending
                                </div>
                                <div
                                  className={styles.menu_item}
                                  style={{
                                    fontSize: ".8rem",
                                    padding: ".5rem 1rem",
                                  }}
                                  onClick={() =>
                                    handleSetSortSpecific(
                                      header.value,
                                      ind,
                                      header.canSort,
                                      true
                                    )
                                  }
                                >
                                  <i className={"bi-arrow-down"}></i> Sort
                                  Descending
                                </div>
                              </>
                            )}
                            {addFilter && (
                              <div
                                className={styles.menu_item}
                                style={{
                                  fontSize: ".8rem",
                                  padding: ".5rem 1rem",
                                }}
                                onClick={() => addFilter(header)}
                              >
                                <i className={"bi-funnel"}></i> Add Filter
                              </div>
                            )}
                            {salesforce && (
                              <div
                                className={styles.menu_item}
                                style={{
                                  fontSize: ".8rem",
                                  padding: ".5rem 1rem",
                                }}
                                onClick={() => {
                                  setMapColumns(true);
                                }}
                              >
                                <i className={"bi-sliders"}></i> Edit Salesforce
                                Mapping
                              </div>
                            )}
                            <div
                              className={styles.menu_item}
                              style={{
                                fontSize: ".8rem",
                                padding: ".5rem 1rem",
                              }}
                              onClick={() => setAdjustFields(true)}
                            >
                              <i className={"bi-gear"}></i> Column Settings
                            </div>
                          </div>
                        )}
                      </th>
                    )}
                  </React.Fragment>
                ))}
              </tr>
            </thead>
          )}
          {data?.length === 0 && !loading && (
            <div className={styles.emptyMessage}>
              {emptyMessage ? emptyMessage : "No data to display"}
            </div>
          )}

          {data?.length > 0 && (
            <tbody>
              {(paginateScroll ? topOfList : data)?.map((rowdata, ind) => (
                <tr
                  key={ind}
                  className={!noRowAction ? styles.hover : styles.nohover}
                >
                  {rowNumbers && (
                    <td
                      key={"row num"}
                      style={{
                        fontSize: tableSize ? tableSize : "",
                        paddingTop: padding ? padding : "",
                        paddingBottom: padding ? padding : "",
                        overflow: overflowVisible ? "visible" : "",
                        paddingLeft: "1em",
                        // width: "100px",
                      }}
                      className={`${ind % 2 === 0 && styles.gray}=`}
                    >
                      {ind + 1}
                    </td>
                  )}
                  {select && (
                    <td
                      key={"checkbox:" + ind}
                      style={{
                        fontSize: tableSize ? tableSize : "",
                        paddingTop: padding ? padding : "",
                        paddingBottom: padding ? padding : "",
                        paddingLeft: "1rem",
                        overflow: overflowVisible ? "visible" : "",
                      }}
                      className={`${ind % 2 === 0 && styles.gray} `}
                    >
                      <Checkbox
                        checked={
                          selected && selected?.length > 0
                            ? selected.find((c) => c.id === rowdata?.id)
                            : false
                        }
                        onChange={(e) =>
                          handleSelect(rowdata, e.target.checked)
                        }
                        className={styles.check}
                      ></Checkbox>
                    </td>
                  )}
                  {actionCell && (
                    <td
                      key={"action button"}
                      style={{
                        fontSize: tableSize ? tableSize : "",
                        padding: ".5rem",
                        paddingTop: padding ? padding : "",
                        paddingBottom: padding ? padding : "",
                        overflow: "visible",
                      }}
                      // className={`${ind % 2 === 0 && styles.gray}=`}
                      // onClick={(e) => {
                      //   e.stopPropagation();
                      //   onClickActionCell
                      //     ? onClickActionCell(rowdata, ind)
                      //     : "";
                      // }}
                    >
                      <Button
                        shadow
                        height={"20px"}
                        width="20px"
                        optionStyle={{
                          left: "0",
                          width: "fit-content",
                          fontSize: "1rem",
                        }}
                        options={
                          actionOptions
                            ? actionOptions.map((v) => {
                                return {
                                  ...v,
                                  onClick: () => v.onClick(rowdata, ind),
                                };
                              })
                            : [
                                {
                                  onClick: onClickEditAction
                                    ? () => onClickEditAction(rowdata, ind)
                                    : undefined,
                                  data: (
                                    <>
                                      <Icon
                                        red={onClickEditAction}
                                        sapphire
                                        iconName={"pencil"}
                                      ></Icon>{" "}
                                      Edit
                                    </>
                                  ),
                                  style: {
                                    backgroundColor: !onClickEditAction
                                      ? "lightgray"
                                      : "",
                                  },
                                },
                                {
                                  onClick: onClickDeleteAction
                                    ? () => onClickDeleteAction(rowdata, ind)
                                    : undefined,
                                  data: (
                                    <>
                                      <Icon
                                        red={onClickDeleteAction}
                                        iconName={"trash"}
                                      ></Icon>{" "}
                                      Delete
                                    </>
                                  ),
                                  style: {
                                    backgroundColor: !onClickDeleteAction
                                      ? "lightgray"
                                      : "",
                                  },
                                },
                              ]
                        }
                      >
                        <i
                          className="bi-caret-down-fill"
                          style={{ marginTop: ".15rem" }}
                        ></i>
                      </Button>
                    </td>
                  )}
                  {headers?.map((head, i) => (
                    <React.Fragment key={i}>
                      {head?.enabled && (
                        <Cell
                          head={head}
                          i={i}
                          selected={selected}
                          handleSelect={handleSelect}
                          rowdata={rowdata}
                          onRowClick={onRowClick}
                          onRowClickSelect={onRowClickSelect}
                          noRowAction={noRowAction}
                          padding={padding}
                          overflowVisible={overflowVisible}
                          draggingColumnIndex={draggingColumnIndex}
                          tableSize={tableSize}
                          ind={ind}
                          editableFields={editableFields}
                          onSaveEditableCell={onSaveEditableCell}
                        ></Cell>
                      )}
                    </React.Fragment>
                  ))}
                </tr>
              ))}
            </tbody>
          )}
        </table>

        {loading === true && (
          <FlexRow style={{ justifyContent: "center" }}>
            <Loading height={60} width={60}></Loading>
          </FlexRow>
        )}
        {fullLoading === true && (
          <FlexRow style={{ justifyContent: "center" }}>
            <Loading height={60} width={60}></Loading>
          </FlexRow>
        )}
      </SimpleBar>
      {adjustFields && (
        // <FieldAdjuster
        //   show={adjustFields}
        //   setShow={setAdjustFields}
        //   orgId={organization?.id}
        //   user={user}
        //   salesforce={salesforce}
        //   startColumn={openHeader}
        // ></FieldAdjuster>
        <EditSingleCustomField
          onClose={() => setAdjustFields(false)}
          field={openHeader}
          // onSaved={handleSaved}
          // brandNew={newField}
          // noModal={noModal}
          // deleteField={handleDeleteField}
        />
      )}
      {salesforce && mapColumns && (
        <SalesforceMapping
          organization={organization}
          user={user}
          initheads={headers}
          customFields={customFields}
          showMapping={mapColumns}
          setShowMapping={setMapColumns}
        >
          {" "}
        </SalesforceMapping>
      )}
    </>
  );
}

export function Cell({
  head,
  i,
  ind,
  rowdata,
  selected,
  handleSelect,
  onRowClick,
  onRowClickSelect,
  padding,
  overflowVisible,
  draggingColumnIndex,
  tableSize,
  editableFields,
  onSaveEditableCell,
  initvalue,
  className,
  triggerEdit,
  editClassName,
  noRowAction,
}) {
  const [edit, setEdit] = useState(false);
  const [cellMap, setMap] = useState();

  const ref = useRef();

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setEdit(false);
    }
  };

  useEffect(() => {
    setMap({
      id: head?.id,
      value: initvalue || initvalue === "" ? initvalue : rowdata[head?.value],
      label: initvalue || initvalue === "" ? initvalue : rowdata[head?.value],
    });
  }, [head]);

  useEffect(() => {
    if (triggerEdit === true || triggerEdit === false) {
      setEdit(triggerEdit);
    }
  }, [triggerEdit]);

  // useEffect(() => {
  //   if (editableFields && head?.editable) {
  //     document.addEventListener("click", handleClickOutside, true);
  //     return () => {
  //       document.removeEventListener("click", handleClickOutside, true);
  //     };
  //   }
  // }, []);

  function getValue() {
    if (!head?.multiple) {
      let value = cellMap?.value;
      if (head?.cell_style) {
        value = head.cell_style(value, rowdata?.id, i, rowdata);
      }
      if (head?.dataType === "date") {
        value = trimDate(cellMap?.value);
      }
      if (head?.dataType === "time") {
        value = convertToStandardTime(cellMap?.value);
      }
      if (head?.dataType === "date/time") {
        value = trimDate(cellMap?.value, true);
      }
      if (!head.dataType) {
        value = rowdata[head?.value];
      }
      return value != undefined ? value : "";
    } else {
      let final = "";
      if (cellMap?.value?.length > 0) {
        let i = 0;
        for (let val of cellMap?.value) {
          final += val?.name;
          if (i < cellMap?.value?.length - 1) {
            final += `${head?.delimiter} `;
          }
          i++;
        }
      }
      return final;
    }
  }
  function handleEdit(value) {
    if (value) {
      document.addEventListener("click", handleClickOutside, true);
    } else {
      document.removeEventListener("click", handleClickOutside, true);
    }
    setEdit(value);
  }

  return (
    <td
      onClick={(e) => {
        if (head?.onClick) {
          head?.onClick(rowdata);
        } else if (onRowClick) {
          onRowClick(rowdata);
        }
        if (onRowClickSelect) {
          let selectedthis =
            selected && selected?.length > 0
              ? selected.find((c) => c.id === rowdata?.id)
              : false;
          handleSelect(rowdata, !selectedthis);
        }
      }}
      style={{
        fontSize: tableSize ? tableSize : "",
        overflow: overflowVisible ? "visible" : "",
        maxWidth: head?.width ? head.width : "",
      }}
      className={`${ind % 2 === 0 && styles.gray} ${
        draggingColumnIndex === i ? styles.dragging : ""
      } ${noRowAction ? styles.nohover : ""} ${className}`}
      // ref={ref}
    >
      {edit && !head?.obscure && (
        <div
          className={`${styles.editBox} ${editClassName}`}
          // style={{
          //   position: "absolute",
          //   left: "0",
          //   width: "100%",
          //   display: "flex",
          //   gap: ".5rem",
          //   flexDirection: "column",
          //   backgroundColor: "white",
          //   padding: ".5rem",
          //   paddingBottom: ".7rem",
          //   borderRadius: ".5rem",
          //   width: "fit-content",
          //   maxWidth: "400px",
          //   minWidth: "300px",
          //   zIndex: "1",
          // }}
        >
          <GetEditField
            head={head}
            cellMap={cellMap}
            onSaveEditableCell={onSaveEditableCell}
            setMap={setMap}
            setEdit={handleEdit}
            rowdata={rowdata}
            i={i}
          ></GetEditField>

          {/* <DataInput
          ></DataInput> */}
        </div>
      )}
      <div className={styles.tdcontent}>
        {!head?.obscure && <div className={styles.tdvalue}>{getValue()}</div>}
        {head?.obscure && "..."}
        {editableFields && head?.editable && !head?.obscure && (
          <div className={styles.editIcon}>
            <Icon
              iconName={"pencil-fill"}
              onClick={() => handleEdit(true)}
            ></Icon>
          </div>
        )}
      </div>
    </td>
  );
}

function GetEditField({
  head,
  cellMap,
  onSaveEditableCell,
  setMap,
  setEdit,
  rowdata,
  i,
}) {
  const [value, setValue] = useState(cellMap?.value);
  const [valid, setValid] = useState(true);

  function handleChange(newvalue, remove) {
    if (head?.multiple && remove) {
      let temp = [...value];
      let index = temp?.findIndex((a) => a?.name === newvalue?.name);
      if (index >= 0) {
        temp?.splice(index, 1);
        setValue(temp);
      }
    } else if (head?.multiple && !remove) {
      let temp = [...value];
      temp.push({ name: newvalue, customFieldId: head.id, id: "new" });
      setValue(temp);
    } else {
      setValue(newvalue);
    }
  }

  return (
    <>
      <DataInput
        label={""}
        value={value}
        shadow
        onChange={handleChange}
        dataType={head?.dataType}
        icon={head?.icon}
        min={head?.min}
        max={head?.max}
        falseValue={head?.falseValue}
        trueValue={head?.trueValue}
        disabled={!head?.editable}
        id={head?.id}
        required={head?.required}
        multiple={head?.multiple}
        unique={head?.unique}
        generic={head?.generic}
        onValidate={setValid}
        // validate={validate}
      ></DataInput>
      <FlexRow gap=".5rem" style={{ justifyContent: "space-between" }}>
        <Button shadow onClick={() => setEdit(false)} width={100}>
          Cancel
        </Button>
        <Button
          blue
          shadow
          onClick={() => {
            if (valid) {
              let newmap = { ...cellMap, value: value };
              setMap(newmap);
              setEdit(false);
              if (onSaveEditableCell) {
                onSaveEditableCell(newmap, head, rowdata, i);
              }
            }
          }}
          width={100}
          disable={!valid}
        >
          Save
        </Button>
      </FlexRow>
    </>
  );
}

// Example of the header format to pass into props
// const headers = [
//     {
//         id: 0,
//         name: "Project",
//         value: "project",
//         enabled: true,
//     },
//     {
//         id: 1,
//         name: "Status",
//         value: "status",
//         enabled: true,
//     },
//     {
//         id: 2,
//         name: "Responses",
//         value: "responses",
//         enabled: true,
//     },
//     {
//         id: 3,
//         name: "Owner",
//         value: "owner",
//         enabled: true,
//     },
//     {
//         id: 4,
//         name: "Created",
//         value: "created_at",
//         enabled: true,
//     },
//     {
//         id: 5,
//         name: "Modified",
//         value: "updated_at",
//         enabled: true,
//     },
// ]
