// External
import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";

// Internal
import AccountType from "pages/register/AccountType";
import YourOrganization from "pages/register/YourOrganization";
import YourDetails from "pages/register/YourDetails";
import VerifyEmail from "pages/register/VerifyEmail";
import JoinOrganization from "pages/register/JoinOrganization";
import WelcomeToReaction from "pages/register/WelcomeToReaction";

export const RegisterRoutes = ({
  steps,
  setSteps,
  activeStep,
  setActiveStep,
  org,
  setOrg,
  type,
  setType,
}) => {
  return (
    <>
      <Routes>
        <Route path="/" element={<Navigate to="account-type" />} />
        <Route
          path="account-type"
          element={
            <AccountType
              setSteps={setSteps}
              steps={steps}
              activeStep={activeStep}
              setActiveStep={setActiveStep}
            />
          }
        />
        <Route
          path="your-organization/:token"
          element={
            <YourOrganization
              setSteps={setSteps}
              steps={steps}
              activeStep={activeStep}
              setActiveStep={setActiveStep}
              org={org}
              setOrg={setOrg}
            />
          }
        />
        <Route
          path="your-details"
          element={
            <YourDetails
              setSteps={setSteps}
              steps={steps}
              activeStep={activeStep}
              setActiveStep={setActiveStep}
              org={org}
              setOrg={setOrg}
            />
          }
        />{" "}
        <Route
          path="verify-email/:token"
          element={
            <VerifyEmail
              setSteps={setSteps}
              steps={steps}
              activeStep={activeStep}
              setActiveStep={setActiveStep}
              org={org}
              setOrg={setOrg}
            />
          }
        />
        <Route
          path="welcome-to-reaction/:token"
          element={
            <WelcomeToReaction
              setSteps={setSteps}
              steps={steps}
              activeStep={activeStep}
              setActiveStep={setActiveStep}
              org={org}
              setOrg={setOrg}
              setType={setType}
            />
          }
        />
        <Route
          path="join-organization"
          element={
            <JoinOrganization
              setSteps={setSteps}
              steps={steps}
              activeStep={activeStep}
              setActiveStep={setActiveStep}
              org={org}
              setOrg={setOrg}
              type={type}
              setType={setType}
            />
          }
        />{" "}
        <Route
          path="join-organization/verify-email/:token"
          element={
            <VerifyEmail
              setSteps={setSteps}
              steps={steps}
              activeStep={activeStep}
              setActiveStep={setActiveStep}
              org={org}
              setOrg={setOrg}
              setType={setType}
              join
            />
          }
        />
        <Route
          path="join-organization/welcome-to-reaction/:token"
          element={
            <WelcomeToReaction
              setSteps={setSteps}
              steps={steps}
              activeStep={activeStep}
              setActiveStep={setActiveStep}
              org={org}
              setOrg={setOrg}
              setType={setType}
              join
            />
          }
        />
      </Routes>
    </>
  );
};
