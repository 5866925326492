import React, { useEffect, useState } from "react";
import styles from "./ConnectObjects.module.scss";
import { SelectFieldCustom, TextFieldSimple } from "components/inputs";
import {
  linkedAccountStatusOptions,
  statusOptions,
} from "./SalesforceSettings";
import FlexCol from "components/layouts/FlexColumn/FlexCol";
import { TextArea } from "components/inputs/input_fields/TextArea/TextArea";
import { HorizontalBar } from "components/layouts/HorizontalBar/HorizontalBar";
import FlexRow from "components/layouts/FlexRow/FlexRow";
import Checkbox from "components/inputs/input_fields/CheckboxBlue/Checkbox";
import Icon from "components/Icon/Icon";
import Button from "components/Button/Button";
import axios from "axios";
import { Loading } from "components/Loading/Loading";
import { objectData } from "assets/functions/Variables";
import {
  useCreateObjectConnection,
  useFetchCurrOrgsFieldsMut,
  useFetchObjectConnection,
  useFetchOrganizationLinkedAccounts,
  useUpdateObjectConnection,
} from "api/resources/organization/organization";
import { useNavigate, useParams } from "react-router-dom";
import { useFetchCalculations } from "api/resources/organization/calculations";

const reactionObjects = [
  { label: "Contact", value: "Contact" },
  { label: "Survey", value: "Survey" },
  { label: "Question", value: "Question" },
  { label: "Answer", value: "Answer" },
  { label: "Participation", value: "Participation" },
];

function ConnectObjects({ user, organization }) {
  const { id } = useParams();
  const MyOrg = useFetchOrganizationLinkedAccounts(organization.id);
  const object = useFetchObjectConnection(id ? id : "new");
  const fetchCalculations = useFetchCalculations();
  return (
    <>
      {(MyOrg.isLoading || object.isLoading) && (
        <div style={{ width: "100%", height: "100vh" }}>
          <Loading></Loading>
        </div>
      )}
      {MyOrg.isSuccess && fetchCalculations.isSuccess && object.isSuccess && (
        <ConnectObjectsContent
          user={user}
          organization={organization}
          linkedAccountId={
            MyOrg?.data?.organization?.linkedAccounts?.find(
              (a) => a.accountType === "Salesforce"
            )?.id
          }
          object={object?.data?.object}
          calculations={fetchCalculations.data.calculations}
        ></ConnectObjectsContent>
      )}
    </>
  );
}

function ConnectObjectsContent({
  user,
  organization,
  linkedAccountId,
  object,
  calculations,
}) {
  const [objectSettings, setObjectSettings] = useState({
    matchFields: object?.matchFields ? JSON.parse(object.matchFields) : [{}],
    id: object?.id,
    connectionStatus: object?.connectionStatus,
    updateType: object?.updateType,
    updateFields: object?.updateFields
      ? JSON.parse(object.updateFields)
      : [{ calculation: "None" }],
    sendExistingData: object?.sendExistingData,
    createNewData: object?.createNewData,
    linkedAccountId: linkedAccountId,
    description: object?.description,
    updater: false,
    internalField: object?.internalField,
    externalField: object?.externalField,
    internalName: object?.internalName,
    externalName: object?.externalName,
  });
  const [loading, setLoading] = useState(true);
  const [salesforceObjects, setSalesforceObjects] = useState([]);
  const [objectFields, setObjectFields] = useState([]);
  const [objectFieldsReaction, setObjectFieldsReaction] = useState([]);
  const [loadingFields, setLoadingFields] = useState(false);
  const [calculationOptions] = useState(() => {
    let calcs = [{ label: "None", value: "None" }];
    let temp = calculations.map((c) => {
      return { value: c.id, label: c.name + ` (${c.type})` };
    });
    return [...calcs, ...temp];
  });
  const handleLoginSalesforce = async () => {
    try {
      const url = `${process.env.REACT_APP_SALESFORCE_KEY2}&userID=${user?.id}`;
      const response = await axios.get(url, { withCredentials: true });
      window.location.href = response.data.loginUrl;
    } catch (error) {
      console.error("Error during login:", error);
    }
  };

  useEffect(() => {
    if (objectSettings?.internalName) {
      setReactionObjectFields(objectSettings.internalName);
    }
    if (objectSettings?.externalName) {
      getObjectFields(objectSettings?.externalName);
    }
  }, []);

  useEffect(() => {
    setLoading(true);

    let url = process.env.REACT_APP_SALESFORCE_OBJECTS;
    const config = {
      withCredentials: true,
      headers: {
        accept: "application/json",
      },
      params: {
        organization_id: organization.id,
        user_id: user.id,
      },
    };

    axios
      .get(url, config)
      .then((response) => {
        if (response?.data?.objects && response?.data?.objects?.length > 0) {
          setSalesforceObjects(response?.data?.objects);
        }
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        if (error?.response?.status === 401) {
          setSessionExpired(true);
          setTimeout(() => {
            handleLoginSalesforce();
          }, 1000);
        }
      });
  }, []);

  function getObjectFields(name) {
    setLoadingFields(true);
    let url = process.env.REACT_APP_SALESFORCE_OBJECT_FIELDS;
    const config = {
      withCredentials: true,
      headers: {
        accept: "application/json",
      },
      params: {
        organization_id: organization.id,
        user_id: user.id,
        name: name,
      },
    };

    axios
      .get(url, config)
      .then((response) => {
        if (response?.data?.fields && response?.data?.fields?.length > 0) {
          setObjectFields(response?.data?.fields);
        }
        setLoadingFields(false);
      })
      .catch((error) => {
        console.error(error);
        if (error?.response?.status === 401) {
          setSessionExpired(true);
          setTimeout(() => {
            handleLoginSalesforce();
          }, 1000);
        }
      });
  }

  const getContactFields = useFetchCurrOrgsFieldsMut();

  function setReactionObjectFields(name) {
    if (name === "Contact") {
      getContactFields.mutate(
        {},
        {
          onSuccess: (data) => {
            if (data?.fields && data?.fields?.length > 0) {
              const transformedArray = data?.fields.map((item) => ({
                value: item.name,
                label: item.displayName + ` (${item.dataType})`,
                id: item.id,
                dataType: item.dataType,
              }));
              setObjectFieldsReaction(transformedArray);
            }
          },
        }
      );
      return;
    }
    setObjectFieldsReaction(objectData[name]);
  }

  const updateObjectMutation = useUpdateObjectConnection();
  const createObjectMutation = useCreateObjectConnection();

  function saveObjectSettings() {
    let finalSettings = {
      ...objectSettings,
    };

    if (objectSettings?.id != "new") {
      finalSettings.updateFields = JSON.stringify(finalSettings.updateFields);
      finalSettings.matchFields = JSON.stringify(finalSettings.matchFields);
      const id = `${finalSettings.id}`;
      delete finalSettings.id;

      updateObjectMutation.mutate(
        {
          data: finalSettings,
          id: id,
        },
        {
          onSuccess: () => {
            navigate("/organization/" + organization.id + "/Salesforce");
            location.reload();
          },
        }
      );
    } else {
      finalSettings.updateFields = JSON.stringify(finalSettings.updateFields);
      finalSettings.matchFields = JSON.stringify(finalSettings.matchFields);
      delete finalSettings.id;

      createObjectMutation.mutate(
        {
          data: finalSettings,
        },
        {
          onSuccess: () => {
            navigate("/organization/" + organization.id + "/Salesforce");
            location.reload();
          },
        }
      );
    }
  }

  const navigate = useNavigate();

  function pushData(object) {
    if (objectSettings?.connectionStatus === "active") {
      setObjectSettings({ ...objectSettings, connectionStatus: "pushing" });
      let url = process.env.REACT_APP_SALESFORCE_SYNC_QUERY;
      const config = {
        withCredentials: true,
        headers: {
          accept: "application/json",
        },
        params: {
          organization_id: organization.id,
          user_id: user.id,
        },
      };

      let finalData = { ...object };
      finalData.matchFields = finalData.matchFields
        ? finalData.matchFields
        : [];
      finalData.updateFields = finalData.updateFields
        ? finalData.updateFields
        : [];
      const formData = new FormData();
      formData.append("objectSettings", JSON.stringify(finalData));

      axios
        .post(url, formData, config)
        .then((response) => {
          if (
            response?.status === 200 &&
            response?.data?.message === "success"
          ) {
            setObjectSettings({
              ...objectSettings,
              connectionStatus: "active",
            });
          }
        })
        .catch((error) => {
          console.error(error);
          if (error?.response?.status === 401) {
            setSessionExpired(true);
            setTimeout(() => {
              handleLoginSalesforce();
            }, 1000);
          }
        });
    }
  }

  return (
    <div className={styles.page}>
      {loading && (
        <div style={{ height: "100vh" }}>
          <Loading></Loading>
        </div>
      )}
      {!loading && (
        <>
          <FlexCol gap="1rem">
            <div
              className={`${styles.header_3} `}
              style={{ fontWeight: "400", color: "black", margin: "0" }}
            >
              {object?.id === "new"
                ? "New Object Connection Setup"
                : "Object Connection Setup"}
            </div>
            <FlexRow justify={"space-between"} style={{ marginBottom: "1rem" }}>
              <FlexRow>
                <Button
                  shadow
                  style={{ gap: ".5rem" }}
                  onClick={() =>
                    navigate(
                      "/organization/" + organization?.id + "/Salesforce"
                    )
                  }
                >
                  <Icon green iconName={"arrow-left-circle"}></Icon>Go Back
                </Button>{" "}
                {object?.id && (
                  <Button
                    shadow
                    style={{ gap: ".5rem" }}
                    onClick={() => pushData(objectSettings)}
                    disable={objectSettings?.connectionStatus === "pushing"}
                  >
                    <Icon sapphire iconName={"box-arrow-right"}></Icon>Push Data
                  </Button>
                )}
              </FlexRow>

              {objectSettings?.id != "new" && (
                <Button
                  shadow
                  style={{ gap: ".5rem", whiteSpace: "nowrap" }}
                  onClick={() =>
                    navigate(
                      "/organization/" + organization?.id + "/Salesforce"
                    )
                  }
                  disable={objectSettings?.connectionStatus === "pushing"}
                >
                  <Icon red iconName={"trash"}></Icon> Delete Connection
                </Button>
              )}
            </FlexRow>
            <HorizontalBar width={"100%"} height={2}></HorizontalBar>
          </FlexCol>
          <FlexCol gap={"1rem"} style={{ maxWidth: "500px" }}>
            <FlexCol>
              <div className={`${styles.header_4} `} style={{ margin: "0" }}>
                Settings
              </div>
              <div
                className={`${styles.text_3} `}
                style={{ marginBottom: "1rem" }}
              >
                These settings apply to both matching details and field updates
                in this connection
              </div>
            </FlexCol>
            <SelectFieldCustom
              label={
                objectSettings?.id === "new"
                  ? "Initial Object Connection Status"
                  : "Connection Status"
              }
              options={
                objectSettings?.connectionStatus != "pushing"
                  ? linkedAccountStatusOptions
                  : []
              }
              style={{ maxWidth: "250px" }}
              value={statusOptions.find(
                (v) => v.value === objectSettings?.connectionStatus
              )}
              onChange={(v) => {
                if (objectSettings?.connectionStatus != "pushing") {
                  setObjectSettings(() => {
                    return { ...objectSettings, connectionStatus: v.value };
                  });
                }
              }}
              noCreate
            ></SelectFieldCustom>
            <SelectFieldCustom
              options={salesforceObjects}
              label={
                <span>
                  Which <span style={{ fontWeight: "600" }}>Salesforce</span>{" "}
                  Object do you want to connect?
                </span>
              }
              searchable
              value={salesforceObjects.find(
                (v) => v.value === objectSettings?.externalName
              )}
              onChange={(v) => {
                setObjectSettings(() => {
                  return { ...objectSettings, externalName: v.value };
                });
                getObjectFields(v.value);
              }}
              noCreate
            ></SelectFieldCustom>
            <SelectFieldCustom
              options={reactionObjects}
              label={
                <span>
                  Which <span style={{ fontWeight: "600" }}>Reaction</span>{" "}
                  Object do you want to connect?
                </span>
              }
              searchable
              value={reactionObjects.find(
                (v) => v.value === objectSettings?.internalName
              )}
              onChange={(v) => {
                setObjectSettings(() => {
                  return { ...objectSettings, internalName: v.value };
                });
                setReactionObjectFields(v.value);
              }}
              noCreate
            ></SelectFieldCustom>
            <TextFieldSimple
              label={"Updates Name Description"}
              className={styles.textarea}
              onChange={(v) =>
                setObjectSettings(() => {
                  return { ...objectSettings, description: v };
                })
              }
              value={objectSettings?.description}
              placeholder="Updates Name Description"
            ></TextFieldSimple>

            {/* {objectSettings?.id === "new" && (
              <FlexRow style={{ marginTop: "1rem" }}>
                <Checkbox
                  checked={objectSettings?.sendExistingData}
                  onChange={(e) =>
                    setObjectSettings(() => {
                      return {
                        ...objectSettings,
                        sendExistingData: e.target.checked,
                      };
                    })
                  }
                ></Checkbox>
                <div className={styles.text_2}>
                  Send Existing Reaction Object Data into Salesforce
                </div>
              </FlexRow>
            )} */}
            <FlexRow>
              <Checkbox
                checked={objectSettings?.createNewData}
                onChange={(e) =>
                  setObjectSettings(() => {
                    return {
                      ...objectSettings,
                      createNewData: e.target.checked,
                    };
                  })
                }
              ></Checkbox>{" "}
              <div className={styles.text_2}>
                Create New in Salesforce if no matches found
              </div>
            </FlexRow>
          </FlexCol>
          <HorizontalBar width={"100%"} height={2}></HorizontalBar>
          <FlexCol gap={"1rem"}>
            <div
              className={`${styles.header_3} `}
              style={{ fontWeight: "400", color: "black", margin: "0" }}
            >
              Matching Details
            </div>
            <div className={`${styles.text_2} `}>
              Choose which Reaction and Salesforce fields are used to match the
              objects together. This allows the system to know what to update.
            </div>
            {loadingFields && <Loading></Loading>}
            {!loadingFields && (
              <ObjectMatching
                sfObjectFields={objectFields}
                reactionObjectFields={objectFieldsReaction}
                objectSettings={objectSettings}
                setObjectSettings={setObjectSettings}
              ></ObjectMatching>
            )}
          </FlexCol>
          <HorizontalBar width={"100%"} height={2}></HorizontalBar>
          <FlexCol gap={"1rem"}>
            <div
              className={`${styles.header_3} `}
              style={{ fontWeight: "400", color: "black", margin: "0" }}
            >
              Field Updates
            </div>
            <div className={`${styles.text_2} `}>
              Choose which Reaction and Salesforce fields are going to be
              updated and map them accordingly. The fields mapped are the only
              fields that will be updated.
            </div>
            {loadingFields && <Loading></Loading>}
            {!loadingFields && (
              <FieldMatching
                sfObjectFields={objectFields}
                reactionObjectFields={objectFieldsReaction}
                objectSettings={objectSettings}
                setObjectSettings={setObjectSettings}
                calculationOptions={calculationOptions}
              ></FieldMatching>
            )}
            <div
              className={`${styles.header_4} `}
              style={{
                fontWeight: "400",
                color: "black",
                margin: "0",
                marginTop: "2rem",
                padding: "0 1rem",
              }}
            >
              How often do you want Reaction to send field updates?
            </div>
            <FlexRow
              style={{
                flexWrap: "wrap",
                marginTop: "2rem",
                gap: "2rem",
              }}
            >
              <div
                className={`${styles.box} ${
                  objectSettings?.updateType === "manually" && styles.boxfill
                }`}
                onClick={() => {
                  setObjectSettings(() => {
                    return { ...objectSettings, updateType: "manually" };
                  });
                }}
              >
                <div className={`${styles.square}`}>
                  <i className={"bi-caret-right-square"}></i>
                </div>
                <div
                  className={styles.text_2}
                  style={{ margin: "0", fontWeight: "400" }}
                >
                  Manually
                </div>
                <div
                  className={styles.text_3}
                  style={{ margin: "0", fontWeight: "400" }}
                >
                  On button click
                </div>
              </div>
              <div
                className={`${styles.box} ${
                  objectSettings?.updateType === "automatically" &&
                  styles.boxfill
                }`}
                onClick={() => {
                  // setObjectSettings(() => {
                  //   return { ...objectSettings, updateType: "automatically" };
                  // });
                }}
              >
                <div
                  className={`${styles.square}`}
                  // style={{ border: "4px dotted #a3a4a8" }}
                >
                  <i
                    className={"bi-arrow-repeat"}
                    style={{ fontSize: "4.8rem" }}
                  ></i>
                </div>
                <div
                  className={styles.text_2}
                  style={{ margin: "0", fontWeight: "400" }}
                >
                  Automatically
                </div>
                <div
                  className={styles.text_3}
                  style={{ margin: "0", fontWeight: "400" }}
                >
                  (Coming Soon)
                </div>
              </div>

              <div
                className={`${styles.box} ${
                  objectSettings?.updateType === "schedule" && styles.boxfill
                }`}
                onClick={() => {
                  // setObjectSettings(() => {
                  //     return { ...objectSettings, updateType: "ma" };
                  //   });
                }}
              >
                <div className={`${styles.square}`}>
                  <i className={"bi-calendar"}></i>
                </div>
                <div
                  className={styles.text_2}
                  style={{ margin: "0", fontWeight: "400" }}
                >
                  Schedule
                </div>
                <div
                  className={styles.text_3}
                  style={{ margin: "0", fontWeight: "400" }}
                >
                  (Coming Soon)
                </div>
              </div>
            </FlexRow>
          </FlexCol>
          <HorizontalBar width={"100%"} height={2}></HorizontalBar>
          <FlexRow>
            <Button
              width={75}
              shadow
              onClick={() =>
                navigate("/organization/" + organization?.id + "/Salesforce")
              }
            >
              Cancel
            </Button>
            <Button
              blue
              shadow
              width={75}
              disable={
                !objectSettings?.externalName ||
                !objectSettings?.internalName ||
                !objectSettings?.matchFields[0]?.externalField ||
                !objectSettings?.matchFields[0]?.internalField ||
                !objectSettings?.updateFields[0]?.internalField ||
                !objectSettings?.updateFields[0]?.externalField ||
                objectSettings?.connectionStatus === "pushing"
              }
              onClick={() => saveObjectSettings()}
            >
              Save
            </Button>
          </FlexRow>
        </>
      )}
    </div>
  );
}

export default ConnectObjects;

function ObjectMatching({
  sfObjectFields,
  reactionObjectFields,
  objectSettings,
  setObjectSettings,
}) {
  return (
    <>
      {" "}
      <table
        className={styles.maptable}
        style={{ marginBottom: "1rem", marginTop: "1rem" }}
      >
        <thead>
          <tr>
            <th>Reaction Field</th>
            <th></th>
            <th>Salesforce Field</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {objectSettings?.matchFields?.map((match, index) => (
            <tr>
              <td style={{ width: "300px" }} key={index}>
                <SelectFieldCustom
                  value={
                    match?.internalField
                      ? reactionObjectFields.find(
                          (f) => f.value === match?.internalField
                        )
                      : undefined
                  }
                  onChange={(val) => {
                    let objectSetTemp = { ...objectSettings };
                    let matchField = { ...objectSetTemp.matchFields[index] };
                    matchField.internalField = val.value;
                    objectSetTemp.matchFields[index] = matchField;
                    setObjectSettings(objectSetTemp);
                  }}
                  searchable
                  icon={<Icon blue iconName={"database"}></Icon>}
                  noCreate
                  options={reactionObjectFields}
                ></SelectFieldCustom>
              </td>
              <td
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <i
                  className="bi-arrow-right"
                  style={{ fontSize: "1.1rem" }}
                ></i>
              </td>
              <td style={{ width: "300px" }}>
                <SelectFieldCustom
                  value={
                    match?.externalField
                      ? sfObjectFields.find(
                          (f) => f.value === match?.externalField
                        )
                      : undefined
                  }
                  onChange={(val) => {
                    let objectSetTemp = { ...objectSettings };
                    let matchField = { ...objectSetTemp.matchFields[index] };
                    matchField.externalField = val.value;
                    objectSetTemp.matchFields[index] = matchField;
                    setObjectSettings(objectSetTemp);
                  }}
                  icon={<Icon blue iconName={"database"}></Icon>}
                  noCreate
                  options={sfObjectFields}
                  searchable
                ></SelectFieldCustom>
              </td>
              <td
                style={{
                  width: "fit-content",
                }}
              >
                <Button
                  shadow
                  onClick={() => {
                    let objectSetTemp = { ...objectSettings };
                    objectSetTemp?.matchFields?.splice(index, 1);
                    setObjectSettings(objectSetTemp);
                  }}
                >
                  Remove
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <Button
        shadow
        onClick={() => {
          let objectSetTemp = { ...objectSettings };
          objectSetTemp?.matchFields?.push({});
          setObjectSettings(objectSetTemp);
        }}
      >
        + Add Field
      </Button>
    </>
  );
}

function FieldMatching({
  sfObjectFields,
  reactionObjectFields,
  objectSettings,
  setObjectSettings,
  calculationOptions,
}) {
  return (
    <>
      <table
        className={styles.maptable}
        style={{ marginBottom: "1rem", marginTop: "1rem" }}
      >
        <thead>
          <tr>
            <th>Reaction Field</th>
            <th></th>
            <th>Salesforce Field</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {objectSettings?.updateFields?.map((updateRow, index) => (
            <tr key={index}>
              <td style={{ width: "300px" }}>
                <SelectFieldCustom
                  value={
                    updateRow?.internalField
                      ? "calculation" in updateRow
                        ? calculationOptions.find(
                            (f) => f.value === updateRow?.internalField
                          )
                        : reactionObjectFields.find(
                            (f) => f.value === updateRow?.internalField
                          )
                      : undefined
                  }
                  onChange={(val) => {
                    let objectSetTemp = { ...objectSettings };
                    let updateField = { ...updateRow };
                    updateField.internalField = val.value;
                    if ("calculation" in updateRow) {
                      updateField.calculation = val.value;
                    }
                    objectSetTemp.updateFields[index] = updateField;
                    setObjectSettings(objectSetTemp);
                  }}
                  searchable
                  icon={
                    "calculation" in updateRow ? (
                      <Icon blue iconName={"calculator"}></Icon>
                    ) : (
                      <Icon blue iconName={"database"}></Icon>
                    )
                  }
                  noCreate
                  options={
                    "calculation" in updateRow
                      ? calculationOptions
                      : reactionObjectFields
                  }
                  containerStyle={{
                    border:
                      "calculation" in updateRow ? "1px solid #58b368" : "",
                    backgroundColor:
                      "calculation" in updateRow ? "#58b36939" : "",
                  }}
                ></SelectFieldCustom>
              </td>
              <td
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <i
                  className="bi-arrow-right"
                  style={{ fontSize: "1.1rem" }}
                ></i>
              </td>

              <td style={{ width: "300px" }}>
                <SelectFieldCustom
                  value={
                    updateRow?.externalField
                      ? sfObjectFields.find(
                          (f) => f.value === updateRow?.externalField
                        )
                      : undefined
                  }
                  onChange={(val) => {
                    let objectSetTemp = { ...objectSettings };
                    let updateField = { ...updateRow };
                    updateField.externalField = val.value;
                    objectSetTemp.updateFields[index] = updateField;
                    setObjectSettings(objectSetTemp);
                  }}
                  icon={<Icon blue iconName={"database"}></Icon>}
                  noCreate
                  options={sfObjectFields}
                  searchable
                ></SelectFieldCustom>
              </td>

              <td
                style={{
                  width: "fit-content",
                }}
              >
                <Button
                  shadow
                  onClick={() => {
                    let objectSetTemp = { ...objectSettings };
                    objectSetTemp?.updateFields?.splice(index, 1);
                    setObjectSettings(objectSetTemp);
                  }}
                >
                  Remove
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>{" "}
      <FlexRow>
        <Button
          shadow
          onClick={() => {
            let objectSetTemp = { ...objectSettings };
            objectSetTemp?.updateFields?.push({});
            setObjectSettings(objectSetTemp);
          }}
        >
          + Add Field
        </Button>
        <Button
          shadow
          onClick={() => {
            let objectSetTemp = { ...objectSettings };
            objectSetTemp?.updateFields?.push({ calculation: "" });
            setObjectSettings(objectSetTemp);
          }}
        >
          + Add Calculation
        </Button>
      </FlexRow>
    </>
  );
}
