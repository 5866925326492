/**
 *
 * @param {*} obj the object to traverse
 * @param {*} whatToDo a callback function which accepts two parameters, key and value.
 */
export function searchObject(obj, whatToDo) {
  for (let key in obj) {
    whatToDo(key, obj[key]);
  }
}

function sortIntoColumnsByName(attributes, customFields) {
  let columns = {};
  for (let att of attributes) {
    if (columns[att.customFieldId]) {
      columns[att.customFieldId].push(att.name);
    } else {
      columns[att.customFieldId] = [att.name];
    }
  }

  for (let field of customFields) {
    if (field.id in columns) {
      columns[field.name] = columns[field.id];
      delete columns[field.id];
    }
  }

  return columns;
}

export function addColAttributes(contact, customFields) {
  if (contact && contact.attribute) {
    let sorted = sortIntoColumnsByName(contact.attribute, customFields);
    for (let key in sorted) {
      contact[key] = sorted[key];
    }
  }

  return contact;
}

export function addColumnStrings(contact, customFields) {
  if (contact && contact.attribute) {
    let sorted = sortIntoColumnsByName(contact.attribute, customFields);
    for (let key in sorted) {
      let values = sorted[key];
      let string = "";
      for (let val of values) {
        if (string) {
          string += ", ";
        }
        string += val;
      }
      contact[key] = string;
    }
  }

  return contact;
}

function sortIntoColumns(attributes) {
  let columns = {};
  for (let att of attributes) {
    if (columns[att.customFieldId]) {
      columns[att.customFieldId].push(att);
    } else {
      columns[att.customFieldId] = [att];
    }
  }
  return columns;
}

export function addColIdStrings(contact, headers) {
  if (contact && contact.attribute) {
    let sorted = sortIntoColumns(contact.attribute);
    for (let key in sorted) {
      let values = sorted[key];
      let column = headers?.find((h) => h?.id === key);
      if (column?.multiple) {
        contact[key] = values;
      } else {
        contact[key] = values[0]?.name;
      }
    }
  }

  return contact;
}

/**
 *
 * @param {*} contact the contact that needs its fields parsed out
 */
export function parseCustomFields(contactArray, headers) {
  if (contactArray && contactArray.length > 0) {
    let temp = [...contactArray];
    for (let i = 0; i < temp.length; i++) {
      temp[i] = addColIdStrings(temp[i], headers);
      delete temp[i]?.attribute;
    }

    return temp;
  } else {
    return contactArray;
  }
}

export function lowerize(obj) {
  if (obj) {
    let newobj = {};
    Object.keys(obj).map((key) => {
      if (
        key.toLowerCase().includes("firstname") ||
        key.toLowerCase().includes("lastname")
      ) {
        let item = key[0].toLowerCase() + key.slice(1);
        newobj[item] = obj[key];
      } else {
        let item = key.toLowerCase();
        newobj[item] = obj[key];
      }
    });
    return newobj;
  } else {
    return obj;
  }
}

export function isConditionComplete(condition) {
  if (condition.questionId && condition?.operator) {
    if ("option" in condition) {
      if (!condition.option) {
        return false;
      }
    }

    if (
      condition?.operator === "between" &&
      condition?.answer &&
      condition.answer.length !== 2
    ) {
      return false;
    }
    if (condition.answer) {
      return true;
    }
    let op = condition.operator;
    if (op === "have answered" || op === "have not answered") {
      return true;
    }
  }
  return false;
}
