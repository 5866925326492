import React, { useEffect, useRef, useState } from "react";
import styles from "./imageUploadModal.module.scss";
import ReactModal from "components/ReactModal/ReactModal.jsx";
import { ImageUpload, TextFieldSimple } from "components/inputs";
import {
  useCreateOrgLogo,
  useCreateUserLogo,
  useGetSignedURL,
  useGetSignedURLUser,
  useUpdateImage,
} from "api/resources/projects/images";
import axios from "axios";
import AvatarEditor from "react-avatar-editor";
import { NumSlider } from "components/inputs/input_fields/NumSlider/NumSlider";
import { NumberInput } from "components/inputs/input_fields/NumberInput/NumberInput";
import Button from "components/Button/Button";
import { Label } from "components/layouts/Label/Label";
import { Loading } from "components/Loading/Loading";
import { ToggleSwitch } from "components/inputs/input_fields/ToggleSwitch/ToggleSwitch";
import FlexRow from "components/layouts/FlexRow/FlexRow";
import EditField from "components/inputs/input_fields/EditField/EditField";
import Modal from "components/ReactModal/ReactModal.jsx";
import Icon from "components/Icon/Icon";

export const EditImageModal = ({
  show,
  setShow,
  setImage,
  isUser,
  refetch,
  isLogo,
  isProject,
  onSave,
  userId,
  src,
  initPosition,
  image,
  orgId,
}) => {
  const [name, setName] = useState(image?.name ? image?.name : "");
  const [about, setAbout] = useState(image?.about ? image?.about : "");
  const [url, setUrl] = useState(src);
  const [position, setPosition] = useState(initPosition ? initPosition : 0);
  const [scale, setScale] = useState(image?.scale ? image?.scale : 1);
  const [file, setFile] = useState("");
  const [change, setChange] = useState(false);
  const [saving, setSaving] = useState(false);
  const [logo, setIsLogo] = useState(isLogo ? true : false);

  const getSignedURL = useGetSignedURL();
  const createOrgImage = useCreateOrgLogo();
  const createUserImage = useCreateUserLogo();
  const getSignedURLUser = useGetSignedURLUser();
  const updateImage = useUpdateImage();

  function combFileName(fileName) {
    let index = fileName?.lastIndexOf("\\");
    if (index == -1) {
      index = fileName?.lastIndexOf("/");
    }
    let shortName = fileName?.substring(index + 1);
    if (shortName?.length > 15) {
      shortName = shorten(shortName);
    }
    return shortName;
  }

  function shorten(fileName) {
    let dotInd = fileName.lastIndexOf(".");
    if (dotInd == -1) {
      return fileName.substring(0, 15) + "...";
    }
    let suffix = fileName.substring(dotInd);
    fileName = fileName.substring(0, 15);
    return fileName + "..." + suffix;
  }

  const handleImageUpload = (event) => {
    if (event.target.value === "") {
      return;
    }
    let shortName = combFileName(event.target.value);
    setName(shortName);
    setFile(event.target.files[0]);
    setUrl(URL.createObjectURL(event.target.files[0])); // ? what this for
    setChange(true);
  };

  function handleUpdateImage() {
    setSaving(true);
    updateImage.mutate(
      {
        data: {
          position: JSON.stringify(position),
          scale: scale,
          name: name,
          about: about,
          isLogo: logo,
        },
        id: image?.id,
      },
      {
        onSuccess: async (data) => {
          if (refetch) {
            refetch();
          }
          setSaving(false);
          setShow(false);
          setChange(false);
        },
      }
    );
  }

  const handleSaveImage = () => {
    if (isUser) {
      getSignedURLUser.mutate(
        {
          filename: name,
          filetype: file.type,
        },
        {
          onSuccess: (data) => {
            uploadToS3(
              file,
              data.getSignedURLUser.signedRequest,
              data.getSignedURLUser.url
            );
          },
        }
      );
    } else if (isProject) {
      onSave(file, url);
      setShow(false);
    } else {
      getSignedURL.mutate(
        {
          filename: name,
          filetype: file.type,
        },
        {
          onSuccess: (data) => {
            uploadToS3(
              file,
              data.getSignedURL.signedRequest,
              data.getSignedURL.url
            );
          },
        }
      );
    }
  };

  async function uploadToS3(file, signedRequest, url) {
    const options = {
      headers: {
        "Content-Type": file.type,
      },
    };
    const instance = axios.create({
      baseURL: "https://reaction.health/",
    });
    instance.defaults.withCredentials = true;
    if (isUser) {
      createUserImage.mutate(
        {
          url: url,
          name: name,
          about: about ? about : "",
          userId: userId ? userId : "",
          position: position
            ? JSON.stringify(position)
            : JSON.stringify({ x: 0.5, y: 0.5 }),
          scale: parseInt(scale),
          isLogo: logo,
        },
        {
          onSuccess: async (data) => {
            if (refetch) {
              refetch();
            }
            await instance.put(signedRequest, file, options);
            if (setImage) {
              setImage(data.createUserImage.imageURL);
            }
            setShow(false);
          },
        }
      );
    } else {
      createOrgImage.mutate(
        {
          url: url,
          name: name,
          about: about,
          position: JSON.stringify(position),
          scale: scale,
          orgId: orgId,
          isLogo: logo,
        },
        {
          onSuccess: async (data) => {
            await instance.put(signedRequest, file, options);
            setShow(false);
            if (refetch) {
              refetch();
            }
            location.reload();
          },
        }
      );
    }
  }

  return (
    <>
      {show && (
        <Modal
          show={show}
          onClose={() => (setShow ? setShow(false) : undefined)}
          dark
          modalStyle={{ width: "700px", height: "100%" }}
        >
          <div className={styles.page}>
            <h2 style={{ marginBottom: "1rem" }}>Edit Image</h2>
            {!logo && (
              <div className={styles.image}>
                <img src={url}></img>
              </div>
            )}
            {logo && (
              <AvatarEditor
                image={url}
                border={20}
                borderRadius={10}
                onPositionChange={(p) => {
                  setPosition(p);
                  setChange(true);
                }}
                position={position}
                height={logo ? 90 : 240}
                width={240}
                scale={scale}
              ></AvatarEditor>
            )}
            {!url && (
              <h5 style={{ fontWeight: "400", marginTop: "1rem" }}>
                No image uploaded
              </h5>
            )}
            <FlexRow style={{ marginTop: "1rem" }}>
              <h4>Image Type:</h4>
              <Button
                active={!logo}
                onClick={() => {
                  setIsLogo(false);
                  setChange(true);
                }}
              >
                As Image
              </Button>
              <Button
                active={logo}
                onClick={() => {
                  setIsLogo(true);
                  setChange(true);
                }}
              >
                As Logo
              </Button>
            </FlexRow>
            <div
              className={styles.modalLeft}
              style={{ marginTop: "1rem", gap: "1rem" }}
            >
              <FlexRow wrap={"wrap"} align={"flex-end"}>
                <TextFieldSimple
                  value={name}
                  placeholder="Name"
                  onChange={(val) => {
                    setName(val);
                    setChange(true);
                  }}
                  label={"File Name"}
                  containerStyle={{ flex: 1, minWidth: "200px" }}
                ></TextFieldSimple>
                <TextFieldSimple
                  value={about}
                  placeholder="None"
                  onChange={(val) => {
                    setAbout(val);
                    setChange(true);
                  }}
                  label={"Image Description"}
                  containerStyle={{ flex: 1, minWidth: "200px" }}
                ></TextFieldSimple>
                <Button
                  shadow
                  orange
                  style={{ flex: 1, minWidth: "100px", padding: "0" }}
                  padding={"0"}
                  height={2.6}
                  htmlFor="orgPicInput"
                >
                  <label
                    htmlFor="orgPicInput"
                    style={{
                      cursor: "pointer",
                      width: "100%",
                      padding: "0 .5rem",
                      height: "100%",
                      display: "flex",
                      gap: ".5rem",
                      alignItems: "center",
                    }}
                  >
                    <input
                      type="file"
                      id="orgPicInput"
                      name="orgPicInput"
                      accept="image/*"
                      content="Upload Image"
                      hidden
                      onChange={(e) => handleImageUpload(e)}
                      style={{ width: "100%" }}
                    ></input>
                    <i className="bi-image"></i>{" "}
                    {!image?.name ? "Choose Image" : "Change Image"}
                  </label>
                </Button>
              </FlexRow>
              {logo && (
                <div style={{ width: "100%", padding: ".5rem .2rem" }}>
                  <h4>Image Scale</h4>
                  <div className={styles.setting2}>
                    <div style={{ width: "100%" }}>
                      <NumSlider
                        value={scale}
                        min={0}
                        max={2}
                        step={0.025}
                        onChange={(val) => {
                          setScale(val);
                          setChange(true);
                        }}
                        color={"#2A627C"}
                      ></NumSlider>
                    </div>
                    <NumberInput
                      startNumber={scale}
                      min={0}
                      max={2}
                      step={0.025}
                      handleNumberChange={(val) => {
                        setScale(val);
                        setChange(true);
                      }}
                      color={"#2A627C"}
                    ></NumberInput>
                  </div>
                </div>
              )}

              <FlexRow justify={"center"} style={{ marginTop: "2rem" }}>
                <Button
                  shadow
                  onClick={() => {
                    setPosition(initPosition);
                    setScale(image?.scale ? image?.scale : 1);
                    setName(image?.name);
                    setAbout(image?.about);
                    setFile("");
                    setUrl(src);
                    setChange(false);
                    setShow(false);
                  }}
                >
                  Cancel{" "}
                </Button>

                {!saving && (
                  <Button
                    shadow
                    disable={!change}
                    onClick={() =>
                      !file ? handleUpdateImage() : handleSaveImage()
                    }
                    blue
                  >
                    Save Changes{" "}
                  </Button>
                )}
                {saving && change && (
                  <Button shadow>
                    Saving <Loading height={20} width={20}></Loading>
                  </Button>
                )}
              </FlexRow>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};
