import { useState } from "react";

//Internal
import styles from "./ConfigureBox.module.scss";
import { SettingsAccordion } from "./SettingsAccordion";
import { MetricType, ValueType } from "../QChart/QuestionChart";
import {
  useFetchScoreChartProjects,
  useFetchProjectsByIdsGql,
  useGetStatChartProjects,
} from "api/resources/projects/projects";
import { combinedProjIds, combinedQs } from "../Visualization";

export default function ConfigureBox({
  visualizationCopy,
  setVisualizationCopy,
  lockData,
  newChart,
  custom_fields,
  externalFilter,
  visibleItems,
  setVisibleItems,
  labels,
  reloadSettings,
}) {
  const [hide, setHide] = useState(false);

  return (
    <>
      {!hide && !reloadSettings && (
        <Menu
          visualizationCopy={visualizationCopy}
          setVisualizationCopy={setVisualizationCopy}
          lockData={lockData}
          newChart={newChart}
          custom_fields={custom_fields}
          externalFilter={externalFilter}
          visibleItems={visibleItems}
          setVisibleItems={setVisibleItems}
          labels={labels}
          setHide={setHide}
        />
      )}
    </>
  );
}

function Menu({
  visualizationCopy,
  setVisualizationCopy,
  lockData,
  newChart,
  custom_fields,
  externalFilter,
  visibleItems,
  setVisibleItems,
  labels,
  setHide,
}) {
  const score =
    visualizationCopy.type === MetricType ||
    visualizationCopy.type === ValueType;

  const getProjects =
    visualizationCopy.dataMethod === "survey"
      ? useGetStatChartProjects(visualizationCopy)
      : score
      ? useFetchScoreChartProjects(visualizationCopy)
      : useFetchProjectsByIdsGql({
          projectIds: combinedProjIds(visualizationCopy),
        });

  const [dataPage, setDataPage] = useState(
    score ? false : newChart || !labels || labels?.length === 0 ? true : false
  );

  function hasOneProject() {
    if (visualizationCopy.projectIdsArray.length) {
      return true;
    }
    for (let id in visualizationCopy.designSettings.dynamics) {
      if (
        visualizationCopy.designSettings.dynamics[id].timeFrame !== "selection"
      ) {
        return true;
      } else if (
        visualizationCopy.designSettings.dynamics[id].selection.length
      ) {
        return true;
      }
    }
    return false;
  }

  const hasData = score
    ? visualizationCopy.designSettings.metrics.length > 0
    : visualizationCopy.dataMethod === "survey"
    ? hasOneProject()
    : combinedQs(visualizationCopy).length > 0;

  function onFilterChange(filters) {
    let newViz = { ...visualizationCopy };
    newViz.filters = JSON.stringify(filters);
    let count = 0;
    for (let prop in filters) {
      if (prop === "surveys") {
        count += filters[prop].length;
      } else if (prop === "answers") {
        count += filters[prop].length;
      } else if (prop !== "orAnswers") {
        count += filters[prop].properties.length;
      }
    }
    newViz.designSettings.showFilterSubtitle = count < 4;
    setVisualizationCopy(newViz);
  }

  // console.log(visualizationCopy);

  return (
    <>
      <SettingsAccordion
        viz={visualizationCopy}
        updateViz={setVisualizationCopy}
        labels={labels}
        lockData={lockData}
        projects={getProjects.isSuccess ? getProjects.data?.survey : []}
        custom_fields={custom_fields}
        onFilterChange={onFilterChange}
        externalFilter={externalFilter}
        newChart={newChart}
        dataPage={dataPage}
        setDataPage={setDataPage}
        hasData={hasData}
        visibleItems={visibleItems}
        setVisible={setVisibleItems}
      />
      <div className={styles.hide} onClick={() => setHide(true)}>
        Hide Settings
      </div>
    </>
  );
}
