// External
import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";

// Internal
import {
  AllContacts,
  Manual,
  Upload,
  ImportedCleaner,
  Audiences,
} from "pages";
import { useGetCurrentUser } from "api/resources/organization/users";
import { useFetchRole } from "api/resources/organization/roles";
import Audience from "pages/contacts/audiences/Audience";

export const ContactRoutes = ({ refetchTasks, onExport }) => {
  const getCurrUser = useGetCurrentUser();
  const getCurrRole = useFetchRole();

  return (
    <Routes>
      {getCurrUser.isSuccess && getCurrRole.isSuccess && (
        <>
          <Route path="/" element={<Navigate to="all-contacts" />} />
          {getCurrRole.data.role?.canViewContacts && (
            <Route
              path="all-contacts"
              element={
                <AllContacts
                  user={getCurrUser?.data?.me}
                  organization={getCurrUser.data?.me?.organization?.find(
                    (org) => org.id === getCurrUser.data?.me.organizationId
                  )}
                  roles={getCurrRole.data.role}
                  onExport={onExport}
                />
              }
            />
          )}

          {getCurrRole.data.role.canViewOrgAudiences && (
            <Route
              path="/audiences/*"
              element={
                <Audiences
                  roles={getCurrRole.data.role}
                  user={getCurrUser?.data?.me}
                  organization={getCurrUser.data?.me?.organization?.find(
                    (org) => org.id === getCurrUser.data?.me.organizationId
                  )}
                />
              }
            />
          )}
          {getCurrRole.data.role.canViewOrgAudiences && (
            <Route
              path="/audiences/:id"
              element={
                <Audience
                  roles={getCurrRole.data.role}
                  user={getCurrUser?.data?.me}
                  organization={getCurrUser.data?.me?.organization?.find(
                    (org) => org.id === getCurrUser.data?.me.organizationId
                  )}
                  onExport={onExport}
                />
              }
            />
          )}
        </>
      )}
    </Routes>
  );
};


export const AddContactRoutes = () => {
  // const getCurrUser = useGetCurrentUser();
  return (
    <Routes>
      <Route path="/" element={<Navigate to="updloadcontacts" />} />
      <Route path="manual" element={<Manual />} />
      <Route path="uploadcontacts" element={<Upload />} />
      <Route path=":id" element={<ImportedCleaner />} />
    </Routes>
  );
};
