import React, { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";
import { TabPill } from "components/layouts";
import { Pagination } from "components/tables/BasicTable/Pagination";
import { PaginationContainer } from "components/tables/BasicTable/PaginationContainer";
import { NavTextField } from "components/inputs/input_fields/NavTextField/NavTextField";
import { Loading } from "components/Loading/Loading";
import styles from "./PreviousImports.module.scss";
import { trimDate } from "assets/functions/DateFunctions";
import { Label } from "components/layouts/Label/Label";
import { useCountSearchUploadByOrg, useSearchUploadByOrg } from "api/resources/contacts/uploads";

const headers = [
  {
    id: 0,
    name: "Type",
    accessor: "type",
    objectAccessor: null,
    enabled: true,
    sort: false,
    canSort: true,
    cell_style: null,
  },
  {
    id: 1,
    name: "Owner",
    accessor: "appUser",
    objectAccessor: "email",
    enabled: true,
    sort: false,
    canSort: true,
    cell_style: null,
  },
  {
    id: 2,
    name: "Warnings",
    accessor: "totalWarnings",
    objectAccessor: null,
    enabled: true,
    sort: false,
    canSort: true,
    cell_style: null,
  },
  {
    id: 3,
    name: "Status",
    accessor: "status",
    objectAccessor: null,
    enabled: true,
    sort: false,
    canSort: true,
    cell_style: null,
  },
  {
    id: 4,
    name: "Uploaded On",
    accessor: "uploadDate",
    objectAccessor: null,
    enabled: true,
    sort: true,
    canSort: true,
    cell_style: null,
  },
];

export function PreviousImports() {
  // const contactImports = useFetchContactImportsGql();

  const [pageNumber, setPageNumber] = useState(1);
  const [searchString, setSearchString] = useState("");
  const [perPage, setPerPage] = useState(10);
  const [pageSkip, setPageSkip] = useState(0);
  const [active, setActive] = useState(0);
  const [refresher, setRefresher] = useState(false);

  const [searchSort, setSearchSort] = useState({
    item: "uploadDate",
    descend: true,
  });

  // const searchProjects = useSearchProjects(searchString);
  const searchUploads = useSearchUploadByOrg(
    searchString,
    pageSkip,
    perPage,
    searchSort,
    active === 1 ? "past" : "current"
  );
  const searchCount = useCountSearchUploadByOrg(
    searchString,
    pageSkip,
    perPage,
    active === 1 ? "past" : "current"
  );

  useEffect(() => {
    searchUploads.refetch();
  }, [pageNumber, searchSort, perPage, active, refresher]);

  const menuItems = [
    { id: 0, name: "Current Jobs" },
    { id: 1, name: "Past Jobs" },
  ];

  return (
    <div className={styles.page}>
      <div className={styles.navBar}>
        <div className={styles.navHeader}>Contact Import Jobs</div>
        <TabPill
          tabBarItems={menuItems}
          active={active ? active : 0}
          updateActive={setActive}
          style={{ flexWrap: "nowrap" }}
          wings
          // showNumber={maxItems}
        />
      </div>

      <div className={styles.content}>
        <div className={styles.search}>
          <NavTextField
            value={searchString}
            setValue={(val) => {
              setSearchString(val);
            }}
            placeholder="Search Imports..."
            className={styles.searchClass}
          />
        </div>
        {(searchUploads.isLoading ||
          searchCount.isLoading ||
          searchUploads.isRefetching ||
          searchCount.isRefetching) && <Loading></Loading>}
        {searchUploads.isError && <p>{searchUploads.error}</p>}
        {searchUploads.isSuccess &&
          searchCount.isSuccess &&
          !searchCount.isRefetching &&
          !searchUploads.isRefetching && (
            <>
              {searchUploads?.data?.uploads.length === 0 && active === 0 && (
                <div className={styles.header}> No Current Jobs</div>
              )}
              {searchUploads?.data?.uploads.map((upload, i) => (
                <>
                  {" "}
                  {upload?.id && (
                    <UploadPreview
                      upload={upload}
                      key={i}
                      active={active}
                      setRefresher={setRefresher}
                      refresher={refresher}
                    ></UploadPreview>
                  )}
                </>
              ))}
              <PaginationContainer
                style={{ position: "sticky", bottom: "0em" }}
              >
                <Pagination
                  pageNum={pageNumber}
                  setPageNum={setPageNumber}
                  maxPage={
                    searchCount.data.count / perPage < 1
                      ? 1
                      : Math.ceil(searchCount.data.count / perPage)
                  }
                  length={searchUploads?.data?.surveys?.length}
                  searchString={searchString}
                  perPage={perPage}
                  setPerPage={setPerPage}
                  setPageSkip={setPageSkip}
                ></Pagination>
              </PaginationContainer>
            </>
          )}
      </div>
    </div>
  );
}

export function UploadPreview({
  upload,
  active,
  setRefresher,
  refresher,
  audience,
}) {
  let navigate = useNavigate();
  // const getUpload = useFetchContactImportMut();
  // function downloadFails() {
  //   getUpload.mutate(
  //     {
  //       id: upload?.id,
  //     },
  //     {
  //       onSuccess: (dara) => {
  //         let dataArray = data?.getUploadMut;
  //         let list = [];
  //         for (let row of dataArray) {
  //           let entry = {};
  //           for (let field of headers) {
  //             let cell = row[field.accessor];
  //             if (cell && typeof cell === "object") {
  //               // for matrix and ranking answers
  //               let string = "";
  //               for (let div of cell.props.children) {
  //                 if (div?.props?.children) {
  //                   if (string) {
  //                     string += "\n";
  //                   }
  //                   string += div.props.children;
  //                 }
  //               }
  //               cell = string;
  //             }

  //             if (field?.project) {
  //               let newentry = cell;
  //               newentry = `${newentry}`;
  //               newentry = newentry.replaceAll(`"`, ``);
  //               newentry = newentry.replaceAll(`'`, ``);
  //               entry[field?.project?.name + " - " + field.name] = newentry;
  //             } else if (field?.accessor === "answer") {
  //               let newentry = cell;
  //               newentry = `${newentry}`;
  //               newentry = newentry.replaceAll(`"`, ``);
  //               newentry = newentry.replaceAll(`'`, ``);
  //               entry[field.name] = newentry;
  //             } else {
  //               entry[field.name] = cell;
  //             }
  //           }
  //           list.push(entry);
  //         }
  //         return list;
  //       },
  //     }
  //   );
  // }

  return (
    <div className={styles.upload}>
      <div className={styles.header}>
        <span>
          {upload?.type === "Salesforce"
            ? "Salesforce Import Details"
            : "File Import Details"}
        </span>{" "}
        {active === 0 && (
          <div className={styles.icon} onClick={() => setRefresher(!refresher)}>
            <i className="bi-arrow-counterclockwise"></i>
            Refresh
          </div>
        )}
        {active === 1 && (
          <div className={styles.icon} onClick={() => navigate(upload.id)}>
            <i className="bi-eye"></i>
            View
          </div>
        )}
      </div>
      <div className={styles.uploadContent}>
        <div className={styles.column}>
          <div className={styles.item}>
            <div className={styles.title}>Job Creator:</div>{" "}
            <a>{`${upload?.appUser?.firstName} ${upload?.appUser?.lastName}`}</a>
          </div>
          <div className={styles.item}>
            <div className={styles.title}>Start Time:</div>{" "}
            <span>{trimDate(upload?.uploadDate, true)}</span>
          </div>
          <div className={styles.item}>
            <div className={styles.title}>End Time:</div>{" "}
            {upload?.progress === 100 && (
              <span>{trimDate(upload?.endDate, true)}</span>
            )}
          </div>
        </div>
        <div className={styles.column}>
          <div className={styles.item}>
            <div className={styles.title}>Progress:</div>{" "}
            <span>{upload?.progress}%</span>
          </div>
          <div className={styles.item}>
            <div className={styles.title}>Created:</div>{" "}
            <span>{upload?.num_uploaded}</span>{" "}
            <Label
              labelIcon={<i className="bi-question-circle"></i>}
              tooltipText={
                "All the contacts that were imported into Reaction that did not exist before"
              }
              style={{
                width: "17px",
                padding: "0em",
                margin: "0em",
              }}
              tooltipStyle={{
                padding: "2em",
                width: "300px",
                right: "0px",
              }}
            ></Label>
            <i
            // className={`bi-cloud-arrow-down ${styles.download}`}
            ></i>
          </div>
          <div className={styles.item}>
            <div className={styles.title}>Updated:</div>{" "}
            <span>{upload?.num_updated}</span>{" "}
            <Label
              labelIcon={<i className="bi-question-circle"></i>}
              tooltipText={
                "All the contacts that existed in Reaction and were update in some way"
              }
              style={{
                width: "17px",
                padding: "0em",
                margin: "0em",
              }}
              tooltipStyle={{
                padding: "2em",
                width: "300px",
                right: "0px",
              }}
            ></Label>
            <i
            // className={`bi-cloud-arrow-down ${styles.download}`}
            ></i>
          </div>
        </div>
        <div className={styles.column}>
          {audience && (
            <div className={styles.item}>
              <div className={styles.title}>Added:</div>{" "}
              <span>{upload?.num_added_to_audience}</span>{" "}
              <Label
                labelIcon={<i className="bi-question-circle"></i>}
                tooltipText={
                  "Contacts that were added to the audience."
                }
                style={{
                  width: "17px",
                  padding: "0em",
                  margin: "0em",
                }}
                tooltipStyle={{
                  padding: "2em",
                  width: "300px",
                  right: "0px",
                }}
              ></Label>
              <i
              // className={`bi-cloud-arrow-down ${styles.download}`}
              ></i>
            </div>
          )}
          <div className={styles.item}>
            <div className={styles.title}>Duplicates:</div>{" "}
            <span>{upload?.num_duplicates}</span>{" "}
            <Label
              labelIcon={<i className="bi-question-circle"></i>}
              tooltipText={
                "Contacts that, on import, had more than one contact with the same email."
              }
              style={{
                width: "17px",
                padding: "0em",
                margin: "0em",
              }}
              tooltipStyle={{
                padding: "2em",
                width: "300px",
                right: "0px",
              }}
            ></Label>
            <i className={`bi-cloud-arrow-down ${styles.download}`}></i>
          </div>

          <div className={styles.item}>
            <div className={styles.title}>Failed:</div>{" "}
            <span>{upload?.num_failed}</span>{" "}
            <Label
              labelIcon={<i className="bi-question-circle"></i>}
              tooltipText={
                "All Contacts that had issues or were flagged on import."
              }
              style={{
                width: "17px",
                padding: "0em",
                margin: "0em",
              }}
              tooltipStyle={{
                padding: "2em",
                width: "300px",
                right: "0px",
              }}
            ></Label>
            <i className={`bi-cloud-arrow-down ${styles.download}`}></i>
          </div>
        </div>
      </div>
    </div>
  );
}
