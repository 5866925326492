import { gql } from "graphql-request";
import { useQueryClient } from "react-query";

import { useGqlQuery, useGqlMutation } from "api/Api";

const calc = `
  id
  name
  formula
  type
  updatedAt
  organizationId
  decimals
`;

export const useFetchCalculation = (id) => {
  const query = gql`
    query  {
      calculation(id: "${id}") {
        ${calc}
      }
    }
  `;

  return useGqlQuery(["calculation: " + id], query, {});
};

export const useFetchCalculations = (id) => {
  const query = gql`
    query Calculation {
      calculations {
        ${calc}
      }
    }
  `;

  return useGqlQuery(["calculations" + id], query, {});
};

export const useCreateCalculation = () => {
  const mutation = gql`
    mutation CreateCalculation($data: CalculationInput!) {
      calc: createCalculation(data: $data) {
        ${calc}
      }
    }
  `;

  const queryClient = useQueryClient();
  const options = {
    onError: (err, _project, rollback) => {
      if (rollback) rollback();
    },
    onSettled: () => {
      queryClient.invalidateQueries("calculations");
    },
  };

  return useGqlMutation(mutation, options);
};

export const useUpdateCalculation = () => {
  const mutation = gql`
    mutation CreateCalculation($data: CalculationInput!, $id: String!) {
      calc: updateCalculation(data: $data, id: $id) {
        ${calc}
      }
    }
  `;

  const queryClient = useQueryClient();
  const options = {
    onError: (err, _project, rollback) => {
      if (rollback) rollback();
    },
    onSettled: () => {
      queryClient.invalidateQueries("calculations");
    },
  };

  return useGqlMutation(mutation, options);
};

export const useDeleteCalculation = () => {
  const mutation = gql`
    mutation DeleteCalculation($id: String!) {
      deleteCalculation(id: $id)
    }
  `;

  const queryClient = useQueryClient();
  const options = {
    onError: (err, _project, rollback) => {
      if (rollback) rollback();
    },
    onSettled: () => {
      queryClient.invalidateQueries("calculations");
    },
  };

  return useGqlMutation(mutation, options);
};

export const useDoCalculation = () => {
  const mutation = gql`
    mutation GetCalculation($id: String!) {
      getCalculation(id: $id)
    }
  `;

  return useGqlMutation(mutation);
};

export const useTestCalculation = () => {
  const mutation = gql`
    mutation TestCalculation(
      $formula: String!
      $decimals: Float
      $filters: String
    ) {
      result: testCalculation(
        formula: $formula
        decimals: $decimals
        filters: $filters
      )
    }
  `;

  return useGqlMutation(mutation);
};

export const useFindTagQsByName = () => {
  const mutation = gql`
    mutation TestCalculation($tagName: String!) {
      Qs: findTagQsByName(tagName: $tagName) {
        id
        questionText
      }
    }
  `;

  return useGqlMutation(mutation);
};

export const useGetQuestion = () => {
  const mutation = gql`
    mutation Question($qId: String!) {
      question(qId: $qId) {
        id
        questionText
      }
    }
  `;

  return useGqlMutation(mutation);
};

export const usePullCalcAnswers = () => {
  const mutation = gql`
    mutation Question($qIds: IdStrings!, $filters: String) {
      answers: pullCalcAnswers(qIds: $qIds, filters: $filters) {
        choiceAnswer
        choiceAnswerIndex
        textAnswer
        scaleAnswer
        matrixAnswer
        questionId
        createdAt
        participation {
          id
          contactId
        }
      }
    }
  `;

  return useGqlMutation(mutation);
};
