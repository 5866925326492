import React from "react";
import styles from "./Icon.module.scss";

function Icon({
  iconName,
  onClick,
  tooltip,
  className,
  style,
  blue,
  gray,
  seafoam,
  green,
  sapphire,
  yellow,
  red,
  orange,
  white,
  black,
  info,
  spin,
}) {
  return (
    <div
      className={`${styles.icon} ${className} ${blue && styles.blue} ${
        gray && styles.gray
      } ${red && styles.red} ${sapphire && styles.sapphire} ${
        seafoam && styles.seafoam
      } ${green && styles.green} ${yellow && styles.yellow} ${
        orange && styles.orange
      } ${white && styles.white}  ${info && styles.info} ${black && styles.black} ${spin && styles.spin}`}
      onClick={onClick}
    >
      <i className={`bi bi-${iconName}`} style={style}></i>
      {tooltip && <div className={styles.tooltip}>{tooltip}</div>}
    </div>
  );
}

export default Icon;
