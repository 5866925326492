import React, { useEffect, useMemo, useRef, useState } from "react";
import styles from "./DataInput.module.scss";
import { TextFieldSimple } from "components/inputs";
import Checkbox from "components/inputs/input_fields/CheckboxBlue/Checkbox";
import FlexRow from "../FlexRow/FlexRow";
import FlexCol from "../FlexColumn/FlexCol";
import Icon from "components/Icon/Icon";
import {
  useCheckUniqueAttribute,
  useFetchCustomFieldAttributes,
} from "api/resources/contacts/custom_field";
import { Loading } from "components/Loading/Loading";
import { formatDateToCustomString } from "assets/functions/DateFunctions";

function DataInput({
  dataType,
  value,
  onChange,
  onBlur,
  icon,
  label,
  min,
  max,
  falseValue,
  trueValue,
  placeholder,
  disabled,
  id,
  required,
  multiple,
  unique,
  onValidate,
}) {
  const isUnique = useCheckUniqueAttribute();
  const [invalidMessage, setInvalidMessage] = useState("");
  const [valid, setValid] = useState(undefined);
  const [finalLabel] = useState(required ? `${label}` : label);
  const [date, setDate] = useState("");
  const [time, setTime] = useState("");

  // Initialize date and time if dataType is "date/time"
  useEffect(() => {
    if (dataType === "date/time" && value) {
      const parsedDate = new Date(value);
      setDate(parsedDate.toISOString().split("T")[0]); // Extract date part
      setTime(parsedDate.toTimeString().slice(0, 5)); // Extract time part
    }
  }, [dataType, value]);

  const validateField = (newValue) => {
    if (required && (!newValue || (multiple && newValue.length === 0))) {
      setInvalidMessage("This field is required");
      setValid(false);
      onValidate?.(false);
    } else {
      setInvalidMessage("");
      setValid(true);
      onValidate?.(true);
    }

    if (unique && newValue !== value) {
      isUnique.mutate(
        { id, name: newValue },
        {
          onSuccess: (data) => {
            if (data?.exists) {
              setInvalidMessage("Value already exists");
              setValid(false);
              onValidate?.(false);
            } else {
              setInvalidMessage("");
              setValid(true);
              onValidate?.(true);
            }
          },
          onError: () => {
            setInvalidMessage("Error validating uniqueness");
            onValidate?.(false);
          },
        }
      );
    }
  };

  const handleBlur = (newValue) => {
    validateField(newValue);
    onBlur?.(newValue);
  };

  // Combine date and time into a single ISO string or custom format
  const getCombinedDateTime = (dateValue, timeValue) => {
    if (!dateValue || !timeValue) return "";
    const combinedDate = new Date(`${dateValue}T${timeValue}`);
    return formatDateToCustomString
      ? formatDateToCustomString(combinedDate)
      : combinedDate.toISOString();
  };

  // Handle date change
  const handleDateChange = (newDate) => {
    setDate(newDate);
    const combinedDateTime = getCombinedDateTime(newDate, time);
    onChange?.(combinedDateTime);
  };

  // Handle time change
  const handleTimeChange = (newTime) => {
    setTime(newTime);
    const combinedDateTime = getCombinedDateTime(date, newTime);
    onChange?.(combinedDateTime);
  };

  const commonProps = {
    label: finalLabel,
    value,
    onChange,
    onBlur: handleBlur,
    placeholder,
    icon,
    disable: disabled,
    valid,
    invalidMessage,
  };

  const renderInput = () => {
    switch (dataType) {
      case "boolean":
        return (
          <FlexCol className={styles.input}>
            <div className={styles.label6} style={{ padding: ".2rem .5rem" }}>
              {finalLabel}
            </div>
            <FlexRow className={styles.bool} onClick={() => onChange(!value)}>
              <Checkbox checked={value} onChange={() => {}} />
              <div className={styles.text_2}>
                {value ? trueValue || "Yes" : falseValue || "No"}
              </div>
            </FlexRow>
          </FlexCol>
        );
      case "email":
        return (
          <TextFieldSimple
            {...commonProps}
            type="email"
            validationRules={[...(required ? ["Required"] : [])]}
          />
        );
      case "currency":
        return (
          <TextFieldSimple
            {...commonProps}
            type="text"
            placeholder="0.00"
            icon="currency-dollar"
          />
        );
      case "number":
        return (
          <TextFieldSimple
            {...commonProps}
            type="number"
            min={min}
            max={max}
            icon="123"
          />
        );
      case "date":
      case "time":
        return (
          <TextFieldSimple
            {...commonProps}
            type={dataType}
            value={value ? new Date(value).toISOString().split("T")[0] : ""}
          />
        );
      case "date/time":
        return (
          <div className={styles.input}>
            <FlexRow align="center" gap=".5rem">
              {/* Date Input */}
              <TextFieldSimple
                label={finalLabel}
                value={date}
                onChange={handleDateChange}
                onBlur={() => handleBlur(getCombinedDateTime(date, time))}
                type="date"
                placeholder="Select Date"
                disable={disabled}
                valid={valid}
                invalidMessage={invalidMessage}
                validationRules={required ? ["This field is required"] : []}
              />
              <div className={styles.text_2} style={{ paddingTop: "1.5rem" }}>
                at
              </div>
              {/* Time Input */}
              <TextFieldSimple
                label="Time"
                value={time}
                onChange={handleTimeChange}
                onBlur={() => handleBlur(getCombinedDateTime(date, time))}
                type="time"
                placeholder="Select Time"
                disable={disabled}
                valid={valid}
                invalidMessage={invalidMessage}
                validationRules={required ? ["This field is required"] : []}
              />
            </FlexRow>
          </div>
        );
      case "list":
        return (
          <ListField
            {...commonProps}
            id={id}
            multiple={multiple}
            required={required}
            placeholder={placeholder}
          />
        );
      default:
        return <TextFieldSimple {...commonProps} />;
    }
  };

  return <div className={styles.input}>{renderInput()}</div>;
}

function ListField({
  value,
  label,
  icon,
  disable,
  onChange,
  id,
  required,
  multiple,
  placeholder,
  valid,
  onBlur,
}) {
  const [drop, setDrop] = useState(false);
  const dropdownRef = useRef(null);
  const valueRef = useRef(value);


  useEffect(() => {
    valueRef.current = value;
  }, [value]);

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setDrop(false);

      onBlur(valueRef.current, false);
    }
  };

  // const handleClickOutside = (event) => {
  //   if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
  //     setDrop(false);
  //     console.log(value)
  //     // onBlur(value,false)
  //     // let el = document.getElementById("data-input-value");
  //     // // console.log(el.innerHTML);
  //     // if (el.innerHTML === "") {
  //     //   console.log(el.innerHTML);
  //     //   onBlur(el.innerHTML, false);
  //     // }
  //   }
  // };

  useEffect(() => {
    if (drop) {
      // Use capture phase to ensure it catches the event before React's synthetic event system
      document.addEventListener("mousedown", handleClickOutside, true);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside, true);
    };
  }, [drop]);

  function removeChosenOption(e, option) {
    e.stopPropagation();
    e.preventDefault();
    onChange(option, true);
  }

  function displayList(value) {
    if (value?.length > 0) {
      return (
        <>
          {value &&
            value?.length > 0 &&
            value?.map((option, i) => (
              <div
                className={`${styles.text_3} ${styles.chosenOption}`}
                key={i}
              >
                <div className={styles.chosenOptionName}>{option?.name}</div>
                <div
                  className={styles.chosenOptionRemove}
                  onClick={(e) => removeChosenOption(e, option)}
                >
                  <i className="bi-x"></i>
                </div>
              </div>
            ))}
        </>
      );
    } else {
      return undefined;
    }
  }

  return (
    <div
      className={styles.input}
      ref={dropdownRef}
      style={{ position: "relative" }}
    >
      {/* Label */}
      <div className={styles.label6} style={{ padding: ".2rem .5rem" }}>
        {label}
      </div>
      {/* Clickable Dropdown Trigger */}
      <div
        className={`${styles.listField} ${disable ? styles.disableList : ""} ${
          valid === false && styles.invalid
        } `}
        onClick={() => !disable && setDrop((prev) => !prev)}
      >
        {icon && <i className={`bi-${icon} ${styles.icon}`} />}
        {multiple && placeholder && !value && <div>{placeholder}</div>}
        {!multiple && placeholder && value?.length > 0 && (
          <div
            className={`${!multiple && styles.listFieldInput} ${
              multiple && styles.listFieldInputMultiple
            } ${styles.text_2} ${styles.placeholder}`}
          >
            {placeholder}
          </div>
        )}
        <div
          className={`${!multiple && styles.listFieldInput} ${
            multiple && styles.listFieldInputMultiple
          } ${styles.text_2}`}
          // id="data-input-value"
        >
          {!multiple ? value : displayList(value)}
        </div>
        <i
          className={`bi-chevron-down ${disable ? styles.disableIcon : ""}`}
        ></i>
      </div>

      {/* Dropdown Content */}
      {drop && (
        <LoadListFieldOptions
          value={value}
          onChange={onChange}
          id={id}
          required={required}
          multiple={multiple}
        />
      )}
    </div>
  );
}

function LoadListFieldOptions({ value, onChange, id, required, multiple }) {
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);

  const getAttributes = useFetchCustomFieldAttributes();

  useEffect(() => {
    if (id) {
      setLoading(true);
      getAttributes.mutate(
        {
          id: id,
        },
        {
          onSuccess: (data) => {
            if (data?.attributes?.length > 0) {
              setOptions(
                data?.attributes?.map((a) => {
                  return a.name;
                })
              );
            }
            setLoading(false);
          },
          // onSettled: () => {
          //   setLoading(false);
          // },
        }
      );
    }
  }, []);

  return multiple ? (
    <ListFieldOptionsMultiple
      options={options}
      value={value}
      onChange={onChange}
      loading={loading}
      required={required}
    ></ListFieldOptionsMultiple>
  ) : (
    <ListFieldOptions
      options={options}
      value={value}
      onChange={onChange}
      loading={loading}
      required={required}
    ></ListFieldOptions>
  );
}

function ListFieldOptionsMultiple({ options, value, onChange, loading }) {
  function getHeight(num) {
    if (num <= 6 && num > 0) {
      return num * 2.46 + "rem";
    } else if (num === 0) {
      return "2.46rem";
    } else {
      return "";
    }
  }

  const [topOfList, setTopOfList] = useState();

  useEffect(() => {
    if (!loading) {
      setTopOfList(
        options && options?.length > 0
          ? options?.length > 100
            ? options?.slice(0, 100)
            : options
          : []
      );
    }
  }, [options, loading]);

  function onScroll(e) {
    if (topOfList.length < options.length) {
      var scrollableContainer = e.target;

      let distanceToBottom =
        scrollableContainer.scrollHeight -
        (scrollableContainer.scrollTop + scrollableContainer.clientHeight);

      if (distanceToBottom <= 50) {
        let nextlength = topOfList.length + 100;
        setTopOfList(options.slice(0, nextlength));
      }
    }
  }

  return (
    <div
      className={styles.dropdown}
      style={{
        height: loading ? "2.46rem" : getHeight(topOfList?.length),
      }}
      onScroll={onScroll}
    >
      {loading && <Loading height={50} width={50}></Loading>}
      {!loading && (
        <>
          {topOfList &&
            topOfList?.length > 0 &&
            topOfList?.map((option, i) => (
              <MultipleOption
                option={option}
                key={i}
                onChange={onChange}
                chosen={
                  value?.length > 0 && value?.find((v) => v?.name === option)
                }
              ></MultipleOption>
            ))}
        </>
      )}
    </div>
  );
}

function MultipleOption({ option, chosen, onChange }) {
  return (
    <div
      className={styles.option}
      onClick={() => onChange(option, chosen ? true : false)}
    >
      <span>{option}</span>
      {chosen && (
        <Icon iconName={"check"} blue style={{ fontSize: "1.3rem" }}></Icon>
      )}
    </div>
  );
}

function ListFieldOptions({ options, value, onChange, loading, required }) {
  function getHeight(num) {
    if (num <= 6 && num > 0 && !required) {
      num += 1;
      return num * 2.46 + "rem";
    } else if (num <= 6 && num > 0 && required) {
      return num * 2.46 + "rem";
    } else if (num === 0 && !required) {
      return "2.46rem";
    } else if (num === 0 && required) {
      return "0";
    } else {
      return "";
    }
  }

  const [topOfList, setTopOfList] = useState();

  useEffect(() => {
    if (!loading) {
      setTopOfList(
        options && options?.length > 0
          ? options?.length > 100
            ? options?.slice(0, 100)
            : options
          : []
      );
    }
  }, [options, loading]);

  function onScroll(e) {
    if (topOfList.length < options.length) {
      var scrollableContainer = e.target;

      let distanceToBottom =
        scrollableContainer.scrollHeight -
        (scrollableContainer.scrollTop + scrollableContainer.clientHeight);

      if (distanceToBottom <= 50) {
        let nextlength = topOfList.length + 100;
        setTopOfList(options.slice(0, nextlength));
      }
    }
  }

  return (
    <div
      className={styles.dropdown}
      style={{
        height: loading ? "2.46rem" : getHeight(topOfList?.length),
      }}
      onScroll={onScroll}
    >
      {loading && <Loading height={50} width={50}></Loading>}
      {!loading && (
        <>
          {!required && (
            <div className={styles.option} onClick={() => onChange("None")}>
              -- None --{" "}
            </div>
          )}
          {topOfList &&
            topOfList?.length > 0 &&
            topOfList?.map((option, i) => (
              <div
                key={i}
                className={styles.option}
                onClick={() => onChange(option)}
              >
                <span>{option}</span>
                {option === value && (
                  <Icon
                    iconName={"check"}
                    blue
                    style={{ fontSize: "1.3rem" }}
                  ></Icon>
                )}
              </div>
            ))}
        </>
      )}
    </div>
  );
}

function ListField2({
  label,
  value,
  onChange,
  id,
  multiple,
  placeholder,
  required,
}) {
  const [drop, setDrop] = useState(false);
  const dropdownRef = useRef(null);
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);

  const getAttributes = useFetchCustomFieldAttributes();

  useEffect(() => {
    if (id) {
      setLoading(true);
      getAttributes.mutate(
        { id },
        {
          onSuccess: (data) => {
            setOptions(data?.attributes || []);
            setLoading(false);
          },
          onError: () => setLoading(false),
        }
      );
    }
  }, [id, getAttributes]);

  const toggleDropdown = () => setDrop((prev) => !prev);

  return (
    <div className={styles.listField} ref={dropdownRef}>
      <div className={styles.label}>{label}</div>
      <div className={styles.dropdownTrigger} onClick={toggleDropdown}>
        {multiple ? (
          value?.map((v, i) => <span key={i}>{v.name}</span>)
        ) : (
          <span>{value || placeholder}</span>
        )}
      </div>
      {drop && (
        <div className={styles.dropdown}>
          {loading && <Loading />}
          {!loading &&
            options.map((opt, idx) => (
              <div
                key={idx}
                className={styles.option}
                onClick={() => onChange(opt)}
              >
                {opt}
              </div>
            ))}
        </div>
      )}
    </div>
  );
}

export default DataInput;
