import TextEditorTry from "components/inputs/input_fields/TextEditorTry/TextEditorTry";
import styles from "./SurveyLogicQ.module.scss";
import { MultipleChoice } from "components/QuestionConstruction/Types/MultipleChoice/MultipleChoice";
import { NumberScaleQuestion } from "components/QuestionConstruction/Types/NumberScaleQuestion";
import { NumberSlideQuestion } from "components/QuestionConstruction/Types/NumberSlideQuestion";
import TextQ from "components/Survey/UXQuestions/TextQuestion/TextQ";
import Matrix from "components/QuestionConstruction/Types/Matrix/Matrix";

export function fadedEncoding(encoding) {
  if (!encoding) return null;
  let copy = JSON.parse(encoding);
  for (let div of copy) {
    if (div.styles) {
      for (let style of div.styles) {
        if (style.code === "color") {
          style.value = "#a3a4a8";
        }
      }
    }
  }
  return JSON.stringify(copy);
}

export const SurveyLogicQ = ({
  question,
  onAnswer,
  onRetract,
  answerMap,
  settings,
  questionNumber,
  faded,
}) => {
  return (
    <>
      <div className={styles.descriptionContainer}>
        <div style={{ display: "flex", width: "100%" }}>
          <div className={styles.Qnum}>{`Q${questionNumber}.`}</div>

          <TextEditorTry
            encoding={
              faded ? fadedEncoding(question.encoding) : question.encoding
            }
            style={{
              color: faded ? "#a3a4a8" : undefined,
              cursor: "pointer",
            }}
          />
        </div>

        {question.description &&
          question.description != "" &&
          question.description != "\n" && (
            <TextEditorTry
              encoding={
                faded
                  ? fadedEncoding(question.descriptionEncoding)
                  : question.descriptionEncoding
              }
              style={{
                color: faded ? "#a3a4a8" : undefined,
                cursor: "pointer",
              }}
            />
          )}
      </div>
      {(question.type === "MultipleChoice" || question.type === "Ranking") && (
        <MultipleChoice
          question={question}
          saveQuestion={() => {}}
          designSettings={settings}
          onAnswer={onAnswer}
          onRetract={onRetract}
          answerMap={answerMap}
          disable={faded}
        />
      )}
      {question.type === "NumberScale" && (
        <NumberScaleQuestion
          question={question}
          color={settings.baseColor}
          onAnswer={onAnswer}
          onRetract={onRetract}
          answerMap={answerMap}
          disable={faded}
        />
      )}
      {question.type === "NumberSlider" && (
        <NumberSlideQuestion
          question={question}
          color={faded ? "#a3a4a8" : settings.baseColor}
          onAnswer={onAnswer}
          answerMap={answerMap}
          disable={faded}
        />
      )}
      {question.type === "Text" && (
        <TextQ
          question={question}
          onAnswered={onAnswer}
          onRetract={onRetract}
          color={settings.baseColor}
          value={answerMap[question.id] ? answerMap[question.id] : ""}
          answered={answerMap[question.id]}
          disable={faded}
        />
      )}
      {question.type === "Matrix" && (
        <Matrix
          question={question}
          saveQuestion={() => {}}
          designSettings={settings}
          onAnswer={onAnswer}
          onRetract={onRetract}
          answerMap={answerMap}
          disable={faded}
        />
      )}
    </>
  );
};
