/*
======================= START OF LICENSE NOTICE =======================
  Copyright (C) 2023 Reaction. All Rights Reserved

  NO WARRANTY. THE PRODUCT IS PROVIDED BY DEVELOPER "AS IS" AND ANY
  EXPRESS OR IMPLIED WARRANTIES, INCLUDING, BUT NOT LIMITED TO, THE
  IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR
  PURPOSE ARE DISCLAIMED. IN NO EVENT SHALL DEVELOPER BE LIABLE FOR
  ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, EXEMPLARY, OR CONSEQUENTIAL
  DAMAGES (INCLUDING, BUT NOT LIMITED TO, PROCUREMENT OF SUBSTITUTE
  GOODS OR SERVICES; LOSS OF USE, DATA, OR PROFITS; OR BUSINESS
  INTERRUPTION) HOWEVER CAUSED AND ON ANY THEORY OF LIABILITY, WHETHER
  IN CONTRACT, STRICT LIABILITY, OR TORT (INCLUDING NEGLIGENCE OR
  OTHERWISE) ARISING IN ANY WAY OUT OF THE USE OF THE PRODUCT, EVEN
  IF ADVISED OF THE POSSIBILITY OF SUCH DAMAGE.
======================== END OF LICENSE NOTICE ========================
  Primary Author: natehanson
*/

// External
import { useQueryClient } from "react-query";
import { gql } from "graphql-request";

// Internal
import { useGqlQuery, useGqlMutation } from "api/Api";
import {
  combinedProjIds,
  combinedQs,
} from "pages/results/Charts/Visualization";

// GRAPHQL API

export const vizQData = `
  id
  type
  questionText
  projectId
  isScore
  isComment
  scaleQuestion {
    id
    min
    max
    step
  }
  textQuestion {
    id
    bucket {
      id
      name
      properties
    }
  }
  choiceQuestion {
    id
    choices
    hasOtherOption
    otherOptionLabel
    isRanking
    isMultiSelect
    limit 
  }
  matrixQuestion {
    id
    options
    type
  }`;

const vizPullData = `
  designSettings
  id
  title
  titleValue
  type
  pivotString
  filters
  projectIdsArray
  tagIdsArray
  analysisId
  questionTags
  programIds
  comparison
  dataMethod
  data
  question {
    ${vizQData}
  }
  dynamicQs {
    ${vizQData}
  }
  comparisonQs {
   ${vizQData}
  }
`;

export const useGetViz = (id) => {
  const query = gql`
    query  {
      viz: getViz(id: "${id}") {
        ${vizPullData}
      }
    }
  `;

  return useGqlQuery(["viz: " + id], query);
};

export const useGetVizPreview = (id) => {
  const query = gql`
    query  {
      viz: getVizPreview(id: "${id}") {
        designSettings
        id
        title
        type
        data
      }
    }
  `;

  return useGqlQuery("viz preview: " + id, query);
};

export const useGetPivotTable = (viz, filters, onDone) => {
  const qIds = {
    ids: combinedQs(viz).map((q) => q.id),
  };
  const projIds = {
    ids: combinedProjIds(viz),
  };
  const tagIds = {
    ids: viz.tagIdsArray,
  };

  const query = gql`
    query Query(
      $pivot: String!
      $tagIds: IdStrings!
      $designSettings: String!
      $projectIds: IdStrings!
      $filters: String!
      $qIds: IdStrings!
    ) {
      table: getPivotTable(
        pivot: $pivot
        tagIds: $tagIds
        designSettings: $designSettings
        projectIds: $projectIds
        filters: $filters
        qIds: $qIds
      ) {
        rows
        columns {
          name
          accessor
        }
      }
    }
  `;

  const options = {
    onSuccess: (data) => {
      if (onDone) {
        onDone(data.table);
      }
    },
  };

  return useGqlQuery(
    "pivot table: " + viz.id + " " + filters,
    query,
    {
      pivot: viz.pivotString,
      tagIds: tagIds,
      designSettings: JSON.stringify(viz.designSettings),
      projectIds: projIds,
      filters: filters,
      qIds: qIds,
    },
    options
  );
};

export const useGetVizMutation = () => {
  const mutation = gql`
    mutation GetVizMutation($id: String!) {
      viz: getVizMutation(id: $id) {
        ${vizPullData}
      }
    }
  `;

  return useGqlMutation(mutation);
};

export const useCreateVisualization = () => {
  const mutation = gql`
    mutation Mutation($data: visualizationInput!) {
      createVisualization(data: $data) {
        designSettings
        id
        title
        type
        pivotString
        filters
        projectIdsArray
        tagIdsArray
        question {
          id
          index
          type
          questionText
          projectId
          buckets
          hasBuckets
          scaleQuestion {
            id
            min
            max
            step
          }
          textQuestion {
            id
          }
          choiceQuestion {
            id
            choices
          }
          matrixQuestion {
            id
            options
          }
        }
      }
    }
  `;
  const queryClient = useQueryClient();
  const options = {
    onError: (err, _project, rollback) => {
      if (rollback) rollback();
    },
    onSettled: () => {
      queryClient.invalidateQueries("analysis");
    },
  };

  return useGqlMutation(mutation, options);
};

export const useCreateVizAndAppendToAnalysis = () => {
  const mutation = gql`
    mutation Mutation($analysisId: String!, $data: NewVizData!) {
      viz: createBlankVizAndAppendToAnalysis(
        analysisId: $analysisId
        data: $data
      ) {
        designSettings
        id
        title
        type
        pivotString
        filters
        projectIdsArray
        tagIdsArray
        question {
          id
        }
      }
    }
  `;
  const queryClient = useQueryClient();
  const options = {
    onError: (err, _project, rollback) => {
      if (rollback) rollback();
    },
    onSettled: () => {
      queryClient.invalidateQueries("analysis");
    },
  };

  return useGqlMutation(mutation, options);
};

export const useGetQChart = () => {
  const mutation = gql`
    mutation GetQChart(
      $qIds: IdStrings!
      $filters: String!
      $projectIds: IdStrings!
      $designSettings: String!
      $tagIds: IdStrings!
      $pivot: String
      $compQIds: IdStrings!
    ) {
      chart: getQChart(
        qIds: $qIds
        filters: $filters
        projectIds: $projectIds
        designSettings: $designSettings
        tagIds: $tagIds
        pivot: $pivot
        compQIds: $compQIds
      ) {
        datasets {
          data
          label
        }
        axisLabels
        split
        N
      }
    }
  `;

  return useGqlMutation(mutation);
};

export const useUpdateVisualization = () => {
  const mutation = gql`
    mutation Mutation($data: visualizationInput!, $id: String!) {
      updateVisualization(data: $data, id: $id) {
        id
        title
      }
    }
  `;

  const queryClient = useQueryClient();
  const options = {
    onError: (err, _project, rollback) => {
      if (rollback) rollback();
    },
    onSettled: (data) => {
      if (data.updateVisualization) {
        queryClient.invalidateQueries("viz: " + data.updateVisualization.id);
      }
    },
  };

  return useGqlMutation(mutation, options);
};

export const useDeleteOneVisualization = () => {
  const mutation = gql`
    mutation DeleteVisualization($id: String!) {
      deleteVisualization(id: $id)
    }
  `;
  const queryClient = useQueryClient();
  const options = {
    onError: (err, _project, rollback) => {
      if (rollback) rollback();
    },
    onSettled: () => {
      queryClient.invalidateQueries("analysis");
    },
  };

  return useGqlMutation(mutation, options);
};

export const useCopyVizToPage = () => {
  const mutation = gql`
    mutation Mutation(
      $reportId: String!
      $analysisId: String!
      $data: visualizationInput!
    ) {
      page: copyVizToPage(
        reportId: $reportId
        analysisId: $analysisId
        data: $data
      ) {
        id
        name
      }
    }
  `;
  const queryClient = useQueryClient();
  const options = {
    onError: (err, _project, rollback) => {
      if (rollback) rollback();
    },
    onSettled: () => {
      queryClient.invalidateQueries("analysis");
    },
  };

  return useGqlMutation(mutation, options);
};

export const useDeleteWidgetCharts = () => {
  const mutation = gql`
    mutation DeleteVisualizations($chartIds: VizIdsArray!) {
      deleteVisualizations(chartIds: $chartIds)
    }
  `;
  const queryClient = useQueryClient();
  const options = {
    onError: (err, _project, rollback) => {
      if (rollback) rollback();
    },
    onSettled: () => {
      queryClient.invalidateQueries("visualizaiton");
    },
  };

  return useGqlMutation(mutation, options);
};

export const useGetComparisonQs = () => {
  const mutation = gql`
    mutation Mutation($comparison: String!) {
      comparisonQs: getQsFromComparison(comparison: $comparison) {
        id
        index
        type
        questionText
        projectId
        hasBuckets
        buckets
        scaleQuestion {
          id
          min
          max
          step
        }
        textQuestion {
          id
        }
        choiceQuestion {
          id
          choices
          hasOtherOption
          otherOptionLabel
          isRanking
          limit
        }
        matrixQuestion {
          id
          options
          type
        }
      }
    }
  `;
  const options = {
    onError: (err, _project, rollback) => {
      if (rollback) rollback();
    },
  };

  return useGqlMutation(mutation, options);
};

export const useEmailTableFile = () => {
  const mutation = gql`
    mutation EmailTableFile(
      $csvString: String!
      $fileName: String!
      $fileType: String!
    ) {
      emailTableFile(
        csvString: $csvString
        fileName: $fileName
        fileType: $fileType
      )
    }
  `;
  const options = {
    onError: (err, _project, rollback) => {
      if (rollback) rollback();
    },
  };

  return useGqlMutation(mutation, options);
};
