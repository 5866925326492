import FlexRow from "components/layouts/FlexRow/FlexRow";

export const timzones = [
  {
    timezone: "Pacific/Tongatapu",
    value: "Pacific/Tongatapu",
    offset: -46800,
    display: "GMT+13:00",
    label: "Nuku'alofa",
  },
  {
    timezone: "Pacific/Auckland",
    value: "Pacific/Auckland",
    offset: -46800,
    display: "GMT+12:00",
    label: "Auckland, Wellington",
  },
  {
    timezone: "Etc/GMT-12",
    value: "Etc/GMT-12",
    offset: -43200,
    display: "GMT+12:00",
    label: "Fiji, Kamchatka, Marshall Is.",
  },
  {
    timezone: "Asia/Magadan",
    value: "Asia/Magadan",
    offset: -43200,
    display: "GMT+12:00",
    label: "Magadan",
  },
  {
    timezone: "Etc/GMT-11",
    value: "Etc/GMT-11",
    offset: -39600,
    display: "GMT+11:00",
    label: "Solomon Is., New Caledonia",
  },
  {
    timezone: "Asia/Vladivostok",
    value: "Asia/Vladivostok",
    offset: -39600,
    display: "GMT+11:00",
    label: "Vladivostok",
  },
  {
    timezone: "Asia/Yakutsk",
    value: "Asia/Yakutsk",
    offset: -36000,
    display: "GMT+10:00",
    label: "Yakutsk",
  },
  {
    timezone: "Australia/Brisbane",
    value: "Australia/Brisbane",
    offset: -36000,
    display: "GMT+10:00",
    label: "Brisbane",
  },
  {
    timezone: "Australia/Sydney",
    value: "Australia/Sydney",
    offset: -39600,
    display: "GMT+10:00",
    label: "Canberra, Melbourne, Sydney",
  },
  {
    timezone: "Australia/Hobart",
    value: "Australia/Hobart",
    offset: -39600,
    display: "GMT+10:00",
    label: "Hobart",
  },
  {
    timezone: "Australia/Adelaide",
    value: "Australia/Adelaide",
    offset: -37800,
    display: "GMT+09:30",
    label: "Adelaide",
  },
  {
    timezone: "Australia/Darwin",
    value: "Australia/Darwin",
    offset: -34200,
    display: "GMT+09:30",
    label: "Darwin",
  },
  {
    timezone: "Asia/Irkutsk",
    value: "Asia/Irkutsk",
    offset: -32400,
    display: "GMT+09:00",
    label: "Irkutsk, Ulaan Bataar",
  },
  {
    timezone: "Asia/Tokyo",
    value: "Asia/Tokyo",
    offset: -32400,
    display: "GMT+09:00",
    label: "Osaka, Sapporo, Tokyo",
  },
  {
    timezone: "Asia/Seoul",
    value: "Asia/Seoul",
    offset: -32400,
    display: "GMT+09:00",
    label: "Seoul",
  },
  {
    timezone: "Asia/Hong_Kong",
    value: "Asia/Hong_Kong",
    offset: -28800,
    display: "GMT+08:00",
    label: "Beijing, Chongqing, Hong Kong, Urumqi",
  },
  {
    timezone: "Asia/Krasnoyarsk",
    value: "Asia/Krasnoyarsk",
    offset: -28800,
    display: "GMT+08:00",
    label: "Krasnoyarsk",
  },
  {
    timezone: "Australia/Perth",
    value: "Australia/Perth",
    offset: -28800,
    display: "GMT+08:00",
    label: "Perth",
  },
  {
    timezone: "Asia/Taipei",
    value: "Asia/Taipei",
    offset: -28800,
    display: "GMT+08:00",
    label: "Taipei",
  },
  {
    timezone: "Asia/Bangkok",
    value: "Asia/Bangkok",
    offset: -25200,
    display: "GMT+07:00",
    label: "Bangkok, Hanoi, Jakarta",
  },
  {
    timezone: "Asia/Novosibirsk",
    value: "Asia/Novosibirsk",
    offset: -25200,
    display: "GMT+07:00",
    label: "Novosibirsk",
  },
  {
    timezone: "Asia/Rangoon",
    value: "Asia/Rangoon",
    offset: -23400,
    display: "GMT+06:30",
    label: "Yangon Rangoon",
  },
  {
    timezone: "Asia/Dhaka",
    value: "Asia/Dhaka",
    offset: -21600,
    display: "GMT+06:00",
    label: "Astana, Dhaka",
  },
  {
    timezone: "Asia/Yekaterinburg",
    value: "Asia/Yekaterinburg",
    offset: -21600,
    display: "GMT+06:00",
    label: "Ekaterinburg",
  },
  {
    timezone: "Asia/Katmandu",
    value: "Asia/Katmandu",
    offset: -20700,
    display: "GMT+05:45",
    label: "Kathmandu",
  },
  {
    timezone: "Asia/Kolkata",
    value: "Asia/Kolkata",
    offset: -19800,
    display: "GMT+05:30",
    label: "Chennai, Kolkata, Mumbai, New Delhi",
  },
  {
    timezone: "Asia/Tashkent",
    value: "Asia/Tashkent",
    offset: -18000,
    display: "GMT+05:00",
    label: "Asia/Tashkent",
  },
  {
    timezone: "Asia/Kabul",
    value: "Asia/Kabul",
    offset: -16200,
    display: "GMT+04:30",
    label: "Kabul",
  },
  {
    timezone: "Asia/Dubai",
    value: "Asia/Dubai",
    offset: -14400,
    display: "GMT+04:00",
    label: "Abu Dhabi, Muscat",
  },
  {
    timezone: "Asia/Baku",
    value: "Asia/Baku",
    offset: -14400,
    display: "GMT+04:00",
    label: "Baku",
  },
  {
    timezone: "Europe/Moscow",
    value: "Europe/Moscow",
    offset: -14400,
    display: "GMT+04:00",
    label: "Moscow, St. Petersburg, Volgograd",
  },
  {
    timezone: "Asia/Tbilisi",
    value: "Asia/Tbilisi",
    offset: -14400,
    display: "GMT+04:00",
    label: "Tbilisi",
  },
  {
    timezone: "Asia/Yerevan",
    value: "Asia/Yerevan",
    offset: -14400,
    display: "GMT+04:00",
    label: "Yerevan",
  },
  {
    timezone: "Asia/Tehran",
    value: "Asia/Tehran",
    offset: -12600,
    display: "GMT+03:30",
    label: "Tehran",
  },
  {
    timezone: "Asia/Baghdad",
    value: "Asia/Baghdad",
    offset: -10800,
    display: "GMT+03:00",
    label: "Baghdad",
  },
  {
    timezone: "Europe/Minsk",
    value: "Europe/Minsk",
    offset: -10800,
    display: "GMT+03:00",
    label: "Kaliningrad, Minsk",
  },
  {
    timezone: "Asia/Kuwait",
    value: "Asia/Kuwait",
    offset: -10800,
    display: "GMT+03:00",
    label: "Kuwait, Riyadh",
  },
  {
    timezone: "Africa/Addis_Ababa",
    value: "Africa/Addis_Ababa",
    offset: -10800,
    display: "GMT+03:00",
    label: "Nairobi",
  },
  {
    timezone: "Asia/Amman",
    value: "Asia/Amman",
    offset: -7200,
    display: "GMT+02:00",
    label: "Amman",
  },
  {
    timezone: "Europe/Bucharest",
    value: "Europe/Bucharest",
    offset: -7200,
    display: "GMT+02:00",
    label: "Athens, Bucharest",
  },
  {
    timezone: "Asia/Beirut",
    value: "Asia/Beirut",
    offset: -7200,
    display: "GMT+02:00",
    label: "Beirut",
  },
  {
    timezone: "Africa/Cairo",
    value: "Africa/Cairo",
    offset: -7200,
    display: "GMT+02:00",
    label: "Cairo",
  },
  {
    timezone: "Asia/Damascus",
    value: "Asia/Damascus",
    offset: -7200,
    display: "GMT+02:00",
    label: "Damascus",
  },
  {
    timezone: "Africa/Blantyre",
    value: "Africa/Blantyre",
    offset: -7200,
    display: "GMT+02:00",
    label: "Harare, Pretoria",
  },
  {
    timezone: "Europe/Helsinki",
    value: "Europe/Helsinki",
    offset: -7200,
    display: "GMT+02:00",
    label: "Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius",
  },
  {
    timezone: "Europe/Istanbul",
    value: "Europe/Istanbul",
    offset: -7200,
    display: "GMT+02:00",
    label: "Istanbul",
  },
  {
    timezone: "Asia/Jerusalem",
    value: "Asia/Jerusalem",
    offset: -7200,
    display: "GMT+02:00",
    label: "Jerusalem",
  },
  {
    timezone: "Europe/Nicosia",
    value: "Europe/Nicosia",
    offset: -7200,
    display: "GMT+02:00",
    label: "Nicosia",
  },
  {
    timezone: "Europe/Amsterdam",
    value: "Europe/Amsterdam",
    offset: -3600,
    display: "GMT+01:00",
    label: "Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna",
  },
  {
    timezone: "Europe/Belgrade",
    value: "Europe/Belgrade",
    offset: -3600,
    display: "GMT+01:00",
    label: "Belgrade, Bratislava, Budapest, Prague, Ljubljana",
  },
  {
    timezone: "Europe/Brussels",
    value: "Europe/Brussels",
    offset: -3600,
    display: "GMT+01:00",
    label: "Brussels, Copenhagen, Madrid, Paris",
  },
  {
    timezone: "Europe/Warsaw",
    value: "Europe/Warsaw",
    offset: -3600,
    display: "GMT+01:00",
    label: "Sarajevo, Skopje, Warsaw, Zagreb",
  },
  {
    timezone: "Africa/Algiers",
    value: "Africa/Algiers",
    offset: -3600,
    display: "GMT+01:00",
    label: "West Central Africa",
  },
  {
    timezone: "Africa/Windhoek",
    value: "Africa/Windhoek",
    offset: -7200,
    display: "GMT+01:00",
    label: "Windhoek",
  },
  {
    timezone: "Africa/Casablanca",
    value: "Africa/Casablanca",
    offset: 0,
    display: "GMT-00:00",
    label: "Casablanca",
  },
  {
    timezone: "Etc/UTC",
    value: "Etc/UTC",
    offset: 0,
    display: "GMT-00:00",
    label: "Coordinated Universal Time",
  },
  {
    timezone: "Africa/Abidjan",
    value: "Africa/Abidjan",
    offset: 0,
    display: "GMT-00:00",
    label: "Monrovia, Reykjavik",
  },
  {
    timezone: "Europe/London",
    value: "Europe/London",
    offset: 0,
    display: "GMT-00:00",
    label: "Dublin, Lisabon, London, Edinburgh",
  },
  {
    timezone: "Atlantic/Cape_Verde",
    value: "Atlantic/Cape_Verde",
    offset: 3600,
    display: "GMT-01:00",
    label: "Cape Verde Is.",
  },
  {
    timezone: "Atlantic/Azores",
    value: "Atlantic/Azores",
    offset: 3600,
    display: "GMT-01:00",
    label: "Azores",
  },
  {
    timezone: "America/Noronha",
    value: "America/Noronha",
    offset: 7200,
    display: "GMT-02:00",
    label: "Mid-Atlantic",
  },
  {
    timezone: "Etc/GMT+2",
    value: "Etc/GMT+2",
    offset: 7200,
    display: "GMT-02:00",
    label: "Coordinated Universal Time - 02",
  },
  {
    timezone: "America/Sao_Paulo",
    value: "America/Sao_Paulo",
    offset: 7200,
    display: "GMT-03:00",
    label: "Brasilia",
  },
  {
    timezone: "America/Argentina/Buenos_Aires",
    value: "America/Argentina/Buenos_Aires",
    offset: 10800,
    display: "GMT-03:00",
    label: "Buenos Aires",
  },
  {
    timezone: "America/Cayenne",
    value: "America/Cayenne",
    offset: 10800,
    display: "GMT-03:00",
    label: "Cayenne, Fortalenza",
  },
  {
    timezone: "America/Godthab",
    value: "America/Godthab",
    offset: 10800,
    display: "GMT-03:00",
    label: "Greenland",
  },
  {
    timezone: "America/Montevideo",
    value: "America/Montevideo",
    offset: 7200,
    display: "GMT-03:00",
    label: "Montevideo",
  },
  {
    timezone: "America/St_Johns",
    value: "America/St_Johns",
    offset: 12600,
    display: "GMT-03:30",
    label: "Newfoundland",
  },
  {
    timezone: "America/Asuncion",
    value: "America/Asuncion",
    offset: 10800,
    display: "GMT-04:00",
    label: "Asuncion",
  },
  {
    timezone: "America/Goose_Bay",
    value: "America/Goose_Bay",
    offset: 14400,
    display: "GMT-04:00",
    label: "Atlantic Time, Goose Bay",
  },
  {
    timezone: "America/Glace_Bay",
    value: "America/Glace_Bay",
    offset: 14400,
    display: "GMT-04:00",
    label: "Atlantic Time, Canada",
  },
  {
    timezone: "America/Cuiaba",
    value: "America/Cuiaba",
    offset: 10800,
    display: "GMT-04:00",
    label: "Cuiaba",
  },
  {
    timezone: "America/La_Paz",
    value: "America/La_Paz",
    offset: 14400,
    display: "GMT-04:00",
    label: "Georgetown, La Paz, Manaus, San Juan",
  },
  {
    timezone: "America/Santiago",
    value: "America/Santiago",
    offset: 10800,
    display: "GMT-04:00",
    label: "Santiago",
  },
  {
    timezone: "America/Caracas",
    value: "America/Caracas",
    offset: 16200,
    display: "GMT-04:30",
    label: "Caracas",
  },
  {
    timezone: "America/Bogota",
    value: "America/Bogota",
    offset: 18000,
    display: "GMT-05:00",
    label: "Bogota, Lima, Quito, Rio Branco",
  },
  {
    timezone: "America/New_York",
    value: "America/New_York",
    offset: 18000,
    display: "GMT-05:00",
    label: "Eastern Time, US & Canada",
  },
  {
    timezone: "America/Havana",
    value: "America/Havana",
    offset: 18000,
    display: "GMT-05:00",
    label: "Cuba",
  },
  {
    timezone: "America/Indiana/Indianapolis",
    value: "America/Indiana/Indianapolis",
    offset: 18000,
    display: "GMT-05:00",
    label: "Indiana (East)",
  },
  {
    timezone: "America/Belize",
    value: "America/Belize",
    offset: 21600,
    display: "GMT-06:00",
    label: "Central America",
  },
  {
    timezone: "America/Chicago",
    value: "America/Chicago",
    offset: 21600,
    display: "GMT-06:00",
    label: "Central Time, US & Canada",
  },
  {
    timezone: "America/Cancun",
    value: "America/Cancun",
    offset: 21600,
    display: "GMT-06:00",
    label: "Guadalajara, Mexico City, Monterrey",
  },
  {
    timezone: "Canada/Saskatchewan",
    value: "Canada/Saskatchewan",
    offset: 21600,
    display: "GMT-06:00",
    label: "Saskatchewan",
  },
  {
    timezone: "America/Dawson_Creek",
    value: "America/Dawson_Creek",
    offset: 25200,
    display: "GMT-07:00",
    label: "Arizona",
  },
  {
    timezone: "America/Chihuahua",
    value: "America/Chihuahua",
    offset: 25200,
    display: "GMT-07:00",
    label: "Chihuahua, La Paz, Mazatlan",
  },
  {
    timezone: "America/Denver",
    value: "America/Denver",
    offset: 25200,
    display: "GMT-07:00",
    label: "Mountain Time, US & Canada",
  },
  {
    timezone: "America/Ensenada",
    value: "America/Ensenada",
    offset: 28800,
    display: "GMT-08:00",
    label: "Tijuana, Baja California",
  },
  {
    timezone: "America/Los_Angeles",
    value: "America/Los_Angeles",
    offset: 28800,
    display: "GMT-08:00",
    label: "Pacific Time, US & Canada",
  },
  {
    timezone: "America/Anchorage",
    value: "America/Anchorage",
    offset: 32400,
    display: "GMT-09:00",
    label: "Alaska",
  },
  {
    timezone: "Etc/GMT+10",
    value: "Etc/GMT+10",
    offset: 36000,
    display: "GMT-10:00",
    label: "Hawaii",
  },
  {
    timezone: "Pacific/Midway",
    value: "Pacific/Midway",
    offset: 39600,
    display: "GMT-11:00",
    label: "Midway Island, Samoa",
  },
  {
    timezone: "Etc/GMT+12",
    value: "Etc/GMT+12",
    offset: 43200,
    display: "GMT-12:00",
    label: "International Date Line West",
  },
];

export const defaultTimeZone = {
  timezone: "America/Denver",
  value: "America/Denver",
  offset: 25200,
  display: "GMT-07:00",
  label: "Mountain Time, US & Canada",
};

export const maintimezones = [
  {
    timezone: "Etc/GMT+10",
    value: "Etc/GMT+10",
    offset: 36000,
    display: "GMT-10:00",
    label: "Hawaii",
  },
  {
    timezone: "America/Anchorage",
    value: "America/Anchorage",
    offset: 32400,
    display: "GMT-09:00",
    label: "Alaska",
  },
  {
    timezone: "America/Los_Angeles",
    value: "America/Los_Angeles",
    offset: 28800,
    display: "GMT-08:00",
    label: "Pacific Time, US & Canada",
  },
  {
    timezone: "America/Denver",
    value: "America/Denver",
    offset: 25200,
    display: "GMT-07:00",
    label: "Mountain Time, US & Canada",
  },
  {
    timezone: "America/Chicago",
    value: "America/Chicago",
    offset: 21600,
    display: "GMT-06:00",
    label: "Central Time, US & Canada",
  },
  {
    timezone: "America/New_York",
    value: "America/New_York",
    offset: 18000,
    display: "GMT-05:00",
    label: "Eastern Time, US & Canada",
  },
  {
    timezone: "America/Glace_Bay",
    value: "America/Glace_Bay",
    offset: 14400,
    display: "GMT-04:00",
    label: "Atlantic Time, Canada",
  },
  {
    timezone: "America/Godthab",
    value: "America/Godthab",
    offset: 10800,
    display: "GMT-03:00",
    label: "Greenland",
  },
  {
    timezone: "America/Godthab",
    value: "America/Godthab",
    offset: 10800,
    display: "GMT-03:00",
    label: "Rio de Janeiro, Brazil",
  },
  {
    timezone: "America/Noronha",
    value: "America/Noronha",
    offset: 7200,
    display: "GMT-02:00",
    label: "Mid-Atlantic",
  },
  {
    timezone: "Atlantic/Cape_Verde",
    value: "Atlantic/Cape_Verde",
    offset: 3600,
    display: "GMT-01:00",
    label: "Cape Verde Is.",
  },
  {
    timezone: "Etc/UTC",
    value: "Etc/UTC",
    offset: 0,
    display: "GMT-00:00",
    label: "Coordinated Universal Time",
  },
  {
    timezone: "Europe/London",
    value: "Europe/London",
    offset: 0,
    display: "GMT-00:00",
    label: "London, United Kingdom (Dublin, Lisabon, Edinburgh)",
  },
  {
    timezone: "Africa/Algiers",
    value: "Africa/Algiers",
    offset: -3600,
    display: "GMT+01:00",
    label: "West Central Africa",
  },
  {
    timezone: "Europe/Amsterdam",
    value: "Europe/Amsterdam",
    offset: -3600,
    display: "GMT+01:00",
    label: "Berlin, Germany (Amsterdam, Bern, Rome, Stockholm, Vienna)",
  },
  {
    timezone: "Europe/Istanbul",
    value: "Europe/Istanbul",
    offset: -7200,
    display: "GMT+02:00",
    label: "Istanbul",
  },
  {
    timezone: "Asia/Jerusalem",
    value: "Asia/Jerusalem",
    offset: -7200,
    display: "GMT+02:00",
    label: "Jerusalem",
  },
  {
    timezone: "Asia/Baghdad",
    value: "Asia/Baghdad",
    offset: -10800,
    display: "GMT+03:00",
    label: "Baghdad",
  },
  {
    timezone: "Europe/Moscow",
    value: "Europe/Moscow",
    offset: -14400,
    display: "GMT+04:00",
    label: "Moscow, Russian Federation (St. Petersburg, Volgograd",
  },
  {
    timezone: "Asia/Dubai",
    value: "Asia/Dubai",
    offset: -14400,
    display: "GMT+04:00",
    label: "Dubai, United Arab Emirates",
  },
  {
    timezone: "Asia/Hong_Kong",
    value: "Asia/Hong_Kong",
    offset: -28800,
    display: "GMT+08:00",
    label: "China Time, China (Beijing, Chongqing, Hong Kong, Urumqi)",
  },
  {
    timezone: "Asia/Tokyo",
    value: "Asia/Tokyo",
    offset: -32400,
    display: "GMT+09:00",
    label: "Tokyo, Japan (Osaka, Sapporo)",
  },
  {
    timezone: "Australia/Sydney",
    value: "Australia/Sydney",
    offset: -39600,
    display: "GMT+10:00",
    label: "Sydney, Australia (Canberra, Melbourne)",
  },
  {
    timezone: "Pacific/Auckland",
    value: "Pacific/Auckland",
    offset: -46800,
    display: "GMT+12:00",
    label: "Auckland, New Zealand (Wellington)",
  },
];

export const frequencies = [
  { value: "One Time", label: "One Time" },
  { value: "Daily", label: "Daily" },
  { value: "Weekly", label: "Weekly" },
  { value: "Monthly", label: "Monthly" },
  { value: "Quarterly", label: "Quarterly" },
  { value: "Semiannually", label: "Semiannually" },
  { value: "Annually", label: "Annually" },
];

export const variants = {
  initial: {
    x: 300,
    opacity: 0,
    transition: { type: "spring" },
  },
  final: {
    x: 0,
    opacity: 1,
    transition: { type: "spring" },
    transitionEnd: { x: 0, y: 0, z: 0 },
  },
  exit: {
    x: -300,
    opacity: 0,
  },
  delay: {
    delay: 1,
  },
};
export const fooVariants = {
  enter: (direction) => {
    return {
      x: direction > 0 ? 300 : -3000,
      opacity: 0,
      transition: { type: "linear" },
    };
  },
  center: {
    x: 0,
    opacity: 1,
    transition: { type: "linear" },
    transitionEnd: { x: 0, y: 0, z: 0 },
  },
  exit: { x: -300, opacity: 0 },
  delay: {
    delay: 1,
  },
};

export const routeVariants = {
  enter: (items) => {
    if (!items.animate) {
      return {
        y: 0,
        opacity: 1,
        transition: { type: "linear" },
      };
    }
    if (items.direction === 0) {
      return {
        y: 0,
        opacity: 1,
        transition: { type: "linear" },
      };
    }
    return {
      x: items.direction > 0 ? 300 : -300,
      opacity: 0,
      transition: { type: "linear" },
    };
  },
  center: {
    x: 0,
    opacity: 1,
    transition: { type: "linear" },
    transitionEnd: { x: 0, y: 0, z: 0 },
  },
  exit: (items) => {
    return {
      x: items.direction < 0 ? 300 : -300,
      opacity: 0,
      transition: { type: "linear" },
    };
  },
  delay: {
    delay: 1,
  },
};

export const exampleevents = [
  {
    id: 0,
    title: "All Day Event very long title",
    allDay: true,
    start: new Date(2015, 3, 0),
    end: new Date(2015, 3, 1),
  },
  {
    id: 1,
    title: "Long Event",
    start: new Date(2015, 3, 7),
    end: new Date(2015, 3, 10),
  },

  {
    id: 2,
    title: "DTS STARTS",
    start: new Date(2016, 2, 13, 0, 0, 0),
    end: new Date(2016, 2, 20, 0, 0, 0),
  },

  {
    id: 3,
    title: "DTS ENDS",
    start: new Date(2016, 10, 6, 0, 0, 0),
    end: new Date(2016, 10, 13, 0, 0, 0),
  },

  {
    id: 4,
    title: "Some Event",
    start: new Date(2015, 3, 9, 0, 0, 0),
    end: new Date(2015, 3, 9, 0, 0, 0),
    allDay: true,
  },

  {
    id: 92,
    title: "Some Other Event",
    start: new Date(2015, 3, 9, 8, 0, 0),
    end: new Date(2015, 3, 10, 11, 30, 0),
  },
  {
    id: 5,
    title: "Conference",
    start: new Date(2015, 3, 11),
    end: new Date(2015, 3, 13),
    desc: "Big conference for important people",
  },
  {
    id: 6,
    title: "Meeting",
    start: new Date(2015, 3, 12, 10, 30, 0, 0),
    end: new Date(2015, 3, 12, 12, 30, 0, 0),
    desc: "Pre-meeting meeting, to prepare for the meeting",
  },
  {
    id: 7,
    title: "Lunch",
    start: new Date(2015, 3, 12, 12, 0, 0, 0),
    end: new Date(2015, 3, 12, 13, 0, 0, 0),
    desc: "Power lunch",
  },
  {
    id: 8,
    title: "Meeting",
    start: new Date(2015, 3, 12, 14, 0, 0, 0),
    end: new Date(2015, 3, 12, 15, 0, 0, 0),
  },

  {
    id: 9,
    title: "Happy Hour",
    start: new Date(2015, 3, 12, 17, 0, 0, 0),
    end: new Date(2015, 3, 12, 17, 30, 0, 0),
    desc: "Most important meal of the day",
  },
  {
    id: 10,
    title: "Dinner",
    start: new Date(2015, 3, 12, 20, 0, 0, 0),
    end: new Date(2015, 3, 12, 21, 0, 0, 0),
  },
  {
    id: 11,
    title: "Planning Meeting with Paige",
    start: new Date(2015, 3, 13, 8, 0, 0),
    end: new Date(2015, 3, 13, 10, 30, 0),
  },
  {
    id: 11.1,
    title: "Inconvenient Conference Call",
    start: new Date(2015, 3, 13, 9, 30, 0),
    end: new Date(2015, 3, 13, 12, 0, 0),
  },
  {
    id: 11.2,
    title: "Project Kickoff - Lou's Shoes",
    start: new Date(2015, 3, 13, 11, 30, 0),
    end: new Date(2015, 3, 13, 14, 0, 0),
  },
  {
    id: 11.3,
    title: "Quote Follow-up - Tea by Tina",
    start: new Date(2015, 3, 13, 15, 30, 0),
    end: new Date(2015, 3, 13, 16, 0, 0),
  },
  {
    id: 12,
    title: "Late Night Event",
    start: new Date(2015, 3, 17, 19, 30, 0),
    end: new Date(2015, 3, 18, 2, 0, 0),
  },
  {
    id: 12.5,
    title: "Late Same Night Event",
    start: new Date(2015, 3, 17, 19, 30, 0),
    end: new Date(2015, 3, 17, 23, 30, 0),
  },
  {
    id: 13,
    title: "Multi-day Event",
    start: new Date(2015, 3, 20, 19, 30, 0),
    end: new Date(2015, 3, 22, 2, 0, 0),
  },
  // {
  //   id: 14,
  //   title: 'Today',
  //   start: new Date(new Date().setHours(new Date().getHours() - 3)),
  //   end: new Date(new Date().setHours(new Date().getHours() + 3)),
  // },
  // {
  //   id: 15,
  //   title: 'Point in Time Event',
  //   start: new Date(),
  //   end: new Date(),
  // },
  {
    id: 16,
    title: "Video Record",
    start: new Date(2015, 3, 14, 15, 30, 0),
    end: new Date(2015, 3, 14, 19, 0, 0),
  },
  {
    id: 17,
    title: "Dutch Song Producing",
    start: new Date(2015, 3, 14, 16, 30, 0),
    end: new Date(2015, 3, 14, 20, 0, 0),
  },
  {
    id: 18,
    title: "Itaewon Meeting",
    start: new Date(2015, 3, 14, 16, 30, 0),
    end: new Date(2015, 3, 14, 17, 30, 0),
  },
  {
    id: 19,
    title: "Online Coding Test",
    start: new Date(2015, 3, 14, 17, 30, 0),
    end: new Date(2015, 3, 14, 20, 30, 0),
  },
  {
    id: 20,
    title: "An overlapped Event",
    start: new Date(2015, 3, 14, 17, 0, 0),
    end: new Date(2015, 3, 14, 18, 30, 0),
  },
  {
    id: 21,
    title: "Phone Interview",
    start: new Date(2015, 3, 14, 17, 0, 0),
    end: new Date(2015, 3, 14, 18, 30, 0),
  },
  {
    id: 22,
    title: "Cooking Class",
    start: new Date(2015, 3, 14, 17, 30, 0),
    end: new Date(2015, 3, 14, 19, 0, 0),
  },
  {
    id: 23,
    title: "Go to the gym",
    start: new Date(2015, 3, 14, 18, 30, 0),
    end: new Date(2015, 3, 14, 20, 0, 0),
  },
  {
    id: 24,
    title: "DST ends on this day (Europe)",
    start: new Date(2022, 9, 30, 0, 0, 0),
    end: new Date(2022, 9, 30, 4, 30, 0),
  },
  {
    id: 25,
    title: "DST ends on this day (America)",
    start: new Date(2022, 10, 6, 0, 0, 0),
    end: new Date(2022, 10, 6, 4, 30, 0),
  },
  {
    id: 26,
    title: "DST starts on this day (America)",
    start: new Date(2023, 2, 12, 0, 0, 0),
    end: new Date(2023, 2, 12, 4, 30, 0),
  },
  {
    id: 27,
    title: "DST starts on this day (Europe)",
    start: new Date(2023, 2, 26, 0, 0, 0),
    end: new Date(2023, 2, 26, 4, 30, 0),
  },
  // {
  //   id: 28,
  //   title: 'New Year\'s Day',
  //   start: new Date(2024, 0, 1),
  //   end: new Date(2024, 0, 1),
  //   allDay: true,
  // },
  // {
  //   id: 29,
  //   title: 'Independence Day',
  //   start: new Date(2024, 6, 4),
  //   end: new Date(2024, 6, 4),
  //   allDay: true,
  // },
  // {
  //   id: 30,
  //   title: 'Christmas Day',
  //   start: new Date(2024, 11, 25),
  //   end: new Date(2024, 11, 25),
  //   allDay: true,
  // },
];

export const majorHolidays = [
  {
    name: "New Year's Day",
    date: new Date(2024, 0, 1), // January 1, 2024
  },
  {
    name: "Martin Luther King Jr. Day",
    date: new Date(2024, 0, 15), // January 15, 2024
  },
  {
    name: "Presidents' Day",
    date: new Date(2024, 1, 19), // February 19, 2024
  },
  {
    name: "Memorial Day",
    date: new Date(2024, 4, 27), // May 27, 2024
  },
  {
    name: "Independence Day",
    date: new Date(2024, 6, 4), // July 4, 2024
  },
  {
    name: "Labor Day",
    date: new Date(2024, 8, 2), // September 2, 2024
  },
  {
    name: "Columbus Day",
    date: new Date(2024, 9, 14), // October 14, 2024
  },
  {
    name: "Veterans Day",
    date: new Date(2024, 10, 11), // November 11, 2024
  },
  {
    name: "Thanksgiving Day",
    date: new Date(2024, 10, 28), // November 28, 2024
  },
  {
    name: "Christmas Day",
    date: new Date(2024, 11, 25), // December 25, 2024
  },
];

export const countries = [
  { label: "Afghanistan", value: "Afghanistan" },
  { label: "Albania", value: "Albania" },
  { label: "Algeria", value: "Algeria" },
  { label: "Andorra", value: "Andorra" },
  { label: "Angola", value: "Angola" },
  { label: "Antigua and Barbuda", value: "Antigua and Barbuda" },
  { label: "Argentina", value: "Argentina" },
  { label: "Armenia", value: "Armenia" },
  { label: "Australia", value: "Australia" },
  { label: "Austria", value: "Austria" },
  { label: "Azerbaijan", value: "Azerbaijan" },
  { label: "Bahamas", value: "Bahamas" },
  { label: "Bahrain", value: "Bahrain" },
  { label: "Bangladesh", value: "Bangladesh" },
  { label: "Barbados", value: "Barbados" },
  { label: "Belarus", value: "Belarus" },
  { label: "Belgium", value: "Belgium" },
  { label: "Belize", value: "Belize" },
  { label: "Benin", value: "Benin" },
  { label: "Bhutan", value: "Bhutan" },
  { label: "Bolivia", value: "Bolivia" },
  { label: "Bosnia and Herzegovina", value: "Bosnia and Herzegovina" },
  { label: "Botswana", value: "Botswana" },
  { label: "Brazil", value: "Brazil" },
  { label: "Brunei", value: "Brunei" },
  { label: "Bulgaria", value: "Bulgaria" },
  { label: "Burkina Faso", value: "Burkina Faso" },
  { label: "Burundi", value: "Burundi" },
  { label: "Cabo Verde", value: "Cabo Verde" },
  { label: "Cambodia", value: "Cambodia" },
  { label: "Cameroon", value: "Cameroon" },
  { label: "Canada", value: "Canada" },
  { label: "Central African Republic", value: "Central African Republic" },
  { label: "Chad", value: "Chad" },
  { label: "Chile", value: "Chile" },
  { label: "China", value: "China" },
  { label: "Colombia", value: "Colombia" },
  { label: "Comoros", value: "Comoros" },
  { label: "Congo (Congo-Brazzaville)", value: "Congo (Congo-Brazzaville)" },
  { label: "Costa Rica", value: "Costa Rica" },
  { label: "Croatia", value: "Croatia" },
  { label: "Cuba", value: "Cuba" },
  { label: "Cyprus", value: "Cyprus" },
  { label: "Czechia (Czech Republic)", value: "Czechia (Czech Republic)" },
  {
    label: "Democratic Republic of the Congo",
    value: "Democratic Republic of the Congo",
  },
  { label: "Denmark", value: "Denmark" },
  { label: "Djibouti", value: "Djibouti" },
  { label: "Dominica", value: "Dominica" },
  { label: "Dominican Republic", value: "Dominican Republic" },
  { label: "Ecuador", value: "Ecuador" },
  { label: "Egypt", value: "Egypt" },
  { label: "El Salvador", value: "El Salvador" },
  { label: "Equatorial Guinea", value: "Equatorial Guinea" },
  { label: "Eritrea", value: "Eritrea" },
  { label: "Estonia", value: "Estonia" },
  {
    label: 'Eswatini (fmr. "Swaziland")',
    value: 'Eswatini (fmr. "Swaziland")',
  },
  { label: "Ethiopia", value: "Ethiopia" },
  { label: "Fiji", value: "Fiji" },
  { label: "Finland", value: "Finland" },
  { label: "France", value: "France" },
  { label: "Gabon", value: "Gabon" },
  { label: "Gambia", value: "Gambia" },
  { label: "Georgia", value: "Georgia" },
  { label: "Germany", value: "Germany" },
  { label: "Ghana", value: "Ghana" },
  { label: "Greece", value: "Greece" },
  { label: "Grenada", value: "Grenada" },
  { label: "Guatemala", value: "Guatemala" },
  { label: "Guinea", value: "Guinea" },
  { label: "Guinea-Bissau", value: "Guinea-Bissau" },
  { label: "Guyana", value: "Guyana" },
  { label: "Haiti", value: "Haiti" },
  { label: "Honduras", value: "Honduras" },
  { label: "Hungary", value: "Hungary" },
  { label: "Iceland", value: "Iceland" },
  { label: "India", value: "India" },
  { label: "Indonesia", value: "Indonesia" },
  { label: "Iran", value: "Iran" },
  { label: "Iraq", value: "Iraq" },
  { label: "Ireland", value: "Ireland" },
  { label: "Israel", value: "Israel" },
  { label: "Italy", value: "Italy" },
  { label: "Jamaica", value: "Jamaica" },
  { label: "Japan", value: "Japan" },
  { label: "Jordan", value: "Jordan" },
  { label: "Kazakhstan", value: "Kazakhstan" },
  { label: "Kenya", value: "Kenya" },
  { label: "Kiribati", value: "Kiribati" },
  { label: "Kuwait", value: "Kuwait" },
  { label: "Kyrgyzstan", value: "Kyrgyzstan" },
  { label: "Laos", value: "Laos" },
  { label: "Latvia", value: "Latvia" },
  { label: "Lebanon", value: "Lebanon" },
  { label: "Lesotho", value: "Lesotho" },
  { label: "Liberia", value: "Liberia" },
  { label: "Libya", value: "Libya" },
  { label: "Liechtenstein", value: "Liechtenstein" },
  { label: "Lithuania", value: "Lithuania" },
  { label: "Luxembourg", value: "Luxembourg" },
  { label: "Madagascar", value: "Madagascar" },
  { label: "Malawi", value: "Malawi" },
  { label: "Malaysia", value: "Malaysia" },
  { label: "Maldives", value: "Maldives" },
  { label: "Mali", value: "Mali" },
  { label: "Malta", value: "Malta" },
  { label: "Marshall Islands", value: "Marshall Islands" },
  { label: "Mauritania", value: "Mauritania" },
  { label: "Mauritius", value: "Mauritius" },
  { label: "Mexico", value: "Mexico" },
  { label: "Micronesia", value: "Micronesia" },
  { label: "Moldova", value: "Moldova" },
  { label: "Monaco", value: "Monaco" },
  { label: "Mongolia", value: "Mongolia" },
  { label: "Montenegro", value: "Montenegro" },
  { label: "Morocco", value: "Morocco" },
  { label: "Mozambique", value: "Mozambique" },
  { label: "Myanmar (formerly Burma)", value: "Myanmar (formerly Burma)" },
  { label: "Namibia", value: "Namibia" },
  { label: "Nauru", value: "Nauru" },
  { label: "Nepal", value: "Nepal" },
  { label: "Netherlands", value: "Netherlands" },
  { label: "New Zealand", value: "New Zealand" },
  { label: "Nicaragua", value: "Nicaragua" },
  { label: "Niger", value: "Niger" },
  { label: "Nigeria", value: "Nigeria" },
  { label: "North Korea", value: "North Korea" },
  { label: "North Macedonia", value: "North Macedonia" },
  { label: "Norway", value: "Norway" },
  { label: "Oman", value: "Oman" },
  { label: "Pakistan", value: "Pakistan" },
  { label: "Palau", value: "Palau" },
  { label: "Palestine State", value: "Palestine State" },
  { label: "Panama", value: "Panama" },
  { label: "Papua New Guinea", value: "Papua New Guinea" },
  { label: "Paraguay", value: "Paraguay" },
  { label: "Peru", value: "Peru" },
  { label: "Philippines", value: "Philippines" },
  { label: "Poland", value: "Poland" },
  { label: "Portugal", value: "Portugal" },
  { label: "Qatar", value: "Qatar" },
  { label: "Romania", value: "Romania" },
  { label: "Russia", value: "Russia" },
  { label: "Rwanda", value: "Rwanda" },
  { label: "Saint Kitts and Nevis", value: "Saint Kitts and Nevis" },
  { label: "Saint Lucia", value: "Saint Lucia" },
  {
    label: "Saint Vincent and the Grenadines",
    value: "Saint Vincent and the Grenadines",
  },
  { label: "Samoa", value: "Samoa" },
  { label: "San Marino", value: "San Marino" },
  { label: "Sao Tome and Principe", value: "Sao Tome and Principe" },
  { label: "Saudi Arabia", value: "Saudi Arabia" },
  { label: "Senegal", value: "Senegal" },
  { label: "Serbia", value: "Serbia" },
  { label: "Seychelles", value: "Seychelles" },
  { label: "Sierra Leone", value: "Sierra Leone" },
  { label: "Singapore", value: "Singapore" },
  { label: "Slovakia", value: "Slovakia" },
  { label: "Slovenia", value: "Slovenia" },
  { label: "Solomon Islands", value: "Solomon Islands" },
  { label: "Somalia", value: "Somalia" },
  { label: "South Africa", value: "South Africa" },
  { label: "South Korea", value: "South Korea" },
  { label: "South Sudan", value: "South Sudan" },
  { label: "Spain", value: "Spain" },
  { label: "Sri Lanka", value: "Sri Lanka" },
  { label: "Sudan", value: "Sudan" },
  { label: "Suriname", value: "Suriname" },
  { label: "Sweden", value: "Sweden" },
  { label: "Switzerland", value: "Switzerland" },
  { label: "Syria", value: "Syria" },
  { label: "Taiwan", value: "Taiwan" },
  { label: "Tajikistan", value: "Tajikistan" },
  { label: "Tanzania", value: "Tanzania" },
  { label: "Thailand", value: "Thailand" },
  { label: "Timor-Leste", value: "Timor-Leste" },
  { label: "Togo", value: "Togo" },
  { label: "Tonga", value: "Tonga" },
  { label: "Trinidad and Tobago", value: "Trinidad and Tobago" },
  { label: "Tunisia", value: "Tunisia" },
  { label: "Turkey", value: "Turkey" },
  { label: "Turkmenistan", value: "Turkmenistan" },
  { label: "Tuvalu", value: "Tuvalu" },
  { label: "Uganda", value: "Uganda" },
  { label: "Ukraine", value: "Ukraine" },
  { label: "United Arab Emirates", value: "United Arab Emirates" },
  { label: "United Kingdom", value: "United Kingdom" },
  { label: "United States", value: "United States" },
  { label: "Uruguay", value: "Uruguay" },
  { label: "Uzbekistan", value: "Uzbekistan" },
  { label: "Vanuatu", value: "Vanuatu" },
  { label: "Vatican City", value: "Vatican City" },
  { label: "Venezuela", value: "Venezuela" },
  { label: "Vietnam", value: "Vietnam" },
  { label: "Yemen", value: "Yemen" },
  { label: "Zambia", value: "Zambia" },
  { label: "Zimbabwe", value: "Zimbabwe" },
];

export const countryphonecodes = [
  { label: "AF +93", value: "+93" },
  { label: "AL +355", value: "+355" },
  { label: "DZ +213", value: "+213" },
  { label: "AS +1-684", value: "+1-684" },
  { label: "AD +376", value: "+376" },
  { label: "AO +244", value: "+244" },
  { label: "AI +1-264", value: "+1-264" },
  { label: "AG +1-268", value: "+1-268" },
  { label: "AR +54", value: "+54" },
  { label: "AM +374", value: "+374" },
  { label: "AW +297", value: "+297" },
  { label: "AU +61", value: "+61" },
  { label: "AT +43", value: "+43" },
  { label: "AZ +994", value: "+994" },
  { label: "BS +1-242", value: "+1-242" },
  { label: "BH +973", value: "+973" },
  { label: "BD +880", value: "+880" },
  { label: "BB +1-246", value: "+1-246" },
  { label: "BY +375", value: "+375" },
  { label: "BE +32", value: "+32" },
  { label: "BZ +501", value: "+501" },
  { label: "BJ +229", value: "+229" },
  { label: "BM +1-441", value: "+1-441" },
  { label: "BT +975", value: "+975" },
  { label: "BO +591", value: "+591" },
  { label: "BA +387", value: "+387" },
  { label: "BW +267", value: "+267" },
  { label: "BR +55", value: "+55" },
  { label: "IO +246", value: "+246" },
  { label: "VG +1-284", value: "+1-284" },
  { label: "BN +673", value: "+673" },
  { label: "BG +359", value: "+359" },
  { label: "BF +226", value: "+226" },
  { label: "BI +257", value: "+257" },
  { label: "KH +855", value: "+855" },
  { label: "CM +237", value: "+237" },
  { label: "CA +1", value: "+1" },
  { label: "CV +238", value: "+238" },
  { label: "KY +1-345", value: "+1-345" },
  { label: "CF +236", value: "+236" },
  { label: "TD +235", value: "+235" },
  { label: "CL +56", value: "+56" },
  { label: "CN +86", value: "+86" },
  { label: "CO +57", value: "+57" },
  { label: "KM +269", value: "+269" },
  { label: "CK +682", value: "+682" },
  { label: "CR +506", value: "+506" },
  { label: "HR +385", value: "+385" },
  { label: "CU +53", value: "+53" },
  { label: "CY +357", value: "+357" },
  { label: "CZ +420", value: "+420" },
  { label: "CD +243", value: "+243" },
  { label: "DK +45", value: "+45" },
  { label: "DJ +253", value: "+253" },
  { label: "DM +1-767", value: "+1-767" },
  { label: "DO +1-809", value: "+1-809" },
  { label: "DO +1-829", value: "+1-829" },
  { label: "DO +1-849", value: "+1-849" },
  { label: "EC +593", value: "+593" },
  { label: "EG +20", value: "+20" },
  { label: "SV +503", value: "+503" },
  { label: "GQ +240", value: "+240" },
  { label: "ER +291", value: "+291" },
  { label: "EE +372", value: "+372" },
  { label: "ET +251", value: "+251" },
  { label: "FK +500", value: "+500" },
  { label: "FO +298", value: "+298" },
  { label: "FJ +679", value: "+679" },
  { label: "FI +358", value: "+358" },
  { label: "FR +33", value: "+33" },
  { label: "GF +594", value: "+594" },
  { label: "PF +689", value: "+689" },
  { label: "GA +241", value: "+241" },
  { label: "GM +220", value: "+220" },
  { label: "GE +995", value: "+995" },
  { label: "DE +49", value: "+49" },
  { label: "GH +233", value: "+233" },
  { label: "GI +350", value: "+350" },
  { label: "GR +30", value: "+30" },
  { label: "GL +299", value: "+299" },
  { label: "GD +1-473", value: "+1-473" },
  { label: "GP +590", value: "+590" },
  { label: "GU +1-671", value: "+1-671" },
  { label: "GT +502", value: "+502" },
  { label: "GN +224", value: "+224" },
  { label: "GW +245", value: "+245" },
  { label: "GY +592", value: "+592" },
  { label: "HT +509", value: "+509" },
  { label: "HN +504", value: "+504" },
  { label: "HU +36", value: "+36" },
  { label: "IS +354", value: "+354" },
  { label: "IN +91", value: "+91" },
  { label: "ID +62", value: "+62" },
  { label: "IR +98", value: "+98" },
  { label: "IQ +964", value: "+964" },
  { label: "IE +353", value: "+353" },
  { label: "IL +972", value: "+972" },
  { label: "IT +39", value: "+39" },
  { label: "JM +1-876", value: "+1-876" },
  { label: "JP +81", value: "+81" },
  { label: "JO +962", value: "+962" },
  { label: "KZ +7", value: "+7" },
  { label: "KE +254", value: "+254" },
  { label: "KI +686", value: "+686" },
  { label: "KW +965", value: "+965" },
  { label: "KG +996", value: "+996" },
  { label: "LA +856", value: "+856" },
  { label: "LV +371", value: "+371" },
  { label: "LB +961", value: "+961" },
  { label: "LS +266", value: "+266" },
  { label: "LR +231", value: "+231" },
  { label: "LY +218", value: "+218" },
  { label: "LI +423", value: "+423" },
  { label: "LT +370", value: "+370" },
  { label: "LU +352", value: "+352" },
  { label: "MG +261", value: "+261" },
  { label: "MW +265", value: "+265" },
  { label: "MY +60", value: "+60" },
  { label: "MV +960", value: "+960" },
  { label: "ML +223", value: "+223" },
  { label: "MT +356", value: "+356" },
  { label: "MH +692", value: "+692" },
  { label: "MR +222", value: "+222" },
  { label: "MU +230", value: "+230" },
  { label: "YT +262", value: "+262" },
  { label: "MX +52", value: "+52" },
  { label: "FM +691", value: "+691" },
  { label: "MD +373", value: "+373" },
  { label: "MC +377", value: "+377" },
  { label: "MN +976", value: "+976" },
  { label: "ME +382", value: "+382" },
  { label: "MS +1-664", value: "+1-664" },
  { label: "MA +212", value: "+212" },
  { label: "MZ +258", value: "+258" },
  { label: "MM +95", value: "+95" },
  { label: "NA +264", value: "+264" },
  { label: "NR +674", value: "+674" },
  { label: "NP +977", value: "+977" },
  { label: "NL +31", value: "+31" },
  { label: "NC +687", value: "+687" },
  { label: "NZ +64", value: "+64" },
  { label: "NI +505", value: "+505" },
  { label: "NE +227", value: "+227" },
  { label: "NG +234", value: "+234" },
  { label: "NU +683", value: "+683" },
  { label: "NF +672", value: "+672" },
  { label: "KP +850", value: "+850" },
  { label: "MK +389", value: "+389" },
  { label: "MP +1-670", value: "+1-670" },
  { label: "NO +47", value: "+47" },
  { label: "OM +968", value: "+968" },
  { label: "PK +92", value: "+92" },
  { label: "PW +680", value: "+680" },
  { label: "PS +970", value: "+970" },
  { label: "PA +507", value: "+507" },
  { label: "PG +675", value: "+675" },
  { label: "PY +595", value: "+595" },
  { label: "PE +51", value: "+51" },
  { label: "PH +63", value: "+63" },
  { label: "PL +48", value: "+48" },
  { label: "PT +351", value: "+351" },
  { label: "PR +1-787", value: "+1-787" },
  { label: "PR +1-939", value: "+1-939" },
  { label: "QA +974", value: "+974" },
  { label: "CG +242", value: "+242" },
  { label: "RE +262", value: "+262" },
  { label: "RO +40", value: "+40" },
  { label: "RU +7", value: "+7" },
  { label: "RW +250", value: "+250" },
  { label: "BL +590", value: "+590" },
  { label: "SH +290", value: "+290" },
  { label: "KN +1-869", value: "+1-869" },
  { label: "LC +1-758", value: "+1-758" },
  { label: "MF +590", value: "+590" },
  { label: "PM +508", value: "+508" },
  { label: "VC +1-784", value: "+1-784" },
  { label: "WS +685", value: "+685" },
  { label: "SM +378", value: "+378" },
  { label: "ST +239", value: "+239" },
  { label: "SA +966", value: "+966" },
  { label: "SN +221", value: "+221" },
  { label: "RS +381", value: "+381" },
  { label: "SC +248", value: "+248" },
  { label: "SL +232", value: "+232" },
  { label: "SG +65", value: "+65" },
  { label: "SX +1-721", value: "+1-721" },
  { label: "SK +421", value: "+421" },
  { label: "SI +386", value: "+386" },
  { label: "SB +677", value: "+677" },
  { label: "SO +252", value: "+252" },
  { label: "ZA +27", value: "+27" },
  { label: "KR +82", value: "+82" },
  { label: "SS +211", value: "+211" },
  { label: "ES +34", value: "+34" },
  { label: "LK +94", value: "+94" },
  { label: "SD +249", value: "+249" },
  { label: "SR +597", value: "+597" },
  { label: "SJ +47", value: "+47" },
  { label: "SZ +268", value: "+268" },
  { label: "SE +46", value: "+46" },
  { label: "CH +41", value: "+41" },
  { label: "SY +963", value: "+963" },
  { label: "TW +886", value: "+886" },
  { label: "TJ +992", value: "+992" },
  { label: "TZ +255", value: "+255" },
  { label: "TH +66", value: "+66" },
  { label: "TL +670", value: "+670" },
  { label: "TG +228", value: "+228" },
  { label: "TK +690", value: "+690" },
  { label: "TO +676", value: "+676" },
  { label: "TT +1-868", value: "+1-868" },
  { label: "TN +216", value: "+216" },
  { label: "TR +90", value: "+90" },
  { label: "TM +993", value: "+993" },
  { label: "TC +1-649", value: "+1-649" },
  { label: "TV +688", value: "+688" },
  { label: "UG +256", value: "+256" },
  { label: "UA +380", value: "+380" },
  { label: "AE +971", value: "+971" },
  { label: "GB +44", value: "+44" },
  { label: "US +1", value: "+1" },
  { label: "UY +598", value: "+598" },
  { label: "UZ +998", value: "+998" },
  { label: "VU +678", value: "+678" },
  { label: "VA +379", value: "+379" },
  { label: "VE +58", value: "+58" },
  { label: "VN +84", value: "+84" },
  { label: "WF +681", value: "+681" },
  { label: "EH +212", value: "+212" },
  { label: "YE +967", value: "+967" },
  { label: "ZM +260", value: "+260" },
  { label: "ZW +263", value: "+263" },
];

export const surveydetailsviz = {
  type: "verticalbarchart",
  designSettings: {
    hasDataLabels: true,
    dataLabelValue: {
      show: true,
      color: "#616565",
      position: "end",
      alignment: "end",
      offset: 0,
      sigFigs: 0,
      fontSize: 14,
    },
    dataLabelLabel: { show: false },
    dataLabelPercent: { show: false },
    hasTitle: true,
    hasSubtitle: true,
    artificialTitle: true,
    titleSize: null,
    titleAlignment: "start",
    titleBackgroundColor: "#ffffff",
    titleColor: "#616565",
    titleBorderRadius: 0,
    hasLegend: false,
    hasLegendTitle: false,
    legendTitle: "",
    legendPosition: "top",
    legendFontSize: 14,
    legendLabel: true,
    legendValue: false,
    legendPercent: false,
    drawXLines: true,
    drawYLines: true,
    paletteId: "default",
    lineWidth: 1,
    pointRadius: 3,
    lineGraphWidth: 3,
    lineGraphColor: "#15bcc7",
    uniformPointColor: "#15bcc7",
    hasUniformPointColor: true,
    sortData: "none",
    barPercentage: 0.9,
    borderRadius: 10,
    cutout: 50,
    byPercent: false,
    byPercentOfTotal: false,
    answerType: "participation count",
    stacked: false,
    hasAxisTitles: false,
    autoGenAxisTitles: true,
    YAxisTitle: "",
    XAxisTitle: "",
    axisTitleFontSize: 12,
    split: "",
    lineTension: 0.3,
    graphMax: null,
    graphMin: null,
    undefinedLabel: "Undefined",
    zoomOut: 3,
    segLabelReplacements: {},
    axisLabelReplacements: {},
    textboxes: [],
    showUndefined: true,
    showN: false,
    NLabel: "N",
    useTagColor: false,
    showNonParticipating: false,
    afterSort: false,
    hideNonChosen: false,
    dynamic: false,
    userConstraints: [],
    showFilterSubtitle: true,
    sigFigs: 0,
    hideXticks: false,
    hideYticks: false,
    hideTicks: true,
    hideBorder: false,
    containerShadow: true,
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 0,
    paddingRight: 0,
    syncPadding: true,
    paddingTopTitle: 0,
    paddingBottomTitle: 0,
    paddingLeftTitle: 0,
    paddingRightTitle: 0,
    syncTitlePadding: true,
    includeComment: true,
  },
  title: "Responses",
  // pivotString: "hour taken",
  projectIdsArray: [],
  tagIdsArray: [],
  filters: "{}",

  dynamicQs: [],
  id: "_4zdoi06",
  questionTags: [],
  titleValue:
    "How likely are you to recommend Reaction's solutions?  | How likely are you to recommend Reaction's solutions?",
};

export const engagementsviz = {
  type: "donutchart",
  designSettings: {
    hasDataLabels: true,
    dataLabelValue: {
      show: false,
      color: "black",
      position: "center",
      alignment: "center",
      offset: 0,
      sigFigs: 0,
      fontSize: 14,
    },
    dataLabelLabel: { show: false },
    dataLabelPercent: { show: false },
    hasTitle: true,
    hasSubtitle: true,
    artificialTitle: true,
    titleSize: null,
    titleAlignment: "start",
    titleBackgroundColor: "#ffffff",
    titleColor: "#616565",
    titleBorderRadius: 0,
    hasLegend: true,
    hasLegendTitle: true,
    legendTitle: "",
    legendTextShapeSize: 15,
    legendTitleColor: "black",
    legendFontColor: "black",
    legendTextFontSize: 11,
    legendPosition: "left",
    legendFontSize: 10,
    legendLabel: true,
    legendValue: false,
    legendPercent: true,
    drawXLines: true,
    drawYLines: true,
    paletteId: "default",
    lineWidth: 1,
    pointRadius: 3,
    lineGraphWidth: 3,
    lineGraphColor: "#15bcc7",
    uniformPointColor: "#15bcc7",
    hasUniformPointColor: true,
    sortData: "none",
    barPercentage: 1,
    borderRadius: 0,
    cutout: 70,
    byPercent: false,
    byPercentOfTotal: false,
    answerType: "participation count",
    stacked: false,
    hasAxisTitles: false,
    autoGenAxisTitles: true,
    YAxisTitle: "",
    XAxisTitle: "",
    axisTitleFontSize: 12,
    split: "",
    lineTension: 0.3,
    graphMax: null,
    graphMin: null,
    undefinedLabel: "Undefined",
    zoomOut: 0,
    segLabelReplacements: {},
    axisLabelReplacements: {},
    textboxes: [],
    showUndefined: true,
    showN: false,
    NLabel: "N",
    useTagColor: false,
    showNonParticipating: false,
    afterSort: false,
    hideNonChosen: false,
    dynamic: false,
    userConstraints: [],
    showFilterSubtitle: true,
    sigFigs: 0,
    hideXticks: false,
    hideYticks: false,
    hideTicks: true,
    hideBorder: false,
    containerShadow: true,
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 0,
    paddingRight: 0,
    syncPadding: true,
    paddingTopTitle: 0,
    paddingBottomTitle: 0,
    paddingLeftTitle: 0,
    paddingRightTitle: 0,
    syncTitlePadding: true,
    includeComment: true,
  },
  title: "Responses",
  // pivotString: "hour taken",
  projectIdsArray: [],
  tagIdsArray: [],
  filters: "{}",

  dynamicQs: [],
  id: "_4zdoi06",
  questionTags: [],
  titleValue:
    "",
};

export const fonts = [
  {
    label: <div style={{ fontFamily: "Arial, sans-serif" }}>Arial</div>,
    value: "Arial, sans-serif",
  },
  {
    label: (
      <div style={{ fontFamily: '"Brush Script MT", cursive' }}>
        Brush Script
      </div>
    ),
    value: '"Brush Script MT", cursive',
  },
  {
    label: <div style={{ fontFamily: "Calibri, sans-serif" }}>Calibri</div>,
    value: "Calibri, sans-serif",
  },
  {
    label: (
      <div style={{ fontFamily: '"Century Gothic", sans-serif' }}>
        Century Gothic
      </div>
    ),
    value: '"Century Gothic", sans-serif',
  },
  {
    label: (
      <div style={{ fontFamily: '"Courier New", monospace' }}>Courier New</div>
    ),
    value: '"Courier New", monospace',
  },
  {
    label: (
      <div style={{ fontFamily: '"Comic Sans MS", sans-serif' }}>
        Comic Sans MS
      </div>
    ),
    value: '"Comic Sans MS", sans-serif',
  },
  {
    label: <div style={{ fontFamily: "Garamond, serif" }}>Garamond</div>,
    value: "Garamond, serif",
  },
  {
    label: <div style={{ fontFamily: "Georgia, serif" }}>Georgia</div>,
    value: "Georgia, serif",
  },
  {
    label: <div style={{ fontFamily: "Helvetica, sans-serif" }}>Helvetica</div>,
    value: "Helvetica, sans-serif",
  },
  {
    label: <div style={{ fontFamily: "Impact, sans-serif" }}>Impact</div>,
    value: "Impact, sans-serif",
  },
  {
    label: (
      <div style={{ fontFamily: '"Lucida Console", monospace' }}>
        Lucida Console
      </div>
    ),
    value: '"Lucida Console", monospace',
  },
  {
    label: (
      <div style={{ fontFamily: "Montserrat, sans-serif" }}>Montserrat</div>
    ),
    value: "Montserrat, sans-serif",
  },
  {
    label: (
      <div style={{ fontFamily: '"Palatino Linotype", serif' }}>
        Palatino Linotype
      </div>
    ),
    value: '"Palatino Linotype", serif',
  },
  {
    label: <div style={{ fontFamily: "Poppins, sans-serif" }}>Poppins</div>,
    value: "Poppins, sans-serif",
  },
  {
    label: <div style={{ fontFamily: '"PT Sans", sans-serif' }}>PT Sans</div>,
    value: '"PT Sans", sans-serif',
  },
  {
    label: <div style={{ fontFamily: "Questrial, sans-serif" }}>Questrial</div>,
    value: "Questrial, sans-serif",
  },
  {
    label: <div style={{ fontFamily: "Tahoma, sans-serif" }}>Tahoma</div>,
    value: "Tahoma, sans-serif",
  },
  {
    label: (
      <div style={{ fontFamily: '"Times New Roman", serif' }}>
        Times New Roman
      </div>
    ),
    value: '"Times New Roman", serif',
  },
  {
    label: <div style={{ fontFamily: "Trebuchet, sans-serif" }}>Trebuchet</div>,
    value: "Trebuchet, sans-serif",
  },
  {
    label: <div style={{ fontFamily: "Verdana, sans-serif" }}>Verdana</div>,
    value: "Verdana, sans-serif",
  },
];

export const iconshapes = [
  {
    label: (
      <div style={{ display: "flex", gap: ".5rem" }}>
        <i className="bi-square-fill"></i> Square
      </div>
    ),
    value: "bi-square-fill",
  },
  {
    label: (
      <div style={{ display: "flex", gap: ".5rem" }}>
        <i className="bi-circle-fill"></i> Circle
      </div>
    ),
    value: "bi-circle-fill",
  },
  {
    label: (
      <div style={{ display: "flex", gap: ".5rem" }}>
        <i className="bi-triangle-fill"></i> Triange
      </div>
    ),
    value: "bi-triangle-fill",
  },
  {
    label: (
      <div style={{ display: "flex", gap: ".5rem" }}>
        <i className="bi-star-fill"></i> Star
      </div>
    ),
    value: "bi-star-fill",
  },
  {
    label: (
      <div style={{ display: "flex", gap: ".5rem" }}>
        <i className="bi-heart-fill"></i> Heart
      </div>
    ),
    value: "bi-heart-fill",
  },
];
export const ReactionStandardSurveySettings = {
  //Global
  theme: "standard",
  baseColor: "#15bcc7",
  secondaryColor: "#e9e9e9",
  backgroundColor: "#f9fbfc",
  colorBank: [],
  firstPageInstructions: false,
  surveyInstructions: true,
  pageSettings: {},
  useOwnColors: false,
  backgroundImage: "",
  questionGap: 10,
  //logo
  logoAlign: "left",
  logoSize: 50,
  logoOnEveryPage: false,
  logoBackgroundColor: "transparent",
  logoBoxShadow: false,
  logoRadius: ".5rem",
  //progress bar
  showLogo: true,
  showProgBar: true,
  showProgQs: true,
  progBarColor: null,
  //Header
  showHeader: true,
  headerFirstPageOnly: false,
  headerBackgroundColor: "",
  headerRadius: 0.5,
  headerShadow: "",
  headerGap: 1,
  //Button
  buttonColor: null,
  buttonStyle: "fill",
  buttonShadow:
    "rgba(0, 0, 0, 0.12) 0px 1px 5px, rgba(0, 0, 0, 0.24) 0px 1px 5px",
  showPageProg: true,
  next: "",
  back: "",
  finish: "",
  // Questions
  boxShadow: "rgba(0, 0, 0, 0.12) 0px 1px 5px, rgba(0, 0, 0, 0.24) 0px 1px 5px",
  questionNameSize: 12,
  questionInstructionSize: 9,
  instructionTextColor: "#d8d9d9",
  questionTextColor: "",
  multipleChoiceBars: false,
  answerColor: "",
  answerColorActive: "#FFFFFF",
  answerTextSize: 12,
  questionBackgroundColor: "#ffffff",
  //Finished
  finishWithoutFinishing: false,
  returnable: false,
  confetti: true,
  confettiColors: [],
};

export const ReactionSimpleSurveySettings = {
  //Global
  theme: "simple",
  baseColor: "#15bcc7",
  secondaryColor: "#e9e9e9",
  backgroundColor: "#ffffff",
  colorBank: [],
  firstPageInstructions: false,
  surveyInstructions: true,
  pageSettings: {},
  useOwnColors: false,
  backgroundImage: "",
  questionGap: 4,
  //logo
  logoAlign: "left",
  logoSize: 50,
  logoOnEveryPage: false,
  logoBackgroundColor: "transparent",
  logoBoxShadow: "",
  logoRadius: ".5rem",
  //progress bar
  showLogo: true,
  showProgBar: true,
  showProgQs: true,
  progBarColor: null,
  //Header
  showHeader: true,
  headerFirstPageOnly: false,
  headerBackgroundColor: false,
  headerRadius: 0.5,
  headerShadow: "",
  headerGap: 1,
  //Button
  buttonColor: null,
  buttonStyle: "fill",
  buttonShadow: "",
  showPageProg: true,
  // Questions
  boxShadow: "",
  questionNameSize: 15,
  questionInstructionSize: 13,
  instructionTextColor: "#d8d9d9",
  questionTextColor: "#616565",
  multipleChoiceBars: true,
  answerColor: "#616565",
  answerColorActive: "#FFFFFF",
  answerTextSize: 15,
  questionBackgroundColor: "#ffffff",
  //Finished
  finishWithoutFinishing: false,
  returnable: false,
  confetti: true,
  confettiColors: [],
};

export const ReactionGradientSurveySettings = {
  //Global
  theme: "gradient",
  baseColor: "#15bcc7",
  secondaryColor: "#e9e9e9",
  backgroundColor: "#ffffff",
  colorBank: [],
  firstPageInstructions: false,
  surveyInstructions: true,
  pageSettings: {},
  useOwnColors: false,
  backgroundImage: "linear-gradient(to bottom right, #ffffff, #7fcfd3)",
  questionGap: 4,
  //logo
  logoAlign: "left",
  logoSize: 50,
  logoOnEveryPage: false,
  logoBackgroundColor: "transparent",
  logoBoxShadow: "",
  logoRadius: ".5rem",
  //progress bar
  showLogo: true,
  showProgBar: true,
  showProgQs: true,
  progBarColor: null,
  //Header
  showHeader: true,
  headerFirstPageOnly: false,
  headerBackgroundColor: "#FFFFFF",
  headerRadius: 0.5,
  headerShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
  headerGap: 1,
  //Button
  buttonColor: null,
  buttonStyle: "fill",
  buttonShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
  showPageProg: true,
  // Questions
  boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
  questionNameSize: 15,
  questionInstructionSize: 13,
  instructionTextColor: "#d8d9d9",
  questionTextColor: "#616565",
  multipleChoiceBars: true,
  answerColor: "#616565",
  answerColorActive: "#FFFFFF",
  answerTextSize: 15,
  questionBackgroundColor: "#ffffff",
  //Finished
  finishWithoutFinishing: false,
  returnable: false,
  confetti: true,
  confettiColors: [],
};

export const shadows = [
  { value: "rgba(0, 0, 0, 0.24) 0px 3px 8px", label: "Normal" },
  { value: "rgba(0, 0, 0, 0.15) 0px 5px 15px", label: "Wide" },
  { value: "rgba(0, 0, 0, 0.3) 0px 3px 8px", label: "Darker" },
  { value: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px", label: "Light" },
  { value: "rgba(0, 0, 0, 0.15) 0px 3px 10px", label: "Small" },
  {
    value: "rgba(0, 0, 0, 0.12) 0px 1px 5px, rgba(0, 0, 0, 0.24) 0px 1px 5px",
    label: "Thin Border",
  },
  {
    value: "rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px",
    label: "Heavy Border",
  },
  {
    value: "",
    label: "No Shadow",
  },
];

export const GenericChartSettings = {
  //chart
  colors: undefined,
  paletteId: "default",
  gradient: undefined,
  sortData: "none",
  cutout: 50,
  byPercent: false,
  byPercentOfTotal: false,
  answerType: "answer count",
  stacked: false,
  split: "",
  graphMax: null,
  graphMin: null,
  zoomOut: 0,
  undefinedLabel: "Undefined",
  segLabelReplacements: {},
  axisLabelReplacements: {},
  textboxes: [],
  showUndefined: true,
  useTagColor: false,
  showNonParticipating: false,
  afterSort: false,
  hideNonChosen: false,
  dynamic: false,
  userConstraints: [],
  sigFigs: 0,
  containerShadow: true,
  paddingTop: 0,
  paddingBottom: 0,
  paddingLeft: 0,
  paddingRight: 0,
  syncPadding: true,
  paddingTopTitle: 0,
  paddingBottomTitle: 0,
  paddingLeftTitle: 0,
  paddingRightTitle: 0,
  syncTitlePadding: true,
  includeComment: true,
  //chart title
  hasTitle: true,
  titleSize: null,
  titleAlignment: "center",
  titleBackgroundColor: "#ffffff",
  titleColor: "",
  titleBorderRadius: 0,
  //subtitle
  hasSubtitle: true,
  artificialTitle: true,
  showFilterSubtitle: true,
  //data labels
  hasDataLabels: true,
  dataLabelValue: {
    show: true,
    color: "#616565",
    position: "end",
    alignment: "end",
    offset: 0,
    sigFigs: 0,
    fontSize: 14,
  },
  dataLabelLabel: {
    show: false,
  },
  dataLabelPercent: {
    show: false,
  },
  //legend
  hasLegend: false,
  hasLegendTitle: false,
  legendTitle: "",
  legendPosition: "top",
  legendFontSize: 14,
  legendLabel: true,
  legendValue: false,
  legendPercent: false,
  legendTextFontSize: "12pt",
  legendTextFont: "Poppins, sans-serif",
  legendTextShapeSize: 19,
  legendTextShape: "bi-circle-fill",
  //axes
  drawXLines: true,
  drawYLines: true,
  hasAxisTitles: true,
  autoGenAxisTitles: true,
  YAxisTitle: "Answer Count",
  XAxisTitle: "Choices",
  axisTitleFontSize: 12,
  hideXticks: false,
  hideYticks: false,
  hideTicks: true,
  hideBorder: false,
  //line chart
  lineWidth: 1,
  pointRadius: 3,
  lineGraphWidth: 3,
  lineGraphColor: "#2A627C",
  uniformPointColor: "#2A627C",
  hasUniformPointColor: true,
  lineTension: 0.3,
  //bar
  barPercentage: 0.9,
  //N count
  showN: false,
  NLabel: "N",
};

//chart plugins
export const shadowPlugin = {
  id: "shadowPlugin",
  beforeDatasetsDraw(chart, args, options) {
    const { ctx } = chart;
    chart.data.datasets.forEach((dataset, datasetIndex) => {
      const meta = chart.getDatasetMeta(datasetIndex);
      meta.data.forEach((point) => {
        ctx.save();
        ctx.shadowColor = options.shadowColor || "rgba(0, 0, 0, 0.5)";
        ctx.shadowBlur = options.shadowBlur || 10;
        ctx.shadowOffsetX = options.shadowOffsetX || 0;
        ctx.shadowOffsetY = options.shadowOffsetY || 0;
        ctx.beginPath();
        ctx.arc(point.x, point.y, point._radius, 0, Math.PI * 2);
        ctx.closePath();
        ctx.fill();
        ctx.restore();
      });
    });
  },
};

export const blankViz = {
  title: `Sample Data`,
  pivotString: "",
  projectIdsArray: [],
  tagIdsArray: [],
  filters: "{}",
  question: [],
  dynamicQs: [],
};

export const sendinternvals = [
  { value: "onetime", label: "One-Time" },
  { value: "daily", label: "Daily" },
  { value: "weekly", label: "Weekly" },
  { value: "monthly", label: "Monthly" },
  { value: "quarterly", label: "Quarterly" },
  { value: "semi-annually", label: "Semi-Annually" },
  { value: "annually", label: "Annually" },
];

export const reminderDates = [
  { value: "one-day", label: "One Day Before" },
  { value: "two-day", label: "Two Days Before" },
  { value: "three-day", label: "Three Days Before" },
  { value: "one-week", label: "One Week Before" },
  { value: "two-week", label: "Two Weeks Before" },
  { value: "three-week", label: "Three Weeks Before" },
];

export const dataTypes = [
  {
    label: "Text",
    value: "text",
    icon: "justify-left",
  },
  {
    label: "Pick List",
    value: "list",
    icon: "list-ol",
  },
  {
    label: "Number",
    value: "number",
    icon: "123",
  },
  {
    label: "Lookup",
    value: "lookup",
    icon: "search",
  },
  {
    label: "Boolean (Yes/No)",
    value: "boolean",
    icon: "toggles",
  },
  {
    label: "Currency",
    value: "currency",
    icon: "piggy-bank",
  },
  {
    label: "Date/Time",
    value: "date/time",
    icon: "calendar-date",
  },
  {
    label: "Date",
    value: "date",
    icon: "calendar",
  },
  {
    label: "Time",
    value: "time",
    icon: "clock",
  },
  {
    label: "Phone Number",
    value: "phone",
    icon: "telephone",
  },
  {
    label: "Email",
    value: "email",
    icon: "envelope",
  },
  {
    label: "URL",
    value: "url",
    icon: "box-arrow-up-right",
  },
];

export const iconSelectList = [
  { label: "Telephone", value: "telephone", icon: "telephone" },
  { label: "Phone", value: "phone", icon: "phone" },
  { label: "Exclamation", value: "exclamation-lg", icon: "exclamation-lg" },
  { label: "Bar Chart", value: "bar-chart", icon: "bar-chart" },
  { label: "Graph Up", value: "graph-up", icon: "graph-up" },
  { label: "Clipboard Data", value: "clipboard-data", icon: "clipboard-data" },
  { label: "Envelope", value: "envelope", icon: "envelope" },
  { label: "Activity", value: "activity", icon: "activity" },
  { label: "Send", value: "send", icon: "send" },
  { label: "Asterisk", value: "asterisk", icon: "asterisk" },
  { label: "Bell", value: "bell", icon: "bell" },
  { label: "Info-lg", value: "info-lg", icon: "info-lg" },
  { label: "People", value: "people", icon: "people" },
  { label: "People-fill", value: "people-fill", icon: "people-fill" },
  { label: "Bookmark", value: "bookmark", icon: "bookmark" },
  { label: "Briefcase", value: "briefcase", icon: "briefcase" },
  { label: "Person-Circle", value: "person-circle", icon: "person-circle" },
  {
    label: "Patch-exclamation",
    value: "patch-exclamation",
    icon: "patch-exclamation",
  },
  { label: "Calendar", value: "calendar", icon: "calendar" },
  { label: "Check-lg", value: "check-lg", icon: "check-lg" },
  {
    label: "Clipboard2-pulse",
    value: "clipboard2-pulse",
    icon: "clipboard2-pulse",
  },
  { label: "Speedometer2", value: "speedometer2", icon: "speedometer2" },
  { label: "Heart-pulse", value: "heart-pulse", icon: "heart-pulse" },
  { label: "Hospital", value: "hospital", icon: "hospital" },
  { label: "Hand-thumbs-up", value: "hand-thumbs-up", icon: "hand-thumbs-up" },
  {
    label: "Hand-thumbs-down",
    value: "hand-thumbs-down",
    icon: "hand-thumbs-down",
  },
  { label: "Emoji-smile", value: "emoji-smile", icon: "emoji-smile" },
  { label: "Emoji-angry", value: "emoji-angry", icon: "emoji-angry" },
  { label: "Emoji-neutral", value: "emoji-neutral", icon: "emoji-neutral" },
  {
    label: "Person-exclamation",
    value: "person-exclamation",
    icon: "person-exclamation",
  },
  { label: "Person", value: "person", icon: "person" },
  {
    label: "Exclamation-diamond",
    value: "exclamation-diamond",
    icon: "exclamation-diamond",
  },
  { label: "House", value: "house", icon: "house" },
  { label: "Flag", value: "flag", icon: "flag" },
  { label: "Percent", value: "percent", icon: "percent" },
  { label: "Bucket", value: "bucket", icon: "bucket" },
  { label: "Question", value: "question", icon: "question" },
  { label: "Search", value: "search", icon: "search" },
  { label: "Star", value: "star", icon: "star" },
  { label: "Journal", value: "journal", icon: "journal" },
  { label: "Cone", value: "cone", icon: "cone" },
  { label: "Brush", value: "brush", icon: "brush" },
  {
    label: "Clipboard-check",
    value: "clipboard-check",
    icon: "clipboard-check",
  },
  { label: "Mailbox", value: "mailbox", icon: "mailbox" },
  {
    label: "Arrow-clockwise",
    value: "arrow-clockwise",
    icon: "arrow-clockwise",
  },
  { label: "Building", value: "building", icon: "building" },
  { label: "Card-list", value: "card-list", icon: "card-list" },
  { label: "Check2-square", value: "check2-square", icon: "check2-square" },
  { label: "Stopwatch", value: "stopwatch", icon: "stopwatch" },
  { label: "Chat", value: "chat", icon: "chat" },
  { label: "Folder", value: "folder", icon: "folder" },
  { label: "Folder-fill", value: "folder-fill", icon: "folder-fill" },
  { label: "Folder2", value: "folder2", icon: "folder2" },
];

export const operators = [
  { value: 0, label: "equals" },
  { value: 1, label: "does not equal" },
  { value: 2, label: "contains" },
  { value: 3, label: "does not contain" },
  // { value: 4, label: "less than" },
  // { value: 5, label: "greater than" },
  // { value: 6, label: "starts with" },
  // { value: 7, label: "ends with" },
];

export const participationFields = [
  { value: "updatedAt", label: "Updated At (date/time)", type: "date/time" },
  { value: "createdAt", label: "Created At (date/time)", type: "date/time" },
  { value: "startedAt", label: "Started At (date/time)", type: "date/time" },
  { value: "finishedAt", label: "Finsished At (date/time)", type: "date/time" },
  { value: "finished", label: "Finished (boolean)", type: "boolean" },
  { value: "started", label: "Started (boolean)", type: "boolean" },
  { value: "abandoned", label: "Abandoned (boolean)", type: "boolean" },
  { value: "projectId", label: "Survey ID (text)", type: "text" },
  { value: "contactId", label: "Contact ID (text)", type: "text" },
  { value: "id", label: "ID (text)", type: "text" },
];

export const answerFields = [
  { value: "updatedAt", label: "Updated At (date/time)", type: "date/time" },
  { value: "createdAt", label: "Created At (date/time)", type: "date/time" },
  { value: "answer", label: "Answer (text)", type: "text" },
  { value: "questionId", label: "Question ID (text)", type: "date/time" },
  { value: "projectId", label: "Survey ID (text)", type: "text" },
  { value: "scaleAnswer", label: "Scale Answer (number)", type: "number" },
  { value: "choiceAnswer", label: "Choice Answer (text)", type: "text" },
  {
    value: "choiceAnswerIndex",
    label: "Choice Answer Index (text)",
    type: "text",
  },
  { value: "textAnswer", label: "Text Answer (text)", type: "text" },
  { value: "matrixAnswer", label: "matrix Answer (text)", type: "text" },
  { value: "rawAnswer", label: "Raw Answer (text)", type: "text" },
  { value: "id", label: "ID (text)", type: "text" },
];

export const surveyFields = [
  { value: "updatedAt", label: "Updated At (date/time)", type: "date/time" },
  { value: "createdAt", label: "Created At (date/time)", type: "date/time" },
  { value: "startedAt", label: "Started At (date/time)", type: "date/time" },
  { value: "closedAt", label: "Finsished At (date/time)", type: "date/time" },
  { value: "status", label: "Status (text)", type: "text" },
  { value: "link", label: "Link (text)", type: "text" },
  { value: "name", label: "Name (text)", type: "date/time" },
  { value: "responseCount", label: "Response Count (text)", type: "text" },
  { value: "id", label: "ID (text)", type: "text" },
];

export const questionFields = [
  { value: "updatedAt", label: "Updated At (date/time)", type: "date/time" },
  { value: "createdAt", label: "Created At (date/time)", type: "date/time" },
  { value: "questionText", label: "Question Text (text)", type: "text" },
  { value: "projectId", label: "Survey ID (text)", type: "text" },
  { value: "description", label: "Instructions (text)", type: "text" },
  { value: "type", label: "Type (text)", type: "text" },
  { value: "id", label: "ID (text)", type: "text" },
];

export const objectData = {
  Question: questionFields,
  Survey: surveyFields,
  Answer: answerFields,
  Participation: participationFields,
};
