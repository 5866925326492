/*
======================= START OF LICENSE NOTICE =======================
  Copyright (C) 2023 Reaction. All Rights Reserved

  NO WARRANTY. THE PRODUCT IS PROVIDED BY DEVELOPER "AS IS" AND ANY
  EXPRESS OR IMPLIED WARRANTIES, INCLUDING, BUT NOT LIMITED TO, THE
  IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR
  PURPOSE ARE DISCLAIMED. IN NO EVENT SHALL DEVELOPER BE LIABLE FOR
  ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, EXEMPLARY, OR CONSEQUENTIAL
  DAMAGES (INCLUDING, BUT NOT LIMITED TO, PROCUREMENT OF SUBSTITUTE
  GOODS OR SERVICES; LOSS OF USE, DATA, OR PROFITS; OR BUSINESS
  INTERRUPTION) HOWEVER CAUSED AND ON ANY THEORY OF LIABILITY, WHETHER
  IN CONTRACT, STRICT LIABILITY, OR TORT (INCLUDING NEGLIGENCE OR
  OTHERWISE) ARISING IN ANY WAY OUT OF THE USE OF THE PRODUCT, EVEN
  IF ADVISED OF THE POSSIBILITY OF SUCH DAMAGE.
======================== END OF LICENSE NOTICE ========================
  Primary Author: natehanson
*/

// External
import { useQueryClient } from "react-query";
import { gql } from "graphql-request";

// Internal
import { useGqlQuery, useGqlMutation, useGqlInfiniteQuery } from "api/Api";

// GRAPHQL API

/**
 * Mutation to add a new contact.
 *
 * This function handles adding a new contact to the system, along with its associated attributes,
 * custom fields, and linking it to an audience. It uses the `addContact` GraphQL mutation.
 *
 * @function useAddContact
 * @returns {Function} - Returns a mutation function to add a contact.
 *
 * @example
 * const addContact = useAddContact();
 * addContact.mutateAsync({
 *   audienceId: "123",
 *   newColumns: { columns: [...] },
 *   newAttData: { attributes: [...] },
 *   attributeData: { attributeIds: [...] },
 *   contact: { firstName: "Jane", email: "jane.doe@example.com" },
 * });
 */
export const useAddContact = () => {
  const mutation = gql`
    mutation AddContact(
      $audienceId: String!
      $newColumns: NewColumnData!
      $newAttData: NewAttData!
      $attributeData: AttributeData!
      $contact: ContactInput!
    ) {
      addContact(
        audienceId: $audienceId
        newColumns: $newColumns
        newAttData: $newAttData
        attributeData: $attributeData
        contact: $contact
      )
    }
  `;
  return useGqlMutation(mutation, {});
};

/**
 * Mutation to update an existing contact.
 *
 * This function handles updating contact information and associated attributes in the system.
 * It ensures that:
 * - The mutation is executed via the `updateContact` GraphQL mutation.
 * - The query cache is invalidated after the mutation to keep the data consistent.
 *
 * @function useUpdateContactGql
 * @returns {Function} - Returns a mutation function to update a contact.
 *
 * @example
 * const updateContact = useUpdateContactGql();
 * updateContact.mutateAsync({ id: "123", data: { firstName: "John" } });
 */
export const useUpdateContactGql = () => {
  const mutation = gql`
    mutation UpdateContact(
      $data: contactUpdate!
      $id: String!
      $attributeData: AttributeUpdate
    ) {
      updateContact(data: $data, id: $id, attributeData: $attributeData) {
        id
        firstName
        lastName
        email
        phone
        prefix
        unsubscribed
      }
    }
  `;

  return useGqlMutation(mutation, {});
};

/**
 * Mutation to delete a contact.
 *
 * This function handles deleting a contact from the system using the `deleteContact` GraphQL mutation.
 * It ensures the query cache is invalidated after the mutation to keep data consistent.
 *
 * @function useDeleteContactGql
 * @returns {Function} - Returns a mutation function to delete a contact.
 *
 * @example
 * const deleteContact = useDeleteContactGql();
 * deleteContact.mutateAsync({ deleteContactId: "123" });
 */
export const useDeleteContactGql = () => {
  const mutation = gql`
    mutation DeleteContact($deleteContactId: String!) {
      DeleteContact(id: $deleteContactId)
    }
  `;

  return useGqlMutation(mutation, {});
};

/**
 * Mutation to fetch filtered contacts.
 *
 * This function executes the `searchContactsFiltersMutation` GraphQL mutation to retrieve a list of contacts
 * based on search criteria, filters, sorting, pagination, and audience filters.
 *
 * @function usefetchContactsFilters
 * @returns {Function} - Returns a mutation function to fetch filtered contacts.
 *
 * @example
 * const fetchContacts = usefetchContactsFilters();
 * fetchContacts.mutateAsync({
 *   search: "John",
 *   page: 1,
 *   perPage: 10,
 *   sort: { field: "firstName", order: "asc" },
 *   filters: "status:active",
 *   audienceId: "123",
 *   audienceFilters: "region:US",
 * });
 */
export const usefetchContactsFilters = () => {
  const mutation = gql`
    mutation Mutation(
      $search: String!
      $page: Float!
      $perPage: Float!
      $sort: SortContact!
      $filters: String!
      $audienceId: String!
      $audienceFilters: String!
    ) {
      contacts: SearchContactsFiltersMutation(
        search: $search
        page: $page
        perPage: $perPage
        sort: $sort
        filters: $filters
        audienceId: $audienceId
        audienceFilters: $audienceFilters
      ) {
        count
        contacts {
          id
          firstName
          lastName
          email
          phone
          unsubscribed
          updatedAt
          createdAt
          color
          participationCount
          salesforceId
          lastEmailStatus
          addressLine1
          addressLine2
          postalCode
          country
          city
          stateProvince
          unsubscribedReason
          preferredCommunication
          dateOfBirth
          unsubscribedAt
          attribute {
            customFieldId
            name
            id
            custom_field {
              id
              name
            }
          }
        }
      }
    }
  `;

  return useGqlMutation(mutation, {});
};

export const useSearchContacts = (search, perPage, sort, audienceId, audienceFilters, filters) => {
  const query = gql`
    query Query(
      $search: String!
      $skip: Float!
      $perPage: Float!
      $sort: SortContact!
      $filters: String!
      $audienceId: String!
      $audienceFilters: String!
    ) {
      response: searchContacts(
        search: $search
        skip: $skip
        perPage: $perPage
        sort: $sort
        filters: $filters
        audienceId: $audienceId
        audienceFilters: $audienceFilters
      ) {
        count
        hasMore
        error
        contacts {
          id
          firstName
          lastName
          email
          phone
          unsubscribed
          updatedAt
          createdAt
          color
          participationCount
          salesforceId
          lastEmailStatus
          addressLine1
          addressLine2
          postalCode
          country
          city
          stateProvince
          unsubscribedReason
          preferredCommunication
          dateOfBirth
          unsubscribedAt
          attribute {
            customFieldId
            name
            id
            custom_field {
              id
              name
            }
          }
        }
      }
    }
  `;

  return useGqlInfiniteQuery(
    ["Get Contacts:" + audienceId],
    query,
    {
      search,
      skip: 0,
      perPage,
      sort,
      audienceId,
      audienceFilters,
      filters,
    },
    {
      getNextPageParam: (lastPage, allPages) => {
        const currentCount = allPages.flatMap((page) => page?.response?.contacts).length;
        return lastPage?.response?.hasMore ? currentCount : undefined; // Use `currentCount` as `skip` for the next page
      },
    }
  );
};

/**
 * Mutation to find a contact by email.
 *
 * This function uses the `findContactByEmail` GraphQL mutation to fetch contact details
 * based on the provided email address.
 *
 * @function useFindContactByEmailGql
 * @returns {Function} - Returns a mutation function to find a contact by email.
 *
 * @example
 * const findContactByEmail = useFindContactByEmailGql();
 * findContactByEmail.mutateAsync({ email: "example@example.com" });
 */
export const useFindContactByEmailGql = () => {
  const mutation = gql`
    mutation Mutation($email: String!) {
      findContactByEmail(email: $email) {
        email
        firstName
        lastName
        phone
        prefix
      }
    }
  `;
  return useGqlMutation(mutation, {});
};

/**
 * Mutation to update a contact's attributes.
 *
 * This function uses the `updateContactSingleAtt` GraphQL mutation to update contact details
 * and their attributes. It also invalidates the "searchContacts" query cache after the mutation.
 *
 * @function useUpdateContactAttGql
 * @returns {Function} - Returns a mutation function to update a contact's attributes.
 *
 * @example
 * const updateContact = useUpdateContactAttGql();
 * updateContact.mutateAsync({
 *   id: "123",
 *   data: { firstName: "John", lastName: "Doe" },
 *   attributeData: { someAttribute: "value" }
 * });
 */
export const useUpdateContactAttGql = () => {
  const mutation = gql`
    mutation UpdateContact(
      $data: contactUpdate!
      $id: String!
      $attributeData: AttributeUpdate
      $newContact: Boolean!
    ) {
      contact: updateContactSingleAtt(
        data: $data
        id: $id
        attributeData: $attributeData
        newContact: $newContact
      ) {
        id
        firstName
        lastName
        email
        phone
        prefix
        updatedAt
        createdAt
        unsubscribed
        addressLine1
        addressLine2
        postalCode
        country
        city
        stateProvince
        unsubscribedReason
        preferredCommunication
        dateOfBirth
        unsubscribedAt
      }
    }
  `;
  const queryClient = useQueryClient();
  const options = {
    onError: (err, _project, rollback) => {
      if (rollback) rollback();
    },
    onSettled: () => {
      queryClient.invalidateQueries(["searchContacts"]);
    },
  };

  return useGqlMutation(mutation, options);
};

/**
 * Query to fetch contact details by ID.
 *
 * This function uses the `getContact` GraphQL query to retrieve detailed information
 * about a contact, including their attributes, custom fields, and participation count.
 *
 * @function useFetchContact
 * @param {String} id - The unique identifier of the contact to fetch.
 * @returns {Object} - Returns the result of the query, including data, error, and loading state.
 *
 * @example
 * const { data, error, isLoading } = useFetchContact("123");
 * if (data) {
 *   console.log("Contact details:", data.contact);
 * }
 */
export const useFetchContact = (id) => {
  const query = gql`
    query Query($id: String!) {
      contact: getContact(id: $id) {
        email
        unsubscribed
        organizationId
        id
        firstName
        customField
        lastName
        phone
        participationCount
        unsubscribedSurveys
        lastEmailStatus
        createdAt
        updatedAt
        addressLine1
        addressLine2
        postalCode
        country
        city
        stateProvince
        unsubscribedReason
        preferredCommunication
        dateOfBirth
        unsubscribedAt
        attribute {
          id
          name
          customFieldId
        }
      }
    }
  `;

  return useGqlQuery(["contact: " + id], query, {
    id: id,
  });
};

export const useFetchContactOnly = (id) => {
  const query = gql`
    query Query($id: String!) {
      contact: getContactOnly(id: $id) {
        email
        unsubscribed
        organizationId
        id
        firstName
        customField
        lastName
        phone
        participationCount
        unsubscribedSurveys
        lastEmailStatus
        createdAt
        updatedAt
        addressLine1
        addressLine2
        postalCode
        country
        city
        stateProvince
        unsubscribedReason
        preferredCommunication
        dateOfBirth
        unsubscribedAt
      }
    }
  `;

  return useGqlQuery(["contactOnly: " + id], query, {
    id: id,
  });
};


/**
 * Query to search for contacts.
 *
 * This function uses the `searchContact` GraphQL query to search for contacts based on a
 * search string, with options to limit the number of results and sort them.
 *
 * @function useSearchContact
 * @param {String} searchString - The search term used to find contacts.
 * @param {Number} limit - The maximum number of results to return.
 * @param {Object} sort - The sorting criteria for the search results.
 * @returns {Object} - Returns the result of the query, including data, error, and loading state.
 *
 * @example
 * const { data, error, isLoading } = useSearchContact("John", 10, { field: "lastName", order: "ASC" });
 * if (data) {
 *   console.log("Search results:", data.contacts);
 * }
 */
export const useSearchContact = (searchString, limit, sort) => {
  const query = gql`
    query Query($search: String!, $limit: Float!, $sort: sortContact!) {
      contacts: searchContact(search: $search, limit: $limit, sort: $sort) {
        id
        firstName
        lastName
        email
        customField
        addressLine1
        addressLine2
        postalCode
        country
        city
        stateProvince
        unsubscribedReason
        preferredCommunication
        dateOfBirth
        unsubscribedAt
        attribute {
          name
          customFieldId
        }
        audience {
          id
        }
      }
    }
  `;

  return useGqlQuery(
    [`search ${limit} contacts: ` + searchString + limit],
    query,
    {
      search: searchString,
      limit: limit,
      sort: sort,
    }
  );
};

/**
 * Query to fetch the count of contacts matching filters and audience criteria.
 *
 * This function uses the `searchContactsFiltersCount` GraphQL query to retrieve the count
 * of contacts that match a search string, applied filters, an audience ID, and additional
 * audience-specific filters.
 *
 * @function useSearchContactsFiltersCount
 * @param {String} searchString - The search term used to find contacts.
 * @param {String} filters - The filters applied to narrow down the search results.
 * @param {String} audienceId - The ID of the audience to filter contacts by.
 * @param {String} audFilters - Additional audience-specific filters.
 * @returns {Object} - Returns the result of the query, including data, error, and loading state.
 *
 * @example
 * const { data, error, isLoading } = useSearchContactsFiltersCount(
 *   "John",
 *   JSON.stringify({ status: "active" }),
 *   "audience123",
 *   JSON.stringify({ region: "US" })
 * );
 * if (data) {
 *   console.log("Matching contacts count:", data.count);
 * }
 */
export const useSearchContactsFiltersCount = (
  searchString,
  filters,
  audienceId,
  audFilters
) => {
  const query = gql`
    query Query(
      $search: String!
      $filters: String!
      $audienceId: String!
      $audienceFilters: String!
    ) {
      count: searchContactsFiltersCount(
        search: $search
        filters: $filters
        audienceId: $audienceId
        audienceFilters: $audienceFilters
      )
    }
  `;

  return useGqlQuery(
    ["searchContactsOGFiltersCount:" + searchString + audienceId],
    query,
    {
      search: searchString,
      filters: filters,
      audienceId: audienceId,
      audienceFilters: audFilters,
    }
  );
};

/**
 * Mutation to fetch unsubscribed contacts.
 *
 * This function uses the `getContactsByUnsubscribed` GraphQL mutation to retrieve a list of
 * unsubscribed contacts and invalidates the "contacts" query cache after the mutation.
 *
 * @function useGetContactsByUnsubscribed
 * @returns {Function} - Returns a mutation function to fetch unsubscribed contacts.
 *
 * @example
 * const getUnsubscribedContacts = useGetContactsByUnsubscribed();
 * getUnsubscribedContacts.mutateAsync().then((data) => {
 *   console.log("Unsubscribed contacts:", data);
 * });
 */
export const useGetContactsByUnsubscribed = () => {
  const mutation = gql`
    mutation {
      getContactsByUnsubscribed {
        id
        firstName
        lastName
        email
        unsubscribed
        unsubscribedAt
        customField
      }
    }
  `;
  const queryClient = useQueryClient();
  const options = {
    onError: (err, _project, rollback) => {
      if (rollback) rollback();
    },
    onSettled: () => {
      queryClient.invalidateQueries("contacts");
    },
  };

  return useGqlMutation(mutation, options);
};

/**
 * Mutation to get the number of contact updates and creations.
 *
 * This function uses the `getContactUpdateNumbers` GraphQL mutation to calculate
 * how many contacts from a provided list need to be updated or created based on
 * the specified operation mode (`checked`).
 *
 * @function useGetContactUpdates
 * @returns {Function} - Returns a mutation function to fetch the count of updates and creations.
 *
 * @example
 * const getContactUpdates = useGetContactUpdates();
 * getContactUpdates.mutateAsync({
 *   contacts: ["email1@example.com", "email2@example.com"],
 *   checked: "add-update",
 *   orgId: "org123"
 * }).then((data) => {
 *   console.log("Update count:", data.values.update, "Create count:", data.values.create);
 * });
 */
export const useGetContactUpdates = () => {
  const mutation = gql`
    mutation Mutation(
      $contacts: [String!]!
      $checked: String!
      $orgId: String!
    ) {
      values: getContactUpdateNumbers(
        contacts: $contacts
        checked: $checked
        orgId: $orgId
      ) {
        update
        create
      }
    }
  `;

  return useGqlMutation(mutation, {});
};

/**
 * Query to validate if an email is available.
 *
 * This function uses the `validateEmailAvailable` GraphQL query to check if an email
 * is already in use and returns the user details associated with it if found.
 *
 * @function useValidateEmailAvailable
 * @param {String} email - The email address to validate.
 * @returns {Object} - Returns the result of the query, including data, error, and loading state.
 *
 * @example
 * const { data, error, isLoading } = useValidateEmailAvailable("test@example.com");
 * if (data?.inUseBy) {
 *   console.log("Email in use by:", data.inUseBy.firstName, data.inUseBy.lastName);
 * } else {
 *   console.log("Email is available.");
 * }
 */
export const useValidateEmailAvailable = (email) => {
  const query = gql`
    query Query($email: String!) {
      inUseBy: validateEmailAvailable(email: $email) {
        firstName
        lastName
      }
    }
  `;

  return useGqlQuery(["validate email: " + email], query, { email: email });
};

/**
 * Mutation to transfer a temporary contact to a permanent contact.
 *
 * This function uses the `transferTempContact` GraphQL mutation to move a temporary contact
 * into the main contact database. It also associates the contact with an audience and upload
 * if specified and invalidates the "contacts" query cache upon success.
 *
 * @function useTransferTempContact
 * @returns {Function} - Returns a mutation function to transfer a temporary contact.
 *
 * @example
 * const transferContact = useTransferTempContact();
 * transferContact.mutateAsync({
 *   contactData: { email: "test@example.com", firstName: "John", lastName: "Doe", temp_contact_id: "123" },
 *   audienceId: "audienceId123",
 *   upload: { id: "uploadId123", metadata: "updated metadata" }
 * }).then(() => {
 *   console.log("Temporary contact transferred successfully.");
 * });
 */
export const useTransferTempContact = () => {
  const mutation = gql`
    mutation Mutation(
      $contactData: ContactInput!
      $audienceId: String!
      $upload: uploadUpdate!
    ) {
      transferTempContact(
        contactData: $contactData
        audienceId: $audienceId
        upload: $upload
      )
    }
  `;
  const queryClient = useQueryClient();
  const options = {
    onSuccess: () => {
      queryClient.invalidateQueries(["contacts"]);
    },
  };
  return useGqlMutation(mutation, options);
};

/**
 * Mutation to export contacts based on specified criteria.
 *
 * This function uses the `exportContacts` GraphQL mutation to export contact data
 * in CSV format with optional sorting, filtering, and audience-specific criteria.
 *
 * @function useExportContacts
 * @returns {Function} - Returns a mutation function to export contacts.
 *
 * @example
 * const exportContacts = useExportContacts();
 * exportContacts.mutateAsync({
 *   headers: { fields: ["firstName", "lastName", "email"] },
 *   settings: { format: "csv", includeHeaders: true },
 *   audienceFilters: JSON.stringify({ region: "US" }),
 *   audienceId: "audience123",
 *   sort: { field: "lastName", order: "ASC" },
 *   filters: JSON.stringify({ status: "active" }),
 *   search: "John"
 * }).then((data) => {
 *   console.log("Exported CSV Base64:", data.zippedBase64);
 *   console.log("Exported CSV String:", data.csvString);
 * });
 */
export const useExportContacts = () => {
  const mutation = gql`
    mutation ExportContacts(
      $headers: headers!
      $settings: exportSettings!
      $audienceFilters: String!
      $audienceId: String!
      $sort: SortContact!
      $filters: String!
      $search: String!
    ) {
      exportContacts(
        headers: $headers
        settings: $settings
        audienceFilters: $audienceFilters
        audienceId: $audienceId
        sort: $sort
        filters: $filters
        search: $search
      ) {
        zippedBase64
        csvString
      }
    }
  `;
  return useGqlMutation(mutation, {});
};

/**
 * Query to fetch contacts associated with a specific upload ID.
 *
 * This function uses the `searchContactsByUploadId` GraphQL query to retrieve contacts
 * and their total count based on search criteria, pagination, sorting, and a specific upload ID.
 *
 * @function useFetchContactByUploadId
 * @param {String} searchString - The search term used to filter contacts.
 * @param {Number} page - The current page number for pagination.
 * @param {Number} perPage - The number of contacts to return per page.
 * @param {Object} sort - Sorting criteria for the contacts (e.g., field and order).
 * @param {String} uploadId - The ID of the upload associated with the contacts.
 * @returns {Object} - Returns the result of the query, including `contacts` and `count`.
 *
 * @example
 * const { data, error, isLoading } = useFetchContactByUploadId(
 *   "John",
 *   1,
 *   20,
 *   { field: "lastName", order: "ASC" },
 *   "uploadId123"
 * );
 * if (data) {
 *   console.log("Contacts:", data.contactsObject.contacts);
 *   console.log("Total Count:", data.contactsObject.count);
 * }
 */
export const useFetchContactByUploadId = (
  searchString,
  page,
  perPage,
  sort,
  uploadId
) => {
  const query = gql`
    query Query(
      $search: String!
      $page: Float!
      $perPage: Float!
      $sort: sortSelectionUpload!
      $uploadId: String!
    ) {
      contactsObject: searchContactsByUploadId(
        search: $search
        page: $page
        perPage: $perPage
        sort: $sort
        uploadId: $uploadId
      ) {
        contacts {
          firstName
          lastName
          email
          customField
        }
        count
      }
    }
  `;

  return useGqlQuery(
    ["searchContactUpload" + searchString + page + perPage],
    query,
    {
      search: searchString,
      page: page,
      perPage: perPage,
      sort: sort,
      uploadId: uploadId,
    }
  );
};
//Supporting function to get the count for the above function
export const useFetchContactByUploadIdCount = (
  searchString,
  page,
  perPage,
  sort,
  uploadId
) => {
  const query = gql`
    query Query(
      $search: String!
      $page: Float!
      $perPage: Float!
      $sort: sortSelectionUpload!
      $uploadId: String!
    ) {
      count: searchContactsByUploadIdCount(
        search: $search
        page: $page
        perPage: $perPage
        sort: $sort
        uploadId: $uploadId
      )
    }
  `;

  return useGqlQuery(
    ["searchContactUploadCount" + searchString + page + perPage],
    query,
    {
      search: searchString,
      page: page,
      perPage: perPage,
      sort: sort,
      uploadId: uploadId,
    }
  );
};

// look into this
export const useApplyColorContacts = () => {
  const mutation = gql`
    mutation {
      applyColorContacts
    }
  `;

  return useGqlMutation(mutation, {});
};
