import React, { useEffect, useState } from "react";
import styles from "./Register.module.scss";
import FlexRow from "components/layouts/FlexRow/FlexRow";
import Icon from "components/Icon/Icon";
import Button from "components/Button/Button";
import { useNavigate } from "react-router-dom";
import Modal from "components/ReactModal/ReactModal";
import ContactSales from "pages/ContactReactionForms/ContactSales";

function AccountType({ steps, setSteps, activeStep, setActiveStep }) {
  const navigate = useNavigate();
  const [contactSales, setContactSales] = useState(false);

  useEffect(() => {
    let temp = [...steps];
    temp[0].done = true;
    setSteps(temp);
    setActiveStep(0);
  }, []);
  return (
    <div className={styles.content}>
      <div className={styles.header_3} style={{ margin: "0" }}>
        The Reaction Platform Experience
      </div>
      <div className={styles.text_2} style={{ maxWidth: "800px" }}>
        To gain access to our full suite of solutions, we ask that you chat with
        one of our Reaction Experts. If you already have a join code click
        "Create Account" below.
      </div>
      <Button
          blue
          shadow
          width={150}
          onClick={() => {
            // localStorage.setItem("reaction-register-page", activeStep + 1)
            navigate("/register/your-details");
          }}
        >
          Create Account
        </Button>
      <FlexRow gap={"2rem"}>
        {/* <div className={`${styles.box} ${styles.activeBox}`}>
          <FlexRow justify={"space-between"}>
            Trial Account <Icon blue className="bi-check-circle-fill"></Icon>
          </FlexRow>
          <div
            className={`${styles.header_5} `}
            style={{ margin: "0", fontSize: "1.2rem" }}
          >
            <a className={`${styles.blue}`} style={{ fontSize: "1.3rem" }}>
              $500
            </a>{" "}
            one-time payment
          </div>
          <div className={styles.list}>
            <div>
              <i className={"bi-check"}></i>1 admin user
            </div>
            <div>
              {" "}
              <i className={"bi-check"}></i>3 additional member users
            </div>
            <div>
              {" "}
              <i className={"bi-check"}></i>30 Day unrestricted access
            </div>
            <div>
              <i className={"bi-check"}></i>Unlimited Survey Creation
            </div>
            <div>
              <i className={"bi-check"}></i>Unlimited Email sending upon domain
              verification
            </div>
            <div>
              {" "}
              <i className={"bi-check"}></i>Seamless Upgrade availability
            </div>
            <div>
              <i className={"bi-check"}></i>Zero data loss guarantee on upgrade
            </div>
          </div>
        </div> */}
        <div className={`${styles.box}`}>
          <FlexRow justify={"space-between"}>Premium Account Offers</FlexRow>
          <div
            className={`${styles.header_5} `}
            style={{ margin: "0", fontSize: "1.2rem" }}
          >
            Customized Plan
          </div>

          <div className={styles.list}>
            <div>
              <i className={"bi-check"}></i>1+ admin users
            </div>
            <div>
              {" "}
              <i className={"bi-check"}></i>3+ additional users
            </div>
            <div>
              {" "}
              <i className={"bi-check"}></i>Unrestricted access.
            </div>
            <div>
              <i className={"bi-check"}></i>Unlimited Survey Creation.
            </div>
            <div>
              <i className={"bi-check"}></i>Unlimited Email & SMS Messaging.
            </div>
            <div>
              <i className={"bi-check"}></i>On-Demand expertise and best
              practice help.
            </div>
          </div>
          <FlexRow>
            <Button
              blue
              shadow
              style={{ margin: "1rem 0rem" }}
              onClick={() => setContactSales(true)}
            >
              Contact Us
            </Button>
            Inquire about a no risk trial today
          </FlexRow>
        </div>
      </FlexRow>
      {/* <div className={styles.header_3} style={{ margin: "0" }}>
        Create a Trial Account
      </div> */}
      <div className={styles.text_2} style={{ maxWidth: "800px" }}>
        To learn more about what Reaction offers without talking to a Rep, go to <a className={styles.link} href="https://reactiondata.com">reactiondata.com</a>
      </div>
      {/* <div className={styles.header_3} style={{ margin: "0" }}>
        Trial Account Pricing
      </div>
      <div className={styles.text_2} style={{ maxWidth: "800px" }}>
        In order to start with your trial account, you will need to pay a one
        time <a className={styles.blue}>$500</a> payment. You will not recieve
        access until you create an account and successfully make the payment.
        <br></br>
        <br></br>
        If you wish to learn more about the account you contact our sales team
        by clicking "Request a demo" below, if you wish to continue account
        setup, click "Continue".
      </div> */}
      

      {contactSales && (
        <Modal show={contactSales} onClose={() => setContactSales(false)} dark>
          <ContactSales />
        </Modal>
      )}
    </div>
  );
}

export default AccountType;
