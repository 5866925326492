import React from "react";
import styles from "./FieldAdjuster.module.scss";
import Modal from "components/ReactModal/ReactModal";
import { EditCustomFields } from "pages/organization/organization_details/OrganizationDetails";
import { useFetchOrganizationCustomFields } from "api/resources/organization/organization";
import { Loading } from "components/Loading/Loading";

function FieldAdjuster({ show, setShow, salesforce }) {
  const fetchCustomFields = useFetchOrganizationCustomFields();
  return (
    <Modal
      show={show}
      onClose={() => setShow(false)}
      modalStyle={{
        paddingBottom: "0em",
        borderRadius: "1rem",
        overflow: "hidden",
        width: "900px",
        height: "800px",
      }}
      dark
    >
      <div className={styles.adjustBox}>
        {fetchCustomFields.isLoading && <Loading></Loading>}
        {fetchCustomFields.isSuccess && (
          <EditCustomFields
            customfields={fetchCustomFields.data.response.fields}
            salesforce={salesforce}
          ></EditCustomFields>
        )}
      </div>
    </Modal>
  );
}

export default FieldAdjuster;
