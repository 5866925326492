import React, { useEffect, useState } from "react";
import styles from "./SyncContacts.module.scss";
import { useFetchOrganizationLinkedAccounts } from "api/resources/organization/organization";
import { Loading } from "components/Loading/Loading";
import Button from "components/Button/Button";
import axios from "axios";
import { checkMap } from "./ImportHow";
import Icon from "components/Icon/Icon";
import { SelectFieldCustom } from "components/inputs";
import FlexCol from "components/layouts/FlexColumn/FlexCol";
import FlexRow from "components/layouts/FlexRow/FlexRow";
import Checkbox from "components/inputs/input_fields/CheckboxBlue/Checkbox";

// Function to find the key with column: "email"
const findKeyWithEmailColumn = (obj, column) => {
  for (const key in obj) {
    if (obj[key]?.column?.name === column) {
      return {
        name: obj[key]?.column.name,
        label: obj[key]?.column.name,
        id: key,
      }; // Return the first key that matches
    }
  }
  return null; // Return null if no key is found
};

export function SyncContacts({ organizationId, objectColumns, setType, user }) {
  const myOrganization = useFetchOrganizationLinkedAccounts(organizationId);
  return (
    <>
      {myOrganization.isLoading && <Loading></Loading>}
      {myOrganization.isSuccess && (
        <SyncPage
          organization={myOrganization.data?.organization}
          objectColumns={objectColumns}
          setType={setType}
          user={user}
        ></SyncPage>
      )}
    </>
  );
}

function SyncPage({ organization, objectColumns, setType, user }) {
  const [page, setPage] = useState(0);
  const [headerMap, setHeaderMap] = useState({});
  const [headers, setHeaders] = useState([]);
  const [loading, setLoading] = useState(false);
  const [identityFields, setIdentityFields] = useState([]);
  const [syncType, setSyncType] = useState();
  const [syncOptions, setSyncOptions] = useState();

  const handleLoginSalesforce = async () => {
    try {
      const url = `${process.env.REACT_APP_SALESFORCE_KEY2}&userID=${user?.id}`;
      const response = await axios.get(url, { withCredentials: true });
      window.location.href = response.data.loginUrl;
    } catch (error) {
      console.error("Error during login:", error);
    }
  };

  useEffect(() => {
    let contactColumns = [];

    let url = process.env.REACT_APP_SALESFORCE_COLUMNS;
    const config = {
      withCredentials: true,
      headers: {
        accept: "application/json",
      },
      params: {
        organization_id: organization.id,
        user_id: user.id,
      },
    };

    setLoading(true);
    //contact columns
    axios
      .get(url, config)
      .then((response) => {
        if (response?.data && response?.data?.length > 0) {
          for (let head of response.data) {
            let custom = false;
            let og = `${head}`;
            let label = `${head}`;
            if (
              //   head != "Email" &&
              //   head != "FirstName" &&
              //   head != "LastName" &&
              head != "Id"
            ) {
              if (head.includes("__c")) {
                label = head.replace("__c", "");
                custom = true;
              }
              let name = head.toLowerCase();
              let header = {
                id: head,
                name: label,
                accessor: head,
                sfName: og,
                canSort: false,
                cell_style: null,
                value: name,
                label: label,
                enabled: false,
                sort: false,
                canSort: true,
                fullName: true,
                custom: false,
                account: false,
                isReaction: false,
              };
              contactColumns.push(header);
            }
          }
          let headMap = {};
          let final = [];
          let i = 0;
          for (let field of contactColumns) {
            headMap[field.name] = [];
            let newfield = {
              id: i,
              name: field.name,
              enabled: true,
              cell_style: null,
              canSort: false,
              value: field.name,
              label: field.name,
              // cell_style: (val) => <div>{checkFields(val, field)}</div>,
            };
            final.push(newfield);
            checkMap(newfield, headMap, objectColumns, i, setIdentityFields);
            i++;
          }
          let newfield = {
            id: i,
            name: "AccountName",
            enabled: true,
            cell_style: null,
            canSort: false,
            value: "AccountName",
            label: "AccountName",
            // cell_style: (val) => <div>{checkFields(val, field)}</div>,
          };
          final.push(newfield);
          checkMap(newfield, headMap, objectColumns, i, setIdentityFields);

          setHeaders(final);
          //   setData(data);
          setHeaderMap(headMap);
        }
      })
      .catch((error) => {
        console.error(error);
        if (error?.response?.status === 401) {
          setSessionExpired(true);
          setTimeout(() => {
            handleLoginSalesforce();
          }, 1000);
        }
      });
  }, []);

  function startSync() {
    const url = process.env.REACT_APP_SALESFORCE_SYNC_QUERY;

    // let finalMap = {};
    let mapArray = [];
    for (const key in headerMap) {
      if (headerMap[key]?.column?.id != "no-import") {
        // finalMap[key] = headerMap[key];
        let val = headerMap[key]?.column;
        val.sfColumn = key;
        mapArray.push(val);
      }
    }
    const formData = new FormData();
    formData.append("columnMap", JSON.stringify(mapArray));
    const config = {
      withCredentials: true,
      headers: {
        accept: "application/json",
      },
      params: {
        organization_id: organization.id,
        user_id: user.id,
        rtoc: syncOptions?.rtoc,
        ctor: syncOptions?.ctor,
      },
    };

    setLoading(true);
    //contact columns
    axios
      .post(url, formData, config)
      .then((response) => {
        console.log(response);
      })
      .catch((error) => {
        console.error(error);
        if (error?.response?.status === 401) {
          setSessionExpired(true);
          setTimeout(() => {
            handleLoginSalesforce();
          }, 1000);
        }
      });
  }

  return (
    <div className={styles.page}>
      {page === 0 && (
        <MapFieldsSync
          objectColumns={objectColumns}
          headerMap={headerMap}
          setHeaderMap={setHeaderMap}
          headers={headers}
        />
      )}
      {page === 1 && (
        <SyncOptions
          objectColumns={objectColumns}
          headerMap={headerMap}
          setHeaderMap={setHeaderMap}
          headers={headers}
          syncType={syncType}
          setSyncType={setSyncType}
          options={syncOptions}
          setOptions={setSyncOptions}
        />
      )}
      <div className={styles.headerFooter}>
        {page === 0 && (
          <Button link onClick={() => setType(null)}>
            Cancel
          </Button>
        )}

        {page > 0 && (
          <Button link onClick={() => setPage((page) => (page -= 1))}>
            Previous
          </Button>
        )}

        {page === 0 && (
          <Button
            blue
            shadow
            style={{
              gap: ".75em",
              fontWeight: "400",
            }}
            height={2.4}
            padding={"0 1rem"}
            // disable={
            //   (page === 0 && !selectedFile) ||
            //   (page === 2 && !findKeyWithEmailColumn(headerMap, "email"))
            // }
            onClick={() => setPage((page) => (page += 1))}
          >
            Next
          </Button>
        )}

        {page > 0 && (
          <Button
            blue
            shadow
            style={{
              gap: ".75em",
              fontWeight: "400",
            }}
            height={2.4}
            padding={"0 1rem"}
            // disable={page === 0 && !selectedFile}
            onClick={() => {
              startSync();
            }}
            disable={!syncType}
          >
            Finish
          </Button>
        )}
      </div>
    </div>
  );
}

function MapFieldsSync({ objectColumns, headerMap, setHeaderMap, headers }) {
  function getFieldOptions() {
    let custom = [];

    custom.push({
      id: "no-import",
      value: "no-import",
      icon: "eye-slash",
      label: "Don't Import",
    });
    custom.push({
      id: "new-column",
      value: "new-column",
      icon: "plus",
      label: "Create New Column",
    });
    for (let f of objectColumns) {
      if (f?.id) {
        if (headerMap && !findKeyWithEmailColumn(headerMap, f?.name)) {
          custom.push({
            id: f?.id,
            value: f?.value,
            icon: f?.icon ? f?.icon : "person",
            label: f?.label ? f?.label : f?.name,
          });
        } else if (!headerMap) {
          custom.push({
            id: f.id,
            value: f?.value,
            icon: f?.icon ? f?.icon : "person",
            label: f?.label ? f?.label : f?.name,
          });
        }
      }
    }

    return custom;
  }
  const columnOptions = getFieldOptions();

  function updateMap(vals, header) {
    let temp = { ...headerMap };
    if (vals.id === "no-import") {
      temp[header?.name] = {};
    } else {
      if (vals.id === "new-column") {
        temp[header?.name].new = true;
      }
      if (vals.id != "email") {
        temp[header?.name].identity = false;
      } else {
        temp[header?.name].identity = true;
      }
      temp[header?.name].column = objectColumns.find((o) => o?.id === vals?.id);
    }
    setHeaderMap(temp);
  }
  return (
    <>
      <div className={styles.headerContainer}>
        {" "}
        <span className={styles.header_4}>Map Contact Object</span>
        <span className={styles.label5}>
          Choose how you want to map the Reaction Contact Object
        </span>
      </div>

      <div className={styles.content}>
        <div className={styles.fileContent} style={{ alignItems: "center" }}>
          <FlexCol style={{ maxWidth: "900px" }}>
            <div
              className={styles.header_3}
              style={{ margin: "0", fontWeight: "400", color: "black" }}
            >
              Set up mapping for the Contact Object.
            </div>
            <div
              className={styles.text_2}
              style={{ margin: "0", fontWeight: "400" }}
            >
              Survey Answers are tied to Contacts. What object in salesforce
              would you like to map to the Reaction Contact Object?
            </div>
            <table
              className={styles.maptable}
              style={{ marginBottom: "1rem", marginTop: "2rem" }}
            >
              <thead>
                <tr>
                  <th>Object from Salesforce</th>
                  <th></th>
                  <th>Object in Reaction</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <SelectFieldCustom
                      value={{ label: "Contact", value: "Contact" }}
                      // onChange={(vals) => {
                      //   updateMap(
                      //     {
                      //       id: "email",
                      //       name: "Email",
                      //       label: "Email",
                      //       name: "email",
                      //       value: "email",
                      //       icon: "at",
                      //     },
                      //     vals
                      //   );
                      // }}
                      icon={<Icon blue iconName={"database"}></Icon>}
                      noCreate
                      options={[]}
                    ></SelectFieldCustom>
                  </td>
                  <td
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <i
                      className="bi-arrow-right"
                      style={{ fontSize: "1.1rem" }}
                    ></i>
                  </td>

                  <td>
                    <div className={styles.columninfile}>
                      <Icon iconName={"person-circle"}></Icon>
                      Contact
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <table
              className={styles.maptable}
              style={{ marginBottom: "1rem", marginTop: "1rem" }}
            >
              <thead>
                <tr>
                  <th>Contact identification field from Salesforce</th>
                  <th></th>
                  <th>Contact identification field in Reaction</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <SelectFieldCustom
                      value={{ label: "Email", value: "Email" }}
                      // onChange={(vals) => {
                      //   updateMap(
                      //     {
                      //       id: "email",
                      //       name: "Email",
                      //       label: "Email",
                      //       name: "email",
                      //       value: "email",
                      //       icon: "at",
                      //     },
                      //     vals
                      //   );
                      // }}
                      icon={<Icon blue iconName={"database"}></Icon>}
                      noCreate
                      options={[]}
                    ></SelectFieldCustom>
                  </td>
                  <td
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <i
                      className="bi-arrow-right"
                      style={{ fontSize: "1.1rem" }}
                    ></i>
                  </td>

                  <td>
                    <div className={styles.columninfile}>
                      <Icon iconName={"person-circle"}></Icon>
                      Email
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </FlexCol>
          <FlexCol style={{ maxWidth: "900px" }}>
            <div
              className={styles.header_3}
              style={{ margin: "0", fontWeight: "400", color: "black" }}
            >
              Set up mapping for the Contact Fields.
            </div>
            <div
              className={styles.text_2}
              style={{ margin: "0", fontWeight: "400" }}
            >
              Are there any fields you like to keep updated with salesforce?
            </div>
            <table className={styles.maptable}>
              <thead>
                <tr>
                  <th>Columns in Salesforce</th>
                  <th></th>
                  <th>Columns in Reaction</th>
                </tr>
              </thead>
              <tbody>
                {headers &&
                  headers?.length > 0 &&
                  headers?.map((header, i) => (
                    <tr key={i}>
                      <td>
                        <div className={styles.columninfile}>
                          <Icon blue iconName={"layout-three-columns"}></Icon>
                          {header?.name}
                        </div>
                      </td>
                      <td
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <i
                          className="bi-arrow-right"
                          style={{ fontSize: "1.1rem" }}
                        ></i>
                      </td>
                      <td>
                        <SelectFieldCustom
                          value={
                            headerMap[header?.name]?.column
                              ? headerMap[header?.name]?.column
                              : {
                                  id: "no-import",
                                  value: "no-import",
                                  icon: "plus",
                                  label: "Create New Column",
                                }
                          }
                          icon={
                            <i
                              className={`bi-${
                                headerMap[header?.name]?.column
                                  ? headerMap[header?.name]?.column?.icon
                                  : "eye-slash"
                              }`}
                            ></i>
                          }
                          onChange={(vals) => {
                            updateMap(vals, header);
                          }}
                          options={columnOptions}
                        ></SelectFieldCustom>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>{" "}
          </FlexCol>
        </div>
      </div>
    </>
  );
}

function SyncOptions({ syncType, setSyncType, options, setOptions }) {
  return (
    <>
      <div className={styles.headerContainer}>
        {" "}
        <span className={styles.header_4}>Map Contact Object</span>
        <span className={styles.label5}>
          Choose how you want to map the Reaction Contact Object
        </span>
      </div>

      <div className={styles.content}>
        <div className={styles.fileContent} style={{ alignItems: "center" }}>
          <div className={styles.page1}>
            {" "}
            <div
              className={styles.header_3}
              style={{ margin: "0", color: "black" }}
            >
              How would you like to sync your contacts?
            </div>
            <div
              className={`${styles.option} ${
                syncType === "reaction" && styles.active2
              }`}
              onClick={() => setSyncType("reaction")}
            >
              <div className={styles.header_4} style={{ margin: "0" }}>
                One Way Reaction
              </div>
              <img
                src={require("assets/images/reactiontosalesforce.png")}
                width={"200px"}
              ></img>
              <span className={styles.text_2}>
                Make Reaction the source of truth. Changes in Reaction are
                updated to Salesforce, but updates in Salesforce are not pushed
                to Reaction.
              </span>
              {syncType === "reaction" && (
                <div
                  style={{ position: "absolute", right: "1rem", top: "40%" }}
                >
                  <Icon iconName="check" blue style={{ fontSize: "1.5rem" }} />
                </div>
              )}
            </div>
            {syncType === "reaction" && (
              <FlexCol
                gap={".5rem"}
                style={{ alignItems: "center", padding: "1rem" }}
              >
                {" "}
                <FlexRow style={{ width: "500px" }}>
                  <Checkbox
                    checked={options?.ctor}
                    onChange={(e) =>
                      setOptions((options) => {
                        return { ...options, ctor: e.target.checked };
                      })
                    }
                  ></Checkbox>
                  <div className={styles.text_2} style={{ color: "black" }}>
                    Create new contacts in Salesforce from Reaction
                  </div>
                </FlexRow>
              </FlexCol>
            )}
            <div
              className={`${styles.option} ${
                syncType === "salesforce" && styles.active2
              }`}
              onClick={() => setSyncType("salesforce")}
            >
              <div className={styles.header_4} style={{ margin: "0" }}>
                One Way Salesforce
              </div>
              <img
                src={require("assets/images/salesforcetoreaction.png")}
                width={"200px"}
              ></img>
              <span className={styles.text_2}>
                Make Salesforce the source of truth. Changes in Salesforce are
                updated to Reaction, but updates in Reaction are not pushed to
                Salesforce.
              </span>
              {syncType === "salesforce" && (
                <div
                  style={{ position: "absolute", right: "1rem", top: "40%" }}
                >
                  <Icon iconName="check" blue style={{ fontSize: "1.5rem" }} />
                </div>
              )}
            </div>
            {syncType === "salesforce" && (
              <FlexCol
                gap={".5rem"}
                style={{ alignItems: "center", padding: "1rem" }}
              >
                <FlexRow style={{ width: "500px" }}>
                  <Checkbox
                    checked={options?.rtoc}
                    onChange={(e) =>
                      setOptions((options) => {
                        return { ...options, rtoc: e.target.checked };
                      })
                    }
                  ></Checkbox>
                  <div className={styles.text_2} style={{ color: "black" }}>
                    Create new contacts in Reaction from Salesforce
                  </div>
                </FlexRow>
              </FlexCol>
            )}
            <div
              className={`${styles.option} ${
                syncType === "two-way" && styles.active2
              }`}
              onClick={() => setSyncType("two-way")}
            >
              <div className={styles.header_4} style={{ margin: "0" }}>
                Two Way Synchronization
              </div>
              <img
                // src={}
                width={"200px"}
              ></img>
              <span className={styles.text_2}>
                Both Salesforce and Reaction Contacts will both stay up to date
                at the same time. Changes in Salesforce are updated to Reaction
                and changes in Reaction are updated to Salesforce.
              </span>
              {syncType === "two-way" && (
                <div
                  style={{ position: "absolute", right: "1rem", top: "40%" }}
                >
                  <Icon iconName="check" blue style={{ fontSize: "1.5rem" }} />
                </div>
              )}
            </div>
            {syncType === "two-way" && (
              <FlexCol
                gap={".5rem"}
                style={{ alignItems: "center", padding: "1rem" }}
              >
                <FlexRow style={{ width: "500px" }}>
                  <Checkbox
                    checked={options?.rtoc}
                    onChange={(e) =>
                      setOptions((options) => {
                        return { ...options, rtoc: e.target.checked };
                      })
                    }
                  ></Checkbox>
                  <div className={styles.text_2} style={{ color: "black" }}>
                    Create new contacts in Reaction from Salesforce
                  </div>
                </FlexRow>
                <FlexRow style={{ width: "500px" }}>
                  <Checkbox
                    checked={options?.ctor}
                    onChange={(e) =>
                      setOptions((options) => {
                        return { ...options, ctor: e.target.checked };
                      })
                    }
                  ></Checkbox>
                  <div className={styles.text_2} style={{ color: "black" }}>
                    Create new contacts in Salesforce from Reaction
                  </div>
                </FlexRow>
              </FlexCol>
            )}
            <div
              className={`${styles.option} ${
                syncType === "none" && styles.active2
              }`}
              onClick={() => setSyncType("none")}
            >
              <div className={styles.header_4} style={{ margin: "0" }}>
                Do not Sync
              </div>
              <img
                // src={}
                width={"200px"}
              ></img>
              <span className={styles.text_2}>
                Do not sync any of the field data. This allows you to push
                contact survey results, without having any other data be
                updated. Identity field will be the only field used to know
                where to connect the data.
              </span>
              {syncType === "none" && (
                <div
                  style={{ position: "absolute", right: "1rem", top: "40%" }}
                >
                  <Icon iconName="check" blue style={{ fontSize: "1.5rem" }} />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
