import React, { useMemo, useState } from "react";
import styles from "./CRM.module.scss";
import {
  useFetchOrganizationLinkedAccounts,
} from "api/resources/organization/organization";
import { Loading } from "components/Loading/Loading";
import { useNavigate, useParams } from "react-router-dom";
import FlexRow from "components/layouts/FlexRow/FlexRow";
import Button from "components/Button/Button";
import Icon from "components/Icon/Icon";
import Modal from "components/ReactModal/ReactModal";
import ConnectSalesProcess from "./ConnectSalesProcess";
import FlexCol from "components/layouts/FlexColumn/FlexCol";
import { deleteLinkedAccount } from "api/resources/organization/linked_account";

function CRM({ roles, user, updateRoute }) {
  const { id } = useParams();
  const MyOrg = useFetchOrganizationLinkedAccounts(id);

  useMemo(() => {
    if (updateRoute) {
      updateRoute(6);
    }
  }, []);
  return (
    <div className={styles.page}>
      {MyOrg.isLoading && <Loading></Loading>}
      {MyOrg.isSuccess && (
        <CRMContent
          organization={MyOrg.data.organization}
          role={roles}
          user={user}
          refetch={MyOrg.refetch}
          updateRoute={updateRoute}
        />
      )}
    </div>
  );
}

export default CRM;

const providers = [
  {
    value: "Salesforce",
    label: (
      <div
        style={{
          display: "flex",
          gap: "1rem",
          alignItems: "center",
          // width: "150px",
        }}
      >
        <img
          src={require("assets/images/Salesforce.png")}
          style={{ width: "50px" }}
        ></img>
        <FlexCol>
          <span style={{ whiteSpace: "nowrap", color: "black" }}>
            Salesforce Account
          </span>
          <span className={styles.text_3}>
            Keep your contacts and results in sync with Salesforce objects
          </span>
        </FlexCol>
      </div>
    ),
  },
  {
    value: "HubSpot",
    label: (
      <div
        style={{
          display: "flex",
          gap: "1rem",
          alignItems: "center",
          width: "150px",
        }}
      >
        <img
          src={require("assets/images/hubspot.webp")}
          style={{ width: "50px" }}
        ></img>
        HubSpot
      </div>
    ),
  },
  {
    value: "Pipedrive",
    label: (
      <div
        style={{
          display: "flex",
          gap: "1rem",
          alignItems: "center",
          width: "150px",
        }}
      >
        <img
          src={require("assets/images/pipedrive.png")}
          style={{ width: "50px" }}
        ></img>
        Pipedrive
      </div>
    ),
  },
];

function CRMContent({ organization, role, user, refetch, updateRoute }) {
  const [show, setShow] = useState();

  const deleteLink = deleteLinkedAccount();

  function removeLinkedAccount(id) {
    deleteLink.mutate(
      {
        id: id,
        orgId: organization.id,
      },
      {
        onSuccess: () => {
          refetch();
          location.reload();
        },
      }
    );
  }

  const navigate = useNavigate();

  return (
    <div className={styles.page}>
      {show === "Salesforce" && (
        <Modal
          onClose={() => setShow(false)}
          show={show}
          modalStyle={{ borderRadius: ".5rem", width: "500px" }}
          dark
        >
          <ConnectSalesProcess
            organization={organization}
            setShow={setShow}
            show={show}
            user={user}
          ></ConnectSalesProcess>
        </Modal>
      )}

      <div className={styles.linkpage}>
        <FlexCol style={{ maxWidth: "500px", alignItems: "center" }}>
          <div
            className={`${styles.header_3} `}
            style={{ fontWeight: "400", color: "black", margin: "0" }}
          >
            Connect Your External Accounts (Beta)
          </div>
          <div className={styles.text_2} style={{ textAlign: "center" }}>
            Get the most out of all your services when connected
          </div>
        </FlexCol>
        <div className={styles.linkpagecontent}>
          <FlexRow
            style={{ flexDirection: "column", alignItems: "flex-start" }}
            start
            gap={"2rem"}
          >
            <div className={styles.accounts}>
              {providers.map((p, i) => (
                <React.Fragment key={i}>
                  <div className={styles.provider}>
                    {p.label}
                    {!organization?.linkedAccounts?.find(
                      (l) => l.accountType === p.value
                    ) ? (
                      <>
                        {p.value === "Salesforce" ? (
                          <Button white shadow onClick={() => setShow(p.value)} style={{gap: ".5rem"}}>
                            <Icon sapphire iconName="plug"></Icon> Connect
                          </Button>
                        ) : (
                          <div></div>
                        )}
                      </>
                    ) : (
                      <FlexRow style={{ width: "fit-content" }}>
                        <Button
                          shadow
                          height={2.1}
                          optionHeight={40}
                          options={[
                            {
                              onClick: () => {
                                updateRoute(8);
                                navigate(
                                  `/organization/${organization?.id}/${p.value}`
                                );
                              },
                              data: (
                                <>
                                  <Icon sapphire iconName={"pencil"}></Icon>{" "}
                                  Edit
                                </>
                              ),
                            },
                            {
                              onClick: () =>
                                removeLinkedAccount(
                                  organization?.linkedAccounts?.find(
                                    (l) => l.accountType === p.value
                                  )?.id
                                ),
                              data: (
                                <>
                                  <Icon red iconName={"outlet"}></Icon>
                                  Disconnect
                                </>
                              ),
                            },
                          ]}
                          style={{ gap: ".5rem" }}
                        >
                          Actions <i className="bi-chevron-down"></i>
                        </Button>
                      </FlexRow>
                    )}
                  </div>
                </React.Fragment>
              ))}{" "}
            </div>
          </FlexRow>
        </div>
      </div>
    </div>
  );
}
