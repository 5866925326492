import React from "react";
import styles from "./SettingToggle.module.scss";
import Checkbox from "components/inputs/input_fields/CheckboxBlue/Checkbox";
import { ToggleSwitch } from "components/inputs/input_fields/ToggleSwitch/ToggleSwitch";
import FlexRow from "components/layouts/FlexRow/FlexRow";

function SettingToggle({ onCheck, checked, text, style, textStyle, thick }) {
  return (
    <div
      className={`${styles.settingContainer} ${
        thick ? styles.thickContainer : ""
      }`}
      style={style}
    >
      <div
        className={`${styles.setting} ${thick ? styles.thick : ""}`}
        style={textStyle}
      >
        {text ? text : "New Setting"}
      </div>
      <FlexRow fit gap={".5rem"}>
        {/* <ToggleSwitch
          startChecked={checked}
          handleCheck={(e) => (onCheck ? onCheck(e) : "")}
          className={styles.check}
        ></ToggleSwitch> */}
        <Checkbox checked={checked}
          onChange={(e) => (onCheck ? onCheck(e) : "")}
          className={styles.check}></Checkbox>
        <div className={styles.text_3} style={{width: "20px"}}>{checked ? "Yes" : "No"}</div>
      </FlexRow>
    </div>
  );
}

export default SettingToggle;
