import FlexRow from "components/layouts/FlexRow/FlexRow";
import { Accordian } from "./Accordian";
import styles from "./Accordian.module.scss";
import FlexCol from "components/layouts/FlexColumn/FlexCol";
import { NumberInput } from "components/inputs/input_fields/NumberInput/NumberInput";

export function Settings({ show, setShow, decimals, setDecimals, given }) {
  return (
    <Accordian
      show={show}
      setShow={setShow}
      title="Settings"
      symbol={<i className="bi-gear"></i>}
    >
      {/* <div className={styles.title}>Settings</div> */}

      <FlexCol>
        <FlexRow>
          <NumberInput
            startNumber={decimals ? decimals : 0}
            handleNumberChange={setDecimals}
            min={0}
            max={6}
            disable={given}
          ></NumberInput>
          <div className={styles.label}>Decimal Places</div>
        </FlexRow>
      </FlexCol>
    </Accordian>
  );
}
