import React from "react";
import styles from "./ContactSales.module.scss";
import { SelectFieldCustom, TextFieldSimple } from "components/inputs";
import FlexRow from "components/layouts/FlexRow/FlexRow";
import Checkbox from "components/inputs/input_fields/CheckboxBlue/Checkbox";
import { TextArea } from "components/inputs/input_fields/TextArea/TextArea";
import FlexCol from "components/layouts/FlexColumn/FlexCol";
import Button from "components/Button/Button";

function ContactSales({}) {
  return (
    <form className={styles.form}>
      <div className={styles.header_3} style={{ margin: "0", padding: "0" }}>
        Connect With a Reaction Expert
      </div>
      <div className={styles.text_1} style={{ margin: "0 .15rem" }}>
        Submit this form and we'll aim to respond within one business day
      </div>
      <FlexRow>
        <TextFieldSimple
          label="First Name"
          icon="person-circle"
        ></TextFieldSimple>
        <TextFieldSimple
          label="Last Name"
          icon="person-circle"
        ></TextFieldSimple>
      </FlexRow>

      <TextFieldSimple type="email" icon="at" label="Email"></TextFieldSimple>
      <FlexRow>
        <TextFieldSimple
          type="phone"
          icon="telephone"
          label="Phone"
        ></TextFieldSimple>
        <TextFieldSimple
          type="text"
          icon="building"
          label="Company"
        ></TextFieldSimple>
      </FlexRow>
      <SelectFieldCustom
        label={"Organization Type"}
        options={[
          { value: "Healthcare Provider", label: "Healthcare Provider" },
          { value: "Non-Profit", label: "Non-profit" },
          { value: "Med-Tech", label: "Med-Tech" },
          { value: "Life Sciences", label: "Life Sciences" },
          { value: "Health Insurance", label: "Health Insurance" },
          { value: "Other", label: "Other" },
        ]}
      ></SelectFieldCustom>
      {/* <div className={styles.header_4} style={{ margin: "0" }}>
        What kind of follow-up would you like:{" "}
      </div> */}

      {/* <FlexRow>
        <Checkbox></Checkbox>
        <div className={styles.text_2}>A demo of Reaction Platform</div>
      </FlexRow>
      <FlexRow>
        <Checkbox></Checkbox>
        <div className={styles.text_2}>A phone call</div>
      </FlexRow>
      <FlexRow>
        <Checkbox></Checkbox>
        <div className={styles.text_2}>Other...</div>
      </FlexRow>
      <FlexCol>
        <div className={styles.label6}>If other, please specify:</div>
        <TextArea style={{ height: "120px" }}></TextArea>
      </FlexCol> */}
      <Button
        shadow
        blue
        height={"50px"}
        width={"100px"}
        style={{ fontSize: "1.1rem" }}
        onClick={() => undefined}
      >
        Submit
      </Button>
    </form>
  );
}

export default ContactSales;
