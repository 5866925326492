import { Accordian } from "./Accordian";
import Filter from "pages/results/Charts/Settings/Filter/Filter";
import { useFetchAllProjects } from "api/resources/projects/projects";
import { Loading } from "components/Loading/Loading";

export function Filters({ show, setShow, filters, onChange }) {
  return (
    <Accordian
      show={show}
      setShow={setShow}
      title={"Filters"}
      symbol={<i className="bi-funnel"></i>}
    >
      <Inside filters={filters} onChange={onChange} />
    </Accordian>
  );
}

function Inside({ filters, onChange }) {
  const fetchProjects = useFetchAllProjects();

  return (
    <>
      {fetchProjects.isLoading && <Loading />}
      {fetchProjects.isSuccess && (
        <Filter
          chosenFilter={filters}
          updateChosenFilters={onChange}
          projects={fetchProjects.data.allProjects}
        ></Filter>
      )}
    </>
  );
}
