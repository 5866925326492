import React, { useEffect, useState, useRef } from "react";
import Button from "components/Button/Button";
import { SelectField, SelectFieldCustom } from "components/inputs";
import { Label } from "components/layouts/Label/Label";
import styles from "./Share.module.scss";
import avatar from "assets/images/blank-profile-picture.png";
import {
  useFetchReportUserInfo,
  useUpdateReport,
  useUpdateSharedReport,
} from "api/resources/organization/reports";
import { Loading } from "components/Loading/Loading";
import {
  useCreateSharedReport,
  useDeleteShared,
  useFetchSharedByReportId,
} from "api/resources/organization/share";
import { useParams } from "react-router-dom";
import { useFetchUsersShare, useGetCurrentUser } from "api/resources/organization/users";
import { ErrorPage } from "pages/error_page/ErrorPage";
import { ToggleSwitch } from "components/inputs/input_fields/ToggleSwitch/ToggleSwitch";
import parse from "html-react-parser";
import TextEditorTry from "components/inputs/input_fields/TextEditorTry/TextEditorTry";
import { HorizontalBar } from "components/layouts/HorizontalBar/HorizontalBar";
import { Player } from "@lottiefiles/react-lottie-player";

export function Share({ setShow }) {
  // console.log(report.status);
  const { id, reportid } = useParams();
  const getCurrUser = useGetCurrentUser();
  const fetchReportInfo = useFetchReportUserInfo(reportid);
  const player1 = useRef(null);
  const ref = useRef(null);
  // console.log(reportid);
  const [publishLoading, setPublishLoading] = useState("");
  // const [publishStatus, setPublishStatus] = useState(report.status);
  // const fetchProjectReports = useFetchProjectReports(id);
  const [searchString, setSearchString] = useState("");
  const fetchSharedReport = useFetchSharedByReportId(reportid);
  const unsharedUsers = useFetchUsersShare(reportid, searchString);
  const updateSharedReport = useUpdateSharedReport();
  const deleteShare = useDeleteShared();
  // console.log(publishStatus);
  const updateReport = useUpdateReport();
  const shareReport = useCreateSharedReport();
  const [sharedUsers, setSharedUsers] = useState([]);
  const [permissionVal, setPermissionVal] = useState(false);
  const [sendEmail, setSendEmail] = useState(true);
  const [showOptions, setShowOptions] = useState(false);

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setShowOptions(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  useEffect(() => {
    unsharedUsers.refetch();
  }, [searchString]);

  function createNewShare() {
    for (let i = 0; i < sharedUsers.length; i++) {
      let user = sharedUsers[i];

      shareReport.mutate(
        {
          data: {
            canEdit: permissionVal,
            reportId: reportid,
            userId: user.value,
            isOrg: user.isOrg,
            isRole: user.isRole,
          },
        },
        {
          onSuccess: () => {
            if (i + 1 >= sharedUsers.length) {
              unsharedUsers.refetch();
              fetchSharedReport.refetch();
              setSharedUsers([]);
            }
          },
        }
      );
    }
  }

  function deleteSharedUser(id) {
    deleteShare.mutate(
      { sharedUserId: id },
      {
        onSuccess: (data) => {
          console.log(data);
          unsharedUsers.refetch();
          fetchSharedReport.refetch();
        },
      }
    );
  }
  function changeReportStatus(status) {
    setPublishLoading(
      <div style={{ display: "flex", flexDirection: "row" }}>
        <Loading height={20} width={20}></Loading>
      </div>
    );
    let data;
    // if (status === "Published") {
    //   data = {
    //     status: status,
    //     organizationId: roles.organizationId,
    //     isUser: false,
    //   };
    // } else {
    //   data = { status: status, organizationId: null, isUser: true};
    // }
    data = {
      status: status,
      organizationId: getCurrUser.data.me.organizationId,
      isUser: false,
    };
    updateReport.mutate(
      {
        data: data,
        id: reportid,
      },
      {
        onSuccess: (data) => {
          setPublishLoading("");
        },
      }
    );
  }

  function getUsersWithAccess() {
    const report = fetchReportInfo.data.report;
    let usersWithAccess = [
      {
        value:
          report.userId === getCurrUser.data.me.id
            ? report.user?.firstName + " " + report.user?.lastName + " (You)"
            : report.user?.firstName + " " + report.user?.lastName,
        label: "Owner",
        email: report.user?.email,
        imageURL: report.user?.image?.imageURL,
      },
    ];

    return usersWithAccess;
  }

  function getshareusers(users) {
    let temp = [...users];

    for (let i = 0; i < temp.length; i++) {
      if (typeof temp[i].label === "string") {
        temp[i].label = parse(temp[i].label);
      }
    }

    return temp;
  }

  const access = [
    { value: "Can Edit", label: "Can Edit" },
    { value: "View Only", label: "View Only" },
  ];

  function changeUserAccess(sharedReport, value) {
    if (value === "Can Edit") {
      sharedReport.canEdit = true;
      updateSharedReport.mutate({
        data: { canEdit: true },
        id: sharedReport.id,
      });
    } else if (value === "View Only") {
      sharedReport.canEdit = false;
      updateSharedReport.mutate({
        data: { canEdit: false },
        id: sharedReport.id,
      });
    }
  }

  return (
    <>
      {(unsharedUsers.isError ||
        getCurrUser.isError ||
        fetchReportInfo.isError ||
        fetchSharedReport.isError) && <ErrorPage />}
      {unsharedUsers.isSuccess &&
        getCurrUser.isSuccess &&
        fetchReportInfo.isSuccess &&
        fetchSharedReport.isSuccess && (
          <div className={styles.shareContainer}>
            <div
              className={styles.shareContent}
              style={{ marginLeft: window.innerWidth < 570 ? "0em" : "" }}
            >
              <div className={styles.shareNav}>
                <span>Share "{fetchReportInfo.data.report.name}"</span>{" "}
                <i className="bi bi-x-lg" onClick={() => setShow(false)}></i>
              </div>
              <div className={styles.shareBody}>
                <div className={styles.shareSelect}>
                  <SelectFieldCustom
                    options={
                      unsharedUsers.isSuccess
                        ? getshareusers(unsharedUsers?.data?.usersForShare)
                        : []
                    }
                    placeholder={"Add people or user groups to give  access..."}
                    selectMultiple={true}
                    onChange={(val, remove) => {
                      if (!remove) {
                        let u = [...sharedUsers];
                        u.push(val);
                        setSharedUsers(u);
                      } else {
                        let newValues = [...sharedUsers];
                        let index = newValues.indexOf(val);
                        newValues.splice(index, 1);
                        setSharedUsers(newValues);
                      }
                    }}
                    values={sharedUsers}
                    noCreate
                    // onInputChange={(val) => setSearchString(val)}
                    searchString={searchString}
                    setSearchString={setSearchString}
                    // searchable
                    style={{ height: "fit-content" }}
                    containerStyle={{ height: "100%", minHeight: "50px" }}
                  ></SelectFieldCustom>
                  {/* <Button height={40} onClick={() => createNewShare()}>
                    Share
                  </Button> */}
                  {sharedUsers.length > 0 && (
                    <div
                      className={styles.selectContainer}
                      style={{ fontSize: ".9em" }}
                    >
                      <SelectField
                        onChange={(value) =>
                          value.value === "Can Edit"
                            ? setPermissionVal(true)
                            : setPermissionVal(false)
                        }
                        options={access}
                        value={
                          permissionVal
                            ? { value: "Can Edit", label: "Can Edit" }
                            : { value: "View Only", label: "View Only" }
                        }
                        noCreate
                      ></SelectField>
                    </div>
                  )}
                </div>

                {sharedUsers.length > 0 && (
                  <>
                    <div
                      className={styles.publishAccess}
                      style={{ padding: "0px 10px" }}
                    >
                      <span>
                        <div
                          className={styles.share}
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            gap: ".5em",
                          }}
                        >
                          <ToggleSwitch
                            startChecked={sendEmail}
                            handleCheck={(val) => setSendEmail(val)}
                          ></ToggleSwitch>
                          Send Email to notify
                        </div>
                      </span>
                    </div>

                    {sendEmail && (
                      <div className={styles.message}>
                        <TextEditorTry
                          editable
                          border
                          placeholder="Type Message..."
                          defaultStyle={{
                            color: "#616565",
                          }}
                          height={"100px"}
                        ></TextEditorTry>
                      </div>
                    )}
                  </>
                )}

                {sharedUsers.length === 0 && (
                  <>
                    <div className={styles.peopleWithAccess}>
                      <Label>People With Access</Label>
                      <table>
                        <tbody>
                          {getUsersWithAccess().map((user, i) => (
                            <tr key={i}>
                              <td>
                                <div className={styles.user}>
                                  <img
                                    src={
                                      user?.imageURL ? user?.imageURL : avatar
                                    }
                                    className={styles.avatar}
                                    alt="user profile image"
                                  />
                                  <div className={styles.content}>
                                    <span className={styles.name}>
                                      {user.value}
                                    </span>
                                    <span className={styles.email}>
                                      {user.email}
                                    </span>
                                  </div>
                                </div>
                              </td>
                              <td
                                style={{
                                  color:
                                    user.label === "Owner" ? "lightgray" : "",
                                }}
                              >
                                {user.label}
                              </td>
                            </tr>
                          ))}
                          {fetchSharedReport.data.sharedByReportId &&
                            fetchSharedReport.data.sharedByReportId.map(
                              (user) => (
                                <>
                                  {" "}
                                  {user?.userId && (
                                    <tr>
                                      <td>
                                        <div className={styles.user}>
                                          <img
                                            src={
                                              user?.user?.image?.imageURL
                                                ? user?.user?.image?.imageURL
                                                : avatar
                                            }
                                            className={styles.avatar}
                                            alt="user profile image"
                                          />
                                          <div className={styles.content}>
                                            <span className={styles.name}>
                                              {user.user?.firstName +
                                                " " +
                                                user.user?.lastName}
                                            </span>
                                            <span className={styles.email}>
                                              {user.user?.email}
                                            </span>
                                          </div>
                                        </div>
                                      </td>
                                      <td className={styles.roleContainer}>
                                        {/* <select
                                          value={
                                            user.canEdit === true
                                              ? "Can Edit"
                                              : "View Only"
                                          }
                                          className={styles.roleEdit}
                                          onChange={(e) =>
                                            changeUserAccess(
                                              user,
                                              e.target.value
                                            )
                                          }
                                        >
                                          <option value={"Can Edit"}>
                                            Can Edit
                                          </option>
                                          <option value={"View Only"}>
                                            View Only
                                          </option>
                                          <option value={"owner"}>
                                            Transfer Ownership
                                          </option>
                                        </select> */}
                                        <div
                                          className={styles.roleEdit}
                                          onClick={() => setShowOptions(true)}
                                        >
                                          {user.canEdit === true
                                            ? "Can Edit"
                                            : "View Only"}
                                          <i className="bi-chevron-down"></i>
                                        </div>
                                        {getCurrUser.data.me.id !=
                                          user.userId && (
                                          <i
                                            className="bi bi-trash"
                                            onClick={() =>
                                              deleteSharedUser(user.id)
                                            }
                                          ></i>
                                        )}

                                        {showOptions && (
                                          <div
                                            className={styles.options}
                                            ref={ref}
                                          >
                                            <span
                                              onClick={() =>
                                                changeUserAccess(
                                                  user,
                                                  "View Only"
                                                )
                                              }
                                            >
                                              View Only{" "}
                                              {!user.canEdit && (
                                                <Player
                                                  autoplay
                                                  onEvent={(e) => {
                                                    if (e === "play") {
                                                      setTimeout(() => {
                                                        player1?.current?.pause();
                                                      }, 2000);
                                                    }
                                                  }}
                                                  ref={player1}
                                                  src={require("assets/animations/checkmarkgreen.json")}
                                                  style={{
                                                    height: "20px",
                                                    width: "20px",
                                                  }}
                                                />
                                              )}
                                            </span>
                                            <span
                                              onClick={() =>
                                                changeUserAccess(
                                                  user,
                                                  "Can Edit"
                                                )
                                              }
                                            >
                                              Can Edit{" "}
                                              {user.canEdit && (
                                                <Player
                                                  autoplay
                                                  onEvent={(e) => {
                                                    if (e === "play") {
                                                      setTimeout(() => {
                                                        player1?.current?.pause();
                                                      }, 2000);
                                                    }
                                                  }}
                                                  ref={player1}
                                                  src={require("assets/animations/checkmarkgreen.json")}
                                                  style={{
                                                    height: "20px",
                                                    width: "20px",
                                                  }}
                                                />
                                              )}
                                            </span>
                                            <HorizontalBar
                                              height={2}
                                              width={"90%"}
                                              style={{ margin: "0em" }}
                                            ></HorizontalBar>
                                            <span>Transfer Ownership</span>
                                          </div>
                                        )}
                                      </td>
                                    </tr>
                                  )}
                                  {user?.organizationId && (
                                    <tr>
                                      <td>
                                        <div className={styles.user}>
                                          <img
                                            src={avatar}
                                            className={styles.avatar}
                                            alt="user profile image"
                                          />
                                          <div className={styles.content}>
                                            <span className={styles.name}>
                                              {user.organization?.name}
                                            </span>
                                            <span className={styles.email}>
                                              Shared with everyone in "
                                              {user.organization?.name}"
                                            </span>
                                          </div>
                                        </div>
                                      </td>
                                      <td>
                                        {/* <select
                                          value={
                                            user.canEdit === true
                                              ? "Can Edit"
                                              : "View Only"
                                          }
                                          className={styles.roleEdit}
                                        >
                                          <option value={"Can Edit"}>
                                            Can Edit
                                          </option>
                                          <option value={"View Only"}>
                                            View Only
                                          </option>
                                        </select> */}
                                        <span className={styles.role}>
                                          User Roles
                                        </span>
                                        {getCurrUser.data.me.id !=
                                          user.userId && (
                                          <i
                                            className="bi bi-trash"
                                            onClick={() =>
                                              deleteSharedUser(user.id)
                                            }
                                          ></i>
                                        )}
                                      </td>
                                    </tr>
                                  )}
                                  {user?.roleId && (
                                    <tr>
                                      <td>
                                        <div className={styles.user}>
                                          <img
                                            src={avatar}
                                            className={styles.avatar}
                                            alt="user profile image"
                                          />
                                          <div className={styles.content}>
                                            <span className={styles.name}>
                                              {user.role?.name}
                                            </span>
                                            <span className={styles.email}>
                                              Shared with everyone in "
                                              {user.role?.name}"
                                            </span>
                                          </div>
                                        </div>
                                      </td>
                                      <td>
                                        <span className={styles.role}>
                                          User Roles
                                        </span>
                                        {getCurrUser.data.me.id !=
                                          user.userId && (
                                          <i
                                            className="bi bi-trash"
                                            onClick={() =>
                                              deleteSharedUser(user.id)
                                            }
                                          ></i>
                                        )}
                                      </td>
                                    </tr>
                                  )}
                                </>
                              )
                            )}
                        </tbody>
                      </table>
                    </div>

                    <div className={styles.publishAccess}>
                      <Label>Access Properties</Label>
                      <span>
                        <i className="bi-person-lock"></i> Only people with
                        access can open up the report link
                      </span>
                      <span>
                        "Can Edit" allows a user to share the report, change
                        permissions, and edit the report
                      </span>
                    </div>
                    {/* {canEdit && roles.canCreateEditOrgReports && (
                      <div className={styles.publishAccess}>
                        <Label>Organization Access</Label>
                        <span>
                          <div
                            className={styles.share}
                            style={{ display: "flex", flexDirection: "row" }}
                          >
                            <ToggleSwitch
                              startChecked={currReport.status === "Published"}
                              handleCheck={() =>
                                changeReportStatus(
                                  currReport.status === "Published"
                                    ? "Not Published"
                                    : "Published"
                                )
                              }
                            ></ToggleSwitch>
                            {publishLoading}
                          </div>
                          <div>
                            <div>
                              <i className="bi bi-people"></i> Everyone at{" "}
                              <a>{organization.name}</a> can view this report
                            </div>
                          </div>
                        </span>
                      </div>
                    )} */}
                  </>
                )}
              </div>

              {sharedUsers.length > 0 && (
                <div className={styles.shareFooter}>
                  <Button shadow onClick={() => createNewShare()}>
                    Share
                  </Button>
                </div>
              )}
            </div>
          </div>
        )}
    </>
  );
}
