import React, { useEffect, useState } from "react";
import styles from "./SurveyPath.module.scss";
import FlexRow from "components/layouts/FlexRow/FlexRow";
import Icon from "components/Icon/Icon";
import { ProgressBar } from "components/QuestionConstruction/ProgressBar/ProgressBar";
import FlexCol from "components/layouts/FlexColumn/FlexCol";
import Button from "components/Button/Button";

function SurveyPath({ paths }) {
  // useLayoutEffect(() => {
  //   if (paths && paths.length > 0) {
  //     let xy1 = {
  //       m_x: 0,
  //       m_y: 0,
  //     };

  //     let xy2 = {
  //       m_x: 200,
  //       m_y: 0,
  //     };

  //     for (let i = 0; i < paths.length - 1; i++) {
  //       // debugger;
  //       // let path = paths[i];
  //       // if (i != 0) {
  //       //   xy1 = {
  //       //     m_x: xy2.m_x,
  //       //     m_y: xy2.m_y,
  //       //   };
  //       //   if (i % 2 === 0) {
  //       //     xy2 = {
  //       //       m_x: xy2.m_x + 150,
  //       //       m_y: 0,
  //       //     };
  //       //   } else {
  //       //     xy2 = {
  //       //       m_x: xy2.m_x + 150,
  //       //       m_y: 0,
  //       //     };
  //       //   }
  //       // }

  //       var dotA = document.getElementById(`dot${i}`);
  //       if (i % 2 === 1) {
  //         // dotA.style.marginTop = "100px"
  //       }
  //       xy1 = {
  //         m_x: dotA.offsetLeft,
  //         m_y: dotA.offsetTop,
  //       };
  //       // dotA.style.left = dotA.offsetLeft + "px";
  //       // dotA.style.top = dotA.offsetTop + "px";

  //       var dotB = document.getElementById(`dot${i + 1}`);
  //       xy2 = {
  //         m_x: dotB.offsetLeft,
  //         m_y: dotB.offsetTop,
  //       };
  //       // dotB.style.left = dotB.offsetLeft + "px";
  //       // dotB.style.top = dotB.offsetTop + "px";

  //       var line = document.getElementById(`line${i}`);

  //       var distance = Math.hypot(xy2.m_x - xy1.m_x, xy2.m_y - xy1.m_y);

  //       line.style.width = distance + "px";
  //       line.style.left = xy1.m_x + 7 + "px";
  //       line.style.top = xy1.m_y + 8 + "px";

  //       var angle =
  //         ((Math.atan2(xy1.m_x - xy2.m_x, xy2.m_y - xy1.m_y) + Math.PI / 2.0) *
  //           180) /
  //         Math.PI;
  //       line.style.transform = "rotate(" + angle + "deg)";
  //       line.style.display = "block";
  //     }
  //   }
  // }, [window.innerWidth]);

  
  const [count, setCount] = useState();
  useEffect(() => {
    let count = 0;
    for (let item of paths) {
      if (item?.done) {
        count++;
      }
    }
    setCount(count);
  }, []);
  return (
    <div className={styles.pathContainer}>
      <FlexRow gap=".5rem" justify="space-between" >
        <FlexRow gap=".5rem">
          <Icon blue iconName={"fire"} style={{ fontSize: "1.3rem" }}></Icon>
          <div
            className={styles.header_4}
            style={{ margin: "0", fontWeight: "400" }}
          >
            Survey Setup
          </div>
        </FlexRow>
        <div
          className={styles.header_4}
          style={{
            margin: "0",
            fontWeight: "400",
            display: "flex",
            alignItems: "flex-end",
            gap: ".5rem",
          }}
        >
          {Math.floor((count / 3) * 100)}%{" "}
          <span className={styles.text_2} style={{ paddingBottom: ".1rem" }}>
            progress
          </span>
        </div>
      </FlexRow>

      <ProgressBar
        numQuestions={3}
        numAnswered={count}
        settings={{ showProgBar: true }}
        style={{ height: ".5rem" }}
        container={{ padding: "0", paddingBottom: "1rem" }}
      ></ProgressBar>

      <FlexRow gap=".5rem" justify="space-around" align={"flex-start"} style={{flexWrap: "wrap", rowGap: "3rem"}} >
        {paths.map((path, i) => (
          <FlexRow align={"flex-start"} key={i} style={{height:"185px", width: "100%", maxWidth: "360px", }} fit>
            {!path.done && <div className={styles.circle}></div>}
            {path.done && (
              <Icon
                style={{ fontSize: "2.5rem" }}
                green
                iconName={"check-circle-fill"}
              ></Icon>
            )}
            <FlexCol fit justify={"space-between"} style={{height: "100%", paddingRight: path?.id === 3 ? "4rem" : "" }}>
              <FlexCol fit>
                <div
                  className={`${styles.header_5} ${
                    path.done ? styles.green : styles.blue
                  }`}
                  style={{ margin: "0" }}
                >
                  Step {path.id}
                </div>
                <div
                  className={`${styles.header_5}`}
                  style={{ margin: "0", fontWeight: "400" }}
                >
                  {path.name}
                </div>
                <ul className={styles.list}>
                  {path?.todo?.map((text, i2) => (
                    <li className={styles.text_2} key={i2}>
                      {text}
                    </li>
                  ))}
                </ul>
              </FlexCol>
              <FlexRow justify={"center"} style={{ marginTop: "1rem" }}>
                {!path.done && (
                  <Button blue shadow width={100} onClick={path?.to}>
                    Start
                  </Button>
                )}
                {path.done && (
                  <Button green shadow width={100} style={{ gap: ".5rem" }}>
                    <i className="bi-check" style={{ fontSize: "1.2rem" }}></i>
                    Ready
                  </Button>
                )}
              </FlexRow>
            </FlexCol>
          </FlexRow>
        ))}
      </FlexRow>

      {/* {paths.map((path, i) => (
        <React.Fragment key={i}>
          <div
            id={`dot${i}`}
            className={`${styles.dot} ${!path.done && styles.hollow}`}
            style={{pointerEvents:  i > 0 && !paths[i - 1].done ? "none" : "" }}
            onClick={
              i > 0 && !paths[i - 1].done ? undefined : path.to
            }
          >
            <div className={styles.name}>
              <i className={path.icon}></i>
              {path.name}
            </div>
          </div>
          {i != paths.length && (
            <div
              id={`line${i}`}
              className={`${!path.done && styles.hollowLine} ${
                path.done && styles.line
              } `}
            ></div>
          )}
        </React.Fragment>
      ))} */}
    </div>
  );
}

export default SurveyPath;
