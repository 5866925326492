import React, { useEffect, useState } from "react";
import styles from "./PushResultsSetup.module.scss";
import FlexCol from "components/layouts/FlexColumn/FlexCol";
import Button from "components/Button/Button";
import FlexRow from "components/layouts/FlexRow/FlexRow";
import { ProgressBar } from "components/QuestionConstruction/ProgressBar/ProgressBar";
import { SelectFieldCustom } from "components/inputs";
import Icon from "components/Icon/Icon";
import Checkbox from "components/inputs/input_fields/CheckboxBlue/Checkbox";

export function PushResultsSetup() {
  const [page, setPage] = useState(0);
  const [go, setGo] = useState(false);
  const [format, setFormat] = useState();
  const [objectSetup, setObjectSetup] = useState();
  const [pushType, setPushType] = useState();
  //   const [progress, setProgress]
  return (
    <FlexCol style={{ height: "100%" }}>
      <div className={styles.headerContainer}>
        <FlexCol gap={".5rem"}>
          <div className={styles.header_3}>
            Set Up Push Results to Salesforce
          </div>
          <div className={styles.text_2}>
            Determine your settings for how the results will flow into
            Salesforce
          </div>
        </FlexCol>
        <div className={styles.progress}>
          {" "}
          <div style={{ width: "100%" }}>
            <ProgressBar
              numAnswered={page + 1}
              numQuestions={3}
              settings={{
                showProgBar: true,
                //   showProgQs: true,
                //   progBarEmptyColor: "gray",
                //   progBarColor: "blue",
              }}
              stepName={"Step"}
            ></ProgressBar>
          </div>
        </div>
      </div>
      <div className={styles.content}>
        {page === 0 && (
          <DataFlow setNext={setGo} format={format} setFormat={setFormat} />
        )}
        {page === 1 && (
          <ObjectSetup
            setNext={setGo}
            // format={objectSetup}
            // setFormat={setObjectSetup}
          />
        )}

        {page === 2 && (
          <ExistingData
            setNext={setGo}
            format={pushType}
            setFormat={setPushType}
          />
        )}
      </div>

      <div className={styles.footerContainer}>
        <FlexRow style={{ width: "fit-content" }}>
          {page === 0 && <Button link>Cancel</Button>}
          {page > 0 && (
            <Button
              link
              onClick={() => {
                setPage((page) => (page -= 1));
                setGo(true);
              }}
            >
              Previous
            </Button>
          )}
          {page < 2 && <Button
            blue
            shadow
            onClick={() => {
              setPage((page) => (page += 1));
              setGo(false);
            }}
            disable={!go}
          >
            Next
          </Button>}
          {page >= 2 && 
          <Button
            blue
            shadow
            // onClick={() => {
            //   setPage((page) => (page += 1));
            //   setGo(false);
            // }}
            disable={!go}
          >
            Finish
          </Button>}
        </FlexRow>
      </div>
    </FlexCol>
  );
}

function DataFlow({ setNext, format, setFormat }) {
  useEffect(() => {
    if (setNext) {
      if (format) {
        setNext(true);
      } else {
        setNext(false);
      }
    }
  }, [format]);

  return (
    <div
      className={styles.settings}
      style={{
        width: "100%",
        maxWidth: "1000px",
        marginLeft: "auto",
        marginRight: "auto",
      }}
    >
      <div
        className={styles.header_3}
        style={{ margin: "0", fontWeight: "400" }}
      >
        How do you want your data to flow into Salesforce?
      </div>
      <div className={styles.text_2} style={{ margin: "0", fontWeight: "400" }}>
        Manually sets up a button that pushes the results when clicked, or have
        it automactically send the results in real time.
      </div>

      <FlexRow
        style={{
          flexWrap: "wrap",
          marginTop: "2rem",
          justifyContent: "center",
        }}
      >
        <div
          className={`${styles.box} ${
            format === "automatically" && styles.boxfill
          }`}
          onClick={() => {
            setFormat(format === "automatically" ? "" : "automatically");
            //   setTemplate(null);
            //   setPage((page) => (page += 1));
          }}
        >
          <div
            className={`${styles.square}`}
            // style={{ border: "4px dotted #a3a4a8" }}
          >
            <i className={"bi-arrow-repeat"} style={{ fontSize: "4.8rem" }}></i>
          </div>
          <div
            className={styles.text_2}
            style={{ margin: "0", fontWeight: "400" }}
          >
            Automatically
          </div>
          <div
            className={styles.text_3}
            style={{ margin: "0", fontWeight: "400" }}
          >
            Real time data flow
          </div>
        </div>
        <div
          className={`${styles.box} ${format === "manually" && styles.boxfill}`}
          onClick={() => {
            setFormat(format === "manually" ? "" : "manually");
            //   setTemplate(null);
            //   setPage((page) => (page += 1));
          }}
        >
          <div className={`${styles.square}`}>
            <i className={"bi-caret-right-square"}></i>
          </div>
          <div
            className={styles.text_2}
            style={{ margin: "0", fontWeight: "400" }}
          >
            Manually
          </div>
          <div
            className={styles.text_3}
            style={{ margin: "0", fontWeight: "400" }}
          >
            On button click
          </div>
        </div>
        <div
          className={`${styles.box} ${format === "schedule" && styles.boxfill}`}
          onClick={() => {
            //   setFormat(format === "copy" ? "" : "copy");
            //   setTemplate(null);
            //   setPage((page) => (page += 1));
          }}
        >
          <div className={`${styles.square}`}>
            <i className={"bi-calendar"}></i>
          </div>
          <div
            className={styles.text_2}
            style={{ margin: "0", fontWeight: "400" }}
          >
            Schedule
          </div>
          <div
            className={styles.text_3}
            style={{ margin: "0", fontWeight: "400" }}
          >
            (Coming Soon)
          </div>
        </div>
        {/* <div
        className={`${styles.box} ${
          format === "scratch" && styles.boxfill
        }`}
        onClick={() => {
          setFormat(format === "scratch" ? "" : "scratch");
          setTemplate(null);
        }}
      >
        <div
          className={`${styles.square}`}
        >
          <i className={"bi-copy"}></i>
        </div>
        <div
          className={styles.text_2}
          style={{ margin: "0", fontWeight: "400" }}
        >
          Import Survey
        </div>
        <div
          className={styles.text_3}
          style={{ margin: "0", fontWeight: "400" }}
        >
          Import a file of a survey
        </div>
      </div> */}
      </FlexRow>
    </div>
  );
}

function ObjectSetup({ format, setFormat, setNext }) {
  useEffect(() => {
    if (setNext) {
      setNext(true);
    }
  }, [format]);
  return (
    <div
      className={styles.settings}
      style={{
        width: "100%",
        maxWidth: "1000px",
        marginLeft: "auto",
        marginRight: "auto",
      }}
    >
      <div
        className={styles.header_3}
        style={{ margin: "0", fontWeight: "400" }}
      >
        Importing results will create the following new objects in salesforce,
        make sure there are no conflicts in your account before you continue.
      </div>
      <div className={styles.text_2} style={{ margin: "0", fontWeight: "400" }}>
        Object Info
      </div>
    </div>
  );
}

function ExistingData({ format, setFormat, setNext }) {
  useEffect(() => {
    if (setNext) {
      if (format) {
        setNext(true);
      } else {
        setNext(false);
      }
    }
  }, [format]);
  return (
    <div
      className={styles.settings}
      style={{
        width: "100%",
        maxWidth: "1000px",
        marginLeft: "auto",
        marginRight: "auto",
      }}
    >
      {/* <div
        className={styles.header_3}
        style={{ margin: "0", fontWeight: "400" }}
      >
        Set up mapping for the Contact Object.
      </div>
      <div className={styles.text_2} style={{ margin: "0", fontWeight: "400" }}>
        Survey Answers are tied to Contacts. What object in salesforce would you
        like to map to the Reaction Contact Object?
      </div>
      <table
        className={styles.maptable}
        style={{ marginBottom: "1rem", marginTop: "2rem" }}
      >
        <thead>
          <tr>
            <th>Object from Salesforce</th>
            <th></th>
            <th>Object in Reaction</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <SelectFieldCustom
                value={{ label: "Contact", value: "Contact" }}
                // onChange={(vals) => {
                //   updateMap(
                //     {
                //       id: "email",
                //       name: "Email",
                //       label: "Email",
                //       name: "email",
                //       value: "email",
                //       icon: "at",
                //     },
                //     vals
                //   );
                // }}
                icon={<Icon blue iconName={"database"}></Icon>}
                noCreate
                options={[]}
              ></SelectFieldCustom>
            </td>
            <td
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <i className="bi-arrow-right" style={{ fontSize: "1.1rem" }}></i>
            </td>

            <td>
              <div className={styles.columninfile}>
                <Icon iconName={"person-circle"}></Icon>
                Contact
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <div
        className={styles.header_3}
        style={{ margin: "0", fontWeight: "400", marginTop: "2rem" }}
      >
        Choose Identification Field
      </div>
      <div className={styles.text_2} style={{ margin: "0", fontWeight: "400" }}>
        Survey Answers are tied to Contacts. What object in salesforce would you
        like to map to the Reaction Contact Object?
      </div>
      <table
        className={styles.maptable}
        style={{ marginBottom: "1rem", marginTop: "2rem" }}
      >
        <thead>
          <tr>
            <th>Field from Salesforce</th>
            <th></th>
            <th>Field in Reaction</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <SelectFieldCustom
                value={{ label: "Email", value: "Email" }}
                // onChange={(vals) => {
                //   updateMap(
                //     {
                //       id: "email",
                //       name: "Email",
                //       label: "Email",
                //       name: "email",
                //       value: "email",
                //       icon: "at",
                //     },
                //     vals
                //   );
                // }}
                icon={<Icon blue iconName={"layout-three-columns"}></Icon>}
                noCreate
                options={[]}
              ></SelectFieldCustom>
            </td>
            <td
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <i className="bi-arrow-right" style={{ fontSize: "1.1rem" }}></i>
            </td>

            <td>
              <div className={styles.columninfile}>
                <Icon iconName={"person-circle"}></Icon>
                Contact
              </div>
            </td>
          </tr>
        </tbody>
      </table> */}
      <div
        className={styles.header_3}
        style={{ margin: "0", fontWeight: "400", marginTop: "2rem" }}
      >
        You have existing Survey Results Data.
      </div>
      <div className={styles.text_2} style={{ margin: "0", fontWeight: "400" }}>
        Would you like to start a transfer of that data?
      </div>
      <FlexCol gap={".5rem"} style={{ alignItems: "center", padding: "1rem" }}>
        <FlexRow style={{ width: "300px" }}>
          <Checkbox checked={format === "push-all"} onChange={() => setFormat("push-all")}></Checkbox>
          <div className={styles.text_2} style={{ color: "black" }}>
            Push all existing data
          </div>
        </FlexRow>
        <FlexRow style={{ width: "300px" }}>
          <Checkbox checked={format === "specific"} onChange={() => setFormat("specific")}></Checkbox>
          <div className={styles.text_2} style={{ color: "black" }}>
            Choose specific surveys to push
          </div>
        </FlexRow>
        <FlexRow style={{ width: "300px" }}>
          <Checkbox checked={format === "none"} onChange={() => setFormat("none")}></Checkbox>
          <div className={styles.text_2} style={{ color: "black" }}>
            Do not push any existing data
          </div>
        </FlexRow>
      </FlexCol>
    </div>
  );
}
