import React, { useState } from "react";
import styles from "./ObjectLayout.module.scss";
import {
  DndContext,
  DragOverlay,
  KeyboardSensor,
  PointerSensor,
  defaultAnnouncements,
  rectIntersection,
  useDroppable,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  SortableContext,
  useSortable,
  arrayMove,
  verticalListSortingStrategy,
  sortableKeyboardCoordinates,
} from "@dnd-kit/sortable";
import DataInput from "./DataInput";
import FlexRow from "../FlexRow/FlexRow";
import Button from "components/Button/Button";
import Groups from "./Groups";
import { CSS } from "@dnd-kit/utilities";
import { useSearchOrgGroups } from "api/resources/organization/organization";
import { Loading } from "components/Loading/Loading";
import { TextFieldSimple } from "components/inputs";
import { useSaveGroupLayout } from "api/resources/contacts/custom_field";
import { shortId } from "components/tables/EditableTable/utils";

function SortableItem({
  id,
  children,
  index,
  layout,
  layoutIndex,
  field,
  row,
  rowId,
  rowIndex,
  removeInput,
}) {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id,
    data: {
      index,
      layout,
      input: true,
      layoutIndex,
      field,
      row,
      rowId,
      rowIndex,
    },
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition: transition,
    opacity: isDragging ? 0.4 : undefined,
  };
  const [showRemove, setShowRemove] = useState(false);

  return (
    <div
      className={styles.sortableItemContainer}
      onMouseEnter={() => setShowRemove(true)}
      onMouseLeave={() => setShowRemove(false)}
    >
      <div
        ref={setNodeRef}
        style={style}
        {...attributes}
        {...listeners}
        className={styles.sortableItem}
      >
        <span style={{ pointerEvents: "none" }}>{children}</span>
      </div>
      {showRemove && (
        <div
          className={styles.remove}
          style={{
            opacity: showRemove ? 1 : 0,
            position: "absolute",
            right: 0,
            bottom: ".25rem",
            zIndex: "1",
          }}
        >
          <Button
            red
            shadow
            onClick={() =>
              removeInput(index, rowIndex, layoutIndex, field?.customField)
            }
          >
            {" "}
            <i className={"bi-trash"}></i>
          </Button>
        </div>
      )}
    </div>
  );
}

function ObjectLayout({ organizationId }) {
  const [searchString, setSearchString] = useState("");
  const [saving, setSaving] = useState("Save Changes");

  const searchGroups = useSearchOrgGroups(searchString, organizationId);
  const saveLayout = useSaveGroupLayout();

  function saveChanges(layout) {
    setSaving(
      <FlexRow>
        <Loading height={40} width={40}></Loading>
        <div>Saving..</div>
      </FlexRow>
    );
    saveLayout.mutate(
      {
        data: layout,
      },
      {
        onSuccess: () => {
          setSaving(
            <FlexRow>
              <i className="bi-check"></i>
              <div>Saved</div>
            </FlexRow>
          );
          setTimeout(() => {
            location.reload();
          }, 1000);
        },
      }
    );
  }

  return (
    <>
      {searchGroups.isLoading && <Loading></Loading>}
      {searchGroups.isSuccess && (
        <Layout
          object={searchGroups?.data?.groups}
          saveChanges={saveChanges}
          saveText={saving}
        ></Layout>
      )}
    </>
  );
}

export function Layout({ object, saveChanges, newGroup, saveText }) {
  const [activeId, setActiveId] = useState(null);
  const [active, setActive] = useState(null);
  const [fullLayout, setFullLayout] = useState(object);
  const [changed, setChanged] = useState(false);
  const [newInputs, setNewInputs] = useState({});
  function handleDragStart(event) {
    const { active } = event;
    const { id } = active;

    setActiveId(id);
    setActive(active);
  }
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );
  const adjustTranslate = ({ transform }) => {
    return {
      ...transform,
      y: !newGroup ? transform.y - 25 : transform.y - 60,
      x: !newGroup ? transform.x - 45 : transform.x - 275,
    };
  };

  function handleDragOver(event) {
    const { active, over } = event;
    // if (!over) return;
    if (!over || active.id === over.id) return; // No operation if dragged over itself

    if (
      active?.data?.current?.dropInput === true ||
      (active?.data?.current?.sortable?.containerId != "overall" &&
        active?.data?.current?.input === true &&
        over?.data?.current?.sortable?.containerId != "overall" &&
        over?.data?.current?.input === true)
    ) {
      const { draggingRect } = event;

      let newlayout = swapArray(active, over, draggingRect);

      if (newlayout && fullLayout) {
        // let temp = fullLayout];
        // temp[over?.data?.current?.layoutIndex].layout = newlayout;
        setFullLayout(newlayout);
      }
    } else if (
      (active?.data?.current?.sortable?.containerId === "overall" ||
        active?.data?.current?.sortable?.containerId === "Sortable") &&
      (over?.data?.current?.sortable?.containerId === "overall" ||
        over?.data?.current?.sortable?.containerId === "Sortable")
    ) {
      const activeIndex = fullLayout.findIndex((f) => f.name === active.id);
      const overIndex = fullLayout.findIndex((f) => f.name === over.id);

      if (fullLayout && activeIndex !== -1 && overIndex !== -1) {
        // Reorder the items array
        let temp = [...fullLayout];
        temp[activeIndex].enabled = true;
        let updatedItems = arrayMove(temp, activeIndex, overIndex);
        for (let i = 0; i < temp?.length; i++) {
          updatedItems[i].position = i;
        }
        setFullLayout(updatedItems);
      }
    }
  }

  function handleDragEnd(event) {
    const { active } = event;
    if (active?.data?.current?.field?.id?.startsWith("new:")) {
      let temp = { ...newInputs };
      delete temp[active?.data?.current?.field?.id];
      setNewInputs(temp);
    }
    setChanged(true);
    setActiveId(null);
    setActive(null);
  }

  const getDraggedElement = () => {
    let field = active?.data?.current?.field;

    return (
      <>
        {field ? (
          <div className={styles.draggedItem}>
            <span style={{ pointerEvents: "none" }}>
              <DataInput
                label={
                  active?.data?.current?.dropInput
                    ? field?.displayName
                    : field?.customField?.displayName
                }
                dataType={field?.customField?.dataType}
                icon={field?.customField?.icon}
                min={field?.customField?.min}
                max={field?.customField?.max}
                falseValue={field?.customField?.falseValue}
                trueValue={field?.customField?.trueValue}
              ></DataInput>
            </span>
          </div>
        ) : (
          <div
            className={`${styles.header_4} ${styles.drag}`}
            style={{ cursor: "grabbing" }}
          >
            {activeId}
          </div>
        )}
      </>
    );
  };

  function swapArray(active, over, draggingRect) {
    if (!over || !active || over?.id === active?.id) {
      return undefined;
    }

    let tempFullLayout = JSON.parse(JSON.stringify(fullLayout));
    let activeRow = active?.data?.current?.row
      ? [...active?.data?.current?.row]
      : undefined;
    let input = active?.data?.current?.input;
    let overRow = over?.data?.current?.row
      ? [...over?.data?.current?.row]
      : undefined;
    let activeIndex = active?.data?.current?.index;
    let activeLayoutIndex = active?.data?.current?.layoutIndex;
    let activeRowIndex = active?.data?.current?.rowIndex;
    let overIndex = over?.data?.current?.index;
    let overRowIndex = over?.data?.current?.rowIndex;
    let overLayoutIndex = over?.data?.current?.layoutIndex;
    let overLayout = over?.data?.current?.layout;

    // if (!activeIndex && activeIndex != 0 || !overIndex && overIndex != 0) {
    //   return undefined;
    // }

    let newIndex = 0;
    if (
      overLayout &&
      overLayout?.findIndex((item) => item?.id === over?.data?.id) >= 0
    ) {
      // We're at the root droppable of a container
      newIndex = overRow?.length + 1;
    } else {
      const isBelowLastItem =
        over &&
        overIndex === overRow?.length - 1 &&
        draggingRect?.offsetTop > over.rect.offsetTop + over.rect.height;

      const modifier = isBelowLastItem ? 1 : 0;

      newIndex = overIndex >= 0 ? overIndex + modifier : overRow?.length + 1;
    }

    if (
      input &&
      active?.data?.current?.rowId === over?.data?.current?.rowId &&
      overRow
    ) {
      overRow = arrayMove(overRow, activeIndex, newIndex);
      tempFullLayout[overLayoutIndex].customFieldGroupRow[
        overRowIndex
      ].customFieldGroupRowField = overRow;
      overRow[activeIndex].position = activeIndex;
      overRow[newIndex].position = newIndex;
      return tempFullLayout;
    } else {
      if (activeRow) {
        let itemToMove = { ...activeRow[activeIndex] };
        //remove from previous row
        activeRow?.splice(activeIndex, 1);
        //add to new row
        if (overIndex === 0) {
          itemToMove.position = 0;
          overRow.push(itemToMove);
        } else {
          itemToMove.position = newIndex;
          overRow?.splice(newIndex, 0, itemToMove);
        }
      } else if (overRow) {
        let itemToMove = {
          id: active?.data?.current?.dropInput
            ? "new:" + active?.data?.current?.field?.id
            : active?.data?.current?.field?.customField?.id,
          customField: {
            id: active?.data?.current?.field?.id,
            displayName: active?.data?.current?.field?.displayName,
            name: active?.data?.current?.field?.name,
          },
          position: newIndex,
        };
        //add to new row
        if (overIndex === 0) {
          itemToMove.position = 0;
          overRow.push(itemToMove);
        } else {
          itemToMove.position = newIndex;
          overRow?.splice(newIndex, 0, itemToMove);
        }
      }

      //updatelayout
      if (overRow) {
        tempFullLayout[overLayoutIndex].customFieldGroupRow[
          overRowIndex
        ].customFieldGroupRowField = overRow;
      }

      if (activeRow) {
        tempFullLayout[activeLayoutIndex].customFieldGroupRow[
          activeRowIndex
        ].customFieldGroupRowField = activeRow;
      }

      return tempFullLayout;
    }

    // return undefined;
    // debugger;
    // let activeItems = [];
    // // if (activeLayout && activeContainer in activeLayout) {
    //   activeItems = activeLayout;
    // // }
    // let overItems = [];
    // // if (overLayout && overContainer in overLayout) {
    //   overItems = overLayout;
    // // }

    // // Find the indexes for the items
    // const activeIndex = activeItems.findIndex((item) => item?.customFieldGroupRowField  );
    // const overIndex = overItems.indexOf(overId);
    // const activeContainerIndex = tempFullLayout[activeLayoutIndex].customFieldGroupRow.indexOf(activeContainer);
    // const overContainerIndex = tempFullLayout[overLayoutIndex].customFieldGroupRow.indexOf(overContainer);

    // let newIndex;
    // if (overId in overLayout) {
    //   // We're at the root droppable of a container
    //   newIndex = overItems.length + 1;
    // } else {
    //   const isBelowLastItem =
    //     over &&
    //     overIndex === overItems.length - 1 &&
    //     draggingRect?.offsetTop > over.rect.offsetTop + over.rect.height;

    //   const modifier = isBelowLastItem ? 1 : 0;

    //   newIndex = overIndex >= 0 ? overIndex + modifier : overItems.length + 1;
    // }
    // if (overLayoutIndex != activeLayoutIndex) {
    //   if (activeLayoutIndex || activeLayoutIndex === 0) {
    //     //remove from previous row
    //     tempFullLayout[activeLayoutIndex].customFieldGroupRow[activeContainerIndex] =
    //       tempFullLayout[activeLayoutIndex]?.customFieldGroupRow[activeContainerIndex].filter(
    //         (item) => item !== active.id
    //       );
    //     //remove from all fields in layout
    //     tempFullLayout[activeLayoutIndex].allFields = tempFullLayout[
    //       activeLayoutIndex
    //     ]?.allFields.filter((item) => item !== active.id);
    //   }
    //   if (overLayoutIndex || overLayoutIndex === 0) {
    //     //add to new row
    //     tempFullLayout[overLayoutIndex]?.customFieldGroupRow[overContainerIndex].splice(
    //       newIndex,
    //       0,
    //       id
    //     );
    //     //add to all fields of layout
    //     tempFullLayout[overLayoutIndex]?.allFields.push(id);
    //   }

    //   setFullLayout(tempFullLayout);
    //   return undefined;
    // } else {
    //   return {
    //     ...overLayout,
    //     [activeContainer]: [
    //       ...overLayout[activeContainer].filter((item) => item !== active.id),
    //     ],
    //     [overContainer]: [
    //       ...overLayout[overContainer].slice(0, newIndex),
    //       overLayout[activeContainer][activeIndex],
    //       ...overLayout[overContainer].slice(
    //         newIndex,
    //         overLayout[overContainer].length
    //       ),
    //     ],
    //   };
    // }
  }
  function changeGroupName(value) {
    let temp = [...fullLayout];
    if (value === "") {
      temp[0].name = "New Group";
    } else {
      temp[0].name = value;
    }

    setFullLayout(temp);
  }

  return (
    <div className={styles.page}>
      <div className={styles.header}>
        {newGroup ? (
          <div>
            <TextFieldSimple
              placeholder="New Group"
              onChange={changeGroupName}
            ></TextFieldSimple>
          </div>
        ) : (
          <div className={styles.header_3}>Edit Layout</div>
        )}
        {!newGroup ? (
          <Button
            shadow
            blue
            disable={!changed}
            onClick={() => saveChanges(fullLayout)}
          >
            {saveText}
          </Button>
        ) : (
          <Button
            shadow
            blue
            disable={!changed}
            onClick={() => saveChanges(fullLayout)}
          >
            {saveText}
          </Button>
        )}
      </div>

      <div className={styles.itemsSplit}>
        <DndContext
          announcements={defaultAnnouncements}
          sensors={sensors}
          collisionDetection={rectIntersection}
          onDragStart={handleDragStart}
          onDragOver={handleDragOver}
          onDragEnd={handleDragEnd}
        >
          <div className={styles.content}>
            {" "}
            <SortableContext
              id={"overall"}
              items={fullLayout}
              strategy={verticalListSortingStrategy}
            >
              <div className={styles.layout}>
                {fullLayout?.map(({ name, customFieldGroupRow, enabled }, i) =>
                  enabled ? (
                    <GroupFields
                      key={i}
                      name={name}
                      layout={customFieldGroupRow}
                      setFullLayout={setFullLayout}
                      index={i}
                      fullLayout={fullLayout}
                      newInputs={newInputs}
                      setNewInputs={setNewInputs}
                      setChanged={setChanged}
                    ></GroupFields>
                  ) : (
                    ""
                  )
                )}
              </div>
            </SortableContext>
          </div>
          <Groups
            fullLayout={fullLayout}
            setFullLayout={setFullLayout}
            newGroup={newGroup}
            newInputs={newInputs}
            setNewInputs={setNewInputs}
          ></Groups>
          <DragOverlay modifiers={[adjustTranslate]}>
            {getDraggedElement()}
          </DragOverlay>
        </DndContext>
      </div>
    </div>
  );
}

export default ObjectLayout;

function GroupFields({
  layout,
  name,
  setFullLayout,
  index,
  fullLayout,
  setNewInputs,
  newInputs,
  setChanged,
}) {
  // const sensors = useSensors(
  //   useSensor(PointerSensor),
  //   useSensor(KeyboardSensor, {
  //     coordinateGetter: sortableKeyboardCoordinates,
  //   })
  // );

  function addRow(rowIndex) {
    let temp = [...fullLayout];
    temp[rowIndex]?.customFieldGroupRow?.push({
      customFieldGroupRowField: [],
      id: "new:" + shortId(),
    });
    setFullLayout(temp);
  }

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id: name, data: { layout, index, input: false } });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition: transition,
    opacity: isDragging ? 0.4 : undefined,
  };

  const [showRemove, setShowRemove] = useState(false);

  function removeGroup() {
    let temp = [...fullLayout];
    temp[index].enabled = false;
    setFullLayout(temp);
    setChanged(true);
  }

  function removeInput(index, rowIndex, layoutIndex, field) {
    let temp = [...fullLayout];
    temp[layoutIndex]?.customFieldGroupRow[
      rowIndex
    ]?.customFieldGroupRowField.splice(index, 1);
    for (
      let i = 0;
      i <
      temp[layoutIndex]?.customFieldGroupRow[rowIndex]?.customFieldGroupRowField
        ?.length;
      i++
    ) {
      temp[layoutIndex].customFieldGroupRow[rowIndex].customFieldGroupRowField[
        i
      ].position = i;
    }
    setFullLayout(temp);

    let tempinputs = { ...newInputs };
    tempinputs["new:" + field?.id] = field;
    setNewInputs(tempinputs);
    setChanged(true);
  }

  function removeRow(rowIndex, layoutIndex) {
    let temp = [...fullLayout];
    temp[layoutIndex]?.customFieldGroupRow?.splice(rowIndex, 1);
    for (let i = 0; i < temp[layoutIndex]?.customFieldGroupRow?.length; i++) {
      temp[layoutIndex].customFieldGroupRow[i].position = i;
    }
    setFullLayout(temp);
    setChanged(true);
  }

  return (
    <div {...attributes} ref={setNodeRef} style={style}>
      <div>
        <div
          className={styles.header_4}
          onMouseEnter={() => setShowRemove(true)}
          onMouseLeave={() => setShowRemove(false)}
        >
          <FlexRow>
            <div {...listeners} style={{ width: "100%" }}>
              {name}
            </div>
            {showRemove && (
              <div
                className={styles.remove}
                style={{ opacity: showRemove ? 1 : 0 }}
              >
                <Button blue shadow onClick={() => addRow(index)} width={100}>
                  + New Row
                </Button>
                <Button red shadow onClick={removeGroup}>
                  {" "}
                  <i className={"bi-trash"}></i>
                </Button>
              </div>
            )}
          </FlexRow>
        </div>
        <div className={styles.rowsContainer}>
          {layout?.map((row, i) => (
            <Container
              key={i}
              id={row?.id}
              row={row?.customFieldGroupRowField}
              index={i}
              layout={layout}
              layoutIndex={index}
              removeInput={removeInput}
              removeRow={removeRow}
            ></Container>
          ))}
        </div>
      </div>
    </div>
  );
}

function Container({
  id,
  row,
  index,
  layout,
  layoutIndex,
  removeInput,
  removeRow,
}) {
  const { setNodeRef } = useDroppable({
    id,
    data: {
      row,
      index,
      layout,
      layoutIndex,
      rowId: id,
      rowIndex: index,
      input: true,
    },
  });

  return (
    <div className={styles.row}>
      <SortableContext id={id} items={row ? row : []} index={index}>
        <div
          className={`${styles.sortableItemContainer} ${
            row?.length === 0 && styles.emptyRow
          }`}
          ref={row?.length === 0 ? setNodeRef : undefined}
        >
          {row?.length === 0 && (
            // <SortableItem
            //   key={id}
            //   id={id}
            //   index={index}
            //   layout={layout}
            //   row={row}
            //   layoutIndex={layoutIndex}
            //   rowId={id}
            //   rowIndex={index}
            // >
            <FlexRow>
              <i className={styles.emptytext}>
                Empty Row <span>(will be removed if saved)</span>
              </i>{" "}
              <Button red shadow onClick={() => removeRow(index, layoutIndex)}>
                <i className={"bi-trash"}></i>
              </Button>
            </FlexRow>
            // </SortableItem>
          )}
          {row?.map((field, i) => (
            <SortableItem
              key={i}
              id={field?.customField?.id}
              index={i}
              layout={layout}
              row={row}
              rowId={id}
              layoutIndex={layoutIndex}
              field={field}
              rowIndex={index}
              removeInput={removeInput}
            >
              <DataInput
                label={field?.customField?.displayName}
                dataType={field?.customField?.dataType}
                icon={field?.customField?.icon}
                min={field?.customField?.min}
                max={field?.customField?.max}
                falseValue={field?.customField?.falseValue}
                trueValue={field?.customField?.trueValue}
              ></DataInput>
            </SortableItem>
          ))}
        </div>
      </SortableContext>
    </div>
  );
}
