import { useQueryClient } from "react-query";
import { gql } from "graphql-request";

// Internal
import { useGqlQuery, useGqlMutation } from "api/Api";


export const deleteLinkedAccount = () => {
    const mutation = gql`
      mutation Mutation($id: String!, $orgId: String!) {
        deleteLinkedAccount(id: $id, orgId: $orgId)
      }
    `;
    return useGqlMutation(mutation, {});
  };
  