import React, { useEffect, useState, useRef } from "react";
import styles from "./InteractiveAudienceTable.module.scss";
import { NavTextField } from "components/inputs/input_fields/NavTextField/NavTextField";
import { Loading } from "components/Loading/Loading";
import Button from "components/Button/Button";
import AudienceWidgets from "./SurveyWidgets/AudienceWidgets/AudienceWidgets";
import { useSearchAudienceGql } from "api/resources/contacts/audiences";
import salesforce from "assets/images/Salesforce.png";
import { useNavigate } from "react-router-dom";
import FieldAdjuster from "pages/contacts/all_contacts/FieldAdjuster";

function InteractiveAudienceTable({
  onRowClick,
  perPage,
  setPerPage,
  queryData,
  queryDataAccessor,
  maxDataQuery,
  disableActionButton,
  widgetType,
  searchPlaceholder,
  roles,
  sort,
  setSort,
  user,
  organization,
}) {
  const [widgetView, setWidgetView] = useState(widgetType ? true : false);
  const [searchString, setSearchString] = useState("");
  const [perPageAud, setPerPagAud] = useState(10);
  const [pageSkipAud, setPageSkipAud] = useState(0);
  const [showAudienceCreate, setShowAudienceCreate] = useState(false);
  const [showFolderCreate, setShowFolderCreate] = useState(false);
  const [imports, setShowImports] = useState(false);
  const [adjustFields, setAdjustFields] = useState(false);
  const [pageSortAud, setPageSortAud] = useState({
    item: "updatedAt",
    descend: true,
  });
  const [active, setActive] = useState();

  const searchAudience = useSearchAudienceGql(
    searchString,
    pageSkipAud,
    perPageAud,
    pageSortAud,
    "search"
  );

  useEffect(() => {
    searchAudience.refetch();
  }, [0, pageSortAud, perPageAud, searchString]);

  const ref = useRef(null);
  function clickOutside(e) {
    if (ref.current && !ref.current?.contains(e.target)) {
      setShowImports(false);
    }
  }

  useEffect(() => {
    document.addEventListener("click", clickOutside, true);
    return () => {
      document.removeEventListener("click", clickOutside, true);
    };
  }, []);

  const navigate = useNavigate();

  return (
    <div className={styles.tableContainer}>
      {/* The Header*/}
      {adjustFields && (
        <FieldAdjuster
          show={adjustFields}
          setShow={setAdjustFields}
        ></FieldAdjuster>
      )}

      <div
        className={styles.tableHeader}
        style={{
          position: window.innerWidth < 700 ? "static" : "",
          paddingLeft: window.innerWidth < 700 ? "2em" : "",
        }}
      >
        <div className={styles.header_3} style={{margin: "0"}}>Manage Audiences</div>
        <div
          style={{
            display: "flex",
            gap: "1em",
            flexWrap: "wrap",
            alignItems: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              gap: "1em",
              paddingTop: ".25em",
              alignItems: "center",
              flexWrap: "wrap",
            }}
          >
            <div style={{ position: "relative" }}>
              {/* <Button
                shadow
                style={{
                  gap: ".5rem",
                }}
                onClick={() => {
                  setShowImports(!imports);
                }}
                disable={disableActionButton}
              >
                <i className="bi-cloud-arrow-up"></i> Import Contacts
                <i className="bi-chevron-down"></i>
              </Button> */}
              {imports && (
                <div
                  className={styles.settingsBox}
                  style={{
                    width: "150px",
                    height: user?.linkedAccounts?.length > 0 ? "120px" : "80px",
                    overflow: "visible",
                  }}
                  ref={ref}
                >
                  <div
                    className={styles.menuItem}
                    onClick={() => navigate("/import-contacts/csv")}
                    style={{
                      borderTopLeftRadius: ".5em",
                      borderTopRightRadius: ".5em",
                    }}
                  >
                    <i className="bi-filetype-csv"></i>
                    From File
                  </div>

                  <div
                    className={styles.menuItem}
                    onClick={() => navigate("/import-contacts/manual")}
                    style={{
                      borderBottomLeftRadius:
                        user?.linkedAccounts?.length === 0 ? ".5em" : "",
                      borderBottomRightRadius:
                        user?.linkedAccounts?.length === 0 ? ".5em" : "",
                    }}
                  >
                    <i className="bi-pen" style={{ color: "#5A8181" }}></i>
                    Manual Input
                  </div>

                  {user?.linkedAccounts?.length > 0 &&
                    user?.linkedAccounts?.map((account, i) => (
                      <div
                        className={styles.menuItem}
                        onClick={() => {
                          if (account?.accountType === "Salesforce") {
                            navigate("/import-contacts/salesforce");
                          }
                        }}
                        key={account?.id}
                        style={{
                          borderBottomLeftRadius:
                            user?.linkedAccounts?.length - 1 === i
                              ? ".5em"
                              : "",
                          borderBottomRightRadius:
                            user?.linkedAccounts?.length - 1 === i
                              ? ".5em"
                              : "",
                        }}
                      >
                        {account.accountType === "Salesforce" && (
                          <>
                            {" "}
                            <img src={salesforce} width={30}></img>
                            Salesforce
                          </>
                        )}
                      </div>
                    ))}
                </div>
              )}
            </div>
            <Button
              // shadow
              disable={disableActionButton}
              onClick={() => setAdjustFields(true)}
              style={{ gap: ".5em" }}
              shadow
            >
              <i className="bi-layout-text-window"></i> Edit Columns
            </Button>

            <div className={styles.searchBox}>
              <NavTextField
                value={searchString}
                setValue={(val) => {
                  setSearchString(val);
                }}
                placeholder={
                  searchPlaceholder ? searchPlaceholder : "Search..."
                }
                className={styles.search}
                shadow
              />
              {searchAudience.isSuccess &&
                !searchAudience.isRefetching &&
                searchString != "" && (
                  <div className={styles.items}>
                    {searchAudience?.data?.audiences.map((report) => (
                      <div
                        className={styles.item}
                        onClick={() => onRowClick(report)}
                      >
                        <span className={styles.name}> {report?.name}</span>
                        <span className={styles.type}>
                          {" "}
                          {report?.members} members
                        </span>
                      </div>
                    ))}
                  </div>
                )}
            </div>
          </div>
        </div>
      </div>
      <div className={styles.audienceContent}>
        {/* The sTable*/}
        {(maxDataQuery?.isLoading || queryData?.isLoading) && (
          <Loading></Loading>
        )}
        {(maxDataQuery?.isError || queryData?.isError) && <p>Error</p>}
        {maxDataQuery?.isSuccess && queryData?.isSuccess && (
          <>
            {widgetView && (
              <AudienceWidgets
                surveys={queryData.data[queryDataAccessor]}
                onClick={
                  roles.canSeeContactInfo
                    ? (a) => {
                        onRowClick(a);
                        setActive(a?.id);
                      }
                    : undefined
                }
                onClickSeeMore={() => setPerPage((perPage += 10))}
                id={active}
                showAudienceCreate={showAudienceCreate}
                setShowAudienceCreate={setShowAudienceCreate}
                showFolderCreate={showFolderCreate}
                setShowFolderCreate={setShowFolderCreate}
                sort={sort}
                setSort={setSort}
                disableActionButton={disableActionButton}
              />
            )}
          </>
        )}
        {/* <div className={styles.routes}>
          <AudienceRoutes></AudienceRoutes>
        </div> */}
      </div>
    </div>
  );
}

export default InteractiveAudienceTable;
