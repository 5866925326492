import React, { forwardRef, useState, useMemo } from "react";
import classNames from "classnames";

// import {Action, Handle, Remove} from '../Action';
import styles from "./TreeItem.module.scss";
import { Handle } from "../Handle/Handle.jsx";
import { Action } from "../Action/Action.jsx";
import { useSearchContactsFiltersCount } from "api/resources/contacts/contacts";
import { Options } from "../Options/Options.jsx";

// export interface Props extends Omit<HTMLAttributes<HTMLLIElement>, 'id'> {
//   childCount?: number;
//   clone?: boolean;
//   collapsed?: boolean;
//   depth: number;
//   disableInteraction?: boolean;
//   disableSelection?: boolean;
//   ghost?: boolean;
//   handleProps?: any;
//   indicator?: boolean;
//   indentationWidth: number;
//   value: string;
//   onCollapse?(): void;
//   onRemove?(): void;
//   wrapperRef?(node: HTMLLIElement): void;
// }

export const TreeItem = forwardRef(
  (
    {
      childCount,
      clone,
      depth,
      disableSelection,
      disableInteraction,
      ghost,
      handleProps,
      indentationWidth,
      indicator,
      collapsed,
      onCollapse,
      onRemove,
      style,
      value,
      wrapperRef,
      members,
      id,
      onClick,
      folder,
      open,
      edit,
      setedit,
      icon,
      setEditFolder,
      index,
      filters,
      dynamic,
      ...props
    },
    ref
  ) => {
    const getContactCount = useSearchContactsFiltersCount(
      "",
      "",
      id,
      filters ? filters : ""
    );
    const [shake, setShake] = useState(false);

    useMemo(() => {
      if (edit) {
        setShake(true);
        setTimeout(() => {
          setShake(false);
        }, 1000);
      }
    }, [edit]);

    return (
      <li
        className={classNames(
          styles.Wrapper,
          clone && styles.clone,
          ghost && styles.ghost,
          indicator && styles.indicator,
          disableSelection && styles.disableSelection,
          disableInteraction && styles.disableInteraction
        )}
        ref={wrapperRef}
        style={{
          "--spacing": `${indentationWidth * depth}px`,
        }}
        {...props}
        onClick={folder ? onCollapse : undefined}
      >
        <div
          className={`${styles.TreeItem} ${styles.hover} ${
            open && styles.TreeItemOpen
          } ${edit && shake && styles.earthquake}`}
          ref={ref}
          style={style}
          onClick={onClick && !folder ? () => onClick({ id: id }) : undefined}
        >
          {edit && id && <Handle {...handleProps} />}

          <div className={styles.icon}>
            {!folder && (
              <i
                className={icon?.icon ? "bi-" + icon?.icon : "bi-people-fill"}
                style={{ color: icon?.color }}
              ></i>
            )}
            {folder && (
              <i
                className={icon?.icon ? "bi-" + icon?.icon : "bi-folder2"}
                style={{ color: icon?.color }}
              ></i>
            )}
          </div>
          <div className={styles.audienceHeader}>
            <span className={styles.header_5} style={{ margin: "0" }}>
              {value}
            </span>
            {!folder && (
              <div className={styles.startedText}>
                <span>
                  {" "}
                  {getContactCount.isSuccess && (
                    <span className={styles.isopen}>
                      {getContactCount?.data?.count}
                    </span>
                  )}
                  {getContactCount.isLoading && (
                    <span className={styles.isopen}>0</span>
                  )}{" "}
                  Member
                  {getContactCount?.data?.count != 1 ? "s" : ""}
                </span>
              </div>
            )}
          </div>
          {onCollapse && (
            <Action
              onClick={(e) => {
                e.stopPropagation();
                onCollapse(e);
              }}
              className={classNames(
                styles.Collapse,
                collapsed && styles.collapsed
              )}
            >
              {collapseIcon}
            </Action>
          )}

          <Options
            setEdit={setedit}
            folder={folder}
            onRemove={onRemove}
            id={id}
            setEditFolder={() => {
              setEditFolder({
                name: value,
                icon: icon?.icon,
                color: icon?.color,
                index: index,
                id: id,
              });
            }}
          ></Options>

          {edit && id && (
            <div className={styles.remove}>
              <i
                className="bi-plus-circle-fill"
                onClick={(e) => {
                  e.stopPropagation();
                  onRemove(e);
                }}
              ></i>
            </div>
          )}

          {/* {!clone && onRemove && <Remove onClick={onRemove} />} */}
          {clone && childCount && childCount > 1 ? (
            <span className={styles.Count}>{childCount}</span>
          ) : null}

          {/* <AudienceWidget
            key={survey.id}
            audience={survey}
            onClick={onClick}
            active={survey.id === id}
          /> */}
        </div>
      </li>
    );
  }
);

const collapseIcon = (
  <svg width="10" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 70 41">
    <path d="M30.76 39.2402C31.885 40.3638 33.41 40.995 35 40.995C36.59 40.995 38.115 40.3638 39.24 39.2402L68.24 10.2402C69.2998 9.10284 69.8768 7.59846 69.8494 6.04406C69.822 4.48965 69.1923 3.00657 68.093 1.90726C66.9937 0.807959 65.5106 0.178263 63.9562 0.150837C62.4018 0.123411 60.8974 0.700397 59.76 1.76024L35 26.5102L10.24 1.76024C9.10259 0.700397 7.59822 0.123411 6.04381 0.150837C4.4894 0.178263 3.00632 0.807959 1.90702 1.90726C0.807714 3.00657 0.178019 4.48965 0.150593 6.04406C0.123167 7.59846 0.700153 9.10284 1.75999 10.2402L30.76 39.2402Z" />
  </svg>
);
