import React, { useMemo, useState } from "react";
import styles from "./NLP.module.scss";
import OrgBuckets from "./OrgBuckets/OrgBuckets";

function NLP({ roles, updateRoute }) {

  useMemo(() => {
    if (updateRoute) {
      updateRoute(4);
    }
  }, []);
  return (
    <div className={styles.page}>
      <NLPContent role={roles} />
    </div>
  );
}

export default NLP;

function NLPContent({ role }) {
  return (
    <div className={styles.page}>
      <div className={styles.section}>
        <div
          className={`${styles.header_3} `}
          style={{ fontWeight: "400", color: "black", margin: "0" }}
        >
          Natural Language Processing Buckets
        </div>

        <OrgBuckets />
      </div>
    </div>
  );
}
