import { useQueryClient } from "react-query";
import { gql } from "graphql-request";

// Internal
import { useGqlQuery, useGqlMutation } from "api/Api";
import { vizQData } from "../projects/visualizations";

export const useSearchQTags = (search, type) => {
  const query = gql`
    query {
      tags: searchQuestionTags(search: "${search}", type: "${type}") {
        color
        description
        id
        name
        type
      }
    }
  `;

  return useGqlQuery(["searchQTags: " + type + search], query);
};

export const useFetchQTags = () => {
  const query = gql`
    query AllQTags {
      tags: allQTags {
        id
        name
        color
        type
        question {
          id
          questionText
          type
          projectId
          buckets
          hasBuckets
          isScore
          isComment
          choiceQuestion {
            id
            isMultiSelect
            isRandomized
            hasOtherOption
            otherOptionLabel
            isRanking
            limit
            choices
          }
          scaleQuestion {
            id
            min
            minDescription
            max
            maxDescription
            step
          }
          textQuestion {
            id
            bucket {
              id
              name
              properties
            }
          }
          matrixQuestion {
            id
            options
            type
          }
        }
      }
    }
  `;

  return useGqlQuery(["all q tags"], query);
};

export const useCreateQuestionTag = () => {
  const mutation = gql`
    mutation Mutation($questionId: String!, $data: QuestionTagInput!) {
      tag: createQuestionTag(questionId: $questionId, data: $data) {
        color
        description
        id
        name
        type
        question {
          id
          questionText
        }
      }
    }
  `;
  return useGqlMutation(mutation);
};

export const useAddQToQTag = () => {
  const mutation = gql`
    mutation Mutation($questionId: String!, $tagId: String!) {
      addQToQTag(questionId: $questionId, tagId: $tagId)
    }
  `;
  return useGqlMutation(mutation);
};

export const useRemoveTagFromQ = () => {
  const mutation = gql`
    mutation RemoveTagFromQ($questionId: String!, $tagId: String!) {
      removeTagFromQ(questionId: $questionId, tagId: $tagId)
    }
  `;
  return useGqlMutation(mutation);
};

export const useRemoveAllTagsFromQ = () => {
  const mutation = gql`
    mutation Mutation($questionId: String!) {
      removeAllTagsFromQ(questionId: $questionId)
    }
  `;
  return useGqlMutation(mutation);
};

export const useGetQuestionsFromQTagInTimeFrame = () => {
  const mutation = gql`
    mutation Mutation($timeFrame: String!, $tagId: String!) {
      Qs: getQuestionsFromQuestionTagInTimeFrame(
        timeFrame: $timeFrame
        tagId: $tagId
      ) {
        ${vizQData}
        project {
          id
          name
        }
      }
    }
  `;

  return useGqlMutation(mutation);
};
