import React, { useEffect, useState } from "react";
import styles from "./Register.module.scss";
import FlexRow from "components/layouts/FlexRow/FlexRow";
import FlexCol from "components/layouts/FlexColumn/FlexCol";
import { HorizontalBar } from "components/layouts/HorizontalBar/HorizontalBar";
import { TextFieldSimple } from "components/inputs";
import Button from "components/Button/Button";
import { useNavigate, useParams } from "react-router-dom";
import {
  useCheckUserToken,
  useValidateJoinCodeToken,
} from "api/resources/authentication/register";
import TokenExpired from "pages/error_page/TokenExpired";
import { Loading } from "components/Loading/Loading";

function VerifyEmail({
  steps,
  setSteps,
  activeStep,
  setActiveStep,
  setOrg,
  org,
  join,
  setType,
}) {
  const { token } = useParams();
  const checkTokenService = useCheckUserToken();
  const navigate = useNavigate();
  const [valid, setValid] = useState(true);
  const [message, setMessage] = useState("");
  const [email, setEmail] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    checkTokenService.mutate(
      {
        token: token,
      },
      {
        onSuccess: (data) => {
          setLoading(false);
          if (data?.status?.join && !join) {
            setValid(false);
            setMessage("Page not valid");
          } else if (data?.status?.status === 2 && !join) {
            navigate(`/register/your-organization/${token}`);
          } else if (data?.status?.status === 2 && join) {
            navigate(`/register/join-organization/welcome-to-reaction/${token}`);
          } else if (data?.status?.status === 1) {
            setEmail(data?.status?.email);
          } else {
            setValid(false);
            setMessage(data?.status?.description);
          }
        },
      }
    );
  }, []);

  return valid ? (
    <>
      {loading && <Loading></Loading>}
      {!loading && (
        <VerifyEmailContent
          setSteps={setSteps}
          steps={steps}
          activeStep={activeStep}
          setActiveStep={setActiveStep}
          org={org}
          setOrg={setOrg}
          email={email}
          token={token}
          join={join}
          setType={setType}
        />
      )}
    </>
  ) : (
    <TokenExpired message={message}>
      <Button blue width={200} shadow onClick={() => navigate("/register")}>
        Create new account
      </Button>
    </TokenExpired>
  );
}

export default VerifyEmail;

function VerifyEmailContent({
  steps,
  setSteps,
  activeStep,
  setActiveStep,
  email,
  token,
  join,
  setType,
}) {
  const [resend, setResend] = useState(true);
  const [resendCount, setResendCount] = useState(0);
  const [working, setWorking] = useState(false);
  const [verifyCode, setVerifyCode] = useState();

  const validateCode = useValidateJoinCodeToken();
  const navigate = useNavigate();

  

  useEffect(() => {
    if (join) {
      setActiveStep(2);
      setType("Join");
    } else {
      let temp = [...steps];
      temp[2].done = true;
      temp[1].done = false;
      setSteps(temp);
      setActiveStep(2);
    }
  }, []);

  useEffect(() => {
    // Stop countdown when it reaches 0
    if (resendCount <= 0) {
      if (!resend) {
        setResend(true);
      }

      return;
    }

    // Decrease the countdown every second
    const timer = setInterval(() => {
      setResendCount((prevTime) => prevTime - 1);
    }, 1000);

    // Cleanup interval on component unmount
    return () => clearInterval(timer);
  }, [resendCount]); // Re-run the effect when timeLeft changes

  useEffect(() => {
    if (activeStep === 2) {
      setResend(false);
      setResendCount(30);
    }
  }, [activeStep]);

  function validateVerifyCode(code) {
    validateCode.mutate(
      {
        code: code,
        token: token,
      },
      {
        onSuccess: (data) => {
          const valid = data?.validCodeForUserToken;
          setWorking(false);
          if (valid && !join) {
            navigate(`/register/your-organization/${token}`);
          }
          if (valid && join) {
            navigate(`/register/welcome-to-reaction/${token}`);
          }
        },
      }
    );
  }

  return (
    <FlexCol style={{ padding: "2rem" }}>
      <FlexRow>
        <img width={"50px"} src={require("assets/images/circlelogo.png")}></img>
        <div>
          <div
            className={styles.header_4}
            style={{
              margin: "0",
              fontWeight: "400",
            }}
          >
            Verify your email
          </div>
          <div className={styles.text_2} style={{}}>
            We sent a code to {email} <a className={styles.text_3}></a>
          </div>
        </div>
      </FlexRow>
      <HorizontalBar height={2}></HorizontalBar>
      <FlexCol
        style={{ maxWidth: "600px" }}
        // align={"center"}
      >
        <TextFieldSimple
          onChange={setVerifyCode}
          value={verifyCode}
          label="Verification Code"
          placeholder="12345"
        ></TextFieldSimple>

        <div
          className={styles.text_2}
          style={{ marginTop: "1rem", textAlign: "center" }}
        >
          Didn't get a code?{" "}
          {resend ? (
            <a
              className={styles.link}
              onClick={() => {
                setResend(false);
                setResendCount(30);
              }}
            >
              Click to resend code
            </a>
          ) : (
            <span>Can resend code in {resendCount} seconds</span>
          )}
        </div>
        <FlexRow gap={"2rem"} style={{ marginTop: "1rem" }} justify={"center"}>
          <Button
            blue
            shadow
            width={150}
            onClick={(e) => {
              setWorking(true);
              validateVerifyCode(verifyCode);
            }}
          >
            {!working ? (
              "Verify"
            ) : (
              <>
                Working
                <span className={styles.dots}>
                  <span>.</span>
                  <span>.</span>
                  <span>.</span>
                </span>
              </>
            )}
          </Button>
        </FlexRow>
      </FlexCol>{" "}
      {window.innerWidth <= 800 && (
        <>
          <div
            style={{
              display: "flex",
              gap: ".5rem",
              alignItems: "center",
              justifyContent: "center",
              flexWrap: "wrap",
              marginTop: "4rem",
            }}
          >
            Already have an account?
            <div
              style={{
                display: "flex",
                gap: ".5rem",
                alignItems: "center",
                flexWrap: "wrap",
              }}
            >
              <Button
                onClick={() => navigate("/login")}
                type="submit"
                shadow
                blue
              >
                Sign In
              </Button>
              or{" "}
              <Button
                onClick={() => {
                  navigate("/register/join-organization"), location.reload();
                }}
                type="submit"
                shadow
                blue
              >
                Join Organization
              </Button>
            </div>
          </div>
          <div className={styles.register}>
            Request a demo or learn more at{" "}
            <a href="https://reactiondata.com" className={styles.aLink}>
              {" "}
              reactiondata.com
            </a>
            .
          </div>
        </>
      )}
    </FlexCol>
  );
}
