import { gql } from "graphql-request";
import { useQueryClient } from "react-query";

import { useGqlQuery, useGqlMutation } from "api/Api";

export const useFetchBuckets = () => {
  const query = gql`
    query {
      orgBuckets {
        id
        name
        properties
      }
    }
  `;

  return useGqlQuery(["buckets"], query, {});
};

export const useFetchBucketCounts = (bucketId, questionId) => {
  const query = gql`
    query  {
      bucketCounts(questionId: "${questionId}", bucketId: "${bucketId}") {
        bucketed
        unbucketed
      }
    }
  `;

  return useGqlQuery(["bucket counts " + bucketId + questionId], query);
};

export const useFetchBucket = (bucketId) => {
  const query = gql`
    query {
      bucket(id: "${bucketId}") {
        id
        name
        plural
        singular
        properties
      }
    }
  `;

  return useGqlQuery(["bucket " + bucketId], query);
};

export const useUpdateBucket = () => {
  const mutation = gql`
    mutation UpdateBucket($id: String!, $data: BucketInput!) {
      updateBucket(id: $id, data: $data) {
        id
        name
        properties
      }
    }
  `;
  const queryClient = useQueryClient();
  const options = {
    onError: (err, _project, rollback) => {
      if (rollback) rollback();
    },
    onSettled: () => {
      queryClient.invalidateQueries("buckets");
    },
  };

  return useGqlMutation(mutation, options);
};

export const useAddBucket = () => {
  const mutation = gql`
    mutation AddBucket($bucketId: String!, $textQId: String!) {
      addBucket(bucketId: $bucketId, textQId: $textQId)
    }
  `;

  return useGqlMutation(mutation);
};

export const useRemoveBucket = () => {
  const mutation = gql`
    mutation RemoveBucket($bucketId: String!, $textQId: String!) {
      removeBucket(bucketId: $bucketId, textQId: $textQId)
    }
  `;

  return useGqlMutation(mutation);
};

export const useCreateBucket = () => {
  const mutation = gql`
    mutation CreateBucket($data: BucketInput!) {
      createBucket(data: $data) {
        id
        name
      }
    }
  `;
  const queryClient = useQueryClient();
  const options = {
    onError: (err, _project, rollback) => {
      if (rollback) rollback();
    },
    onSettled: () => {
      queryClient.invalidateQueries("buckets");
    },
  };

  return useGqlMutation(mutation, options);
};

export const useDeleteBucket = () => {
  const mutation = gql`
    mutation DeleteBucket($id: String!) {
      deleteBucket(id: $id)
    }
  `;
  const queryClient = useQueryClient();
  const options = {
    onError: (err, _project, rollback) => {
      if (rollback) rollback();
    },
    onSettled: () => {
      queryClient.invalidateQueries("buckets");
    },
  };

  return useGqlMutation(mutation, options);
};

export const useRunSingleBucketingBatch = () => {
  const mutation = gql`
    mutation Mutation(
      $questionId: String!
      $bucketId: String!
      $all: Boolean!
    ) {
      count: runSingleBucketingBatch(
        questionId: $questionId
        bucketId: $bucketId
        all: $all
      )
    }
  `;

  const options = {
    onError: (err, _project, rollback) => {
      if (rollback) rollback();
    },
  };

  return useGqlMutation(mutation, options);
};

export const useUpdateAnswerBucket = () => {
  const mutation = gql`
    mutation UpdateAnswerBucket($buckets: String!, $id: String!) {
      updateAnswerBucket(buckets: $buckets, id: $id)
    }
  `;

  return useGqlMutation(mutation);
};
