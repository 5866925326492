// External
import React, { useEffect, useRef, useState } from "react";
import styles from "./Users.module.scss";

// Internal
import {
  useFetchUsersByOrgId,
  useInviteUser,
  useRemoveUserFromOrg,
} from "api/resources/organization/users";
import { useParams } from "react-router-dom";
import UserProfile2 from "components/Popout/UserProfile2";
import FlexRow from "components/layouts/FlexRow/FlexRow";
import { NavTextField } from "components/inputs/input_fields/NavTextField/NavTextField";
import Button from "components/Button/Button";
import { Loading } from "components/Loading/Loading";
import { Sorting } from "components/tables/BasicTable/InteractiveSurveysTable";
import { Pagination } from "components/tables/BasicTable/Pagination";
import ImageEditor from "components/Image Editor/ImageEditor";
import Icon from "components/Icon/Icon";
import DoYouWantToSave from "components/Popout/DoYouWantToSave";
import { SelectFieldCustom, TextFieldSimple } from "components/inputs";
import FlexCol from "components/layouts/FlexColumn/FlexCol";
import { Footer } from "App";
import {
  useChangeUserRole,
  useFetchRolesGql,
} from "api/resources/organization/roles";
import Modal from "components/ReactModal/ReactModal";
import { Player } from "@lottiefiles/react-lottie-player";
import { CreateUser } from "./CreateUser";
import CreateJoinCode from "./CreateJoinCode";
import { Pie } from "components/inputs/input_fields/CircleProgress/Circle";
import { HorizontalBar } from "components/layouts/HorizontalBar/HorizontalBar";

export const Users = ({ organization, roles }) => {
  const { id } = useParams();
  const removeUser = useRemoveUserFromOrg();
  const orgRoles = useFetchRolesGql(id);
  const [showUser, setShowUser] = useState(false);
  const [currUser, setCurrUser] = useState(null);
  const [invite, setInvite] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [searchString, setSearchString] = useState("");
  const [perPage, setPerpage] = useState(8);
  const [pageSkip, setPageSkip] = useState(0);
  const [checkRemove, setCheckRemove] = useState(false);
  const [confirmRoleChange, setConfirmRoleChange] = useState();
  const [createNew, setCreateNew] = useState(false);
  const [createCode, setCreateCode] = useState(false);

  const [sort, setSort] = useState({
    descend: false,
    item: "firstName",
  });

  const getUsers = useFetchUsersByOrgId(
    searchString,
    pageSkip,
    perPage,
    sort,
    id
  );

  const selectUser = (user) => {
    delete user.organizationId;
    setCurrUser(user);
    setShowUser(true);
  };

  useEffect(() => {
    getUsers.refetch();
  }, [pageNumber, sort, perPage, searchString]);

  function handleRemoveUser(id) {
    if (id) {
      removeUser.mutate(
        {
          orgId: organization.id,
          userId: id,
        },
        {
          onSuccess: () => {
            setCheckRemove(false);
            location.reload();
          },
        }
      );
    }
  }

  function getRoles(roles) {
    let role = roles?.find((role) => role?.organizationId === id);
    return role
      ? { value: role?.id, label: role?.name }
      : { value: "", label: "" };
  }

  const newOptions = [
    {
      onClick: () => setInvite(true),
      data: (
        <div className={styles.bigOption}>
          <FlexRow start>
            <Icon blue iconName={"envelope-fill"}></Icon> Invite a new user
          </FlexRow>{" "}
          <div className={styles.description2}>
            Invite someone to join your org by email.
          </div>
        </div>
      ),
    },
    {
      onClick: () => setCreateNew(true),
      data: (
        <div className={styles.bigOption}>
          <FlexRow start>
            <Icon blue iconName={"person-plus-fill"}></Icon> Create a new user
          </FlexRow>{" "}
          <div className={styles.description2}>
            Manually create a new user with password info.
          </div>
        </div>
      ),
    },
    {
      onClick: () => setCreateCode(true),
      data: (
        <div className={styles.bigOption}>
          <FlexRow start>
            <Icon blue iconName={"123"}></Icon> Generate a Join Code
          </FlexRow>{" "}
          <div className={styles.description2}>
            Create a join code to share in any way you want.
          </div>
        </div>
      ),
    },
  ];

  return (
    <div className={styles.page}>
      <div className={styles.top}>
        <h2>Manage Users</h2>
      </div>
      <FlexCol
        gap={"1rem"}
        style={{
          maxWidth: "1000px",
          margin: "2rem 0",
          height: "100%",
        }}
      >
        {checkRemove && (
          <DoYouWantToSave
            cancelText={"No, do not remove"}
            confirmText={"Yes"}
            prompt={
              "Are you sure you want to remove this user from your organization?"
            }
            navAway={() => setCheckRemove(false)}
            onSave={() => handleRemoveUser(checkRemove)}
          ></DoYouWantToSave>
        )}

        <FlexRow
          justify={"space-between"}
          style={{ flexWrap: "wrap", padding: "0 2rem" }}
        >
          <Sorting sort={sort} setSort={setSort} person></Sorting>
          <FlexRow style={{ width: "fit-content", flexWrap: "wrap" }}>
            <div className={styles.text_2}>
              {getUsers.data?.users?.count}{" "}
              {getUsers.data?.users?.count === 1 ? "user" : "users"}
            </div>
            <NavTextField
              value={searchString}
              setValue={setSearchString}
              placeholder={"Search user..."}
              shadow
            />
            <Button
              blue
              shadow
              // onClick={() => setCreateCode(true)}
              height={2.1}
              optionHeight={100}
              // optionStyle={{maxWidth: "200px"}}
              options={newOptions}
            >
              + Add User
            </Button>
          </FlexRow>
        </FlexRow>

        {(getUsers.isLoading || orgRoles.isLoading) && (
          <div style={{ height: "100vh" }}>
            <Loading></Loading>
          </div>
        )}
        {getUsers.isError && <p>Error</p>}
        {getUsers.isSuccess && orgRoles.isSuccess && (
          <>
            <FlexCol gap={"1rem"} style={{}}>
              {getUsers.isRefetching && (
                <div style={{ height: "100vh" }}>
                  <Loading></Loading>
                </div>
              )}
              {!getUsers.isRefetching && (
                <div className={styles.users}>
                  {getUsers.data?.users?.users?.map((user, index) => (
                    <User
                      key={index}
                      user={user}
                      index={index}
                      onClick={() => selectUser(user)}
                      orgId={organization?.id}
                      setCheckRemove={setCheckRemove}
                      roles={
                        orgRoles.isSuccess ? orgRoles.data.rolesByOrgId : []
                      }
                      currRole={user?.roles ? getRoles(user?.roles) : {}}
                      setConfirmRoleChange={setConfirmRoleChange}
                    ></User>
                  ))}
                </div>
              )}
              <FlexRow justify={"flex-end"} style={{ padding: "0 2rem" }}>
                <Pagination
                  pageNum={pageNumber}
                  setPageNum={setPageNumber}
                  maxPage={
                    getUsers.data?.users?.count / perPage < 1
                      ? 1
                      : Math.ceil(getUsers.data?.users?.count / perPage)
                  }
                  length={getUsers.data?.users?.users?.length}
                  searchString={searchString}
                  perPage={perPage}
                  setPerPage={setPerpage}
                  setPageSkip={setPageSkip}
                ></Pagination>
              </FlexRow>
              {/* <FlexRow>
                {" "}
                <div className={styles.infoBox}>
                  <FlexCol gap={".5rem"}>
                    <div
                      className={styles.label4}
                      style={{ fontWeight: "500", color: "black", margin: "0" }}
                    >
                      Admin Users
                    </div>
                    <div
                      className={styles.label4}
                      style={{
                        margin: "0",
                        display: "flex",
                        gap: ".4rem",
                        alignItems: "center",
                      }}
                    >
                      <div className={styles.infoValue}>
                        2 of {organization?.adminsAllowed}
                      </div>{" "}
                      used
                    </div>
                    <Button blue shadow>
                      Request more slots
                    </Button>
                  </FlexCol>

                  <div>
                    <Pie
                      percentage={Math.ceil(
                        (2 / organization?.adminsAllowed) * 100
                      )}
                      colour={"#15bcc7"}
                      progress
                      strokeWidth={12}
                      backColor={"#efefef"}
                    ></Pie>
                  </div>
                </div>
                <div className={styles.infoBox}>
                  <FlexCol gap={".5rem"}>
                    <div
                      className={styles.label4}
                      style={{ fontWeight: "500", color: "black", margin: "0" }}
                    >
                      Other Users
                    </div>
                    <div
                      className={styles.label4}
                      style={{
                        margin: "0",
                        display: "flex",
                        gap: ".4rem",
                        alignItems: "center",
                      }}
                    >
                      <div className={styles.infoValue}>
                        of {organization?.usersAllowed}
                      </div>{" "}
                      used
                    </div>
                    <Button blue shadow>
                      Request more slots
                    </Button>
                  </FlexCol>

                  <div>
                    <Pie
                      percentage={Math.ceil(
                        (2 / organization?.usersAllowed) * 100
                      )}
                      colour={"#15bcc7"}
                      progress
                      strokeWidth={12}
                      backColor={"#efefef"}
                    ></Pie>
                  </div>
                </div>
              </FlexRow> */}
            </FlexCol>
            {confirmRoleChange && (
              <ConfrimChangeRole
                roleData={confirmRoleChange}
                setShow={setConfirmRoleChange}
                orgId={id}
              />
            )}
            {invite && (
              <InviteUser
                show={invite}
                setShow={setInvite}
                orgId={id}
                roles={orgRoles.isSuccess ? orgRoles.data.rolesByOrgId : []}
              />
            )}
            {createNew && (
              <CreateUser
                onClose={() => setCreateNew(false)}
                onDone={getUsers.refetch}
                orgId={id}
              />
            )}

            {createCode && (
              <CreateJoinCode onClose={() => setCreateCode(false)} orgid={id} />
            )}
            {showUser && roles?.isAdmin && (
              <UserProfile2
                show={showUser}
                setShow={setShowUser}
                userId={currUser?.id}
                orgs={currUser?.organization}
                roles={currUser?.roles}
                organizationId={organization?.id}
                canEdit={roles.canEditOrgUsers}
                popout
              />
            )}
          </>
        )}
      </FlexCol>

      <Footer></Footer>
      {/* <FlexCol
        style={{ width: "400px", padding: "5rem 2rem", height: "100%" }}
        gap={"2rem"}
      >
        <div className={styles.header_4} style={{ margin: "0" }}>
          Permission Control
        </div>
        <div className={styles.text_3} style={{ margin: "0" }}>
          Manage users of your organizaiton and set their permission level. You
          can invite new users as long as you have available seats left in your
          plan.
        </div>
        <div className={styles.text_3} style={{ margin: "0" }}>
          <div className={styles.header_5} style={{ margin: "0" }}>
            Admin Permissions
          </div>
          Admins have access to the entire organization. They can edit and
          manage all project and organization settings, manage members as well
          as security, billing settings.
        </div>
        <div className={styles.text_3} style={{ margin: "0" }}>
          <div className={styles.header_5} style={{ margin: "0" }}>
            Editor Permissions
          </div>
          Editors can access all services. However, they cannot edit any
          organization settings, including billing information and inviting
          additional users to the organization.
        </div>
        <div className={styles.text_3} style={{ margin: "0" }}>
          <div className={styles.header_5} style={{ margin: "0" }}>
            Viewer Permissions
          </div>
          Viewers can access all services. However, they cannot edit any of the
          information in any of these services. Similar to the Editor, they
          cannot edit any organization settings, including billing information
          and inviting additional users to the organization
        </div>
        <HorizontalBar height={2}></HorizontalBar>
        <div className={styles.text_3} style={{ margin: "0" }}>
          Learn more about{" "}
          <span className={styles.link}>
            User Permissions <i className="bi-box-arrow-up-right"></i>
          </span>
        </div>
      </FlexCol> */}
    </div>
  );
};

function InviteUser({ show, setShow, orgId, roles }) {
  const [currRole, setCurrRole] = useState({
    value: roles[0]?.id,
    label: roles[0]?.name,
  });
  const [sending, setSending] = useState(false);
  const [success, setSuccess] = useState(false);
  const [email, setEmail] = useState("");
  const [joinCode, setJoinCode] = useState("");
  const InviteUserMutation = useInviteUser();
  const ref = useRef(null);

  function getOptions(options) {
    let response = [];
    for (let option of options) {
      if (option?.id) {
        response.push({ value: option?.id, label: option?.name });
      }
    }
    return response;
  }

  function SendInvite() {
    setSending(true);
    InviteUserMutation.mutate(
      {
        email,
        roleId: currRole?.value,
      },
      {
        onSuccess: (data) => {
          setSuccess(true);
          setJoinCode(data?.InviteUser?.code);
        },
      }
    );
  }

  function cancel() {
    setJoinCode("");
    setEmail("");
    setSending(false);
    setSuccess(false);
    setShow(undefined);
  }

  return (
    <Modal
      show={show}
      onClose={() => setShow(undefined)}
      modalStyle={{ padding: "2rem", borderRadius: ".5rem", width: "600px" }}
      dark
    >
      <div className={styles.header_3} style={{ margin: "0" }}>
        Invite a user
      </div>
      {sending && success && (
        <>
          <FlexRow
            gap={".5rem"}
            style={{ marginBottom: "1rem" }}
            justify={"center"}
          >
            <div className={styles.text_1} style={{ textAlign: "center" }}>
              Invite Sent
            </div>
            <Player
              autoplay={true}
              loop={false}
              ref={ref}
              onEvent={(e) => {
                if (e === "play") {
                  setTimeout(() => {
                    ref?.current?.pause();
                  }, 2000);
                }
              }}
              src={require("assets/animations/checkmarkgreen.json")}
              style={{
                height: "40px",
                // width: "50px",
              }}
            />
          </FlexRow>
          <div className={styles.text_3} style={{ margin: "0" }}>
            Your invite was successfully sent to {email}. If they claim to have
            not recieved the email after 10 minutes, have them check their
            junk/spam folder.
            <br /> <br />
            If the email is still not found, Use the following join code and
            send the email externally or try the invite again:{" "}
            <span className={styles.blue}>{joinCode}</span>
          </div>
          <div
            className={styles.text_1}
            style={{ margin: "1rem 0", textAlign: "center" }}
          ></div>
          <div
            className={styles.text_3}
            style={{ margin: "0rem 0", textAlign: "center" }}
          >
            Get help from{" "}
            <span className={styles.link}>
              Reaction Support <i className="bi-box-arrow-up-right"></i>
            </span>
          </div>
          <FlexRow justify={"flex-end"}>
            <Button shadow onClick={cancel}>
              Close
            </Button>
          </FlexRow>
        </>
      )}
      {sending && !success && (
        <>
          <FlexCol gap={"0"}>
            <Player
              autoplay
              loop
              src={require("assets/animations/EmailSending.json")}
              style={{
                height: "150px",
                // width: "100px",
              }}
            />
            <div className={styles.text_2} style={{ textAlign: "center" }}>
              Sending Invite
              <span className={styles.dots}>
                <span>.</span>
                <span>.</span>
                <span>.</span>
              </span>
            </div>
          </FlexCol>
        </>
      )}
      {!sending && (
        <>
          <div className={styles.text_3} style={{ margin: "0" }}>
            Send an invite link to an email. Their role can be changed later.
          </div>
          <FlexRow style={{ marginTop: "1.5rem", marginBottom: "0" }}>
            <TextFieldSimple
              type={"email"}
              icon="at"
              value={email}
              onChange={setEmail}
            ></TextFieldSimple>
            <SelectFieldCustom
              style={{ width: "200px" }}
              options={getOptions(roles)}
              value={currRole}
              onChange={(v) => setCurrRole(v)}
            ></SelectFieldCustom>
          </FlexRow>
          <div className={styles.text_3} style={{ margin: "1.5rem 0" }}>
            Learn more about{" "}
            <span className={styles.link}>
              User Roles <i className="bi-box-arrow-up-right"></i>
            </span>
          </div>
          <FlexRow justify={"space-between"}>
            <Button shadow onClick={cancel}>
              Cancel
            </Button>{" "}
            <Button blue shadow onClick={SendInvite}>
              Send Invite
            </Button>
          </FlexRow>
        </>
      )}
    </Modal>
  );
}

function ConfrimChangeRole({ roleData, setShow, orgId }) {
  const changeRole = useChangeUserRole();
  const [saveWords, setSaveWords] = useState("Submit");

  function handleChangeRole() {
    setSaveWords(
      <>
        Saving <Loading width={20} height={20}></Loading>
      </>
    );
    changeRole.mutate(
      {
        orgId: orgId,
        userId: roleData?.user?.id,
        roleId: roleData?.newRole?.value,
      },
      {
        onSuccess: (data) => {
          setSaveWords("Saved");
          location.reload();
        },
      }
    );
  }

  return (
    <Modal
      show={roleData}
      onClose={() => setShow(undefined)}
      modalStyle={{ padding: "2rem", borderRadius: ".5rem", maxWidth: "470px" }}
      dark
    >
      <div className={styles.header_3} style={{ margin: "0" }}>
        Change User Permissions
      </div>
      <div className={styles.text_3} style={{ margin: "0" }}>
        Changing user's permissions will change their access throughout the
        website.
      </div>
      <FlexRow style={{ marginTop: "1.5rem", marginBottom: "0" }}>
        <ImageEditor
          src={roleData?.user?.image?.imageURL}
          canEdit={false}
          isUser={true}
          userId={roleData?.user?.id}
          position={
            roleData?.user?.image?.position &&
            typeof roleData?.user?.image?.position === "string"
              ? JSON.parse(roleData?.user?.image?.position)
              : roleData?.user?.image?.position
          }
          image={roleData?.user?.image}
          // refetch={getUser.refetch}
          height={35}
          width={35}
          noShadow
        ></ImageEditor>
        <span
          className={styles.text_2}
          style={{ display: "flex", gap: "0rem", flexDirection: "column" }}
        >
          <span style={{ fontWeight: "500" }}>
            {roleData?.user.firstName} {roleData?.user.lastName}
          </span>
          <FlexRow>
            <span style={{ textDecoration: "line-through" }}>
              {roleData?.prevRole?.label}
            </span>
            <i className="bi-arrow-right"></i>
            <span className={styles.blue}> {roleData?.newRole?.label} </span>
          </FlexRow>
        </span>
      </FlexRow>
      <div className={styles.text_3} style={{ margin: "1.5rem 0" }}>
        Learn more about{" "}
        <span className={styles.link}>
          User Permissions <i className="bi-box-arrow-up-right"></i>
        </span>
      </div>
      <FlexRow justify={"space-between"}>
        <Button shadow>Cancel</Button>{" "}
        <Button blue shadow onClick={() => handleChangeRole()}>
          {saveWords}
        </Button>
      </FlexRow>
    </Modal>
  );
}

function User({
  user,
  onClick,
  orgId,
  setCheckRemove,
  currRole,
  roles,
  setConfirmRoleChange,
}) {
  function getOptions(options) {
    let response = [];
    for (let option of options) {
      if (currRole?.id != option?.id) {
        response.push({ value: option?.id, label: option?.name });
      }
    }
    return response;
  }

  return (
    <div className={styles.user}>
      {/* <FlexRow
        style={{ alignItems: "center", justifyContent: "space-between" }}
      >
        <FlexRow>
          <ImageEditor
            src={user?.image?.imageURL}
            canEdit={false}
            isUser={true}
            userId={user?.id}
            position={
              user?.image?.position && typeof user?.image?.position === "string"
                ? JSON.parse(user?.image?.position)
                : user?.image?.position
            }
            image={user?.image}
            // refetch={getUser.refetch}
            height={35}
            width={35}
            noShadow
          ></ImageEditor>
          <span
            className={styles.text_3}
            style={{ display: "flex", gap: ".5rem" }}
          >
            <span
              className={styles.link}
              style={{ color: "black" }}
              onClick={(e) => {
                e.stopPropagation();
                onClick();
              }}
            >
              {user.firstName} {user.lastName}
            </span>
            <span className={styles.email}>{user.email}</span>
          </span>
        </FlexRow>
        <FlexRow fit>
          <SelectFieldCustom
            style={{ height: "2.1rem", fontSize: ".8rem" }}
            containerStyle={{
              maxHeight: "2.1rem",
              minHeight: "2.1rem",
              padding: ".4rem",
              minWidth: "180px",
            }}
            options={getOptions(roles)}
            value={currRole}
            onChange={(v) =>
              setConfirmRoleChange({
                newRole: v,
                prevRole: currRole,
                user: user,
              })
            }
          ></SelectFieldCustom>

          <Button
            // onClick={(e) => {
            //   e.stopPropagation();
            //   setOptions(true);
            // }}
            height={2.1}
            options={[
              {
                onClick: (e) => {
                  e.stopPropagation();
                  onClick(user.id);
                },
                data: (
                  <>
                    {" "}
                    <i
                      className="bi-layout-text-sidebar-reverse"
                      style={{ color: "#A7D5C5" }}
                    ></i>{" "}
                    View Details
                  </>
                ),
              },
              {
                onClick: (e) => {
                  e.stopPropagation();
                  setCheckRemove(user.id);
                },
                data: (
                  <>
                    {" "}
                    <i
                      className="bi-trash"
                      style={{ color: "#FF8878" }}
                    ></i>{" "}
                    Remove User
                  </>
                ),
              },
            ]}
          >
            <i
              className={"bi-three-dots-vertical"}
              style={{ fontSize: "1.1rem" }}
            ></i>
          </Button>
        </FlexRow>
      </FlexRow> */}
      <FlexRow
        style={{ flexDirection: "column", gap: "0", alignItems: "flex-start" }}
        gap={0}
        start
      >
        <FlexCol start gap=".5rem">
          <FlexRow justify={"space-between"} align={"flex-start"}>
            <ImageEditor
              src={user?.image?.imageURL}
              canEdit={false}
              isUser={true}
              userId={user?.id}
              position={
                user?.image?.position &&
                typeof user?.image?.position === "string"
                  ? JSON.parse(user?.image?.position)
                  : user?.image?.position
              }
              image={user?.image}
              // refetch={getUser.refetch}
              height={45}
              width={45}
              noShadow
            ></ImageEditor>
            <Button
              height={2}
              width={"2rem"}
              style={{marginRight: "-.5rem"}}
              options={[
                {
                  onClick: (e) => {
                    e.stopPropagation();
                    onClick(user.id);
                  },
                  data: (
                    <>
                      {" "}
                      <i
                        className="bi-layout-text-sidebar-reverse"
                        style={{ color: "#A7D5C5" }}
                      ></i>{" "}
                      View Details
                    </>
                  ),
                },
                {
                  onClick: (e) => {
                    e.stopPropagation();
                    setCheckRemove(user.id);
                  },
                  data: (
                    <>
                      {" "}
                      <i
                        className="bi-trash"
                        style={{ color: "#FF8878" }}
                      ></i>{" "}
                      Remove User
                    </>
                  ),
                },
              ]}
            >
              <i
                className={"bi-three-dots-vertical"}
                style={{ fontSize: "1.1rem" }}
              ></i>
            </Button>
          </FlexRow>

          <h4>
            {user?.firstName} {user?.lastName}
          </h4>
        </FlexCol>
        <span
          className={styles.section_label6}
          style={{ margin: "0", height: "fit-content" }}
        >
          {user?.position}
        </span>
      </FlexRow>
      <HorizontalBar height={2} style={{  }}></HorizontalBar>
      <FlexRow
        style={{ flexDirection: "column", gap: "0", alignItems: "flex-start" }}
        gap={0}
        start
      >
        <SelectFieldCustom
        // label="Role"
          style={{ height: "2.1rem", fontSize: ".8rem" }}
          containerStyle={{
            maxHeight: "2.1rem",
            minHeight: "2.1rem",
            padding: ".4rem",
            minWidth: "180px",
          }}
          options={getOptions(roles)}
          value={currRole}
          onChange={(v) =>
            setConfirmRoleChange({
              newRole: v,
              prevRole: currRole,
              user: user,
            })
          }
        ></SelectFieldCustom>
      </FlexRow>
      {/* <FlexRow
        style={{ flexDirection: "column", gap: "0", alignItems: "flex-start" }}
        gap={0}
        start
      >
        <span
          className={styles.permission}
          style={{ margin: "0", padding: "0" }}
        >
          Recent Activity:
        </span>

        {getActivity.isSuccess &&
          getActivity.data?.object?.activities.map((a) => (
            <span className={styles.activity}>
              {a.message} {trimDate(a.createdAt, true)}
            </span>
          ))}
      </FlexRow> */}
    </div>
  );
}
