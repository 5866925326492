import React, { useEffect, useMemo, useState } from "react";

import styles from "./org-images.module.scss";
import {
  useDeleteImage,
  useGetOrgImagesGql,
} from "api/resources/projects/images";
import { Loading } from "components/Loading/Loading";
import { saveAs } from "file-saver";
import ImageUploadModal from "components/ImageUploadModal/imageUploadModal";
import Button from "components/Button/Button";
import { useParams } from "react-router-dom";
import EditField from "components/inputs/input_fields/EditField/EditField";
import ImageEditor from "components/Image Editor/ImageEditor";
import FlexCol from "components/layouts/FlexColumn/FlexCol";
import { HorizontalBar } from "components/layouts/HorizontalBar/HorizontalBar";
import { EditImageModal } from "components/ImageUploadModal/EditImageModal";

export const OrgImages = ({ updateRoute }) => {
  const getOrgImage = useGetOrgImagesGql();
  const deleteImage = useDeleteImage();
  const [show, setShow] = useState(false);
  const { id } = useParams();

  useMemo(() => {
    if (updateRoute) {
      updateRoute(5);
    }
  }, []);

  const handleDelete = (name, id) => {
    name = name.substring(name.lastIndexOf("/") + 1);
    deleteImage.mutate(
      {
        filename: name,
        filetype: "image/*",
        id: id,
      },
      {
        onSuccess: (data) => {
          getOrgImage.refetch();
        },
      }
    );
  };

  return (
    <div className={styles.page}>
      <FlexCol>
        <h3> Organization Images</h3>
        <span>
          Add/Edit images here and use them throughout the site or externally
        </span>
        <HorizontalBar
          height={2}
          style={{ marginBottom: "2rem" }}
        ></HorizontalBar>
      </FlexCol>

      <Button shadow onClick={() => setShow(true)} blue>
        Upload Image
      </Button>

      {getOrgImage.isLoading && <Loading></Loading>}
      {getOrgImage.isSuccess && (
        <>
          <div className={styles.imageRow}>
            {getOrgImage.data.imagesByOrgId.map((image, ind) => (
              <>
                <EditField
                  // label={
                  //   <span>
                  //     {image?.name} {image?.isLogo ? "Logo" : ""}
                  //   </span>
                  // }
                  onClickEdit={() => setShow(image)}
                  onClickDelete={() => handleDelete(image.imageURL, image.id)}
                  onClickContainer={() => saveAs(image.imageURL, image.name)}
                  value={
                    <>
                      {image?.isLogo && (
                        <div className={styles.imageContainer}>
                          <ImageEditor
                            src={image?.imageURL}
                            alt={image?.name}
                            isLogo={image?.isLogo}
                            position={
                              image?.position &&
                              typeof image?.position === "string"
                                ? JSON.parse(image?.position)
                                : image?.position
                            }
                            image={image}
                            refetch={getOrgImage.refetch}
                            orgId={id}
                            noShadow
                          ></ImageEditor>
                        </div>
                      )}
                      {!image?.isLogo && (
                        <div style={{ padding: "1rem" }}>
                          {" "}
                          <img
                            src={image?.imageURL}
                            style={{ maxHeight: "350px", maxWidth: "800px" }}
                          ></img>
                        </div>
                      )}
                    </>
                  }
                ></EditField>
              </>
            ))}
          </div>

          {/* {show && (
            <ImageUploadModal
              show={show}
              setShow={setShow}
              refetch={getOrgImage.refetch}
              orgId={id}
              isLogo={show?.isLogo}
              image={show}
              src={show?.imageURL}
              initPosition={show?.position ? JSON.parse(show?.position) : ""}
            ></ImageUploadModal>
          )} */}
          {show && (
            <EditImageModal
              show={show}
              setShow={setShow}
              refetch={getOrgImage.refetch}
              orgId={id}
              isLogo={show?.isLogo}
              image={show}
              src={show?.imageURL}
              initPosition={show?.position ? JSON.parse(show?.position) : ""}
            />
          )}
        </>
      )}
    </div>
  );
};
