import { useState, useEffect, useRef } from "react";
import accordianStyles from "../../SettingsAccordion.module.scss";
import styles from "./Data.module.scss";
import { TableType } from "../../../QChart/QuestionChart";
import { useFetchQuestionsGql } from "api/resources/projects/questions";
import { Label } from "components/layouts/Label/Label";
import { Loading } from "components/Loading/Loading";
import {
  useFetchSurveyTags,
  useFetchSurveyTagsGql,
} from "api/resources/organization/surveytags";
import {
  useFetchAllPrograms,
  useFetchPrograms,
  useFetchProjectsByIdsChart,
  useSearchProjectsForChart,
} from "api/resources/projects/projects";
import { forEach } from "assets/functions/ArrayFunctions";
import { useFetchReport } from "api/resources/organization/reports";
import { useParams } from "react-router-dom";
import { QuestionTag } from "./QuestionTag/QuestionTag";
import { useFetchQTags } from "api/resources/organization/questiontag";
import { getQuestionType } from "./QData";
import { combinedQs } from "pages/results/Charts/Visualization";
import FlexCol from "components/layouts/FlexColumn/FlexCol";
import { ChartLabel } from "../../Tools";

export const Comparison = ({ viz, updateViz }) => {
  const { reportid } = useParams();

  return (
    <>
      <div
        className={styles.body}
        style={{
          padding: "0px",
          marginTop: "0px",
          width: "100%",
          backgroundColor: "transparent",
          maxWidth: "500px",
        }}
      >
        {reportid && (
          <MiddleMan viz={viz} updateViz={updateViz} reportId={reportid} />
        )}
        {!reportid && <PickData viz={viz} updateViz={updateViz} />}
      </div>
    </>
  );
};

function MiddleMan({ viz, updateViz, reportId }) {
  const getReport = useFetchReport(reportId);

  return (
    <>
      {getReport.isLoading && (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Loading height={120} width={120} />
        </div>
      )}
      {getReport.isSuccess && (
        <PickData
          viz={viz}
          updateViz={updateViz}
          tiedIds={getReport.data.report.project.map((p) => p.id)}
        />
      )}
    </>
  );
}

function PickData({ viz, tiedIds, updateViz }) {
  const comparison = {
    questionIds: [],
    questionTags: [],
  };

  function getProjectIds() {
    let included = [...tiedIds];
    if (viz.comparison.questionIds.length && viz.comparisonQs) {
      for (let id of viz.comparison.questionIds) {
        let q = viz.comparisonQs.find((qst) => qst.id === id);
        if (q && !included.includes(q.projectId)) {
          included.push(q.projectId);
        }
      }
    }
    return included;
  }

  const [projectIds, setProjectIds] = useState(getProjectIds());
  const [addSurvey, setAddSurvey] = useState(false);
  const [potentialTags, setPotentialTags] = useState([...viz.tagIdsArray]);
  const [programIds, setProgramIds] = useState(
    viz.programIds ? [...viz.programIds] : []
  );
  const getChosenTags = useFetchSurveyTags({ tagIds: potentialTags });
  const getChosenPrograms = useFetchPrograms(programIds);
  const getProjects = useFetchProjectsByIdsChart({
    projectIds: projectIds,
  });
  const getAllQTags = useFetchQTags();

  function removeQ(vizCopy, q) {
    if (vizCopy.comparison.questionIds.includes(q.id)) {
      return;
    }

    for (let tag of vizCopy.comparison.questionTags) {
      if (tag.timeFrame === "selection") {
        if (tag.selection.includes(q.id)) {
          return;
        }
      } else {
        let realTag = getAllQTags.data.tags.find((t) => t.id === tag.id);

        if (realTag && realTag.question.some((qst) => qst.id === q.id)) {
          return;
        }
      }
    }
    // clear to remove it

    let ind = vizCopy.comparisonQs.findIndex((qst) => qst.id === q.id);
    if (ind > -1) {
      vizCopy.comparisonQs.splice(ind, 1);
    }
  }

  function addQ(vizCopy, q) {
    if (!vizCopy.comparisonQs.some((qst) => qst.id === q.id)) {
      vizCopy.comparisonQs.push(q);
    }
  }

  function addSurveyTag(tag) {
    setPotentialTags((old) => [...old, tag.id]);
    let projIdsCopy = [...projectIds];

    for (let project of tag.project) {
      if (!projIdsCopy.includes(project.id)) {
        projIdsCopy.push(project.id);
      }
    }
    setProjectIds(projIdsCopy);
    setAddSurvey(false);
  }

  function removeSurveyTag(tag) {
    let index = potentialTags.indexOf(tag.id);
    let tagsCopy = [...potentialTags];
    tagsCopy.splice(index, 1);
    setPotentialTags(tagsCopy);
    let projIdsCopy = [...projectIds];

    //remove associated projects
    let copy = { ...viz };
    const otherTags = getChosenTags.data?.tags.filter((t) => t.id !== tag.id);
    for (let proj of tag.project) {
      // if there are no more tags or projects associated with it
      if (
        !otherTags.some((otherTag) =>
          otherTag.project.some((p) => p.id === proj.id)
        ) &&
        !getChosenPrograms.data.programs.some((program) =>
          program.project.some((p) => p.id === proj.id)
        )
      ) {
        index = projIdsCopy.indexOf(proj.id);
        if (index >= 0) {
          projIdsCopy.splice(index, 1);

          let survey = getProjects.data.survey.find((s) => s.id === proj.id);
          for (let q of survey.question) {
            let ind = copy.comparison.questionIds.indexOf(q.id);
            if (ind > -1) {
              copy.comparison.questionIds.splice(ind, 1);
              removeQ(copy, q);
            }
          }
        }
      }
    }

    setProjectIds(projIdsCopy);
    updateViz(copy);
  }

  function addProgram(program) {
    setProgramIds((old) => [...old, program.id]);

    let projIdsCopy = [...projectIds];
    for (let project of program.project) {
      if (!projIdsCopy.includes(project.id)) {
        projIdsCopy.push(project.id);
      }
    }
    setProjectIds(projIdsCopy);
    setAddSurvey(false);
  }

  function removeProgram(program) {
    let index = programIds.indexOf(program.id);
    let programIdsCopy = [...programIds];
    programIdsCopy.splice(index, 1);
    setProgramIds(programIdsCopy);

    let copy = { ...viz };
    let projIdsCopy = [...projectIds];

    //remove associated projects
    const otherPrograms = getChosenPrograms.data.programs.filter(
      (p) => p.id !== program.id
    );
    for (let proj of program.project) {
      // check if its in other tags or programs
      if (
        !getChosenTags.data.tags.some((otherTag) =>
          otherTag.project.some((p) => p.id === proj.id)
        ) &&
        !otherPrograms.some((program) =>
          program.project.some((p) => p.id === proj.id)
        )
      ) {
        let index = projIdsCopy.indexOf(proj.id);
        if (index > -1) {
          projIdsCopy.splice(index, 1);

          let survey = getProjects.data.survey.find((s) => s.id === proj.id);
          for (let q of survey.question) {
            let ind = copy.comparison.questionIds.indexOf(q.id);
            if (ind > -1) {
              copy.comparison.questionIds.splice(ind, 1);
              removeQ(copy, q);
            }
          }
        }
      }
    }

    setProjectIds(projIdsCopy);
    updateViz(copy);
  }

  function addProject(id) {
    let surveys = [...projectIds];
    surveys.push(id);
    setProjectIds(surveys);
    setAddSurvey(false);
  }

  function removeProject(project) {
    let surveyIds = [...projectIds];
    let indexOf = surveyIds.indexOf(project.id);
    surveyIds.splice(indexOf, 1);
    setProjectIds(surveyIds);

    let copy = { ...viz };
    for (let q of project.question) {
      let ind = copy.comparison.questionIds.indexOf(q.id);
      if (ind > -1) {
        copy.comparison.questionIds.splice(ind, 1);
        removeQ(copy, q);
      }
    }
    updateViz(copy);
  }

  function removeQuestion(q) {
    let copy = { ...viz };
    let ind = copy.comparison.questionIds.indexOf(q.id);
    copy.comparison.questionIds.splice(ind, 1);

    removeQ(copy, q);

    updateViz(copy);
  }

  function removeQuestionTag(i, oldQs) {
    let copy = { ...viz };
    copy.comparison.questionTags.splice(i, 1);

    for (let q of oldQs) {
      removeQ(copy, q);
    }

    updateViz(copy);
  }

  function onQTagTimeFrameChange(i, newTagCopy, oldQs, newQs) {
    let copy = { ...viz };
    copy.comparison.questionTags.splice(i, 1);
    for (let q of oldQs) {
      removeQ(copy, q);
    }

    for (let q of newQs) {
      addQ(copy, q);
    }

    copy.comparison.questionTags.splice(i, 0, newTagCopy);
    updateViz(copy);
  }

  function addToSelection(i, questions) {
    let copy = { ...viz };
    for (let q of questions) {
      copy.comparison.questionTags[i].selection.push(q.id);
      addQ(copy, q);
    }
    updateViz(copy);
  }

  function removeFromSelection(i, questions) {
    let copy = { ...viz };
    for (let q of questions) {
      let ind = copy.comparison.questionTags[i].selection.indexOf(q.id);
      copy.comparison.questionTags[i].selection.splice(ind, 1);

      removeQ(copy, q);
    }

    updateViz(copy);
  }

  function addQuestionTag(tag) {
    let newViz = { ...viz };

    newViz.comparison.questionTags.push({
      id: tag.id,
      name: tag.name,
      timeFrame: "All",
      color: tag.color,
    });
    for (let newQ of tag.question) {
      addQ(newViz, newQ);
    }
    updateViz(newViz);
  }

  function addQuestion(question) {
    let newViz = { ...viz };
    addQ(newViz, question);
    newViz.comparison.questionIds.push(question.id);
    updateViz(newViz);
  }

  const QType = getQuestionType(combinedQs(viz)[0]);

  return (
    <>
      <div className={styles.comparison}>
        <TagsAndPrograms
          viz={viz}
          QType={QType}
          allTags={getAllQTags.isSuccess ? getAllQTags.data.tags : null}
          chosenSurveyTags={
            getChosenTags.isSuccess ? getChosenTags?.data.tags : null
          }
          chosenPrograms={
            getChosenPrograms.isSuccess
              ? getChosenPrograms?.data.programs
              : null
          }
          removeProgram={removeProgram}
          removeSurveyTag={removeSurveyTag}
          addQuestionTag={addQuestionTag}
          removeQuestionTag={removeQuestionTag}
          onQTagTimeFrameChange={onQTagTimeFrameChange}
          addToSelection={addToSelection}
          removeFromSelection={removeFromSelection}
        />

        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            paddingTop: "10px",
          }}
        >
          <div
            className={`${accordianStyles.title}`}
            style={{ paddingBottom: "5px" }}
          >
            Surveys
            <i style={{ fontSize: "1.2em" }} className="bi bi-clipboard"></i>
          </div>
          {projectIds.length > 0 && getProjects.isSuccess && (
            <PickQuestions
              questionType={viz.type === TableType ? undefined : QType}
              comparison={viz.comparison}
              projects={getProjects.data.survey}
              removeProject={removeProject}
              removeQuestion={removeQuestion}
              addQuestion={addQuestion}
            ></PickQuestions>
          )}
        </div>

        <div className={styles.addSurvey}>
          <div
            className={styles.add}
            style={addSurvey ? { visibility: "hidden" } : undefined}
            onClick={() => setAddSurvey(true)}
          >
            + Add Survey
          </div>

          {getChosenTags.isSuccess && getChosenPrograms.isSuccess && (
            <AddSurvey
              viz={viz}
              addProject={addProject}
              potentialProjects={projectIds}
              setPotentialProjects={setProjectIds}
              chosenTags={getChosenTags?.data.tags}
              chosenPrograms={getChosenPrograms?.data.programs}
              addSurveyTag={addSurveyTag}
              addProgram={addProgram}
              close={() => setAddSurvey(false)}
              show={addSurvey}
            />
          )}
        </div>
      </div>
    </>
  );
}

function TagsAndPrograms({
  viz,
  QType,
  allTags,
  chosenSurveyTags,
  chosenPrograms,
  removeProgram,
  removeSurveyTag,
  addQuestionTag,
  removeQuestionTag,
  onQTagTimeFrameChange,
  addToSelection,
  removeFromSelection,
}) {
  const [searchString, setSearchString] = useState("");
  const [active, setActive] = useState(false);

  return (
    <>
      <div className={accordianStyles.title} style={{ paddingLeft: "10px" }}>
        Question Tags <i className="bi bi-tags"></i>
      </div>
      <div className={styles.searchFor}>
        <input
          type="text"
          onChange={(e) => setSearchString(e.target.value)}
          className={styles.search}
          value={searchString}
          // placeholder="search"
          onFocus={() => setActive(true)}
          onBlur={() => setTimeout(() => setActive(false), 250)}
        ></input>
        <div className={styles.tagsAnchor}>
          {active && allTags && (
            <SearchQTags
              viz={viz}
              QType={QType}
              searchString={searchString}
              addQuestionTag={addQuestionTag}
              allTags={allTags}
            />
          )}
        </div>
      </div>

      {viz.comparison.questionTags.length > 0 && (
        <div className={styles.qTags}>
          {viz.comparison.questionTags.map((t, i) => (
            <QuestionTag
              tag={t}
              viz={viz}
              onRemove={(oldQs) => removeQuestionTag(i, oldQs)}
              onTimeFrameChange={(copy, oldQs, newQs) =>
                onQTagTimeFrameChange(i, copy, oldQs, newQs)
              }
              addToSelection={(Qs) => addToSelection(i, Qs)}
              removeFromSelection={(Qs) => removeFromSelection(i, Qs)}
              forComparison
            />
          ))}
        </div>
      )}

      {chosenPrograms && chosenPrograms.length > 0 && (
        <div className={styles.potentialSurveyTags}>
          <div className={accordianStyles.title}>
            Programs <i className="bi bi-calendar-week"></i>
          </div>
          <div className={styles.tags}>
            {chosenPrograms.map((p) => (
              <Program program={p} removeProgram={removeProgram} />
            ))}
          </div>
        </div>
      )}
      {chosenSurveyTags && chosenSurveyTags.length > 0 && (
        <div className={styles.potentialSurveyTags}>
          <div className={accordianStyles.title}>
            Survey Tags <i className="bi bi-tags"></i>
          </div>
          <div className={styles.tags}>
            {chosenSurveyTags.map((tag) => (
              <SurveyTag tag={tag} removeSurveyTag={removeSurveyTag} />
            ))}
          </div>
        </div>
      )}
    </>
  );
}

function Program({ program, removeProgram }) {
  return (
    <div className={styles.tag}>
      {program?.mainProject?.name}{" "}
      <i
        className={`bi bi-x ${styles.deleteTag}`}
        onClick={() => removeProgram(program)}
      ></i>
    </div>
  );
}

function SurveyTag({ tag, removeSurveyTag }) {
  return (
    <div className={styles.tag}>
      {tag.label}
      <i
        className={`bi bi-x ${styles.deleteTag}`}
        onClick={() => removeSurveyTag(tag)}
      ></i>
    </div>
  );
}

function SearchQTags({ viz, QType, searchString, addQuestionTag, allTags }) {
  function getQuestionTags() {
    let tags = [...allTags];

    if (viz.type !== TableType) {
      if (QType) {
        tags = tags.filter((t) => t.type === QType);
      }
    }

    if (viz.comparison.questionTags.length) {
      tags = tags.filter(
        (t) =>
          !viz.comparison.questionTags.some(
            (includedTag) => includedTag.id === t.id
          )
      );
    }
    if (searchString) {
      tags = tags.filter((t) =>
        t.name.toLowerCase().includes(searchString.toLowerCase())
      );
    }
    return tags;
  }

  return (
    <div className={styles.tagOptions}>
      {getQuestionTags().map((t) => (
        <div className={styles.tagOption} onClick={() => addQuestionTag(t)}>
          <div className={styles.qTagName}>
            {t.name}{" "}
            {t.color && (
              <i className={`bi bi-tag-fill`} style={{ color: t.color }}></i>
            )}
          </div>
        </div>
      ))}
    </div>
  );
}

function AddSurvey({
  viz,
  addProject,
  potentialProjects,
  setPotentialProjects,
  chosenTags,
  chosenPrograms,
  addSurveyTag,
  addProgram,
  close,
  show,
}) {
  useEffect(() => {
    //Add to potential projects all projects from the survey tag
    let surveys = [...potentialProjects];
    let changed = false;
    for (let tag of chosenTags) {
      for (let proj of tag.project) {
        if (!surveys.includes(proj.id)) {
          surveys.push(proj.id);
          changed = true;
        }
      }
    }

    for (let prog of chosenPrograms) {
      for (let proj of prog.project) {
        if (!surveys.includes(proj.id)) {
          surveys.push(proj.id);
          changed = true;
        }
      }
    }

    if (changed) {
      setPotentialProjects(surveys);
    }
  }, []);

  return (
    <>
      {show && (
        <AddSurveyModal
          addProject={addProject}
          potentialProjects={potentialProjects}
          chosenTags={chosenTags}
          chosenPrograms={chosenPrograms}
          addSurveyTag={addSurveyTag}
          addProgram={addProgram}
          close={close}
        />
      )}
    </>
  );
}

function AddSurveyModal({
  chosenTags,
  chosenPrograms,
  addSurveyTag,
  addProgram,
  addProject,
  potentialProjects,
  close,
}) {
  function clickOutListener(e) {
    if (ref.current && !ref.current.contains(e.target)) {
      close();
      document.removeEventListener("click", clickOutListener, true);
    }
  }

  const ref = useRef();

  useEffect(() => {
    document.addEventListener("click", clickOutListener, true);
    return () => {
      document.removeEventListener("click", clickOutListener, true);
    };
  }, []);

  return (
    <>
      <div className={styles.addSettings} ref={ref}>
        <i className="bi-x-lg" onClick={close}></i>
        <FlexCol gap={"5px"}>
          <ChartLabel
            style={{
              paddingLeft: "5px",
            }}
          >
            Add Survey
          </ChartLabel>
          <SearchSurvey
            addProject={addProject}
            exceptForIds={potentialProjects}
          ></SearchSurvey>
        </FlexCol>
        {chosenTags && chosenPrograms && (
          <AddTagsAndPrograms
            chosenTags={chosenTags}
            chosenPrograms={chosenPrograms}
            addSurveyTag={addSurveyTag}
            addProgram={addProgram}
          ></AddTagsAndPrograms>
        )}
      </div>
    </>
  );
}

export function SearchSurvey({ addProject, exceptForIds }) {
  const [searchString, setSearchString] = useState("");
  const [searchVisible, setSearchVisible] = useState(false);
  function changeSearch(e) {
    setSearchString(e.target.value);
  }
  const [perPage, setPerPage] = useState(0);
  const [pageSkip, setPageSkip] = useState(0);
  const [searchSort, setSearchSort] = useState({
    item: "startedAt",
    descend: true,
  });

  useEffect(() => {
    searchOptions.refetch();
  }, [searchString]);

  const searchOptions = useSearchProjectsForChart(
    searchString,
    pageSkip,
    perPage,
    searchSort
  );

  const ref = useRef(null);
  //handling the clicking outside of elements
  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setSearchVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  return (
    <div className={styles.searchQuestionBox} ref={ref}>
      <input
        className={styles.searchQuestionInput}
        type="text"
        placeholder="search"
        onChange={changeSearch}
        onFocus={() => {
          setSearchVisible(true);
        }}
        // onBlur={() => {
        //   setSearchVisible(false);
        // }}
      ></input>
      {searchVisible &&
        searchOptions.isSuccess &&
        !searchOptions.isRefetching && (
          <div className={styles.optionsBox}>
            <div className={styles.options}>
              {searchOptions.data?.surveys.map((project, index) => (
                <>
                  {!exceptForIds.includes(project.id) && (
                    <div
                      key={index}
                      className={styles.option}
                      onClick={() => {
                        addProject(project.id);
                        setSearchVisible(false);
                      }}
                    >
                      {project.name}
                    </div>
                  )}
                </>
              ))}
            </div>
          </div>
        )}
      {/* <Button onClick={() => {searchOptions.refetch()}}>refetch</Button> */}
    </div>
  );
}

function AddTagsAndPrograms({
  chosenTags,
  chosenPrograms,
  addSurveyTag,
  addProgram,
}) {
  const [active, setActive] = useState(false);
  const [searchString, setSearchString] = useState("");

  const ref = useRef(null);
  const handleClickOutside = (event) => {
    if (ref.current) {
      if (!ref.current.contains(event.target)) {
        document.removeEventListener("click", handleClickOutside, true);
        setActive(false);
      }
    } else {
      document.removeEventListener("click", handleClickOutside, true);
    }
  };

  const setUpActive = () => {
    document.addEventListener("click", handleClickOutside, true);
    setActive(true);
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {};
  }, []);

  return (
    <>
      <FlexCol gap={"5px"}>
        <ChartLabel
          style={{
            paddingLeft: "5px",
          }}
        >
          Add Surveys By Program or Survey Tag
        </ChartLabel>

        <div className={styles.searchQuestionBox} ref={ref}>
          <input
            className={styles.searchQuestionInput}
            type="text"
            placeholder="search"
            onChange={(e) => setSearchString(e.target.value)}
            onFocus={setUpActive}
          ></input>
          {active && (
            <SearchTagsAndPrograms
              searchString={searchString}
              addProgram={addProgram}
              addSurveyTag={addSurveyTag}
              chosenTags={chosenTags}
              chosenPrograms={chosenPrograms}
            />
          )}
        </div>
      </FlexCol>
    </>
  );
}

function SearchTagsAndPrograms({
  searchString,
  addProgram,
  addSurveyTag,
  chosenTags,
  chosenPrograms,
}) {
  const getSurveyTags = useFetchSurveyTagsGql();
  const getPrograms = useFetchAllPrograms();

  function programs() {
    let programs = getPrograms.data.programs.filter(
      (p) => !chosenPrograms.some((prog) => prog.id === p.id)
    );
    if (searchString) {
      programs = programs.filter((p) =>
        p.mainProject.name.toLowerCase().includes(searchString.toLowerCase())
      );
    }
    return programs;
  }

  function tags() {
    let tags = getSurveyTags.data.surveyTagByOrgId.filter(
      (t) => !chosenTags.some((tag) => tag.id === t.id)
    );
    if (searchString) {
      tags = tags.filter((t) =>
        t.label.toLowerCase().includes(searchString.toLowerCase())
      );
    }
    return tags;
  }

  return (
    <div className={styles.optionsBox}>
      <div className={styles.options}>
        {getSurveyTags.isSuccess && getPrograms.isSuccess && (
          <>
            {programs().map((p) => (
              <div className={styles.option} onClick={() => addProgram(p)}>
                {p?.mainProject?.name}
                <div
                  className={`${styles.indicator} ${styles.programIndicator}`}
                >
                  Program
                </div>
              </div>
            ))}
            {tags().map((t) => (
              <div className={styles.option} onClick={() => addSurveyTag(t)}>
                {t?.label}
                <div
                  className={`${styles.indicator} ${styles.surveyTagIndicator}`}
                >
                  Tag
                </div>
              </div>
            ))}
          </>
        )}
      </div>
    </div>
  );
}

function PickQuestions({
  comparison,
  projects,
  removeProject,
  removeQuestion,
  addQuestion,
  questionType,
}) {
  function getProjectsInOrder() {
    let surveys = [...projects];

    surveys.sort((a, b) => {
      //Sort the projects by survey date
      if (a.startedAt === null || b.startedAt === null) {
        if (a.startedAt) return -1;
        if (b.startedAt) return 1;
        return 0;
      }
      let aDate = new Date(a.startedAt);
      let bDate = new Date(b.startedAt);
      return bDate - aDate;
    });

    for (let s of surveys) {
      s.question.sort((a, b) => {
        if (a.pageNumber === b.pageNumber) {
          return a.pageOrderIndex > b.pageOrderIndex ? 1 : -1;
        } else {
          return a.pageNumber > b.pageNumber ? 1 : -1;
        }
      });
    }

    return surveys;
  }
  return (
    <div className={styles.currentData}>
      {getProjectsInOrder().map((project, index) => (
        <div key={index} className={styles.projRow}>
          <Project
            comparison={comparison}
            project={project}
            removeProject={removeProject}
            addQuestion={addQuestion}
            removeQuestion={removeQuestion}
            questionType={questionType}
          ></Project>
        </div>
      ))}
    </div>
  );
}

function Project({
  comparison,
  project,
  removeProject,
  addQuestion,
  removeQuestion,
  questionType,
}) {
  const [showQuestions, setShowQuestions] = useState(false);
  const [addAll, setAddAll] = useState(false);

  function handleAddQ(q) {
    addQuestion(q);
    setShowQuestions(false);
  }

  const includedQs = project.question.filter((q) =>
    comparison.questionIds.includes(q.id)
  );

  return (
    <>
      <div className={styles.chosenSurvey}>
        <div className={styles.projectName}> {project.name} </div>

        <i className="bi bi-x-lg" onClick={() => removeProject(project)}></i>
      </div>

      <div className={styles.questionContainer}>
        {includedQs.map((question) => (
          <div key={question.id} className={styles.mid}>
            <div className={styles.midItem}>
              <>{question?.questionText}</>
            </div>

            <div
              className={styles.xicon}
              onClick={() => {
                removeQuestion(question);
              }}
            >
              <i className="bi bi-x-lg"></i>
            </div>
          </div>
        ))}
        <div
          className={styles.plusicon}
          onClick={() => setShowQuestions(true)}
          style={includedQs.length ? { alignSelf: "center" } : undefined}
        >
          <i className="bi bi-plus"></i>
          {!includedQs.length && <span>Question</span>}
        </div>
      </div>

      {showQuestions && (
        <Questions
          projectId={project.id}
          setShowQuestions={setShowQuestions}
          currQuestions={includedQs}
          questionType={questionType}
          addQuestion={handleAddQ}
          addAll={addAll}
        ></Questions>
      )}
    </>
  );
}

function Questions({
  projectId,
  setShowQuestions,
  currQuestions,
  questionType,
  addQuestion,
  addAll,
}) {
  const getQuestions = useFetchQuestionsGql(projectId);

  const ref = useRef(null);
  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setShowQuestions(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  function getUnpickedQs() {
    if (addAll) {
      forEach(getQuestions.data.QuestionByProjectID, (question) => {
        if (!currQuestions.some((q) => q.id === question.id)) {
          addQuestion(question);
        }
      });
      setShowQuestions(false);
      return [];
    } else {
      let unPicked = [];
      forEach(getQuestions.data.QuestionByProjectID, (question) => {
        if (!currQuestions.some((q) => q.id === question.id)) {
          unPicked.push(question);
        }
      });
      unPicked.sort((a, b) => {
        if (a.pageNumber != b.pageNumber) {
          return a.pageNumber - b.pageNumber;
        } else {
          return a.pageOrderIndex - b.pageOrderIndex;
        }
      });
      return unPicked;
    }
  }

  return (
    <div className={styles.optionsBox2} ref={ref}>
      {getQuestions.isLoading && <Loading></Loading>}
      {getQuestions.isSuccess && (
        <div className={styles.options}>
          {getUnpickedQs().map((q) => (
            <Q q={q} questionType={questionType} addQuestion={addQuestion} />
          ))}
        </div>
      )}
    </div>
  );
}

function Q({ q, questionType, addQuestion }) {
  const [prompt, setPrompt] = useState();

  const thisQsType = getQuestionType(q);

  function onNonClickableClick() {
    if (!prompt) {
      setPrompt(
        <div>
          Not of type:{" "}
          <span
            style={{
              fontSize: ".9em",
              color: "#a3a4a8",
              fontWeight: 500,
              paddingLeft: "4px",
            }}
          >
            {questionType}
          </span>
        </div>
      );

      setTimeout(() => setPrompt(null), 1500);
    } else {
      setPrompt(null);
    }
  }

  const goodToGo = thisQsType === questionType || !questionType;

  return (
    <div
      className={goodToGo ? styles.option : styles.nullOption}
      onClick={goodToGo ? () => addQuestion(q) : onNonClickableClick}
    >
      {q.questionText}
      <span className={styles.qType}>{thisQsType}</span>
      {prompt && <div className={styles.blur}>{prompt}</div>}
    </div>
  );
}
