/*
======================= START OF LICENSE NOTICE =======================
  Copyright (C) 2023 Reaction. All Rights Reserved

  NO WARRANTY. THE PRODUCT IS PROVIDED BY DEVELOPER "AS IS" AND ANY
  EXPRESS OR IMPLIED WARRANTIES, INCLUDING, BUT NOT LIMITED TO, THE
  IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR
  PURPOSE ARE DISCLAIMED. IN NO EVENT SHALL DEVELOPER BE LIABLE FOR
  ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, EXEMPLARY, OR CONSEQUENTIAL
  DAMAGES (INCLUDING, BUT NOT LIMITED TO, PROCUREMENT OF SUBSTITUTE
  GOODS OR SERVICES; LOSS OF USE, DATA, OR PROFITS; OR BUSINESS
  INTERRUPTION) HOWEVER CAUSED AND ON ANY THEORY OF LIABILITY, WHETHER
  IN CONTRACT, STRICT LIABILITY, OR TORT (INCLUDING NEGLIGENCE OR
  OTHERWISE) ARISING IN ANY WAY OUT OF THE USE OF THE PRODUCT, EVEN
  IF ADVISED OF THE POSSIBILITY OF SUCH DAMAGE.
======================== END OF LICENSE NOTICE ========================
  Primary Author: natehanson
*/
// External
import { useQueryClient } from "react-query";
import { gql } from "graphql-request";

// Internal
import { useGqlQuery, useGqlMutation } from "api/Api";

// GRAPHQL API

export const useFetchParticipations = () => {
  const mutation = gql`
    mutation Mutation($projectId: String!) {
      participations: getParticipationsByProjectMutation(
        projectId: $projectId
      ) {
        contact {
          firstName
          email
          id
          lastName
        }
        started
        completed
        abandoned
        link
      }
    }
  `;

  const queryClient = useQueryClient();
  const options = {
    onError: (err, _project, rollback) => {
      if (rollback) rollback();
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["participations"]);
    },
  };

  return useGqlMutation(mutation, options);
};

export const useFetchParticipationsByProject = (
  id,
  sort,
  pageSkip,
  perPage,
  searchString
) => {
  const query = gql`
    query Query(
      $projectId: String!
      $sort: SortSelectionDelivery!
      $page: Float!
      $perPage: Float!
      $search: String!
    ) {
      participations: getParticipationByProject(
        search: $search
        projectId: $projectId
        sort: $sort
        page: $page
        perPage: $perPage
      ) {
        contact {
          id
          email
          firstName
          lastName
          customField
        }
        abandoned
        completed
        started
        link
      }
    }
  `;

  return useGqlQuery("searchParts", query, {
    search: searchString,
    projectId: id,
    sort: sort,
    page: pageSkip,
    perPage: perPage,
  });
};

export const useFetchParticipationsByProjectCount = (
  id,
  sort,
  pageSkip,
  perPage,
  searchString
) => {
  const query = gql`
    query Query(
      $projectId: String!
      $sort: SortSelectionDelivery!
      $page: Float!
      $perPage: Float!
      $search: String!
    ) {
      count: getParticipationByProjectCount(
        search: $search
        projectId: $projectId
        sort: $sort
        page: $page
        perPage: $perPage
      )
    }
  `;

  return useGqlQuery("searchPartsCount", query, {
    search: searchString,
    projectId: id,
    sort: sort,
    page: pageSkip,
    perPage: perPage,
  });
};

export const useGetParticipation = () => {
  const mutation = gql`
    mutation Mutation($participationId: String!) {
      getParticipation(participationId: $participationId) {
        id
        abandoned
        completed
        started
        answers {
          participationId
          questionId
          scaleAnswer
          textAnswer
          choiceAnswerIndex
          choiceAnswer
          matrixAnswer
        }
      }
    }
  `;

  const options = {
    onError: (err, _project, rollback) => {
      if (rollback) rollback();
    },
  };

  return useGqlMutation(mutation, options);
};

export const useCreateParticipationFromAud = () => {
  const mutation = gql`
    mutation Mutation($projectId: String!, $audienceIds: audienceIDs!) {
      createParticipationFromAud(
        projectId: $projectId
        audienceIds: $audienceIds
      )
    }
  `;

  const options = {
    onError: (err, _project, rollback) => {
      if (rollback) rollback();
    },
  };

  return useGqlMutation(mutation, options);
};

export const useCreateParticipation = () => {
  const mutation = gql`
    mutation CreateParticipation($data: participationInput!) {
      createParticipation(data: $data) {
        id
        abandoned
        completed
        started
      }
    }
  `;

  const queryClient = useQueryClient();
  const options = {
    onError: (err, _project, rollback) => {
      if (rollback) rollback();
    },
    onSettled: (data) => {
      queryClient.invalidateQueries("searchParts");
    },
  };

  return useGqlMutation(mutation, options);
};

export const useCreateAnonParticipation = () => {
  const mutation = gql`
    mutation CreateAnonymousParticipation($projectId: String!) {
      part: createAnonymousParticipation(projectId: $projectId) {
        id
        abandoned
        completed
        started
      }
    }
  `;

  const queryClient = useQueryClient();
  const options = {
    onError: (err, _project, rollback) => {
      if (rollback) rollback();
    },
    onSettled: (data) => {
      queryClient.invalidateQueries("participations");
    },
  };

  return useGqlMutation(mutation, options);
};

export const useSaveDistId = () => {
  const mutation = gql`
    mutation SaveDistId($distId: String!, $partId: String!) {
      saveDistId(distId: $distId, partId: $partId)
    }
  `;

  return useGqlMutation(mutation);
};
