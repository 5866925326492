import { Accordian } from "../Accordian";
import FlexCol from "components/layouts/FlexColumn/FlexCol";
import styles from "./Examples.module.scss";
import {
  choiceColor,
  funcColor,
  numColor,
  opColor,
  optionColor,
  parenthColors,
  tagColor,
  varColor,
} from "pages/organization/calculations/Formula/Formula";

export function Examples({ show, setShow, isNPS, tags }) {
  return (
    <Accordian show={show} setShow={setShow} title="Examples" symbol={"NPS"}>
      <Inside isNPS={isNPS} tags={tags} />
    </Accordian>
  );
}

function Inside({ isNPS, tags }) {
  const examples = [
    {
      formula: [
        <>
          <Var>promoters</Var> <Op>=</Op>{" "}
          <Fn name="count">
            <Tag>NPS</Tag>
            <Op>?</Op>
            <Ch>10</Ch>
            <Op> or </Op>
            <Tag>NPS</Tag>
            <Op>?</Op>
            <Ch>9</Ch>
          </Fn>
        </>,
        <>
          <Var>detractors</Var> <Op>=</Op>{" "}
          <Fn name="count">
            <Tag>NPS</Tag>
            <Op>{" < "}</Op> <Num>7</Num>
          </Fn>
        </>,
        <>
          <Var>entries</Var> <Op>=</Op>{" "}
          <Fn name="count">
            <Tag>NPS</Tag>
          </Fn>
        </>,
        <>
          <Var>nps</Var> <Op>=</Op>{" "}
          <Pr>
            <Var>promoters</Var> <Op>-</Op> <Var>detractors</Var>
          </Pr>{" "}
          <Op>/</Op> <Var>entries</Var> <Op>*</Op> <Num>100</Num>
        </>,
      ],

      desc: "Returns the net promoter score",
    },
    {
      formula: [
        <>
          <Var>a</Var> <Op>=</Op> <Num>5</Num> <Op>+</Op> <Num>7</Num>
        </>,
        <>
          <Var>b</Var> <Op>=</Op> <Var>a</Var> <Op>/</Op> <Num>30</Num>
        </>,
        <>
          <Var>c</Var> <Op>=</Op> <Var>b</Var> <Op>*</Op> <Var>a</Var>
        </>,
      ],
      desc: "Returns c (4.8)",
    },
  ];

  function getExamples() {
    const ex = [];

    let scaleTag = tags.find(
      (tag) =>
        tag.type === "Number Scale" &&
        tag.question.length &&
        tag.question[0].scaleQuestion
    );
    let choiceTag = tags.find(
      (tag) =>
        tag.type === "Multiple Choice" &&
        tag.question.length &&
        tag.question[0].choiceQuestion
    );

    if (scaleTag) {
      ex.push({
        formula: [
          <>
            <Fn name="avg">
              <Tag>{scaleTag.name}</Tag>
            </Fn>
          </>,
        ],
        desc: "Returns the average score",
      });

      let { min, max } = { ...scaleTag.question[0].scaleQuestion };
      let mid = Math.round((max - min) / 2);
      ex.push({
        formula: [
          <>
            <Fn name="count">
              <Fn name="every" num={1}>
                <Tag>{scaleTag.name}</Tag> <Op>{">"}</Op> <Num>{mid}</Num>
              </Fn>
            </Fn>
          </>,
        ],
        desc: `Counts every answer given above ${mid}`,
      });
    }

    if (choiceTag) {
      let choices = choiceTag.question[0].choiceQuestion.choices;
      ex.push({
        formula: [
          <>
            <Var>first</Var> <Op>=</Op>{" "}
            <Fn name="count">
              <Tag>{choiceTag.name}</Tag>
              <Op>?</Op>
              <Ch>{choices[0]}</Ch>
            </Fn>
          </>,
          <>
            <Var>second</Var> <Op>=</Op>{" "}
            <Fn name="count">
              <Tag>{choiceTag.name}</Tag>
              <Op>?</Op>
              <Ch>{choices[1]}</Ch>
            </Fn>
          </>,
          <>
            <Var>result</Var> <Op>=</Op> <Var>first</Var> <Op>-</Op>{" "}
            <Var>second</Var>
          </>,
        ],
        desc: "Returns how many more times the first option was chosen than the second",
      });
    }

    if (scaleTag) {
      ex.push({
        formula: [
          <>
            <Fn name="max">
              <Tag>{scaleTag.name}</Tag>
            </Fn>{" "}
            <Op>-</Op>{" "}
            <Fn name="min">
              <Tag>{scaleTag.name}</Tag>
            </Fn>
          </>,
        ],
        desc: "Returns the spectrum of answers given",
      });
    }

    return [...ex, ...(isNPS ? examples.slice(1) : examples)];
  }

  return (
    <>
      <FlexCol gap={"10px"}>
        {getExamples().map((ex) => (
          <FlexCol>
            <div className={styles.ex}>
              Ex:
              <FlexCol>
                {ex.formula.map((line) => (
                  <div className={styles.formula}>{line}</div>
                ))}

                <div className={styles.desc}>{ex.desc}</div>
              </FlexCol>
            </div>
          </FlexCol>
        ))}
      </FlexCol>
    </>
  );
}

function Fn({ name, children, num = 0 }) {
  return (
    <>
      <span style={{ color: funcColor }}>{name}</span>

      <Pr num={num}>{children}</Pr>
    </>
  );
}

function Op({ children }) {
  return <span style={{ color: opColor }}>{children}</span>;
}

function Pr({ num = 0, children }) {
  return (
    <>
      <span style={{ color: parenthColors[num] }}>{"("}</span>
      {children}
      <span style={{ color: parenthColors[num] }}>{")"}</span>
    </>
  );
}

function Tag({ children }) {
  return <span style={{ color: tagColor }}>{children}</span>;
}

function Opt({ children }) {
  return <span style={{ color: optionColor }}>{children}</span>;
}

function Ch({ children }) {
  return <span style={{ color: choiceColor }}>{children}</span>;
}

function Num({ children }) {
  return <span style={{ color: numColor }}>{children}</span>;
}

function Var({ children }) {
  return <span style={{ color: varColor }}>{children}</span>;
}
