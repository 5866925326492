import { opColor } from "pages/organization/calculations/Formula/Formula";
import { Accordian } from "../Accordian";
import styles from "./Operators.module.scss";
import FlexCol from "components/layouts/FlexColumn/FlexCol";

export function Operators({ show, setShow }) {
  const operatorCategories = [
    {
      category: "Arithmetic",
      i: "plus-slash-minus",
      operators: [
        { op: "+", desc: "add" },
        { op: "-", desc: "subtract" },
        { op: "*", desc: "multiply" },
        { op: "/", desc: "divide" },
        {
          op: "%",
          desc: "modulo",
          hint: "gives the remainder",
          ex: "3 % 2 = 1",
        },
      ],
    },

    {
      category: "True or False",
      i: "question",
      operators: [
        { op: "<", desc: "less than" },
        { op: "<=", desc: "less than or equal to" },
        { op: ">", desc: "greater than" },
        { op: ">=", desc: "greater than or equal to" },
        { op: "==", desc: "equals" },
        { op: "!=", desc: "does not equal" },
        {
          op: "?",
          desc: (
            <span>
              answered <span className={styles.blank}>{"(blank)"}</span>?
            </span>
          ),
          ex: "NPS?10",
        },
        { op: "or", desc: "left or right" },
        { op: "and", desc: "left and right" },
      ],
    },

    {
      category: "Assignment",
      i: "box-arrow-in-left",
      operators: [
        {
          op: "=",
          desc: "left assigned to right",
          ex: "a = NPS / 10",
        },
      ],
    },
  ];

  return (
    <Accordian
      show={show}
      setShow={setShow}
      title={"Operators"}
      symbol={"+ / -"}
    >
      <FlexCol gap={"10px"}>
        {operatorCategories.map((cat) => (
          <FlexCol gap="5px">
            <div className={styles.category}>
              {cat.category} {cat.i && <i className={`bi-${cat.i}`}></i>}
            </div>

            <table>
              {cat.operators.map((op) => (
                <tr>
                  <td className={styles.op} style={{color: opColor}}>{op.op}</td>
                  <td>
                    <FlexCol>
                      <div className={styles.desc}>{op.desc}</div>

                      {op.hint && (
                        <div className={styles.hint}>{`-  (${op.hint})`}</div>
                      )}
                      {op.ex && <div className={styles.ex}>{op.ex}</div>}
                    </FlexCol>
                  </td>
                </tr>
              ))}
            </table>
          </FlexCol>
        ))}
      </FlexCol>
    </Accordian>
  );
}
