import TextEditorTry from "components/inputs/input_fields/TextEditorTry/TextEditorTry";

export const SurveyLogicSection = ({ section, faded }) => {
  return (
    <TextEditorTry
      encoding={section.encoding}
      style={{ color: faded ? "#a3a4a8" : undefined, cursor: "pointer" }}
    />
  );
};
