import React from "react";

import Modal from "components/ReactModal/ReactModal";
import styles from "./AddContact.module.scss";
import blankProfile from "assets/images/blank-profile-picture.png";
import { ContactLayout } from "components/Popout/ContactLayout";

export function AddContact({ onClose}) {
  
  return (
    <>
      <Modal
        onClose={onClose}
        show
        modalStyle={{
          borderRadius: "7px",
          height: "100%",
          width: "100%",
        }}
        dark
      >
        <div className={styles.container} id="New Contact Input">
          <div className={styles.header}>
            <img src={blankProfile} className={styles.profile} />

            <div
              className={`${styles.header_3} ${styles.title}`}
              style={{ margin: "0" }}
            >
              New Contact Input
            </div>
          </div>
          <ContactLayout newContact contact={{id: "", email: "", phone: "", firstName: "", lastName: "", attribute: []}}></ContactLayout>

          
          <div id="bottom of container"></div>
        </div>
      </Modal>
    </>
  );
}

