import { useState } from "react";
import { DataLabels } from "./AccordianItems/DataLabels";
import { LegendSettings } from "./AccordianItems/LegendSettings";
import { TitleSettings } from "./AccordianItems/TitleSettings";
import { Axes } from "./AccordianItems/Axes";
import styles from "./SettingsAccordion.module.scss";
import {
  ValueType,
  TableType,
  MetricType,
  VertBarType,
  splitSomehow,
  LineType,
  HorizBarType,
} from "../QChart/QuestionChart";
import { SplitsNPivotsAccordion } from "./AccordianItems/SplitsNPivots";
import { FilterAccordian } from "./AccordianItems/FilterSettings";
import { SortingSettings } from "./AccordianItems/SortingSettings";
import { TableColumns } from "./AccordianItems/TableColumns";
import { TextBoxSettings } from "./AccordianItems/TextBoxSettings";
import { HorizontalBar } from "components/layouts/HorizontalBar/HorizontalBar";
import { ChartType } from "./AccordianItems/ChartType";
import { ColorSettings } from "./AccordianItems/ColorSettings";
import { AdvancedSettings } from "./AccordianItems/AdvancedSettings";
import TableChart from "components/Charts/Table/TableChart";
import { LayoutSettings } from "./AccordianItems/LayoutSettings";
import { DataSettings } from "./AccordianItems/DataSettings";
import MetricSettings from "./AccordianItems/MetricSettings/MetricSettings";
import { combineFilters } from "../Visualization";
import { TablePivotsAccordian } from "./AccordianItems/TablePivots";
import { StatSplitsNPivotsAccordian } from "./AccordianItems/StatSplitsNPivots";
import { AdvancedStatSettings } from "./AccordianItems/AdvancedStatSettings";
import { useFetchFilterableCustomFields } from "api/resources/organization/organization";
import { Loading } from "components/Loading/Loading";

export function splitting(viz) {
  if (viz.dataMethod === "survey") {
    return (
      viz.pivotString ||
      viz.designSettings.split ||
      viz.designSettings.useComparison
    );
  } else {
    return splitSomehow(viz) || viz.designSettings.useComparison;
  }
}

/**
 *
 * @param {object} viz the visualization object
 * @param {function} updateSettings function to change the design settings
 * @param {function} updateType function to change the type of the chart
 * @param {function} updateTitle function to change the visualization title
 * @param {function} changeData fiunction to change the
 * @returns
 */

export const SettingsAccordion = ({
  viz,
  updateViz,
  labels,
  lockData,
  projects,
  custom_fields,
  onFilterChange,
  newChart,
  dataPage,
  setDataPage,
  externalFilter,
  hasData,
  visibleItems,
  setVisible,
}) => {
  // const getRole = useFetchQChartRoles();
  const fetchPivotableFields = useFetchFilterableCustomFields();

  const scoreType = viz.type === MetricType || viz.type === ValueType;

  const changeSettingsField = (field, val) => {
    let newViz = { ...viz };
    newViz.designSettings[field] = val;
    updateViz(newViz);
  };

  const changeVizField = (field, val) => {
    let newViz = { ...viz };
    newViz[field] = val;
    updateViz(newViz);
  };

  const [originalColors, setOriginalColors] = useState(
    viz.designSettings.colors
      ? {
          colors: viz.designSettings.colors,
          split: viz.designSettings.split,
          pivot: viz.pivotString,
          filters: viz.filters,
        }
      : undefined
  );

  function changePage(dPage) {
    setDataPage(dPage);
  }

  function toggle(val) {
    visibleItems === val ? setVisible("") : setVisible(val);
  }

  return (
    <>
      {!scoreType && (
        <div className={styles.navContainer}>
          <div className={styles.nav} style={{ flexDirection: "column" }}>
            <div className={styles.nav} style={{ justifyContent: "end" }}>
              <div className={styles.nav2}>
                <div
                  className={`${styles.navItem} ${
                    dataPage ? styles.active : ""
                  }`}
                  onClick={() => changePage(true)}
                >
                  <i className="bi-receipt-cutoff"></i>Data
                </div>
                <HorizontalBar width={5} height={25} rotate />
                <div
                  className={`${styles.navItem} ${
                    !dataPage ? styles.active : ""
                  }`}
                  onClick={hasData ? () => changePage(false) : undefined}
                >
                  <i className="bi-brush"></i>Style
                </div>
              </div>
            </div>

            <div
              style={{
                display: "flex",
                width: "100%",
                maxWidth: "315px",
                justifyContent: "space-around",
              }}
            >
              {dataPage && (
                <>
                  <HorizontalBar
                    height={3}
                    width={"27%"}
                    backgroundColor={"#15bcc7"}
                    rotate
                  />
                  <HorizontalBar
                    height={3}
                    width={"32%"}
                    backgroundColor={"transparent"}
                    rotate
                  />
                </>
              )}{" "}
              {!dataPage && (
                <>
                  <HorizontalBar
                    height={3}
                    width={"32%"}
                    backgroundColor={"transparent"}
                    rotate
                  />
                  <HorizontalBar
                    height={3}
                    width={"27%"}
                    backgroundColor={"#15bcc7"}
                    rotate
                  />
                </>
              )}
            </div>
          </div>
        </div>
      )}
      <div className={styles.accordion}>
        {dataPage && (
          <>
            {fetchPivotableFields.isLoading && <Loading />}
            {fetchPivotableFields.isSuccess && (
              <>
                <DataSettings // columns
                  viz={viz}
                  title="Data"
                  visible={visibleItems === "Data"}
                  setVisible={() => toggle("Data")}
                  changeSettingsField={changeSettingsField}
                  updateViz={updateViz}
                  projects={projects}
                  newChart={newChart}
                  lockData={lockData}
                />
                <FilterAccordian // columns
                  viz={viz}
                  projects={projects}
                  visible={visibleItems === "FilterAccordian"}
                  setVisible={() => toggle("FilterAccordian")}
                  onFilterChange={onFilterChange}
                  changeSettingsField={changeSettingsField}
                  externalFilter={externalFilter}
                />
                {viz.dataMethod === "survey" && (
                  <>
                    <StatSplitsNPivotsAccordian
                      viz={viz}
                      updateViz={updateViz}
                      projects={projects}
                      custom_fields={fetchPivotableFields.data.fields}
                      originalColors={originalColors}
                      visible={visibleItems === "SplitsNPivots"}
                      setVisible={() => toggle("SplitsNPivots")}
                    ></StatSplitsNPivotsAccordian>

                    {hasData && (
                      <AdvancedStatSettings
                        viz={viz}
                        updateViz={updateViz}
                        changeSettingsField={changeSettingsField}
                        projects={projects}
                        visible={visibleItems === "AdvancedSettings"}
                        setVisible={() => toggle("AdvancedSettings")}
                      ></AdvancedStatSettings>
                    )}
                  </>
                )}
                {viz.dataMethod !== "survey" && (
                  <>
                    {viz.type === TableType ? (
                      <>
                        {!viz.designSettings.toLast && (
                          <TablePivotsAccordian
                            viz={viz}
                            updateViz={updateViz}
                            changeSettingsField={changeSettingsField}
                            custom_fields={fetchPivotableFields.data.fields}
                            projects={projects}
                            visible={visibleItems === "SplitsNPivots"}
                            setVisible={() => toggle("SplitsNPivots")}
                          />
                        )}
                      </>
                    ) : (
                      <SplitsNPivotsAccordion
                        viz={viz}
                        visible={visibleItems === "SplitsNPivots"}
                        setVisible={() => toggle("SplitsNPivots")}
                        changeSettingsField={changeSettingsField}
                        updateViz={updateViz}
                        projects={projects}
                        custom_fields={fetchPivotableFields.data.fields}
                      />
                    )}
                    <AdvancedSettings
                      viz={viz}
                      updateViz={updateViz}
                      visible={visibleItems === "AdvancedSettings"}
                      setVisible={() => toggle("AdvancedSettings")}
                      filters={combineFilters(viz, externalFilter)}
                      changeSettingsField={changeSettingsField}
                    />
                  </>
                )}
              </>
            )}
          </>
        )}
        {!dataPage && (
          <>
            {viz.type === TableType && hasData && (
              <TableColumns // layout-text-window-reverse
                viz={viz}
                title="Table Columns"
                changeSettingsField={changeSettingsField}
                updateViz={updateViz}
                visible={visibleItems === "Table"}
                setVisible={() => toggle("Table")}
                projects={projects}
                custom_fields={custom_fields}
              ></TableColumns>
            )}

            {!scoreType && viz.type !== TableType && (
              <ChartType // pie-chart
                viz={viz}
                title="Chart Type"
                updateViz={updateViz}
                visible={visibleItems === "ChartType"}
                setVisible={() => toggle("ChartType")}
              />
            )}

            {scoreType && (
              <>
                <MetricSettings
                  title="Metrics"
                  changeSettings={changeSettingsField}
                  updateViz={updateViz}
                  settings={viz.designSettings}
                  viz={viz}
                  visible={visibleItems === "MetricSettings"}
                  setVisible={() => toggle("MetricSettings")}
                />
                <FilterAccordian // funnel-fill      person-lines-fill
                  viz={viz}
                  visible={visibleItems === "Filters"}
                  setVisible={() => toggle("Filters")}
                  projects={projects}
                  onFilterChange={onFilterChange}
                  changeSettingsField={changeSettingsField}
                  externalFilter={externalFilter}
                />
              </>
            )}

            <LayoutSettings // columns
              viz={viz}
              title="Layout"
              visible={visibleItems === "LayoutSettings"}
              setVisible={() => toggle("LayoutSettings")}
              changeSettingsField={changeSettingsField}
              projects={projects}
            />

            {!scoreType && (
              <ColorSettings // bar-chart-fill
                viz={viz}
                changeSettingsField={changeSettingsField}
                labels={labels}
                updateViz={updateViz}
                visible={visibleItems === "Colors"}
                setVisible={() => toggle("Colors")}
              />
            )}
            {!scoreType && (
              <>
                {(viz.type === VertBarType ||
                  viz.type === HorizBarType ||
                  viz.type === LineType) &&
                  labels && (
                    <Axes // graph-up
                      viz={viz}
                      title="Axes"
                      changeSettingsField={changeSettingsField}
                      updateViz={updateViz}
                      visible={visibleItems === "Axes"}
                      setVisible={() => toggle("Axes")}
                      labels={labels}
                    />
                  )}
              </>
            )}

            {hasData && (
              <>
                {!scoreType && (
                  <TitleSettings //input-cursor-text    input cursor
                    viz={viz}
                    title="Title" //"Chart
                    changeVizField={changeVizField}
                    changeSettingsField={changeSettingsField}
                    visible={visibleItems === "Title"}
                    setVisible={() => toggle("Title")}
                    updateViz={updateViz}
                  ></TitleSettings>
                )}

                {!scoreType && viz.type !== TableType && (
                  <>
                    <LegendSettings // list-ul    map     menu-app-fill
                      viz={viz}
                      title="Legend"
                      changeSettingsField={changeSettingsField}
                      visible={visibleItems === "Legend"}
                      setVisible={() => toggle("Legend")}
                    ></LegendSettings>
                    <DataLabels // 2-circle-fill    superscript
                      viz={viz}
                      title="Data Labels"
                      changeSettingsField={changeSettingsField}
                      changeVizField={changeVizField}
                      visible={visibleItems === "Labels"}
                      setVisible={() => toggle("Labels")}
                    ></DataLabels>
                    {labels && (
                      <SortingSettings // sort-alpha-down    or     sort-down
                        viz={viz}
                        title="Sorting"
                        changeSettingsField={changeSettingsField}
                        labels={labels}
                        visible={visibleItems === "SortingSettings"}
                        setVisible={() => toggle("SortingSettings")}
                      ></SortingSettings>
                    )}
                  </>
                )}
              </>
            )}
            <TextBoxSettings // sort-alpha-down    or     sort-down
              viz={viz}
              title="Text Boxes"
              changeSettingsField={changeSettingsField}
              visible={visibleItems === "TextBoxSettings"}
              setVisible={() => toggle("TextBoxSettings")}
            ></TextBoxSettings>
          </>
        )}
      </div>
    </>
  );
};

function ManualDataTable({ viz }) {
  const [data, setData] = useState(getData(viz.data));

  function getData(data) {
    let tempData = data;
    while (typeof tempData === "string") {
      tempData = JSON.parse(tempData);
    }

    return tempData;
  }

  return (
    <>
      <TableChart manual chartData={data} spreadsheetmode />
    </>
  );
}
