//External
import React, { useEffect, useState } from "react";

//internal
import styles from "./Register.module.scss";
import Button from "components/Button/Button";
import { useNavigate } from "react-router-dom";
import FlexRow from "components/layouts/FlexRow/FlexRow";
import { HorizontalBar } from "components/layouts/HorizontalBar/HorizontalBar";
import { Footer } from "App";
import FlexCol from "components/layouts/FlexColumn/FlexCol";
import { RegisterRoutes } from "routes/RegisterRoutes";

export function Register({ join }) {
  let navigate = useNavigate();

  const [activeStep, setActiveStep] = useState(0);

  const [org, setOrg] = useState({});
  const [registerType, setRegisterType] = useState("Trial");

  const [steps, setSteps] = useState(initSteps());

  useEffect(() => {
    setSteps(initSteps());
  }, [registerType]);

  function initSteps() {
    if (registerType === "Trial") {
      return [
        {
          id: 0,
          name: "Get Started",
          description: "Start your account creation",
          icon: "question-circle",
          done: false,
        },

        {
          id: 1,
          name: "Your details",
          description: "Provide an email and password",
          icon: "person",
          done: false,
        },
        {
          id: 2,
          name: "Verify your email",
          description: "Enter your verification code",
          icon: "envelope",
          done: false,
        },
        {
          id: 3,
          name: "Your organization",
          description: "About your organization",
          icon: "building",
          done: false,
        },
        // {
        //   id: 4,
        //   name: "Payment Information",
        //   description: "Provide a payment type",
        //   icon: "cash",
        //   done: false,
        // },
        {
          id: 4,
          name: "Welcome to your new account",
          description: "Get up and running in 5 minutes",
          icon: "rocket",
          done: false,
        },
      ];
    }

    if (registerType === "Join") {
      return [
        {
          id: 1,
          name: "Join Organization",
          description: "Provide a join code, create or use existing account",
          icon: "person",
          done: activeStep === 1,
        },
        {
          id: 2,
          name: "Verify your email",
          description: "Enter your verification code",
          icon: "envelope",
          done: activeStep === 2,
        },
        // {
        //   id: 3,
        //   name: "Your organization",
        //   description: "About your organization",
        //   icon: "building",
        //   done: false,
        // },
        // {
        //   id: 4,
        //   name: "Payment Information",
        //   description: "Provide a payment type",
        //   icon: "cash",
        //   done: false,
        // },
        {
          id: 4,
          name: "Welcome to your new account",
          description: "Get up and running in 5 minutes",
          icon: "rocket",
          done: activeStep === 4,
        },
      ];
    }
  }

  return (
    <div className={styles.page}>
      <FlexRow
        style={{ height: "100%", overflow: "auto" }}
        align={"flex-start"}
      >
        {window.innerWidth > 800 && (
          <div className={styles.navProcess}>
            <FlexCol gap={"0rem"}>
              <div
                className={styles.imageContainer}
                style={{ marginBottom: "4rem" }}
              >
                <img
                  src={require("assets/images/reactionlogonew.png")}
                  width={"200px"}
                />
              </div>

              {steps.map((step, i) => (
                <FlexRow align={"flex-start"} key={i}>
                  <FlexCol fit>
                    <Button
                      style={{ border: "1px solid lightgray" }}
                      height={"40px"}
                      width={"40px"}
                    >
                      <i
                        className={`bi-${step?.icon}`}
                        style={{
                          fontSize: "1rem",
                          color: !step?.done ? "gray" : "",
                        }}
                      ></i>
                    </Button>
                    {i < steps?.length - 1 && (
                      <HorizontalBar
                        rotate
                        style={{ margin: "0", marginLeft: "1.15rem" }}
                        height={"50px"}
                        width={1}
                        backgroundColor={"lightgray"}
                      ></HorizontalBar>
                    )}
                  </FlexCol>
                  <FlexCol fit>
                    <div
                      className={styles.header_6}
                      style={{ margin: "0", color: !step?.done ? "gray" : "" }}
                    >
                      {step?.name}
                    </div>
                    <div
                      className={styles.text_3}
                      style={{ color: !step?.done ? "gray" : "" }}
                    >
                      {step?.description}
                    </div>
                  </FlexCol>
                </FlexRow>
              ))}
            </FlexCol>
            <div>
              <div
                style={{
                  display: "flex",
                  gap: ".5rem",
                  alignItems: "center",
                  justifyContent: "center",
                  flexWrap: "wrap",
                }}
              >
                Already have an account?
                <div
                  style={{
                    display: "flex",
                    gap: ".5rem",
                    alignItems: "center",
                    flexWrap: "wrap",
                  }}
                >
                  <Button
                    onClick={() => navigate("/login")}
                    type="submit"
                    shadow
                    blue
                  >
                    Sign In
                  </Button>
                  or{" "}
                  <Button
                    onClick={() => {
                      navigate("/register/join-organization"),
                        location.reload();
                    }}
                    type="submit"
                    shadow
                    blue
                  >
                    Join Organization
                  </Button>
                </div>
              </div>
              <div className={styles.register}>
                Request a demo or learn more at{" "}
                <a href="https://reactiondata.com" className={styles.aLink}>
                  {" "}
                  reactiondata.com
                </a>
                .
              </div>
            </div>
          </div>
        )}
        <RegisterRoutes
          steps={steps}
          setSteps={setSteps}
          activeStep={activeStep}
          setActiveStep={setActiveStep}
          org={org}
          setOrg={setOrg}
          type={registerType}
          setType={setRegisterType}
        ></RegisterRoutes>
        
      </FlexRow>
      <Footer></Footer>
    </div>
  );
}

export function useToken() {
  const getToken = () => {
    const tokenString = localStorage.getItem("reaction_token");
    if (tokenString != undefined) {
      return JSON.parse(tokenString);
    }
    return null;
  };

  const [token, setToken] = useState(getToken());

  const saveToken = (userToken) => {
    localStorage.setItem("reaction_token", JSON.stringify(userToken));
    setToken(userToken);
  };

  return {
    setToken: saveToken,
    token,
  };
}
