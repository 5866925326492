import Modal from "components/ReactModal/ReactModal";
import styles from "./CreateUser.module.scss";
import { SelectFieldCustom, TextEntry } from "components/inputs";
import { useState } from "react";
import { useFetchRolesGql } from "api/resources/organization/roles";
import FlexCol from "components/layouts/FlexColumn/FlexCol";
import FlexRow from "components/layouts/FlexRow/FlexRow";
import Button from "components/Button/Button";
import { HorizontalBar } from "components/layouts/HorizontalBar/HorizontalBar";
import { isValidEmail } from "assets/functions/StringFunctions";
import { Loading } from "components/Loading/Loading";
import { useCreateUser } from "api/resources/organization/organization";
import profPic from "assets/images/blank-profile-picture.png";

export function CreateUser({ onClose, onDone, orgId }) {
  const fetchRoles = useFetchRolesGql(orgId);
  const create = useCreateUser();
  const [error, setError] = useState("");

  const [user, setUser] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    roleId: "",
  });
  const [pswdCopy, setPswdCopy] = useState("");
  const [creating, setCreating] = useState(false);

  function changeField(field, val) {
    let copy = { ...user };
    copy[field] = val;
    setUser(copy);
  }

  function getRole() {
    if (user.roleId) {
      let role = fetchRoles.data.rolesByOrgId.find((r) => r.id === user.roleId);
      if (role) {
        return {
          value: role.id,
          label: role.name,
        };
      }
    }
    return null;
  }

  function filled() {
    return (
      user.firstName &&
      user.lastName &&
      user.email &&
      isValidEmail(user.email) &&
      user.roleId &&
      user.password &&
      user.password === pswdCopy
    );
  }

  function onCreate() {
    if (!creating) {
      create.mutate(
        {
          data: user,
        },
        {
          onSuccess: (data) => {
            console.log("User Created");
            setCreating(false);
            onDone();
            onClose();
          },
          onError: (data) => {
            setError(data?.response?.errors[0]?.message);
            setCreating(false);
          },
        }
      );

      setCreating(true);
      if (error) setError("");
    }
  }

  return (
    <Modal
      show
      onClose={onClose}
      modalStyle={{
        padding: "1.5rem",
        borderRadius: ".5rem",
        minWidth: "350px",
      }}
      dark
    >
      <div className={styles.container}>
        <FlexRow justify={"flex-start"} gap={20}>
          <img src={profPic} className={styles.prof}></img>
          <div className={styles.title}>Create User</div>
        </FlexRow>

        <HorizontalBar height={"3px"}></HorizontalBar>

        <TextEntry
          value={user.firstName}
          onSave={(val) => changeField("firstName", val)}
          label={"First Name"}
          blue
        />

        <TextEntry
          value={user.lastName}
          onSave={(val) => changeField("lastName", val)}
          label={"Last Name"}
          blue
        />

        <TextEntry
          value={user.email}
          onChange={user.email ? (val) => changeField("email", val) : undefined}
          onSave={(val) => changeField("email", val)}
          label={"Email"}
          valid={user.email ? isValidEmail(user.email) : undefined}
          blue
        />

        {fetchRoles.isSuccess && (
          <SelectFieldCustom
            options={fetchRoles.data.rolesByOrgId.map((role, index) => ({
              value: role.id,
              label: role.name,
            }))}
            value={getRole()}
            onChange={(role) => changeField("roleId", role.value)}
            label="Role"
          ></SelectFieldCustom>
        )}

        <HorizontalBar height={"3px"}></HorizontalBar>

        <TextEntry
          label={
            <FlexRow gap={5}>
              Password <span className={styles.forThem}>{"(for them)"}</span>
            </FlexRow>
          }
          value={user.password}
          onChange={(val) => changeField("password", val)}
          valid={user.password ? user.password.length >= 8 : undefined}
          invalidMessage={"8 or more characters"}
          blue
          obscure
        />

        <TextEntry
          label="Confirm Password"
          value={pswdCopy}
          onChange={setPswdCopy}
          valid={user.password ? user.password === pswdCopy : undefined}
          blue
          obscure
        />

        <HorizontalBar height={"3px"}></HorizontalBar>

        {error && <div className={styles.error}>{error}</div>}

        <FlexRow justify={"center"} style={{ paddingTop: "15px" }}>
          <Button
            shadow
            blue
            disable={!filled()}
            onClick={onCreate}
            style={{ gap: "7px" }}
          >
            {creating ? (
              <>
                <Loading height={25} width={25}></Loading> Creating
              </>
            ) : (
              <>+ Create</>
            )}
          </Button>
        </FlexRow>
      </div>
    </Modal>
  );
}
