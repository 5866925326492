// External
import { useQueryClient } from "react-query";
import { gql } from "graphql-request";

// Internal
import { useGqlQuery, useGqlMutation } from "api/Api";


export const useFetchErrorContactsByUpload = (id) => {
    const query = gql`
      query {
        output: getTempContacts(id: "${id}") {
          headers {
            id
            name
            accessor
            canSort
            enabled
            cell_style
            value
            editable
            dataType
          }
          contacts {
            id
            firstName
            lastName
            state
            flags
            customField
            email
          }
        }
      }
    `;
    return useGqlQuery(["temperrorcontactsbyupload" + id, id], query, {});
  };