// External
import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import styles from "./Deliveries.module.scss";

// Internal
import {
  useGetDeliveryStats,
  useSearchDeliveries,
} from "api/resources/projects/deliveries";

import Modal from "components/ReactModal/ReactModal";
import { useFetchRole } from "api/resources/organization/roles";
import { Loading } from "components/Loading/Loading";
import { GetContact } from "components/Popout/ContactProfile";
import { trimDate } from "../distributions/EmailBuilder/Confirmation/Confirmation";
import Section from "components/layouts/Section/Section";
import FlexRow from "components/layouts/FlexRow/FlexRow";
import FlexCol from "components/layouts/FlexColumn/FlexCol";
import { Pie } from "components/inputs/input_fields/CircleProgress/Circle";
import { engagementsviz } from "assets/functions/Variables";
import CustomLegend from "components/Charts/Legend";
import { DoughnutChart } from "components/Charts/DoughnutChart/DoughnutChart";
import { BasicTable } from "components/tables/BasicTable/BasicTable";
import { NavTextField } from "components/inputs/input_fields/NavTextField/NavTextField";
import Button from "components/Button/Button";
import Icon from "components/Icon/Icon";
import { SelectFieldCustom } from "components/inputs";
import { ExportEmails } from "./ExportDeliveries/ExportEmails";

export const Deliveries = ({ email, onClose, onExport }) => {
  const { id } = useParams();
  const [headers] = useState(initHeaders());
  const [exportEmails, setExportEmails] = useState(false);
  const [contact, setContact] = useState();

  function initHeaders() {
    let heads = [
      {
        id: "email",
        value: "email",
        name: "email",
        accessor: "email",
        label: "Email",
        // width: 250,
        dataType: "text",
        enabled: true,
        sort: true,
        canSort: true,
        cell_style: null,
        editable: true,
      },
      {
        id: "date",
        value: "date",
        name: "Date",
        accessor: "date",
        label: "Date",
        // width: 100,
        dataType: "text",
        enabled: true,
        sort: false,
        canSort: true,
        editable: true,
        cell_style: (date) => <div>{date && trimDate(date)}</div>,
      },
      {
        id: "status",
        value: "status",
        name: "Status",
        // accessor: "status",
        label: "Status",
        // width: 80,
        dataType: "text",
        enabled: true,
        sort: true,
        canSort: true,
        cell_style: (delivery) => (
          <div>{delivery && delivery[0].toUpperCase() + delivery.slice(1)}</div>
        ),
      },
      {
        id: "error",
        value: "error",
        name: "error",
        accessor: "error",
        label: "Reason",
        width: 100,
        dataType: "text",
        enabled: true,
        sort: false,
        canSort: true,
        editable: true,
      },
      {
        id: "opens",
        value: "opens",
        name: "Opens",
        accessor: "opens",
        label: "Opens",
        // width: 100,
        dataType: "text",
        enabled: true,
        sort: false,
        canSort: true,
        editable: true,
      },
      {
        id: "clicks",
        value: "clicks",
        name: "Clicks",
        accessor: "clicks",
        label: "Clicks",
        // width: 100,
        dataType: "text",
        enabled: true,
        sort: false,
        canSort: true,
        editable: true,
      },
    ];
    return heads;
  }

  const [search, setSearch] = useState("");
  const [searched, setSearched] = useState("");
  const perPage = 50;
  const [pageNumber, setPageNumber] = useState(1);
  const [sort, setSort] = useState({
    item: "date",
    descend: true,
  });
  const [send, setSend] = useState(email?.sendCount);
  const [sendOptions, setSendOptions] = useState([]);
  const statsQuery = useGetDeliveryStats(id, email.id, search);
  const {
    data,
    isLoading,
    isSuccess,
    isError,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    refetch,
    remove,
  } = useSearchDeliveries(search, perPage, sort, id, email.id, send);

  const fetchRole = useFetchRole();
  const deliveries =
    data?.pages.flatMap((page) => page?.response?.deliveries) || [];
  const count = data?.pages?.length > 0 ? data?.pages[0]?.response?.count : 0;

  function handleNextpage(nextPage) {
    if (hasNextPage) {
      fetchNextPage();
      setPageNumber(nextPage);
    }
  }

  function searchItem() {
    setSearched(search);
    remove(); // Remove cached query state
    refetch(); // Refetch with new search text
    statsQuery.remove();
    statsQuery.refetch();
  }

  useMemo(() => {
    let options = [];
    for (let i = 1; i <= email?.sendCount; i++) {
      options.push({ value: i, label: `Send ${i}` });
    }
    setSendOptions(options);
  }, []);

  const actionOptions = [
    {
      onClick: (data) => {
        setContact({ id: data?.contactId, email: data?.email });
      },
      data: (
        <>
          <Icon blue iconName={"eye"}></Icon> View Contact
        </>
      ),
    },
    // {
    //   onClick: () => undefined,
    //   data: (
    //     <>
    //       <Icon red iconName={"slash-circle"}></Icon> Cancel Send
    //     </>
    //   ),
    // },
  ];

  return (
    <>
      {!contact && !exportEmails && (
        <Modal
          show
          onClose={onClose}
          modalStyle={{
            borderRadius: "1em",
            height: "95vh",
            width: "95vw",
            maxWidth: "100%",
            overflow: "hidden",
            padding: "2rem",
            gap: "1rem",
            paddingBottom: "0",
          }}
          dark
        >
          <>
            <FlexRow
              align={"flex-start"}
              justify={"space-between"}
              style={{ flexWrap: "wrap-reverse" }}
            >
              <FlexCol gap={".5rem"} style={{ flex: 1 }}>
                <FlexRow gap={".5rem"} fit>
                  <h2>Emails</h2>
                </FlexRow>
                <FlexRow
                  gap={".5rem"}
                  style={{ flexWrap: "wrap-reverse", rowGap: "1rem" }}
                >
                  <FlexRow gap={".5rem"} style={{ flex: 1 }}>
                    <NavTextField
                      shadow
                      value={search}
                      setValue={setSearch}
                      placeholder={"Search status or email..."}
                      handlePressEnter={() => searchItem()}
                    ></NavTextField>
                    <Button shadow blue onClick={() => searchItem()}>
                      Search
                    </Button>
                  </FlexRow>
                  <FlexRow fit>
                    <Button
                      style={{ gap: ".5rem" }}
                      onClick={() => setExportEmails(true)}
                    >
                      <i className="bi-cloud-download"></i>Export
                    </Button>
                    <div style={{ fontSize: ".8rem" }}>
                      <SelectFieldCustom
                        value={{ value: send, label: `Send ${send}` }}
                        onChange={(val) => {
                          setSend(val.value);
                          remove();
                          refetch();
                        }}
                        noCreate
                        options={sendOptions}
                        containerStyle={{
                          height: "2.3rem",
                          padding: "0 .5rem",
                        }}
                      ></SelectFieldCustom>
                    </div>
                  </FlexRow>
                </FlexRow>
              </FlexCol>
              <FlexRow fit style={{ flexWrap: "wrap" }}>
                <Section
                  childrenStyle={{
                    height: "130px",
                    width: "350px",
                    boxSizing: "border-box",
                  }}
                >
                  {(isLoading || statsQuery.isLoading) && (
                    <Loading height={110} width={110}></Loading>
                  )}
                  {isSuccess && statsQuery.isSuccess && (
                    <FlexRow style={{ height: "100%" }} align={"flex-start"}>
                      <FlexCol>
                        <div
                          className={`${styles.header_2} ${styles.blue}`}
                          style={{ margin: "0", marginBottom: "-.25rem" }}
                        >
                          {(
                            (statsQuery?.data?.stats?.opens / count) *
                            100
                          ).toFixed(2)}
                          %
                        </div>
                        <div className={styles.text_2}>Opened Email</div>
                        <div className={styles.text_3}>
                          <span className={`${styles.blue}`}>
                            {statsQuery?.data?.stats?.opens}
                          </span>{" "}
                          / {count}
                        </div>
                      </FlexCol>
                      <div>
                        <Pie
                          percentage={(
                            (statsQuery?.data?.stats?.opens / count) *
                            100
                          ).toFixed(2)}
                          backColor={"lightgray"}
                          progress={(
                            (statsQuery?.data?.stats?.opens / count) *
                            100
                          ).toFixed(2)}
                          strokeWidth={".75rem"}
                          colour={"#15bcc7"}
                          // hideText
                        ></Pie>
                      </div>
                    </FlexRow>
                  )}
                </Section>
                <Section
                  childrenStyle={{
                    height: "130px",
                    width: "350px",
                    boxSizing: "border-box",
                  }}
                >
                  {(isLoading ||
                    fetchRole.isLoading ||
                    statsQuery.isLoading) && (
                    <Loading height={110} width={110}></Loading>
                  )}
                  {isSuccess && statsQuery.isSuccess && fetchRole.isSuccess && (
                    <StatsChart
                      viz={engagementsviz}
                      filters={"[]"}
                      role={fetchRole?.data?.role}
                      stats={statsQuery?.data?.stats}
                    ></StatsChart>
                  )}
                </Section>
              </FlexRow>{" "}
            </FlexRow>
            {isLoading && <Loading></Loading>}
            {isSuccess && (
              <BasicTable
                initheaders={headers}
                data={deliveries}
                // paginateScroll
                sort={sort}
                setSort={(newSort) => {
                  setSort(newSort);
                  remove();
                  refetch();
                }}
                scrollPage
                pageNum={pageNumber}
                setPageNum={handleNextpage}
                maxItems={count}
                fullLoading={isFetchingNextPage}
                actionCell
                actionOptions={actionOptions}
              ></BasicTable>
            )}
            <div className={styles.viewing}>
              <span>
                Viewing {pageNumber * perPage} of {count}
              </span>
            </div>
          </>
        </Modal>
      )}

      {contact && fetchRole.isSuccess && (
        <GetContact
          onClose={() => setContact(false)}
          contact={contact}
          editable={fetchRole?.data?.role?.canEditContacts}
        ></GetContact>
      )}
       {exportEmails && fetchRole.isSuccess && (
        <ExportEmails
          onClose={() => setExportEmails(false)}
          name={email?.subject}
          search={search}
          sort={sort}
          headers={headers}
          contact={contact}
          onExport={onExport}
          projectId={id}
          send={send}
          distId={email?.id}
        />
      )}
    </>
  );
};

function StatsChart({ viz, role, filters, stats }) {
  const [data, setData] = useState();

  useEffect(() => {
    const labels = [
      { label: "Delivered", value: "delivered" },
      { label: "Dropped", value: "dropped" },
      { label: "Blocked", value: "blocked" },
      { label: "Bounced", value: "bounced" },
      { label: "Marked Spam", value: "spam" },
      { label: "Deferred", value: "deferred" },
    ];
    let labelArray = [];
    let data = [];
    // labels.sort((a, b) => a.label - b.label);
    for (let label of labels) {
      data.push(stats[label?.value]);
      labelArray.push(label?.label);
    }

    let dataset = {
      data: data,
      label: undefined,
      backgroundColor: [
        "#58b368",
        "#d8d9d9",
        "#ff523b",
        "#faca50",
        "#ed9146",
        "#5a8181",
        ,
      ],
      borderWidth: 0.7,
    };

    setData({ datasets: [dataset], labels: labelArray });
  }, []);
  return (
    <FlexRow justify={"space-around"} align={"flex-start"}>
      <div style={{ width: "140px", height: "100px" }}>
        {data && (
          <DoughnutChart
            settings={viz.designSettings}
            filters={filters}
            role={role}
            data={data}
            onSegClick={() => undefined}
            responsiveFonts={() => undefined}
          />
        )}
      </div>
      <div style={{ height: "110px" }}>
        {data && (
          <CustomLegend
            viz={viz}
            data={data}
            settings={viz.designSettings}
            visible={[true, true, true, true, true, true]}
            onClick={() => undefined}
          ></CustomLegend>
        )}
      </div>
    </FlexRow>
  );
}
