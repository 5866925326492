import React from "react";
import styles from "./EditField.module.scss";
import FlexCol from "components/layouts/FlexColumn/FlexCol";
import FlexRow from "components/layouts/FlexRow/FlexRow";

function EditField({
  style,
  label,
  value,
  icon,
  iconDelete,
  onClickEdit,
  onClickContainer,
  onClickDelete,
}) {
  return (
    <FlexCol style={{ height: "fit-content", width: "100%", gap: ".2rem", flex: 1 }}>
      {label && <div className={styles.label6}>{label}</div>}
      <FlexRow align={"flex-start"}>
        <div
          className={`${styles.container}`}
          style={style}
          onClick={onClickContainer}
        >
          {value}
        </div>
        {(onClickEdit || onClickDelete) && (
          <FlexCol fit gap={".5rem"}>
            {onClickEdit && (
              <div className={styles.editBox} onClick={onClickEdit}>
                <i className={`bi-${icon ? icon : "pencil"}`}></i>
              </div>
            )}
            {onClickDelete && (
              <div className={styles.editBoxDelete} onClick={onClickDelete}>
                <i className={`bi-${iconDelete ? iconDelete : "trash"}`}></i>
              </div>
            )}
          </FlexCol>
        )}
      </FlexRow>
    </FlexCol>
  );
}

export default EditField;
