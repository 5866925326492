import { useFetchPageProjects } from "api/resources/projects/projects";
import Filter from "../Charts/Settings/Filter/Filter";
import { Loading } from "components/Loading/Loading";

export function PageFilter({
  onFilterChange,
  chosenFilter,
  analysisId,
}) {
  // function getQueryData() {  // OLD, keeping here just in case
  //   let data = {
  //     projectIds: [],
  //     dynamics: [],
  //   };

  //   for (let visualization of page.visualization) {
  //     let viz = parsedViz(visualization);
  //     let supplement;
  //     if (viz.type === MetricType || viz.type === ValueType) {
  //       supplement = getScoreChartSurveyPullData(viz);
  //     } else if (viz.dataMethod === "survey") {
  //       supplement = getStatChartSurveyPullData(viz);
  //     } else {
  //       data.projectIds = [...data.projectIds, ...combinedProjIds(viz)];
  //     }

  //     if (supplement) {
  //       data.projectIds = [...data.projectIds, ...supplement.projectIds];
  //       data.dynamics = [...data.dynamics, ...supplement.dynamics];
  //     }
  //   }

  //   return data;
  // }

  const getProjects = useFetchPageProjects(analysisId);

  return (
    <>
      {getProjects.isError && <div>Error</div>}
      {getProjects.isLoading && <Loading />}
      {getProjects.isSuccess && (
        <Filter
          updateChosenFilters={onFilterChange}
          chosenFilter={chosenFilter}
          projects={getProjects.data.survey}
        ></Filter>
      )}
    </>
  );
}
