import React, { useEffect, useState } from "react";
import styles from "./Register.module.scss";
import FlexRow from "components/layouts/FlexRow/FlexRow";
import FlexCol from "components/layouts/FlexColumn/FlexCol";
import { HorizontalBar } from "components/layouts/HorizontalBar/HorizontalBar";
import { SelectFieldCustom, TextFieldSimple } from "components/inputs";
import Button from "components/Button/Button";
import { maintimezones } from "assets/functions/Variables";
import { useNavigate, useParams } from "react-router-dom";
import {
  useCheckOrgName,
  useCreateOrgainization,
} from "api/resources/organization/organization";
import EditField from "components/inputs/input_fields/EditField/EditField";
import Modal from "components/ReactModal/ReactModal";
import AddressInput from "components/inputs/input_fields/AddressInput/AddressInput";
import { useCheckUserToken } from "api/resources/authentication/register";
import TokenExpired from "pages/error_page/TokenExpired";
import { Loading } from "components/Loading/Loading";
import Checkbox from "components/inputs/input_fields/CheckboxBlue/Checkbox";

function YourOrganization({
  steps,
  setSteps,
  activeStep,
  setActiveStep,
  setOrg,
  org,
}) {
  const { token } = useParams();
  const checkTokenService = useCheckUserToken();
  const navigate = useNavigate();
  const [valid, setValid] = useState(true);
  const [message, setMessage] = useState("");
  const [user, setUser] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    checkTokenService.mutate(
      {
        token: token,
      },
      {
        onSuccess: (data) => {
          setLoading(false);
          if (data?.status?.join) {
            setValid(false);
            setMessage("Page not valid");
          } else if (data?.status?.status === 2) {
            setUser({email: data?.status?.email, id: data?.status?.id});
          } else if (data?.status?.status === 1) {
            navigate(`/register/verify-email/${token}`);
          } else {
            setValid(false);
            setMessage(data?.status?.description);
          }
        },
      }
    );
  }, []);

  return valid ? (
    <>
      {loading && <Loading></Loading>}
      {!loading && (
        <YourOrganizationContent
          setSteps={setSteps}
          steps={steps}
          activeStep={activeStep}
          setActiveStep={setActiveStep}
          org={org}
          setOrg={setOrg}
          user={user}
          token={token}
        />
      )}
    </>
  ) : (
    <TokenExpired message={message}>
      <Button blue width={200} shadow onClick={() => navigate("/register")}>
        Create new account
      </Button>
    </TokenExpired>
  );

  return;
}

export default YourOrganization;

function YourOrganizationContent({
  steps,
  setSteps,
  activeStep,
  setActiveStep,
  setOrg,
  org,
  token,
  user,
}) {
  const [validOrgRules, setValidOrgRules] = useState([]);
  const [editAddress, setEditAddress] = useState(false);
  const [working, setWorking] = useState(false);
  const navigate = useNavigate();
  const checkOrgMutation = useCheckOrgName();
  const createOrgMutation = useCreateOrgainization();

  function handleCheckOrgName(orgName) {
    checkOrgMutation.mutate(
      {
        name: orgName,
      },
      {
        onSuccess: (data) => {
          if (data?.exists) {
            setValidOrgRules([
              { rule: "Organization name already exists", valid: false },
            ]);
          } else {
            setValidOrgRules([]);
            setOrg({ ...org, name: orgName });
          }
        },
      }
    );
  }

  function createOrganization() {
    setWorking(true);
    const users = JSON.stringify([{ email: user?.email, id: user?.id, role: "Admin" }]);
    createOrgMutation.mutate(
      {
        users: users,
        meAdmin: false,
        data: {
          name: org?.name,
          displayName: org?.name,
          timezone: org?.timezone?.timezone,
          defaultLocale: undefined,
          subdomain: org?.domain,
          customFields: undefined,
          responsesAllowed: 0,
          adminsAllowed: 1,
          usersAllowed: 3,
          surveysAllowed: 0,
          contactsAllowed: 0,
          reportsAllowed: 0,
          isLiscense: false,
          planPrice: "500$",
          planFrequency: 1,
          requireMFA: org?.requireMFA,
        },
      },
      {
        onSuccess: (data) => {
          console.log(data?.organization);
          setWorking(false);
          navigate(`/register/welcome-to-reaction/${token}`);
        },
      }
    );
  }

  useEffect(() => {
    const browserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const matchedTimezone = maintimezones.find(
      (zone) => zone.timezone === browserTimezone
    );

    setOrg({ ...org, timezone: matchedTimezone || null }); // Set null if no match is found
  }, []);

  useEffect(() => {
    let temp = [...steps];
    temp[3].done = true;
    temp[2].done = false;
    setSteps(temp);
    setActiveStep(3);
  }, []);

  function onChangeBilling(val, field) {
    let edits = { ...org };
    edits[field] = val;
    setOrg(edits);
  }

  return (
    <FlexRow>
      <FlexCol style={{ padding: "2rem" }}>
        <FlexRow>
          <img
            width={"50px"}
            src={require("assets/images/circlelogo.png")}
          ></img>
          <div>
            <div
              className={styles.header_4}
              style={{
                margin: "0",
                fontWeight: "400",
              }}
            >
              Your Organization & Payment Details
            </div>
            <div className={styles.text_2} style={{}}>
              Provide your organization and payment information below.
            </div>
          </div>
        </FlexRow>

        <HorizontalBar height={2}></HorizontalBar>

        <FlexCol gap={"1rem"} style={{ maxWidth: "600px" }}>
          <div
            className={styles.header_4}
            style={{ margin: "0", marginTop: "1rem" }}
          >
            Organization
          </div>
          <TextFieldSimple
            type="text"
            value={org?.name}
            label="Organization Name*"
            valid={validOrgRules?.length === 0 || !validOrgRules}
            invalidMessage="Name already exists"
            placeholder="Untitled Organization, LLC"
            onBlur={handleCheckOrgName}
            validationRules={validOrgRules}
            validationRulesTitle=""
          />
          <SelectFieldCustom
            style={{
              topMargin: "15px",
            }}
            label="Timezone"
            options={maintimezones}
            customstyles={{
              border: "black",
            }}
            onChange={(value) => {
              setOrg({ ...org, timezone: value });
            }}
            value={org?.timezone}
            noCreate
          ></SelectFieldCustom>
          <TextFieldSimple
            type="url"
            onChange={(value) => {
              setOrg({ ...org, domain: value });
            }}
            value={org?.domain}
            label="Website or Domain*"
            placeholder="untitled.com"
          />
          <FlexRow>
            <Checkbox
              checked={org?.requireMFA}
              onChange={(e) => {
                let temp = { ...org, requireMFA: e.target.checked };
                setOrg(temp);
              }}
            ></Checkbox>{" "}
            <div className={styles.text_2}>Require Multi Factor for users</div>
          </FlexRow>
          <div
            className={styles.header_4}
            style={{ margin: "0", marginTop: "1rem" }}
          >
            Payment
          </div>
          <EditField
            label={"Billing Address*"}
            onClickEdit={() => setEditAddress(true)}
            value={
              org?.billingAddress1
                ? `${org?.billingAddress1}${
                    org?.billingAddress2 ? "," + org?.billingAddress2 : ""
                  } ${org?.billingCity}, ${org?.billingState} ${
                    org?.billingPostalCode
                  } \n ${org?.billingCountry}`
                : "No Address"
            }
          ></EditField>
          <EditField
            label="Payment Method"
            value={"No Mayment Method"}
            onClickEdit={() => undefined}
          ></EditField>
          <FlexRow
            gap={"2rem"}
            style={{ marginTop: "1rem" }}
            justify={"center"}
          >
            <Button
              shadow
              width={150}
              onClick={() => {
                let temp = [...steps];
                temp[activeStep - 1].done = true;
                temp[activeStep].done = false;
                setSteps(temp);
                setActiveStep(activeStep - 1);
                navigate("/register/account-type");
              }}
            >
              Previous
            </Button>
            <Button
              blue
              shadow
              width={150}
              disable={!org?.name || validOrgRules?.length > 0 || !org?.domain}
              onClick={createOrganization}
            >
              {!working ? (
                "Continue"
              ) : (
                <>
                  Working
                  <span className={styles.dots}>
                    <span>.</span>
                    <span>.</span>
                    <span>.</span>
                  </span>
                </>
              )}
            </Button>
          </FlexRow>
        </FlexCol>
        {editAddress && (
          <Modal
            dark
            show={editAddress}
            onClose={() => setEditAddress(false)}
            modalStyle={{ padding: "2rem", height: "600px", width: "800px" }}
          >
            <div
              className={styles.header_4}
              style={{ margin: "0", marginBottom: "1rem" }}
            >
              Billing Address
            </div>
            <AddressInput
              org={org}
              onChange={onChangeBilling}
              billing
            ></AddressInput>
            <FlexRow justify={"center"} style={{ padding: "2rem 0" }}>
              <Button
                blue
                shadow
                width={100}
                onClick={() => setEditAddress(false)}
              >
                Done
              </Button>
            </FlexRow>
          </Modal>
        )}
        {window.innerWidth <= 800 && (
          <>
            <div
              style={{
                display: "flex",
                gap: ".5rem",
                alignItems: "center",
                justifyContent: "center",
                flexWrap: "wrap",
                marginTop: "4rem",
              }}
            >
              Already have an account?
              <div
                style={{
                  display: "flex",
                  gap: ".5rem",
                  alignItems: "center",
                  flexWrap: "wrap",
                }}
              >
                <Button
                  onClick={() => navigate("/login")}
                  type="submit"
                  shadow
                  blue
                >
                  Sign In
                </Button>
                or{" "}
                <Button
                  onClick={() => {
                    navigate("/register/join-organization"), location.reload();
                  }}
                  type="submit"
                  shadow
                  blue
                >
                  Join Organization
                </Button>
              </div>
            </div>
            <div className={styles.register}>
              Request a demo or learn more at{" "}
              <a href="https://reactiondata.com" className={styles.aLink}>
                {" "}
                reactiondata.com
              </a>
              .
            </div>
          </>
        )}
      </FlexCol>
    </FlexRow>
  );
}
