import React from "react";
import styles from "./UGSidebar.module.scss";
import { MenuItem } from "./MenuItem";
import Button from "components/Button/Button";
import { Loading } from "components/Loading/Loading";

/**
 * A generic sub sidebar specifically designed for the UserGroup page. You can add more sidebar Items with onNewClick
 * @param {Array} menuItems array of objects that have the menuItems and their paths to go to
 * @param {Int} active id of the active menuItem
 * @param {Function} updateActive a function that is called when a menuItem is clicked on. Updates the active id
 * @param {String} type a string that defines the new type that will added to the sidebar menu when the + New button is clicked
 * @param {Function} onNewClick function that is called when a the + New button is clicked
 * @returns {React.ReactElement} a Sidebar2 component
 */

export const UGSidebar = ({
  menuItems,
  active,
  updateActive,
  type,
  onNewClick,
  canEdit,
  creating,
}) => {
  return (
    <div className={styles.menu}>
      {menuItems
        .sort((a, b) =>
          a.isAdmin
            ? -1
            : b.isAdmin
            ? 1
            : new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
        )
        .map((menuItem) => (
          <MenuItem
            key={menuItem.id}
            id={menuItem.id}
            label={menuItem.name}
            condensed
            isActive={active == menuItem.id}
            makeActive={(id) => {
              updateActive(id);
            }}
            description={menuItem.description}
          />
        ))}
      <div className={styles.addRole}>
        <Button
          blue
          shadow
          onClick={onNewClick}
          disable={!canEdit}
          style={{ gap: "7px" }}
        >
          {creating ? (
            <>
              <Loading height={25} width={25}></Loading> Creating
            </>
          ) : (
            <>
              {" "}
              <i className={"bi bi-plus"}></i> New Role
            </>
          )}
        </Button>
      </div>
      {/* <li className={styles.addRole} onClick={onNewClick}><button>+ New {type}</button></li> */}
    </div>
  );
};
