import {
  funcColor,
  parenthColors,
} from "pages/organization/calculations/Formula/Formula";
import { Accordian } from "../Accordian";
import styles from "./Functions.module.scss";
import FlexCol from "components/layouts/FlexColumn/FlexCol";

export function Functions({ show, setShow }) {
  ["not", "avg", "max", "min", "sum", "count", "every"];

  const functions = [
    { name: "avg", desc: "average" },
    { name: "sum", desc: "total" },
    { name: "max", desc: "maximum value" },
    { name: "min", desc: "minimum value" },
    { name: "count", desc: "counts valid occurences" },
    {
      name: "not",
      desc: "flips the validity of what's inside",
      ex: "not(true) = false",
    },
    {
      name: "every",
      desc: "iterates over every answer each contact's given, not just the most recent",
    },
  ];

  return (
    <Accordian show={show} setShow={setShow} title={"Functions"} symbol={"f()"}>
      <table>
        {functions.map((func) => (
          <tr>
            <td className={styles.name} style={{ color: funcColor }}>
              {func.name}

              <span style={{ color: parenthColors[0] }}>{"()"}</span>
            </td>
            <td>
              <FlexCol className={styles.desc}>
                {func.desc}{" "}
                {func.ex && <div className={styles.ex}>{func.ex}</div>}
              </FlexCol>
            </td>
          </tr>
        ))}
      </table>
    </Accordian>
  );
}
